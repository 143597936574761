.pup-section {
    padding: 40px 50px 30px;
    font-size: 26px;
}

.pup-cards {
    display: flex;
}

.pup-title {
    font-size: 24px;
    margin-bottom: 20px;
}

.pup-card {
    display: inline-flex;
    flex-direction: column;
    justify-content: space-between;
    width: 220px;
    border: 1px solid rgba(0,0,0,0.1);
    border-radius: 12px;
    padding: 20px;
    box-sizing: border-box;
    margin-right: 15px;
    transition: none;
    .top {
        flex: 1;
        display: flex;
        flex-direction: column;
    }
    img {
        width: 50px;
        height: 50px;
        border-radius: 14px;
        object-fit: cover;
    }
    .name {
        font-size: 17px;
        font-weight: 500;
        margin: 15px 0 0px;
    }
    .description {
        color: #a2aab6;
        font-size: 15px;
    }
    &:hover {
        border-color: #cce2ed;
        box-shadow: 0 4px 14px 0 rgba(5, 73, 209, 0.1);
    }
    &.disabled {
        pointer-events: none;
        opacity: 0.3;
    }
}

.integration-status {
    font-size: 16px;
    color: #2ecc71;
    margin-top: 20px;
    i {
        margin-right: 10px;
    }
}
