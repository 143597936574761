.page-layout.profile {
    height: 100vh;
    padding-bottom: 0;
    // background: #e4e7ef;

    .people-data {
        background-color: transparent;
        position: relative;
        min-width: 380px;

        .user-card-basic {
            display: flex;
            flex-direction: column;
            .user-image-big {
                height: 90px;
                width: 90px;
                margin-bottom: 15px;
                z-index: 1;
                border: 6px solid white;
            }
            .user-card-name {
                font-size: 22px;
                color: #2c3b48;
            }
        }
    }
    .people-data-bg {
        background-color: #3498db !important;
        background-size: 200px 200px !important;
        width: 100%;
        position: absolute;
        top: 0;
        left: 0;
        height: 80px;
    }
    .chat-header, .people-data-bg {
        border-radius: 5px 5px 0 0;
    }
    
    .chat-header {
        justify-content: flex-end;
        font-size: 14px;
        .select-space {
            margin-left: 10px;
        }
    }
    .chat-box {
        border-radius: 0 0 5px 5px;
        background: #F2F5F7;
    }
}

.people-name-email {
    display: flex;
    flex-direction: column;
    margin-left: 10px;
    > span:last-child {
        opacity: 0.4 !important;
        font-size: 15px;
        margin-top: 2px;
        // width: calc(100% - 90px);
        max-width: 260px;
        overflow: hidden;
        text-overflow: ellipsis;
    }
}

.compact-user-inbox.active .people-name-email {
    span:first-child {
        font-weight: 500;
    }
}

.segment-title {
    color: #575C62;
    margin-left: 10px;
}


/* Timeline
==================================== */
.timeline-wrap {
    // background-color: white;
    // height: 100%;
    width: 360px;
    min-width: 360px;
    box-sizing: border-box;
    // padding: 20px;

    .box-header {
        border-bottom: solid 1px #e8e8e8;
        background-color: #F6F8FA;
        padding: 19px 15px;
        border-radius: 5px 5px 0 0;
        h3 {
            padding-left: 20px;
            text-transform: none;
            padding-bottom: 0;
            margin: 0;
            border: none;
        }
    }
    .box-space {
        padding: 20px;
        height: auto;
    }
}

.timeline-space {
    // padding: 20px;
    box-sizing: border-box;
    position: relative;
}

.timeline-event {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    margin-bottom: 15px;
}

.timeline-date-space {
    display: flex;
}

.timeline-event-date {
    position: relative;
    color: #5D657A;
    border-radius: 24px;
    background-color: #D5E0EF;
    font-weight: 500;
    padding: 3px 15px;
    margin: 0 27px;
    font-size: 12px;
    margin-bottom: 16px;
}

.timeline-event-icon i {
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
    color: #fff;
    font-size: 10px;
    border: 2px solid #FFF;
    border-radius: 50%;
    background-color: #e74c3c;
    padding: 4px;
    width: 24px;
    height: 24px;
    box-sizing: border-box;
    border: 4px solid #fff;
    margin: -7px 0 0 10px;
}

.timeline-event-details {
    font-size: 12px;
    color: #1F2B36;
    display: flex;
    flex-direction: column;
    margin-left: 12px;
    word-break: break-all;
    .top {
        margin-bottom: 7px;
    }
}

.timeline-event-time {
    font-size: 12px;
    color: #7C818B;
    width: 97px;
    white-space: nowrap;
    min-width: 50px;
    text-align: right;
}

.timeline-line {
    position: absolute;
    border-right: 2px solid #D5E0EF;
    left: 70px;
    height: 100%;
    top: 0;
}

.timeline-event-content {
    display: flex;
}

.timeline-line-end {
    background: #D5E0EF;
    height: 10px;
    width: 10px;
    border-radius: 50%;
    margin-left: 66px;
    margin-top: 10px;
}