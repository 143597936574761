.normal-switch {
    display: flex;
    align-items: center;

    .label-input {
        margin-bottom: 0;
        margin-left: 10px;
    }
}

// .react-switch-bg {
//     background-color: #e9e9e9 !important;
// }
.react-switch-handle {
    box-shadow: 0 1px 6px 0 rgba(0,0,0,.25);
}

.switch-field {
    display: flex;
    align-items: center;
    .sf-left {
        flex: 1;
        display: flex;
        flex-direction: column;
        line-height: 18px;
    }
    .sf-label {
        font-size: 16px;
    }
    .sf-description {
        color: #9b9c9e;
    }
}