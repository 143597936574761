.list-heading {
    display: flex;
    align-items: center;
    margin-top: -1px;
    padding-bottom: 3px;
    min-height: 31px;

    h2 {
        margin-right: 20px;
    }
}