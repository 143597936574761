.info-box {
    border: 1px solid #0000001a;
    border-radius: 4px;
    width: 100%;

    .editMode {
        background-color: transparent;
    }
}

.info-box-title {
    display: flex;
    border-radius: 4px 4px 0 0;
    align-items: center;
    justify-content: center;
    background-color: #dfe3e6;
    text-align: center;
    padding: 6px 0;
    // border-bottom: 1px solid rgba(0, 0, 0, 0.1);
    font-size: 13px;
    font-weight: 500;
    i { 
        margin-right: 10px;
    }
}

.info-box-content {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 10px;
}

.info-separator {
    height: 20px;
    width: 1px;
    background-color: rgba(0,0,0,0.15);
    margin: 0 15px;
}

.agent-not-assign {
    // color: rgba(0,0,0,0.6);
    color: #7F8FA4;
}

.agent-assigned {
    color: rgba(0,0,0,0.8);
    display: flex;
    align-items: center;
    img {
        border-radius: 50%;
        width: 27px;
        min-width: 27px;
        height: 27px;
        margin-right: 12px;
    }
}