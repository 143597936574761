.checkbox-space {
    display: flex;
    // align-items: center;
    font-size: 14px;
    margin-bottom: 7px;
    &.disabled {
        opacity: 0.3;
        pointer-events: none;
    }
}

.checkbox-box {
    cursor: pointer;
    width: 18px;
    height: 18px;
    margin-right: 10px;
    float: left;
    position: relative;
    transition: all .25s cubic-bezier(0,0,0,1);
}

.checkbox-description {
    line-height: 21px;
    cursor: pointer;
}

.checkbox-group {
    // margin-bottom: 30px;    
    // .checkbox-group-title {
    //     width: 100%;
    //     margin-bottom: 10px;
    //     font-weight: 600;
    //     font-size: 14px;
    //     color: #1F2B36;
    //     display: block;
    // }
    .checkbox-space {
        margin-bottom: 10px;
        margin-left: 10px;
    }
    
    .help-icon {
        margin-left: 3px;
    }
}

.rc-checkbox-input, .rc-checkbox-inner  {
    width: 18px;
    height: 18px;

}

.rc-checkbox-inner {
    transition: none;
    margin: 0;
    &:after {
        left: 6px !important;
        top: 2px !important;
    }
}

.rc-checkbox-checked .rc-checkbox-inner {
    border-color: #36b37e;
    background-color: #36b37e;
}