.page-layout.settings {
    flex-direction: column;
    padding-left: 15px;
    padding-right: 15px;
    padding-bottom: 60px;
    .title {
        font-size: 14px;
        text-transform: uppercase;
        font-weight: 700;
        margin: 10px 0 12px;
    }

    .settings-section:first-child {
        padding-top: 30px;
    }
}

.page-layout.has-subheader {
    flex-direction: column;
}

.settings-row {
    display: flex;
    flex-wrap: wrap;
}

.settings-card {
    margin: 8px;
    // background: #f5f5f5;
    border: 1px solid #f5f5f5;
    border-radius: 5px;
    width: 150px;
    min-height: 150px;
    cursor: pointer;
    // box-shadow: 0 2px 2px rgba(36, 37, 38, 0.08);
    transition: box-shadow 0.25s ease, transform 0.25s ease;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    padding: 0 10px;
    box-sizing: border-box;

    img {
        width: 60px;
        height: 60px;
        margin-top: -8px;
        transition-duration: 0.3s;
    }
    .settings-card-title {
        color: #2c3b48;
        font-size: 11px;
        padding-top: 16px;
        text-transform: uppercase;
        line-height: 16px;
        text-align: center;
        font-weight: 500;
    }

    &:hover {
        background: #ebebeb;
        // box-shadow: 5px 12px 20px rgba(36, 37, 38, 0.13);
        font-weight: 500;
        img {
            transform: scale(1.1) rotate(4deg);
        }
    }
}

.settings-section.separator-top {
    margin-top: 30px;
    padding-top: 30px;
    // border-top: 1px dotted #ccc;
}

.settings-section {
    padding: 0 15px;
    &:first {
        padding-top: 30px;
    }
}

/* Settings Header
========================== */
.settings-header {
    // background-color: #f8f9fb;
    background-color: $background-light;
    padding: 40px 50px;
    // box-shadow: 0 0 4px rgba(0, 0, 0, 0.12);
    margin-bottom: 30px;
    border-bottom: 1px solid #dfe3e6;
    .midcol {
        display: flex;
        align-items: center;
    }
    img {
        width: 64px;
        height: 64px;
    }
    .right {
        display: flex;
        flex-direction: column;
        margin-left: 20px;
        margin-top: -10px;
    }
    .more-right {
        margin-left: auto;
        flex-shrink: 0;
        margin-top: -30px;
    }
    .title {
        font-size: 18px;
        margin: 0 0 3px;
        font-weight: 500;
        color: #1f2b36;
        text-transform: uppercase;
    }
    .subtitle {
        font-size: 14px;
        color: #455a64;
        margin: 0;
        line-height: 1.3;
    }
}

.settings-content {
    margin-top: 20px;
}
/* Tabs
========================== */

.settings-tab {
    // background: #ebedef;
    border-radius: 3px;
    border: none;
    color: #42526e;
    // font-size: 15px !important;
    // margin-bottom: 5px;
    padding: 10px 15px;
    text-align: left;
    // text-transform: lowercase;
    width: 100%;
    display: flex;
    align-items: center;
    box-sizing: border-box;
    position: relative;

    // SETTINGS ONLY
    padding-top: 8px;
    padding-bottom: 8px;
    max-height: 32px;
    height: 100%;
    font-weight: 400;
    // letter-spacing: 0.6px;
    // color: rgba(25, 23, 17, 0.6);
    color: white;

    .right {
        position: absolute;
        right: 15px;
    }

    .drop-trigger {
        visibility: hidden;
    }

    .side-more {
        margin-right: 0;
        font-size: 13px;
    }

    .drop-trigger {
        margin-right: -11px;
        padding: 3px;
    }

    &:hover {
        color: rgb(55, 53, 47);

        // background: #fff;
        background: #e8eaed;
        border: none;
        .fa-arrow-right {
            opacity: 1 !important;
            animation: slide-right 0.3s forwards;
        }
        .item-count {
            display: none;
        }
        .drop-trigger {
            visibility: visible;
        }
        .drop-trigger:hover {
            background-color: transparent;
        }
    }
    .name {
        text-overflow: ellipsis;
        overflow: hidden;
        white-space: nowrap;
        margin-right: 5px;
        opacity: 0.8;
        text-transform: lowercase;
    }
    &.active {
        // color: #49475d;
        color: rgb(55, 53, 47);
        // font-weight: 500;
        border: none;
        text-align: left;
        // background: rgba(0, 0, 0, 0.04);
        background: #e8eaed;
        .name {
            opacity: 1;
        }
    }
    i {
        margin-right: 22px;
        font-size: 18px;
        opacity: 0.64;
    }
    .fa-arrow-right {
        opacity: 0;
        margin-left: auto;
        margin-right: 0;
        transform: translateX(-50%);
        font-size: 14px;
    }
    .fa-arrow-left {
        font-size: 12px !important;
    }
    &.hasCount .name {
        font-weight: 700;
        opacity: 1 !important;
    }
}

.settings-tab.active .item-count {
    background-color: white !important;
}

.tab-vertical-separator {
    height: 1px;
    width: 100%;
    background-color: rgba(0, 0, 0, 0.1);
    margin: 15px 0;
}

.tab-content {
    width: 100%;
    min-width: 500px;
    margin: 25px 30px;
}

.domain-url {
    margin: 8px 0;
    border: 1px solid #eaeaea;
    border-radius: 4px;
    padding: 8px;
    word-break: break-all;
    background: #eaeaea;
    font-family: Menlo, Monaco, Consolas, "Courier New", monospace;
    &.light {
        background-color: #f8f8f8;
        border: 0;
        padding: 11px;
    }
}

.key-bg {
    background-color: #f8f8f8;
    padding: 12px 20px;
    box-sizing: border-box;
    margin-bottom: 2px;
    border-radius: 3px;
    font-size: 14px;
    color: rgba(0, 0, 0, 0.6);

    &.code {
    }
}

.to-copy-row {
    display: flex;
    align-items: center;
    margin: 15px 0;
    .to-copy-row-title {
        font-size: 14px;
        font-weight: 700;
        margin-right: 14px;
    }
    .btn {
        border-radius: 2px;
        font-size: 14px;
    }
}
.copy-block {
    &.code {
        .to-copy-row-title {
            font-size: 16px;
            font-weight: 500;
        }
    }
    &.step {
        border-bottom: 1px dotted #ccc;
        padding-bottom: 20px;
    }
}

/* Integration Box
========================== */

.integration-box {
    text-align: center;
    border-radius: 5px;
    width: 270px;
    min-height: 250px;
    padding: 40px;
    transition: box-shadow 0.25s ease, transform 0.25s ease;
    overflow: hidden;
    // box-shadow: 0 2px 2px rgba(36,37,38,.08);
    // background: #fafbfd;
    background: #f9fafa;
    display: inline-flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    box-sizing: border-box;
    margin: 10px;

    img {
        max-width: 50px;
        height: 50px;
        transition-duration: 0.3s;
    }
    .title {
        font-size: 14px;
        font-weight: 700;
        margin-top: 25px;
        margin-bottom: 5px;
        color: #2c3b48;
        text-transform: uppercase;
    }
    .subtitle {
        padding-top: 5px;
        font-size: 13px;
        position: relative;
        color: #7c818b;
        line-height: 1.25;
    }
    &:hover {
        // box-shadow: 5px 12px 20px rgba(36,37,38,.13);
        // background: #FFF;
        background: #f5f5f5;
        img {
            transform: scale(1.1) rotate(4deg);
        }
    }
}

.settings-integration-service {
    .service-header {
        position: relative;
        img {
            max-height: 40px;
            margin-bottom: 20px;
        }
        .connect-button {
            position: absolute;
            right: 0;
            bottom: 10px;
        }
    }
}

.settings-layout {
    padding: 12px 30px 60px;
    max-width: 800px;
    margin: auto;

    .form-dialog {
        border: 1px solid #e8e8e8;
        box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.06);
        border-radius: 12px;
        padding: 23px 28px 0;
        .modal-footer {
            padding: 20px 2px;
            border: none;
        }
        .modal-body {
            padding: 0;
        }
    }
}

/* Labels
===================================== */
.label-wrapper {
    display: inline-block;
    padding: 5px 15px;
    background-color: #9175c0;
    border-radius: 16px;
    color: white;
}

@keyframes slide-right {
    100% {
        transform: translateX(0%);
    }
}

/* Settings Header
===================================== */
.settings-title {
    display: flex;
    align-items: center;
    // justify-content: space-between;
    // padding: 28px 45px 11px;
    padding: 10px 15px;
    border-bottom: 1px solid rgba(0, 0, 0, 0.065);
    height: 53px;
    box-sizing: border-box;
    h1,
    h2 {
        margin: 0;
    }
    h1 {
        font-size: 17px;
        font-weight: 500;
    }
}

.settings-title-badge {
    // background-color: #ffdbd7;
    color: rgba(0, 0, 0, 0.7);
    border-radius: 14px;
    min-width: 12px;
    padding: 3px 8px 0px;
    margin-left: 9px;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 12px;
    font-weight: 600;
    border: 1px solid rgba(0, 0, 0, 0.1);
    display: none;
}

.settings-midcol {
    max-width: 600px;
    width: 100%;
    margin: 0 auto;
}

.settings-midcols {
    padding: 0 15px;
    max-width: 600px;
    margin: 0 auto;
}

.theme-preview {
    width: 110px;
    height: 60px;
    border-radius: 6px;
    margin-top: 8px;
    box-shadow: 0 0 0 1px rgba(0, 0, 0, 0.08), 0 1px 3px 0 rgba(0, 0, 0, 0.08);
    // padding: 10px;
    box-sizing: border-box;
    overflow: hidden;
    margin-right: 40px;
    margin-bottom: 40px;
    margin-left: 30px;

    .tp-header {
        background-color: #db4c3f;
        height: 20px;
    }

    .tp-shadow {
        background-color: rgba(93, 87, 89, 0.3);
        width: 50px;
        height: 4px;
        border-radius: 25px;
    }
    .tp-line {
        padding: 5px 4px;
        display: flex;
        align-items: center;
        justify-content: space-between;
        border-radius: 3px;
        margin: 5px;
    }
    .tp-line.selected {
        // background-color: blue;
        background-color: #f4f4f4 !important;
    }
    .tp-count {
        background-color: orange;
        border-radius: 20px;
        height: 9px;
        width: 15px;
    }
}

.theme-item-preference {
    display: inline-block;
}

.themes-defaults {
    max-width: 600px;
}

.api-page {
    .label-input {
        font-size: 25px;
    }
}