.page-table {
    height: 100%;
    min-height: 100%;
    display: flex;
    flex-direction: column;
    box-sizing: border-box;
}

.page-table-content {
    flex: 1 1;
    height: 100%;
    box-sizing: border-box;
    display: flex;
    max-height: calc(100vh - 80px);
    & > div, & > section {
        padding-bottom: 100px;
    }
}

.page-table-title {
    max-width: 700px;
    padding: 23px 32px;
    h1 {
        font-size: 28px;
        font-weight: 300;
        margin: 0;
    }
    p {
        margin: 15px 0 0;
        font-size: 16px;
        color: #9b9b9b;
    }
}

.page-table-table {
    padding: 0 32px;
}

.page-table-content-left {
    flex: 1;
}

.page-table.openSidePanel .page-table-content-left {
    overflow-y: scroll;
}