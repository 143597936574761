.banner-notification-space {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    margin: 0 auto;
    z-index: 10;
}
.banner-notification {
    height: 36px;
    display: flex;
    justify-content: center;
    margin: 0 auto;
    text-align: center;
    text-overflow: ellipsis;
    white-space: nowrap;
    font-weight: 500;
    font-size: 14px;
    color: #fff;
    border-radius: 0 0 6px 6px;
    max-width: 750px;
    align-items: center;
    animation: slide-down 0.3s forwards;
    font-size: 15px;

    &.warning {
        background: #e3b200;
        box-shadow: 0 1px 5px 0 rgba(121, 95, 4, 0.21);
    }

    a {
        color: white;
        text-decoration: underline;
        margin-left: 10px;
    }
}

@keyframes slide-down {
    0% {
        // opacity: 0;
        transform: translateY(-36px);
    }
    100% {
        // opacity: 1;
        transform: translateY(0);
    }
}
