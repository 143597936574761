.sidenav-space {
    // width: calc(#{$sidenav-width} + 20px);
    // min-width: calc(#{$sidenav-width} + 20px);
    width: $sidenav-width;
    height: 100vh;
    background: $sidenav;
}

.sidenav {
    // background-color: rgb(7, 71, 166);
    color: #ffffff;
    top: 0;
    left: 0;
    height: 100%;
    z-index: 11;
    overflow: visible;
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    background: $sidenav;
    position: relative;

    i {
        font-size: 24px;
        // color: $sidenav-icon;
        // color: #909caa;
        // color: #cdcde3;
        color: $sidenav-icon;
    }
    .bottom {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        margin-bottom: 15px;
    }
}

.nav-logo-space,
.nav-user-space {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 55px;
    a {
        // background-color: white;
        // width: 30px;
        // height: 30px;
        border-radius: 50%;
        padding: 6px;
        text-align: center;
    }
    img {
        width: 30px;
        height: 30px;
        object-fit: cover;
        backface-visibility: hidden;
    }
    .div-img {
        height: 32px;
        width: 32px;
        border-radius: 50%;
        box-sizing: border-box;
        background-image: url("../assets/images/agent.svg");
        background-size: contain;
    }
    // &:hover .div-img {
    //     background-image: url('../assets/images/agentActive.svg');
    // }
    // i {
    //     color: a;
    // }

    svg {
    }
}

.nav-user-space {
    width: 34px;
    height: 34px;
    border-radius: 50%;
    // background-color: $sidenav-item-active;
    transition: height 85ms;
    position: relative;
    z-index: 999;
    // & > div {
    //     width: 34px;
    //     height: 34px;
    //     border-radius: 12px;
    // }
    img {
        width: 28px !important;
        height: 28px !important;
    }
    &:hover {
        background-color: rgba(9, 30, 66, 0.42);
        // border-radius: 12px;
        // border-radius: 50%;

        // width: 50px;
        // height: 50px;
    }
}

.nav-logo-space {
    margin-bottom: 20px;
    margin-top: 7px;
    // background-color: #ec0000;

    a {
        transition: all 0.25s cubic-bezier(0, 0, 0, 1);
    }
    &:active a {
        transform: scale(0.9);
    }
    path:first-child {
        fill: #0fccce;
    }
}

// .nav-logo-space path:first-child,
// .auth-header path:first-child {
//     fill: $logo-color;
//     // fill: $primary;
// }

.nav-user-space img {
    left: 0;
    height: 32px;
    width: 32px;
    border-radius: 50%;
    box-sizing: border-box;
    object-fit: cover;
}

.sidenav-item {
    width: 100%;
    // height: 50px;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-bottom: 4px;

    a {
        display: flex;
        justify-content: center;
        align-items: center;
        color: white;
        border-radius: 50%;
        padding: 10px;
        width: 40px;
        height: 40px;
        box-sizing: border-box;
        svg {
            transition: fill 200ms cubic-bezier(0.25, 0.46, 0.45, 0.94) 0ms,
                border-color 400ms cubic-bezier(0.25, 0.46, 0.45, 0.94) 0ms,
                color 400ms cubic-bezier(0.25, 0.46, 0.45, 0.94) 0ms,
                box-shadow 400ms cubic-bezier(0.25, 0.46, 0.45, 0.94) 0ms;
        }
        i {
            transition: all 0.25s cubic-bezier(0, 0, 0, 1);
            // opacity: 0.4;
            font-size: 20px;
        }
        &:hover {
            background-color: rgba(9, 30, 66, 0.42);
            svg {
                fill: $logo-color;
            }
            i {
                opacity: 1;
                color: white;
            }
            // background-color: rgba(255,255,255,0.1);
            // background-color: rgba(255,255,255,0.1);
        }
        &.active {
            // background-color: rgba(0,0,0,0.3);
            // background-color: #20a2e0;
            // background-color: #e9eef0;
            background-color: $sidenav-item-active;
            i {
                opacity: 1;
                color: white;
                width: 24px;
                height: 24px;
                text-align: center;
                display: flex;
                align-items: center;
            }
        }
        &:active {
            i {
                transform: scale(0.8);
            }
        }
    }

    path {
        cursor: pointer;
    }

    a:hover .purple,
    .fa.purple {
        color: #6c5ce7 !important;
    }
}

.bottom .sidenav-item {
    margin-bottom: 8px;
    a {
        width: 32px;
        height: 32px;
        i {
            font-size: 18px;
        }
    }
}

.nav-tooltip {
    opacity: 0.1 !important;
    transition: opacity 0.3s ease-in-out, margin-left 0.3s ease-in-out, visibility 0.3s ease-in-out;
    margin-left: 20px !important;
    box-shadow: 0 4px 14px 0 rgba(0, 0, 0, 0.2), 0 0 0 1px rgba(0, 0, 0, 0.05) !important;
    background-color: white !important;
    color: #222 !important;
    font-size: 14px;
    font-weight: 500;
    padding: 6px 15px;

    &:after {
        border-right-color: #fff !important;
        border-top: 8px solid transparent !important;
        border-bottom: 8px solid transparent !important;
        left: -6px !important;
        margin-top: -8px !important;
    }
    &:before {
        border-right: 7px solid rgba(0, 0, 0, 0.09) !important;
        border-top: 9px solid transparent !important;
        border-bottom: 9px solid transparent !important;
        left: -7px !important;
        margin-top: -9px !important;
    }
    &.show {
        // transform: translateX(-10px);
        margin-left: 10px !important;
        opacity: 1 !important;
    }
}

// .menu {
//     bottom: 20px !important;
//     right: 0px !important;
// }
