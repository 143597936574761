.basic-layout {
    display: flex;
    flex-direction: column;
    overflow: hidden;
    height: 100vh;
}

.basic-layout-content {
    display: flex;
    flex: 1;
    height: calc(100vh - 40px);
}

.page {
    position: relative;
}
.layout-header,
.sidebar {
    transition: left 400ms cubic-bezier(0.25, 0.46, 0.45, 0.94) 0ms,
        right 400ms cubic-bezier(0.25, 0.46, 0.45, 0.94) 0ms;
}

.layout-header-space {
    height: $header-height;
    position: relative;
}
.layout-header {
    height: $header-height;
    left: $sidenav-width;
    color: #2a2a2a;
    top: 0;
    right: 0;
    z-index: 5;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 3px 20px;
    position: fixed;
    background: #fff;
    // box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.08);
    // border-bottom: 1px solid rgba(0,0,0,0.1);
    box-sizing: border-box;
}

.layout-header-space.settings {
    .layout-header {
        margin-top: 0;
        padding-left: 0;
        left: 350px;
        max-width: 750px;
        box-sizing: content-box;
        padding-top: 4px;
        padding-bottom: 0px;
        h3 {
            font-size: 16px;
        }
    }
}

.closed .layout-top {
    left: 64px;
}

.bordered {
    .layout-top {
        border-bottom: 1px solid #ebebeb;
        padding: 3px 50px 3px 30px;
    }
    .panel-content:before {
        display: none !important;
    }
    .panel-content {
        margin: 100px auto;
    }
}

.layout-main {
    background: $background;
    // background-color: #f8f9fa;
    // background-color: #faf9f8;

    position: relative;
    // z-index: 10;
    flex: 1;
    // border-radius: 12px 0 0 0;
    // margin-left: -20px;
    z-index: 5;
    overflow: auto;

    &.email-unverified {
        .resizer {
            max-height: calc(100vh - 80px) !important;
        }
        .page-layout {
            height: calc(100vh - 80px);
        }
    }
}

/* Company info
==============================*/
.layout-header {
    .company-info {
        display: flex;
        align-items: center;
    }
    .info-details {
        display: flex;
        flex-direction: column;
        text-align: right;
    }
    .company-name {
        font-weight: 500;
    }
    .permission-name {
        color: rgba(0, 0, 0, 0.4);
        i {
            margin-right: 8px;
            color: rgba(0, 0, 0, 0.3);
            font-size: 12px;
        }
    }
    .company-avatar {
        display: flex;
        align-items: center;
        img {
            border-radius: 50%;
            object-fit: cover;
            width: 40px;
            height: 40px;
            margin-left: 10px;
        }
    }
}

/* Page Info
==============================*/
.page-info {
    display: flex;
    align-items: center;

    h3 {
        font-size: 18px;
        font-weight: 400;
    }
}

.page-info span {
    font-weight: 700;
}

.page-info i {
    font-size: 18px;
    margin-top: 1px;
    // display: none;
}

.goBack {
    margin-right: 15px;
    &.active {
        display: none;
    }
}

.goBack.active ~ .page-info {
    i {
        display: inline-block;
    }
    span {
        margin-left: 10px;
    }
}

// .toggle-menu {
//     margin-right: 15px;
//     margin-top: 4px;
// }
.go-back-arrow {
    margin-right: 5px;
    i {
        padding: 0px 6px;
        color: #222 !important;
    }
}

.page-actions {
    display: flex;
    & > button,
    & > a,
    & > div {
        margin-left: 10px;
    }
}

.email-confirm {
    background-color: #3267bc;
    text-align: center;
    color: #fff;
    font-size: 14px;
    padding: 3px;
    & > button,
    & > a {
        background-color: transparent;
        text-align: center;
        color: #fff;
        font-size: 15px;
        font-weight: bold;
        padding: 7px;
        text-decoration: underline;
    }
}

/* Side Layout
==============================*/
.side-layout {
    display: flex;
}
.side-layout-content {
    flex: 1;
}
