.tooltip-custom {
    max-width: 400px;
    background-color: rgb(25, 25, 26);
    box-shadow: rgba(0, 0, 0, 0.15) 0px 2px 4px;
    color: rgb(255, 255, 255);
    font-size: 12px;
    font-weight: 400;
    line-height: 16px;
    padding: 6px 10px;
    border-radius: 4px;
}

// .tooltip {
//     margin-top: -3px !important;
//     border-radius: 5px !important;
//     background-color: $sidenav !important;
//     border: transparent !important;
//     &:after {
//         border-top-width: 6px !important;
//         bottom: -5px !important;
//         border-top-color: $sidenav !important;
//     }
//     &:before {
//         border-top-color: transparent !important;
//     }
// }