.report-table-header {
    color: #6e828c;
    font-weight: 700;
    height: 57px;
    border-radius: 4px;
    background-color: #e1e4e9;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 10px 30px;
    margin: 15px 0 5px;

    h3 {
        text-transform: uppercase;
        font-size: 14px;
    }
}

.reports .ReactTable .rt-tbody .rt-tr-group {
    min-height: 40px;
}

.report-col {
    flex: 1 1;
    text-align: center;
    &.bordered {
        border-left: 1px solid #ececec;
        border-right: 1px solid #ececec;
    }
}

.report-stat {
    margin-bottom: 40px;
}

.report-stat-title {
    text-transform: uppercase;
    opacity: 0.4;
    font-weight: 500;
    font-size: 13px;
}
.report-stat-value {
    font-weight: 600;
    font-size: 33px;
}

.pulse {
    display: block;
    width: 8px;
    height: 8px;
    border-radius: 50%;
    background: #2ecc71;
    cursor: pointer;
    box-shadow: 0 0 0 rgba(46,204,113, 0.4);
    animation: pulse 2s infinite;
  }
  .pulse:hover {
    animation: none;
  }
  
  @-webkit-keyframes pulse {
    0% {
      -webkit-box-shadow: 0 0 0 0 rgba(46,204,113, 0.4);
    }
    70% {
        -webkit-box-shadow: 0 0 0 10px rgba(46,204,113, 0);
    }
    100% {
        -webkit-box-shadow: 0 0 0 0 rgba(46,204,113, 0);
    }
  }
  @keyframes pulse {
    0% {
      -moz-box-shadow: 0 0 0 0 rgba(46,204,113, 0.4);
      box-shadow: 0 0 0 0 rgba(46,204,113, 0.4);
    }
    70% {
        -moz-box-shadow: 0 0 0 10px rgba(46,204,113, 0);
        box-shadow: 0 0 0 10px rgba(46,204,113, 0);
    }
    100% {
        -moz-box-shadow: 0 0 0 0 rgba(46,204,113, 0);
        box-shadow: 0 0 0 0 rgba(46,204,113, 0);
    }
  }