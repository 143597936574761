.menu-drop {
    // width: 100%;
    position: relative;
}

.menu-drop-inner {
    display: flex;
    flex-direction: column;
    background-color: white;
    box-shadow: rgba(0, 0, 0, 0.3) 0px 0px 1px, rgba(0, 0, 0, 0.1) 0px 3px 10px;
    overflow: hidden;
    border-radius: 6px;
    max-height: 420px;
    position: absolute;
    z-index: 99;
    width: 100%;
    .input-space {
        width: 100%;
    }
}

.menu-drop-header {
    display: flex;
    flex-direction: column;
    padding: 0px 12px;
    border-bottom: 1px solid rgb(232, 234, 237);
    align-items: center;

    .title {
        padding: 12px 6px 10px;
        font-weight: 500;
        white-space: nowrap;
        text-align: center;
        text-overflow: ellipsis;
        overflow: hidden;
    }
}

.menu-drop-inner .text-space,
.search.text-space {
    margin: 0;
    .kinput {
        color: rgb(25, 25, 26);
        // background-color: rgb(247, 248, 250);
        background-color: rgba(0, 0, 0, 0.03);
        user-select: text;
        border-radius: 4px;
        margin: 12px 0px;
        height: 26px;
        min-height: 26px;
        border: none;
        margin: 0 0 12px;
        input {
            height: 100%;
            font-size: 13px;
            margin: 0px 8px 2px;
            background-color: transparent;
            box-sizing: border-box;
            padding: 0;
            &::placeholder {
                color: rgba(0, 0, 0, 0.5);
            }
        }
        &:focus-within {
            background-color: white;
            box-shadow: $primary 0px 0px 0px 1px, $primary 0px 0px 0px 1px inset !important;
            border: none;
        }
    }
}

.menu-drop-content {
    padding: 7px 0;
}

.menu-drop-item {
    display: flex;
    align-items: center;
    min-height: 30px;
    .left {
        margin-right: 8px;
    }
    &:hover,
    &.focused {
        background-color: rgba(93, 105, 133, 0.1);
    }
    &.selected {
        .name {
            font-weight: 600;
        }
    }
}

.menu-drop-item-inner {
    padding: 0 12px;
    display: flex;
    align-items: center;
    flex: 1;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;

    .selected-icon {
        margin-left: auto;
        color: $primary-dark;
        font-size: 11px;
    }
    .name {
        white-space: nowrap;
        text-overflow: ellipsis;
        overflow: hidden;
        // max-width: 200px;
        // span {
        //     white-space: nowrap;
        //     text-overflow: ellipsis;
        //     overflow: hidden;
        // }
    }
    .cell-avatar {
        margin-right: 8px;
    }
}

.menu-item-with-avatar {
    display: flex;
    align-items: center;
    img {
        width: 20px;
        height: 20px;
        border-radius: 50%;
        margin-right: 8px;
        object-fit: cover;
    }
    &.not-assigned i {
        // margin: 0 13px 0 3px;
        margin: 0 10px 0 0px;
        width: 25px;
        height: 25px;
        text-align: center;
        display: flex;
        align-items: center;
        justify-content: center;
    }
}

.drop-trigger {
    border-radius: 4px;
    overflow: hidden;
    box-sizing: border-box;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    &:hover {
        background-color: rgba(93, 105, 133, 0.1);
        i,
        .icon-trigger {
            color: rgb(119, 120, 122);
        }
    }
}

.select-trigger {
    display: flex;
    align-items: center;
    box-sizing: border-box;
    width: 100%;
    .left {
        display: flex;
        align-items: center;
        flex: 1;
    }
    img {
        border-radius: 50%;
    }
    svg {
        margin-left: 7px;
        opacity: 0.4;
    }
}

.menu-drop-separator {
    width: 100%;
    border-top: 1px solid #e8eaed;
    margin: 7px 0;
}

.icon-trigger {
    padding: 3px;
    width: 16px;
    height: 16px;
    font-size: 12px;
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
    color: rgb(187, 189, 191);
}

.drop-icon-add,
.drop-icon-tag {
    color: $primary-dark;
    background-color: rgb(224, 237, 255);
    border-radius: 50%;
    width: 20px;
    height: 20px;
    display: flex;
    align-items: center;
    justify-content: center;
}

.drop-icon-tag {
    background-color: #fff;
    font-size: 13px;
    :before {
        margin-top: 2px;
    }
}

.emoji-trigger {
    font-size: 20px;
    padding: 9px;
    width: 36px;
    height: 36px;
    box-sizing: border-box;
    border-radius: 4px;
    &.bordered {
        border: 1px solid rgb(212, 214, 217);
    }

    span {
        margin-top: -2px;
    }
    &.selected {
        background-color: rgb(241, 246, 253);
        border-color: rgb(190, 215, 250);
    }
}

.emoji-mart-scroll {
    height: 200px !important;
    padding: 0px 6px 6px 6px;
}

.emoji-mart {
    border: none !important;
}

.emoji-mart-search {
    border-bottom: 1px solid #d9d9d9;
    padding: 0 10px;
    // padding: 7px 7px 12px;
    & > input {
        background-color: rgb(247, 248, 250);
        border: none;
        margin: 12px 0px;
        &:focus-within {
            box-shadow: $primary-dark 0px 0px 0px 1px, $primary-dark 0px 0px 0px 1px inset;
            background-color: white;
        }
    }
}

.emoji-mart-category-label {
    font-size: 13px;
    position: relative;
    color: rgb(119, 120, 122);
    margin: 12px 0 2px;
}

.emoji-mart-search-icon {
    display: none;
}

.emoji-mart-bar:last-child {
    border-top: 1px solid #d9d9d9;
}

.emoji-box {
    position: relative;
    & > .fa-check {
        background-color: $primary;
        border-radius: 50%;
        position: absolute;
        top: -3px;
        right: -7px;
        color: white;
        font-size: 10px;
        width: 15px;
        height: 15px;
        display: flex;
        align-items: center;
        justify-content: center;
        font-size: 10px;
    }
}

.emoji-mart-emoji span {
    display: block;
}

.menu-drop-section-title {
    color: rgb(155, 156, 158);
    font-size: 13px;
    font-weight: 600;
    padding: 9px 12px 6px;
}

.drop-trigger .medium {
    width: 18px;
    height: 18px;
    font-size: 16px;
    padding: 6px;
    box-sizing: content-box;
}

.drop-trigger .big {
    width: 20px;
    height: 20px;
    font-size: 20px;
    padding: 4px;
    box-sizing: content-box;
}

.drop-trigger .svg {
    background-repeat: no-repeat;
    background-position: center;
    opacity: 0.54;
}

.drop-trigger:hover .svg {
    opacity: 1;
}

.actions {
    display: flex;
    align-items: center;

    .drop-trigger {
        margin-right: 7px;
    }
}

.select-dropdown-space {
    .drop-trigger:hover {
        background-color: transparent;
    }
}

.ellipse {
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
    width: 100%;
}
