/* Tags
================================================ */
.editable-tag {
    display: flex;
    align-items: center;
    margin-bottom: 2px;
    .left {
        position: relative;
        box-sizing: border-box;
        background-color: #8f7ed2;
        color: white;
        border-radius: 4px;
        padding: 9px 15px;
        font-weight: 500;
        width: 200px;
        text-align: left;
        transition: padding 85ms, margin 85ms, width 85ms, box-shadow 85ms;
        &:hover {
            // box-shadow: -8px 0 #6c5ca9;
            box-shadow: 8px 0 rgba(0,0,0,0.15) inset;
            margin-left: 4px;
            width: 196px;
        }
    }
}

.tag-box {
    display: flex;
    align-items: center;
    // margin-left: 10px;

    .drop-trigger:hover {
        background: none;
        overflow: hidden;
        .tag-box-item:before {
            border-radius: 4px;
            content:"";
            top: 0;
            left: 0;
            position: absolute;
            width: 100%;
            height: 100%;
            background-color: rgba(0,0,0,0.01);
        }
    }
}

.tag-box-item {
    box-sizing: border-box;
    background-color: #8f7ed2;
    color: white;
    border-radius: 15px;
    padding: 2px 10px;
    font-weight: 500;
    font-size: 12px;
    margin-right: 5px;
    position: relative;
    position: relative;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
}

.tag-colors {
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.tag-color {
    width: 24px;
    height: 24px;
    border-radius: 4px;
    // margin-right: 8px;
    position: relative;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    &:hover::before {
        position: absolute;
        content: "";
        height: 100%;
        width: 100%;
        background-color: rgba(255,255,255,0.3);
    }
    i {
        margin: auto;
        bottom: 0;
        left: 0;
        right: 0;
        width: 10px;
        color: white;
        font-size: 14px;
        margin-left: 5px;
    }
}

.color-emoji-separator {
    width: 1px;
    height: 30px;
    background-color: rgb(212, 214, 217);
    margin: 0 15px;
}