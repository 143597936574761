/* Help Icon
===============================*/
.help-icon {
    display: flex;
    align-items: center;
    margin-left: 3px;
    i {
        font-size: 15px;
    }
}

.icon-middle {
    display: flex;
    align-items: center;
    i {
        font-size: 16px;
        margin-right: 8px;
    }
}

/* Actions
===============================*/
.bottom-actions {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    button {
        margin-left: 10px;
    }
    &.inverse {
        flex-direction: row-reverse;
    }
}

/* ScriptBox
===============================*/
.script-box {
    border-radius: 3px;
    border: 1px solid rgba(79, 84, 92, 0.3);
    overflow: hidden;
    font-size: 14px;
}

/* Invisible
===============================*/
.invisible {
    visibility: hidden;
}

/* Badge
================================ */
.badge-count {
    background-color: #f0f0f0;
    padding: 4px 8px 3px;
    border-radius: 15px;
    color: $text-color;
    margin-right: 2px;
    font-size: 11px;
    font-weight: 600;
    line-height: 11px;
    text-align: center;
    display: inline-block;
}

/* Status
===============================*/
.mailing-status {
    display: flex;
    align-items: center;
}
.mailing-status .circle-status {
    height: 12px;
    width: 12px;
    border-radius: 50%;
    margin-right: 7px;
    display: inline-block;
}
.mailing-status.Enviando {
    color: #e67e22;

    .circle-status {
        background-color: #e67e22;
    }
}

/* Import
===============================*/
.filename {
    position: relative;
    color: #5d657a;
    border-radius: 24px;
    background-color: #d5e0ef;
    font-weight: 500;
    padding: 3px 15px;
    margin: 0 27px;
    font-size: 12px;
    margin-left: 16px;
    display: flex;
    align-items: center;
    
    button {
        padding: 2px;
        display: flex;
        align-items: center;
        border-radius: 50%;
        margin-left: 5px;
        i {
            font-size: 13px;
        }
        &:hover {
            background-color: rgba(0, 0, 0, 0.1);
        }
    }
}

.import-file-row {
    display: flex;
    align-items: center;
}

/* DEV
========================*/
.dev-dropdown {
    // position: fixed;
    // left: 120px;
    // bottom: 30px;
    .btn i {
        font-size: 20px;
        width: 19px;
        height: 19px;
        display: inline-block;
        color: #e74c3c !important;
        margin-left: 5px;
    }

    .script-box {
        z-index: 0;
    }
}

.dev-method {
    width: 100%;
    display: flex;
    span {
        margin-right: 10px;
        &:first-child {
            background-color: #6f7dd8;
            color: white;
            border-radius: 5px;
            font-size: 10px;
            padding: 2px 7px;
        }
        &:last-child {
            display: inline-block;
            margin-left: auto;
            opacity: 0.6;
        }
    }
}

.dev-space {
    position: fixed;
    left: 20px;
    bottom: 16px;
    z-index: 99;
    display: flex;
    align-items: center;
}

.dev-current-api {
    background-color: #e74c3c;
    padding: 1px 10px;
    border-radius: 12px;
    // position: fixed;
    // left: 80px;
    // bottom: 30px;
    i {
        color: #e74c3c;
        margin-right: -14px;
        font-size: 17px;
        top: 2px;
        position: relative;
    }

}

/* Loading Icon
========================== */
.icon-loader {
    animation: rotation 0.5s infinite linear;
    margin-left: 18px;
    font-size: 15px;
    color: #798d99;
}

.agent-avatar {
    display: flex;
    align-items: center;
    img {
        object-fit: cover;
        border-radius: 50%;
    }
}

/* Context BG
=================================== */
.contextual-bg {
    padding: 15px;
    background-color: #f2dede;
    margin-bottom: 20px;   
    h3 {
        margin: 0;
    }
}

.contextual-bg.success {
    background-color: $primary;
    color: white;
}