.page.login {
    background-color: #f8f8f8;
    height: 100vh;
    display: flex;
    flex-direction: column;
    overflow: auto;
}

.auth-header {
    height: 70px;
    min-height: 70px;
    width: 100%;
    background-color: #fff;
    border-bottom: 1px solid #ddd;
    display: flex;
    align-items: center;
    justify-content: center;

    font-weight: 900;
    font-size: 28px;
    span {
        margin-left: 7px;
    }
    a {
        display: flex;
        align-items: center;
    }
}

.login-page-inner {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding-top: 35px;
}

.login-box {
    // box-shadow: 0 10px 25px #ddd;
    // border: 1px solid #ddd;
    box-shadow: 0 2px 40px 0 rgba(0, 0, 0, 0.06);
    z-index: 1;
    box-sizing: content-box;
}

.login-signup {
    font-size: 16px;
    margin-bottom: 20px;
}

.login-actions a {
    &:hover {
        text-decoration: underline;
    }
}

.auth-image {
    display: flex;
    justify-content: center;
    margin-top: 20px;
    img {
        height: 240px;
    }
}

/* Old 
============================== */
.auth-box {
    // margin: 30px 0;
    width: 330px;
    // width: 100%;
    .kinput {
        height: 45px;
        input {
            font-size: 16px;
            height: 100%;
        }
    }
}

#login-bg {
    position: fixed;
    top: 1px;
    height: 100%;
    right: 0;
    fill: #f3f3f8;
    z-index: -1;
}

.login-dashboard {
    z-index: 99;
    height: 700px;
    right: -400px;
    top: 200px;
    position: fixed;
}

// .login.page {
//     display: flex;
//     .left {
//         display: flex;
//         align-items: flex-start;
//         height: 100vh;
//         flex-direction: column;
//         width: 1024px;
//     }
//     .right {
//         flex: 1;
//         position: fixed;
//         overflow: hidden;
//     }
// }

h1.headline {
    font-size: 28px;
    font-weight: 700;
    max-width: 470px;
    margin-bottom: 20px;
    display: block;
    text-align: center;
    margin-top: 0;
}

.logo-login {
    // height: 25px;
    margin-bottom: 60px;
    margin-top: 25px;
}

.login-actions {
    display: flex;
    flex-direction: column;
    align-items: center;
    // margin-top: 30px;
}

.login-btn {
    border-radius: 100px;
    padding: 14px 80px;
    font-size: 16px;
    margin-bottom: 20px;
    background-color: $primary-dark !important;
    
    &:hover {
        background-color: $primary-hover !important;
    }

    border-radius: 4px;
    width: 100%;
    text-align: center;
    .btn-text {
        display: flex;
        align-items: center;
        text-align: center;
        width: 100%;
        display: block;
    }
}

.register-success-space {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    h1 {
        width: 470px;
        text-align: center;
    }
    i {
        color: $primary;
        border-radius: 50%;
        font-size: 55px;
        padding: 30px;
        margin: 10px 0 20px;
        border: 4px solid;
    }
    h2 {
        text-align: center;
        background-color: rgba(0, 0, 0, 0.05);
        border-radius: 21px;
        padding: 10px 20px;
        font-size: 17px;
    }
}

/* Responsividade 
============================== */
@media screen and (max-width: 1740px) {
    .login-dashboard {
        height: 600px;
        right: -360px;
        top: 170px;
    }
}

@media screen and (max-width: 1440px) {
    h1.headline {
        font-size: 34px;
    }
    .login-dashboard {
        height: 510px;
        right: -355px;
        top: 110px;
    }
}

@media screen and (max-width: 1200px) {
    h1.headline {
        font-size: 27px;
        max-width: 300px;
    }
    .login-dashboard {
        height: 460px;
        right: -325px;
        top: 110px;
    }
    .auth-box {
        max-width: 300px;
    }
}

.registering {
    .loading-space {
        margin-top: 0 !important;
    }
    .loading2 {
        stroke: $primary;
    }
}
