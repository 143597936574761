.ico {
    font-family: icomoon;
    font-style: normal;
}
.ico-users {
    &:before {
        content: "\E985";
    }
}
.ico-user {
    &:before {
        content: "\E992";
    }
}
.ico-logo {
    &:before {
        content: "\E98A";
    }
}
.ico-shield {
    &:before {
        content: "\E931";
    }
}
.ico-throphy {
    &:before {
        content: "\E981";
    }
}
.ico-hearth {
    &:before {
        content: "\E968";
    }
}
.ico-tactic {
    &:before {
        content: "\E920";
    }
}
.ico-paper {
    &:before {
        content: "\E948";
    }
}
.ico-pad {
    &:before {
        content: "\E642";
    }
}
.ico-play {
    &:before {
        content: "\E904";
    }
}
.ico-settings {
    &:before {
        content: "\E905";
    }
}
.ico-inbox {
    &:before {
        content: "\E95B";
    }
}
.ico-circle-arrow {
    &:before {
        content: "\E984";
    }
}
.ico-message {
    &:before {
        content: "\E927";
    }
}
.ico-faq {
    &:before {
        content: "\E924";
    }
}
.ico-anexo {
    &:before {
        content: "\E60A";
    }
}
.ico-dashboard2 {
    &:before {
        content: "\E99B";
    }
}
.ico-dashboard3 {
    &:before {
        content: "\E954";
    }
}
.ico-dashboard {
    &:before {
        content: "\E93C";
    }
}
.ico-reports {
    &:before {
        content: "\E938";
    }
}

// .fab.fa-facebook-f {
//     color: #3C5A99;
// }