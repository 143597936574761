.page-layout.inbox {
    // height: 100vh;
    // height: calc(100vh - #{$header-height});
    padding-bottom: 0;
    // background-color: $sidebar_color;
    .conversations-wrapper {
        // width: 400px;
        // border-right: 1px solid #e0e4ea;
        width: 100%;
        height: 100%;
        font-size: 12px;
        // background-color: #fff;
        // background-color: #fafafb;
        // border-left: 1px solid $border-color;
        /* border-left: 1px solid #e7e9ec; */
        // border-right: 2px solid $border-color;
        // background-color: #fff;
        border-right: 1px solid #edebe9;
        background-color: #faf9f8;
        // background-color: rgb(250, 250, 250);
        // border-right: 1px solid rgba(0, 0, 0, 0.05);
        // border-left: 1px solid rgba(0, 0, 0, 0.05);
        // padding: 0 15px;

        &.loading {
            .inbox-conv-inner.first {
                display: none;
            }
            .inbox-separator {
                display: none;
            }
        }
    }
    &.open .conversations-wrapper {
        // max-width: 460px;
        display: flex;
        flex-direction: column;
        box-sizing: border-box;
        // background-color: #fafafa;
        // border-right: 1px solid #e0e4ea;
    }
}

.dark-theme ~ .layout-main .conversations-wrapper {
    border-left: none;
}

.conversations-header,
.chat-header {
    // position: absolute;
    // padding: 10px 15px 10px 10px;
    padding: 0 12px;
    min-height: 44px;

    // height: 60px;
    // min-height: 50px;
    // background-color: #fff;
    // border-bottom: solid 1px #e0e4ea;
    box-sizing: border-box;
    z-index: 1;
    width: 100%;
    // border-bottom: 1px solid rgb(232, 234, 237);
    display: flex;
    align-items: center;
    // border-bottom: 1px solid #ececec;
    border-bottom: 1px solid rgb(237, 235, 233);
    // max-height: 48px;

    // background-color: #f3f2f1;
}

.conversation-separator {
    background-color: #f9f9f9;
    width: 100%;
    height: 12px;
}

.conversations-header-context {
    height: 43px;
    min-height: 43px;
    background-color: #f4f4f4;
    border-bottom: 1px solid #edebe9;
    width: calc(100% + 1px);
}

.conversations-header {
    // margin-bottom: 20px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: flex-start;
    padding: 12px;
    height: 110px;
    box-sizing: border-box;
    background-color: white;
    min-height: 48px;
    padding: 12px 16px 12px 28px;
    // background-color: #f3f2f1;
    .conversation-title {
        margin: 0;
        padding: 0;
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
        font-size: 1rem;
        color: #202020;
        font-weight: 600;
        line-height: 1.8;
    }
    .tab-header {
        box-shadow: none;
        background-color: transparent;
        padding: 0;
        width: 100%;
        max-width: 390px;
        margin-top: 0;
        // padding: 12px 0 0;
    }
    .row {
        height: 34px;
        // padding: 0 20px;

        // padding: 0 20px;
        margin: 0;
        padding: 0;
        justify-content: space-between;
        .kinput {
            min-height: 30px;
            margin-bottom: 0;
            input {
                font-size: 14px;
            }
        }
        // .input-space {
        //     flex: 1;
        //     // margin-right: 30px;
        // }
        // .kinput {
        //     // background-color: #ebecf0 !important;
        //     // background-color: rgba(0, 0, 0, 0.03) !important;
        //     background-color: rgb(247, 248, 250) !important;
        //     border-radius: 4px;
        //     color: rgba(9, 30, 66, 0.74);
        //     padding: 0 17px;
        //     max-width: 100%;
        //     min-height: 30px;
        //     height: 30px;
        //     width: 100%;
        //     // border: 1px solid rgba(0, 0, 0, 0.05) !important;
        //     border: none !important;
        //     // padding-left: 0;
        //     margin-left: 0;
        //     i {
        //         font-size: 13px;
        //         margin-right: 0;
        //     }
        //     input {
        //         background-color: transparent;
        //         color: rgba(9, 30, 66, 0.74);
        //         height: 40px;
        //         font-size: 13px;
        //         &::placeholder {
        //             color: rgba(9, 30, 66, 0.74);
        //             font-size: 13px;
        //             opacity: 1;
        //         }
        //     }
        //     .btn {

        //     }
        // }
    }
    .row:last-child {
        // border-bottom: 1px solid rgba(0, 0, 0, 0.1);
        // border-top: 1px solid rgba(0, 0, 0, 0.1);
        // padding-bottom: 5px;
        // margin-bottom: 10px;
        // padding: 3px 20px 6px;
        justify-content: flex-start;
        // margin: 0 0 8px;
    }
    .filters-tab {
        padding: 7px 10px;
        font-size: 13px;
        margin: 0;
        background-color: transparent;
        color: rgba(0, 0, 0, 0.3);
        font-weight: 500;
        margin-top: 7px;
        text-align: center;
        padding: 0;
        margin-right: 25px;
        &:hover {
            // background-color: rgba(0, 0, 0, 0.03);
            color: rgba(0, 0, 0, 0.7);
        }
        &.isActive {
            // border-bottom: 2px solid rgba(0, 0, 0, 0.8);
            // color: rgba(0, 0, 0, 0.8);
            // border-radius: 4px 4px 0 0;
            // padding-bottom: 14px;
            // padding-top: 19px;
            // background-color: rgba(0, 0, 0, 0.03);
            // background-color: rgb(234, 237, 241);
            color: rgba(0, 0, 0, 0.8);
            font-weight: 600;
        }
    }
    .btn-text {
        align-items: flex-start;
    }
    .drop-trigger i:first-child {
        transition: none !important;
        width: 25px;
        text-align: right;
    }
}

.search-container {
    display: flex;
    align-items: center;
    gap: 12px;
    width: 100%;
}

.date-filter-button {
    font-size: 24px;
    color: $primary;
    text-align: center !important;
}

.date-filter-button {
  font-size: 24px;
  color: $primary;
  text-align: center !important;
}

.date-filter-button-wrapper {
    display: flex;
    justify-content: end;
    gap: 16px;
}

.date-filter-content {
    padding: 12px;

    label {
      color: #202020;
      font-weight: 600;
    }
}

.conversation-item {
    height: 90px;
    display: flex;
    padding: 20px;
    box-sizing: border-box;
    position: relative;
    border-bottom: solid 1px #efefef;
    transition: all 0.3s ease-out;

    &:hover {
        background-color: #f9f9f9;
    }
    &.active {
        background-color: #f2f3f7;
    }

    .customer-avatar {
        width: 42px;
        height: 42px;
        border-radius: 50%;
        object-fit: cover;
    }

    .info {
        margin-left: 20px;
        display: flex;
        flex-direction: column;

        .last-message {
            display: flex;
            align-items: center;
            margin: 12px 0 20px 0;
            font-size: 13px;
            color: #7c818b;
            i {
                font-size: 16px;
                color: #7c818b;
                margin-right: 5px;
                margin-bottom: 3px;
            }
        }

        .name {
            font-size: 14px;
            text-transform: capitalize;
        }
    }

    .channel {
        display: flex;
        align-items: center;
        img {
            width: 12px;
            height: 12px;
            margin-right: 6px;
        }
    }

    .date {
        position: absolute;
        right: 30px;
        color: #90a4ae;
    }
}

.arrow-left {
    width: 0;
    height: 0;
    border-top: 10px solid transparent;
    border-bottom: 10px solid transparent;
    border-right: 10px solid #f2f4fb;
    top: 12px;
    position: absolute;
    left: -7px;
    z-index: 0;
    content: "";
    // border-right-color: #b1e5fd;
    // border-right-color: $balloon;
}

.inbox-user-title {
    font-weight: 600;
    margin-left: 17px;
    margin-top: 20px;
    font-size: 16px;
    color: #2a2a2a !important;
}

.inbox-filter-drop {
    .menu-dropdown {
        width: 260px;
    }
}

.inbox-filters-table-wrap {
    .ReactTable .rt-thead.-header {
        display: none;
    }
}

.button-with-count {
    .btn-text {
        display: flex;
        align-items: center;
        justify-content: space-between;
        width: 100%;
    }
}

/* Chat
=================================== */
.chat {
    display: flex;
    flex-direction: column;
    height: 100%;
    flex: 1;
    overflow: hidden;
    // padding: 0 15px;
    // padding: 0 20px;
    // border-right: 20px solid #fafafa;
    // border-left: 20px solid #fafafa;
}

.sidebar .context-header {
    padding-left: 0;
    padding-right: 0;
}

.chat .context-header {
    padding-left: 0;
}

.chat .context-header,
.sidebar {
    min-height: 44px;
    border-bottom: 1px solid #edebe9;
    // padding-right: 50px;
    .drop-trigger {
        border-radius: 0;
    }
    .context-header-button {
        height: 43px;
    }
    .normal-trigger {
        height: 43px;
        border-radius: 0;
        padding-left: 12px;
        .name-space {
            align-items: center;
            display: flex;
        }
        .name {
            display: block;
            max-width: 115px;
        }
        i {
            font-size: 17px;
        }
    }
}

.chat-inner-layout {
    display: flex;
    flex: 1;
    overflow: hidden;
    .left {
        flex: 1;
        flex-direction: column;
        display: flex;
        overflow: hidden;
        overflow: auto;
    }
    .right {
        display: flex;
    }
}

.chat-inner {
    flex: 1;
    display: flex;
    flex-direction: column;
    // border-right: solid 1px #e0e4ea;
    position: relative;
    overflow-y: scroll;
    overflow-x: hidden;
}

.chat-midcol {
    box-sizing: border-box;
    // max-width: 1200px;
    margin: 0 auto;
    width: 100%;
}

.chat-header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    // padding: 9px 5px 8px 30px;
    // padding: 7px 5px 6px 20px;
    background-color: #f4f4f4;

    .left .drop-trigger {
        border-radius: 50%;
        padding: 2px;
    }
}

.chat-header-separator {
    width: 1px;
    box-shadow: inset -1px 0 0 rgba(100, 121, 143, 0.122);
    height: 20px;
    margin: 0 17px 0 5px;
}

.chat-box {
    // background: url(https://assetscdn-web.freshchat.com/agent/static/assets/images/pattern_agent_2-31032ee7c45d4e01cffc497de57e3168.png)
    //     #f7f7ff;
    // background-color: white;
    background-size: 200px 200px;
    position: relative;
    height: 100%;
    display: flex;
    flex-direction: column;
    outline: none;
}

.chat-actions-box {
    // border-top: 1px solid rgba(0, 0, 0, 0.1);
    // padding: 10px 30px 30px;
    display: flex;
    flex-direction: column;
    position: relative;

    // .chat-actions-box-inner {
    //     padding: 10px 25px 30px;
    // }

    .separator {
        border-top: 1px solid #ddd;
        width: 100%;
        height: 1px;
    }

    .send {
        border-radius: 50%;
        width: 30px;
        height: 30px;
        padding: 0;
        display: flex;
        align-items: center;
        justify-content: center;
        i {
            font-size: 16px;
        }
        &[disabled] {
            background-color: rgb(212, 214, 217);
            i {
                color: rgb(119, 120, 122);
                // color: rgba(0, 0, 0, 0.15);
            }
        }
    }
}

.inbox-separator {
    border-top: 1px solid #ddd;
    width: 100%;
    height: 1px;
    margin-top: 20px;
}

.inbox-conv-inner {
    // padding: 0 22px;
    // padding: 0;
    margin-top: -12px;
}

.no-data-conversations {
    margin: 21px 17px 10px;
    padding: 11px 20px;
    border-radius: 12px;
    display: flex;
    align-items: center;
    border: 1px dashed rgba(0, 0, 0, 0.1);
}

.people-data {
    // width: 420px;
    width: 300px;
    background: #f8f8f8;
    height: 100%;
    border-left: 1px solid $border-color;
    // display: none;
    // overflow-y: auto;
}
.people-data-inner {
    padding: 25px;
}

.quill {
    font-family: $font_family;
    // font-size: 14px;
    // border: 1px solid rgb(232, 234, 237);
    // border-radius: 6px;
    // background-color: #f5f5f7;
    border-top: 1px solid #dcdcdc;
    flex: 1;
    padding-right: 210px;
    overflow: hidden;
    background-color: white;
    min-height: 64px;

    .ql-editor {
        padding: 15px 20px;
        font-family: $font_family;
        max-width: calc(100% - 40px);

        img {
            display: block;
            margin-bottom: 5px;
            max-height: 100px;
        }
    }

    .ql-editor.ql-blank::before {
        color: #444352;
        // font-family: apple-system, BlinkMacSystemFont, "Segoe UI", "Helvetica Neue", Helvetica,
        //     Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";
        font-family: "Segoe UI", "Segoe UI Web (West European)", "Segoe UI", -apple-system,
            BlinkMacSystemFont, Roboto, "Helvetica Neue", sans-serif;
        font-style: normal;
        font-size: 14px;
        opacity: 0.5;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        margin-right: 40px;
        max-width: 400px;
    }
    img {
        max-height: 200px;
    }
}

.blot-file {
    padding: 5px 12px;
    background-color: rgba(0, 0, 0, 0.05);
    border: 2px dashed rgba(0, 0, 0, 0.1);
    margin-bottom: 10px;
}

.isPrivate .quill {
    background: #fff9d9;
    // border-top-left-radius: 5px;
    // border-top-right-radius: 5px;
    border-color: #f2b161;
    .ql-editor.ql-blank::before {
        color: #ecbd82;
    }
}

.chat-actions-box .actions-space {
    position: absolute;
    top: 12px;
    right: 20px;
}

.chat-actions-box .actions {
    display: flex;
    align-items: center;
    .action-tab {
        padding: 10px 15px;
        display: flex;
        justify-content: center;
        align-items: center;
        font-size: 14px;
        color: #ababab;
        border-radius: 5px;
        // border-top: 1px solid #ddd;

        &.active {
            // border-top: 1px solid $primary;
            color: black;
            font-weight: 600;
        }
        &:hover {
            background: #f5f5f5;
        }
    }

    .action-button {
        border-radius: 3px;
        transition: 0.3s all;
        display: flex;
        justify-content: center;
        align-items: center;
        height: 30px;
        width: 30px;
        margin-right: 2px;
        // margin-left: 5px;

        i {
            color: #9298a7;
            font-size: 18px;
        }
        &:hover {
            background-color: #f5f5f5;
            color: #5d657a;
        }
    }
}

.channel-indicator {
    padding: 7px 16px 7px 14px;
    top: 13px;
    font-size: 13px;
    background-color: #dbdfe9;
    border-radius: 25px;
    font-weight: 500;
    text-transform: capitalize;
    // box-shadow: 0 1px 1px rgba(0, 0, 0, 0.3);
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
    width: 150px;
    position: absolute;
    transform: translateX(-50%) translateY(0);
    left: 50%;
    text-align: center;
    z-index: 9;
    i {
        font-size: 14px;
        margin-right: 7px;
    }
}

.channel-indicator-inner {
    display: flex;
    align-items: center;
    justify-content: center;
}

.chat-balloon {
    display: inline-block;
    line-height: 1.24;
    max-width: 700px;
    word-wrap: break-word;
    position: relative;
    font-size: 14px;
    background-color: #f1f0f0;
    padding: 6px 10px;
    border-radius: 8px;
    min-width: 8px;
    &:has(img) {
        max-width: 300px;
    }

    img {
        cursor: zoom-in;
        max-height: 350px;
        object-fit: cover;
        object-position: top;
        width: 100%;
        border-radius: 4px;
    }
    audio {
        border-radius: 4px;
        outline: none;
    }

    video {
        border-radius: 4px;
    }

    a {
        text-decoration: underline;
    }

    .chat-text {
        max-width: 700px;
        // white-space: pre-wrap;
        word-break: break-word;
        position: relative;
        z-index: 9;

        & span {
            background-color: transparent !important;
        }

        & p {
            display: flex;
            flex-direction: row;
            align-items: flex-start;
            flex-wrap: wrap;
            &:has(img) {
                flex-direction: column;
            }
            &:has(audio) {
                flex-direction: column;
            }
            &:has(video) {
                flex-direction: column;
            }
        }
    }
    .reply-to {
        background: rgba(0, 0, 0, 0.2);
        padding: 4px 8px 4px 8px;
        border-radius: 10px;
        position: relative;
        margin-bottom: 4px;
        display: flex;
        flex-direction: column;
        // &:before {
        //     content: " ";
        //     width: 3px;
        //     height: 100%;
        //     background-color: rgba(0, 0, 0, 0.3);
        //     display: block;
        //     left: -1px;
        //     position: absolute;
        //     top: 0;
        //     border-radius: 4px 0 0 4px;
        // }
        span:first-child {
            font-weight: 600;
            font-size: 13px;
        }
    }
    .message-actions {
        position: absolute;
        right: -50px;
        top: 5px;
        display: none;
        & > div {
            display: flex;
            align-items: center;
        }

        &.no-delete {
            right: -25px !important;
        }
    }
    .removed-message {
        font-weight: 600;
        color: #202020;
    }

    .time {
        margin-bottom: 4px;
        font-size: 10px;
        color: rgba(0, 0, 0, 0.7);
        text-align: right;
        display: block;
        width: 100%;
    }
}

.agentMessage .message-actions {
    left: -50px;
}

.agentMessageWithoutDelete .message-actions {
    left: -30px;
}

.message-box:hover .message-actions {
    display: inline-block;
}

.customer {
    // .chat-balloon {
    //     background-color: $balloon;
    //     color: #000 !important;
    // }
    .arrow-left {
        // border-right-color: #f2f4fb;
        border-right-color: $balloon;
    }
}

.chatWhatsapp .customer {
    .chat-balloon {
        background-color: rgb(199, 231, 200);
    }
    .arrow-left {
        border-right-color: rgb(199, 231, 200);
    }
}
.chatEmail .customer {
    .chat-balloon {
        // background-color: rgb(209, 196, 233);
        // background-color: rgb(225, 189, 232);
        border-width: 1px;
        border-style: solid;
        border-color: rgb(232, 232, 232);
        background-color: #fff;
        border-color: rgba(0, 0, 0, 0.08);
        box-shadow: rgba(0, 0, 0, 0.08) 0px 1px 6px, rgba(0, 0, 0, 0) 0px 4px 30px;
        padding: 12px 16px;
    }
    .arrow-left {
        border-right-color: rgb(225, 189, 232);
    }
}

.agentMessage .chat-balloon {
    background-color: $primary;
    color: white;
    word-break: break-word;
    a {
        background-color: $primary !important;
        color: white !important;
        text-decoration: underline;
        margin: 0 0.3125rem;
    }

    & span {
        background-color: transparent !important;
        color: white !important;
    }

    i {
        position: absolute;
        right: 8px;
        top: 10px;
    }
    
}

.private {
    .chat-balloon {
        background-color: #fff9d9;
        border: 1px solid #f2b161;
        color: #2a2a2a;

        a {
            background-color: #fff9d9 !important;
            color: #2a2a2a !important;
            position: relative;
            z-index: 999;
            text-decoration: underline;
        }
    }
    .arrow-left {
        border-right-color: #fff4bb;
    }
}
// .bot {
//     .chat-balloon {
//         background-color: transparent;
//         // background-color: #cfc7f4;
//     }
//     .arrow-left {
//         border-right-color: #cfc7f4;
//     }
// }

.system {
    .message-content {
        justify-content: center;
        flex: 1;
        margin: 0 !important;
    }
    .chat-balloon {
        background-color: transparent !important;
        color: #546e7a !important;
        padding: 0 8px;
        width: auto;
        white-space: nowrap;
        font-weight: 500;
        font-size: 13px;
        opacity: 0.5;
        // margin-left: auto;
        font-style: italic;
        box-shadow: none;
        border: none;
        margin: 12px auto;
        // color: rgb(155, 156, 158) ;
    }
    .arrow-left {
        border-right: none;
    }
    img {
        display: none;
    }
    .message-author-avatar {
        display: none;
    }
}

.message-box {
    display: flex;
    align-items: flex-start;
    // align-items: center;
}

.sent-status {
    margin-left: 44px;
    color: #949db2;
    font-size: 12px;
    opacity: 0;
}

.messages {
    display: flex;
    margin-top: auto;
    max-height: 100%;
    height: 100%;
    // padding-top: 20px;

    .messages-inner {
        width: 100%;
        max-height: 100%;
        padding: 0 30px;
        box-sizing: border-box;
    }

    .message {
        margin-bottom: 40px;
        &.system {
            margin-bottom: 20px;
            margin-top: -15px;
        }
        // display: flex;
        // align-items: center;
        &.deleted .chat-balloon {
            background-color: #f7d7d5;
        }
    }

    .message-content {
        display: flex;
        flex-direction: column;
        position: relative;
        // align-items: center;
        // flex: 1;
    }

    .message-author,
    .message-date {
        font-size: 11px;
        color: #697379;
        // padding-left: 37px;
        font-weight: 400;
        // margin-left: auto;
        margin-bottom: 4px;
        // visibility: hidden;
    }

    .message-date {
        margin-top: 4px;
        margin-left: 20px;
        font-size: 11px;
        color: #949db2;
        margin: 0;
        padding: 0;
        position: absolute;
        top: -22px;
        min-width: 220px;
        font-size: 0.75rem;
        color: #808080;
        font-weight: 400;
        text-transform: uppercase;
        text-transform: initial;
        display: flex;
        justify-content: flex-start;
        align-items: center;
        cursor: default;
        .time {
            margin: 0 5px;
        }
        i {
            visibility: hidden;
        }
    }
    .user-name {
        font-size: 0.75rem;
        color: #202020;
        font-weight: 600;
        text-transform: uppercase;
        text-transform: capitalize;
        margin-right: 5px;
    }
    // .message-content:hover .message-date {
    //     visibility: unset;
    // }
    .message-content:hover .message-date i {
        visibility: unset;
    }

    .message-author-avatar {
        line-height: 0;
        min-width: 28px;
        margin-top: 2px;
        img {
            width: 28px;
            height: 28px;
            border-radius: 50%;
            margin-right: 8px;
            object-fit: cover;
        }
        .default-letter {
            margin-right: 8px;
        }
    }

    // &.isFetching {
    //     opacity: 0.5;
    //     transition: opacity 0.5;
    // }
}

.message.agentMessage:hover .sent-status,
.last-agent .sent-status {
    opacity: 1;
}

// .sent-status {
//     opacity: 1;
// }

.send-indicator {
    i {
        position: absolute;
        right: 4px;
        bottom: 2px;
        color: #9ba2b4;
        font-size: 12px;
    }
}

.date-separator {
    display: flex;
    align-items: center;
    margin-bottom: 22px;
    opacity: 0.5;
    .line {
        background-image: linear-gradient(90deg, transparent, #c5d0d5);
        width: 50%;
        background-size: 100% 1px;
        background-position: 0 0, 0 100%;
        background-repeat: no-repeat;
        height: 2px;
        display: inline-block;
        opacity: 0;
        &.right {
            background-image: linear-gradient(90deg, #c5d0d5, transparent);
        }
    }
    .date {
        color: #546e7a;
        padding: 0 8px;
        width: auto;
        white-space: nowrap;
        font-weight: 500;
        font-size: 13px;
        margin: 20px auto;
    }
}

/* CHAT DATA
=================================== */
.people-data-section {
    padding: 0 8px 20px;
    // border-bottom: 1px solid #e0e4ea;
    font-size: 14px;
    font-weight: 400;
    overflow-x: hidden;
}

.user-card-basic {
    margin: 0 12px;
    padding: 15px 0 0;
    // border-bottom: 1px solid #f2f5f7;
    display: flex;
    flex-direction: column;
    // align-items: center;

    .user-image-big {
        width: 100%;
        height: 160px;
        border-radius: 6px;
        object-fit: cover;
        background-color: #eff1f3;
        cursor: zoom-in;
        margin-bottom: 15px;
    }
    .user-card-name .contact-property-value {
        font-size: 14px;
        font-weight: 700;
        text-transform: capitalize;
    }
    .user-card-name .editable-property {
        padding: 0;
        // border-radius: 4px;
    }
}

.user-card-details {
    font-weight: 400;
    margin: 0 12px;
    // padding: 15px 0 0;
    // border-bottom: 1px solid #F2F5F7;
}

.people-data-row {
    display: flex;
    padding: 6px 0;
    .left {
        flex: 1;
        color: #7c818b;
    }
    .right {
        flex: 2;
        display: flex;
        align-items: center;
        overflow: hidden;
        text-overflow: ellipsis;
        display: block;
    }
}

.people-data-section-title {
    font-size: 12px;
    font-weight: 700;
    text-transform: uppercase;
    line-height: 1;
    color: #2c3b48;
    margin: 20px 15px;
    position: relative;
}

/* Chat Data -> PROPRIEDADES
=================================== */
.add-property {
    position: absolute;
    right: 0;
    top: 0;
    button {
        border-radius: 2px;
        box-sizing: border-box;
        padding: 0;
        display: flex;
        justify-content: center;
        align-items: center;
        margin: 0;
        background: $primary-lighter;
        height: 24px;
        width: 24px;
    }
    i {
        color: $primary;
        font-size: 18px;
    }
}

.isSameUser {
    margin-top: -36px;
    .message-author-avatar {
        visibility: hidden;
    }
    .arrow-left {
        display: none;
    }
    .message-date {
        visibility: hidden;
    }
}

/* Dropzone chat
===================================*/
.chat-drag-active {
    position: absolute;
    z-index: 99;
    background-color: rgba(255, 255, 255, 0.95);
    width: 100%;
    height: 100%;
    top: 0;
}
.chat-drag-active-inner {
    display: flex;
    align-items: center;
    justify-content: center;
    border: 2px dashed $primary;
    height: calc(100% - 50px);
    box-sizing: border-box;
    border-radius: 15px;
    margin: 25px;
    h1 {
        font-size: 25px;
        color: $primary;
    }
}

.attach-file-wrap {
    padding: 12px;
    border-radius: 4px;
    background-color: rgba(0, 0, 0, 0.05);
    border: 2px dashed rgba(0, 0, 0, 0.05);
    img {
        max-height: 110px;
        object-fit: contain;
    }
}

.attach-file-item {
    padding: 12px;
    background-color: rgba(0, 0, 0, 0.05);
    border-radius: 7px;
    display: flex;
    align-items: center;
    margin-bottom: 10px;
    border: 2px dashed rgba(0, 0, 0, 0.1);
    i {
        font-size: 20px;
        margin-right: 12px;
    }
    span {
        font-size: 15px;
    }
}

.ql-video {
    width: 498px;
    height: 280px;
}
.ql-image {
    display: block;
    // height: 180px;
    // min-width: 180px;
    // max-height: none !important;
    // object-fit: contain;
    // &:active {
    //     height: 380px;
    // }
}

.image-modal {
    img {
        max-width: calc(100% - 40px);
        margin: 40px 20px;
        max-height: 80dvh;
    }
}

.resolve-space {
    height: 40px;
    background-color: $pending-color;
    color: rgba(0, 0, 0, 0.7);
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 16px;
    font-weight: 500;
    border-radius: 0 0 40px 40px;
    position: absolute;
    top: 0;
    z-index: 99;
    width: 100%;
    transition: height 85ms;
    &.resolved {
        background-color: #34bb1f;
        color: white;
    }
    &:hover {
        height: 50px;
    }
}

.user-custom-data {
    border-bottom: 1px solid #f0f0f0;
    // padding: 5px 15px 15px 15px;
    padding: 15px 20px;
    margin-top: -35px;
    .editable-property {
        // border-bottom: 1px dashed rgba(0, 0, 0, 0.1);
    }
    .people-data-row.readable-row {
        flex-direction: column;
    }
    .left {
        font-weight: 600;
        font-size: 12px;
        text-transform: capitalize;
        color: black;
    }
    // .right {
    //     opacity: 0.5;
    // }
}

.tab-badge {
    // background-color: #f04747;
    // // background-color: $primary;
    // min-width: 8px;
    // height: 14px;
    // font-size: 10px;
    // padding: 1px 4px;
    // color: white;
    // border-radius: 150px;
    // font-weight: 800;
    // display: block;
    // position: absolute;
    // right: -16px;
    // top: -8px;
    background-color: rgba(0, 0, 0, 0.05);
    min-width: 8px;
    height: 14px;
    font-size: 10px;
    padding: 1px 4px;
    border-radius: 150px;
    font-weight: 800;
    display: block;
    margin-left: 5px;
}

.compact-user-inbox {
    width: 100%;
    // padding: 8px 0;
    // padding: 2px 0 12px;
    .details {
        margin-left: auto;
        display: flex;
        flex-direction: column;

        i {
            background-color: $pending-color;
            border-radius: 50%;
            width: 20px;
            height: 20px;
            // padding: 3px;
            color: white;
            display: flex;
            align-items: center;
            justify-content: center;
            font-size: 11px;
        }
    }
    .time {
        margin-bottom: 4px;
        font-size: 12px;
        color: rgba(0, 0, 0, 0.7);
    }
}

.inbox-customer-avatar {
    position: relative;
}
.conversation-channel-indicator {
    width: 18px;
    height: 18px;
    position: absolute;
    bottom: -1px;
    right: 9px;
    /* border: 0px solid #fafafb; */
    border-radius: 50%;
    object-fit: cover;
}

.conversations-wrapper-content {
    height: 100%;
    display: flex;
    flex-direction: column;
}

.conversations-header.bottom {
    border-top: 1px solid rgba(0, 0, 0, 0.1);
    min-height: 60px;
    height: 60px;
    position: relative;
    padding: 0 0;
    .tab-header {
        padding: 0;
        margin: 0;
    }
    .row {
        padding: 0;
        margin: 0;
    }
}

.ql-container {
    font-size: 14px;
}

// .inbox .conversations-wrapper .cell-avatar {
//     width: 55px !important;
//     height: 55px !important;
// }

.inbox .ReactTable .rt-tbody {
    margin-top: 0;
}

.newMessageBadge {
    display: none;
}

.hasNewMessages {
    position: relative;
    .people-name-email span {
        position: relative;
        font-weight: 800;
        color: black;
        > &:last-child {
            opacity: 1 !important;
            font-weight: 400;
        }
    }

    .people-name-email .people-name {
        display: flex;
    }

    .newMessageBadge {
        text-align: center;
        color: white !important;
        font-size: 11px;
        padding: 0px 6px 3px;
        height: 16px;
        border-radius: 40px;
        margin-left: 5px;
        margin-top: 2px;
        display: none;
        background-color: #feca57;
    }
}

.finished-chat-footer {
    border-top: 1px solid #ececec;
    height: 64px;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: flex-end;
    padding: 0 20px;
    box-sizing: border-box;
}

.closed-conversation-banner {
    display: flex;
    padding: 8px 10px 8px 30px;
    background-color: #dfefe1;
    flex-shrink: 0;
    font-size: 0.875rem;
    color: rgb(41, 148, 56);
    font-weight: 400;
    line-height: 1;
    align-items: center;
    height: 40px;
    box-sizing: border-box;
    i {
        color: rgb(41, 148, 56);
        font-size: 16px;
        margin-right: 15px;
    }
}

/*
* Conversation Item
======================================= */
.message-item {
    width: 100%;
    // padding: 10px 20px 10px 0;
    flex: 1;
    display: flex;
    background-color: white;
    border-bottom: 1px solid #ececec;
    &:hover {
        background-color: #ececec;
    }
    &.selected {
        background-color: #e2f0ff;
        .message-item-content {
            border-color: #d8e6f4;
        }
    }

    .unread-icon {
        i {
            color: rgb(63, 130, 239);
            font-size: 10px;
        }
    }

    .what-channel {
        font-size: 11px;
        margin-left: 8px;
        background-color: rgba(0, 0, 0, 0.05);
        padding: 0 5px;
        border-radius: 25px;
        min-width: 14px;
        text-align: center;
        color: rgba(0, 0, 0, 0.3);
    }

    .message-item-content {
        display: flex;
        flex: 1;
        padding: 10px 20px 10px 0;
        // border-bottom: 1px solid #ececec;
        width: calc(100% - 30px);
        box-sizing: border-box;
    }
    .mid {
        display: flex;
        align-items: center;
        justify-content: center;
        margin-right: 12px;
        position: relative;
        width: 48px;
        height: 65px;
        img {
            width: 48px;
            height: 48px;
            object-fit: cover;
            border-radius: 50%;
        }
        .channel-indicator-conversation {
            position: absolute;
            bottom: 5px;
            right: 0px;
            width: 24px;
            height: 24px;
            background-color: white;
            border-radius: 50%;
            z-index: 1;
        }
        .channel-bg {
            background-position: center;
            background-size: cover;
            width: 17px;
            height: 17px;
            display: block;
            z-index: 1;
            margin: auto;
            margin-top: 3px;
            margin-left: 4px;
        }
    }

    .left {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 30px;
        min-width: 30px;
    }
    .bottom {
        font-size: 0.75rem;
        color: #808080;
        font-weight: 400;
        line-height: 1.8;
        line-height: 1.6;
        // max-height: 38px;
        overflow: hidden;
        max-height: 42px;
    }
    .right {
        flex: 1;
        overflow: hidden;
        .top {
            display: flex;
            align-items: center;
            justify-content: space-between;
            flex: 1;
        }
    }
    .user-name {
        margin: 0;
        font-size: 0.875rem;
        color: #202020;
        font-weight: 600;
        line-height: 1.7;
        text-transform: capitalize;
        // display: flex;
        // align-items: center;
    }
    .last-response-time {
        font-size: 0.75rem;
        color: #808080;
        font-weight: 400;
        line-height: 1.8;
        margin-left: 7px;
    }
    .cell-avatar.default-letter {
        margin: 0;
    }
    .more-bottom {
        display: flex;
        align-items: center;
        .what-channel {
            margin-left: 0;
        }
    }
    &.assigned {
        .cell-avatar,
        .person-avatar-inbox {
            width: 32px !important;
            height: 32px !important;
            min-width: 32px !important;
            margin-top: -22px;
            margin-right: -9px;
            font-size: 19px !important;
        }
        .user-agent {
            position: absolute;
            bottom: 4px;
            left: -6px;
            img {
                width: 32px !important;
                height: 32px !important;
                border: 2px solid white;
                background-color: white;
            }
        }
        .channel-indicator-conversation {
            bottom: 7px;
            right: -3px;
            background-color: transparent;
        }
    }
    .tag-box-item {
        background-color: rgb(52, 128, 234);
        font-size: 11px;
        padding: 2px 7px;
    }
    .finished-icon {
        font-size: 14px;
    }
}

.inbox-conversation-item {
    // height: 81px;
    width: 100%;
    // border-bottom: 1px solid $border-color;
    border-bottom: 1px solid rgba(0, 0, 0, 0.04);
    padding: 18px 14px;
    display: flex;
    flex-direction: column;
    box-sizing: border-box;

    .top {
        display: flex;
        justify-content: space-between;
    }

    .cell-avatar.default-letter {
        margin-right: 8px;
    }
    .person-avatar-inbox {
        width: 20px;
        height: 20px;
        border-radius: 50%;
        object-fit: cover;
        margin-right: 8px;
    }
    .inbox-channel-indicator {
        // display: none;
        text-align: center;
        img {
            width: 16px;
            height: 16px;
            margin-top: 3px;
        }
    }
    .left {
        display: flex;
        flex: 1;
        overflow: hidden;
        align-items: center;
        .col {
            & > span {
                color: rgb(62, 62, 64);
                white-space: nowrap;
                text-overflow: ellipsis;
                overflow: hidden;
                font-size: 14px;
            }
        }
        .col-2 {
            display: flex;
            flex-direction: column;
            margin-left: 12px;
            margin-top: -4px;
            line-height: 1.6;
            margin-right: 10px;
            overflow: hidden;
        }
    }
    .user-name {
        flex-shrink: 2;
        font-weight: 600;
        font-size: 13px;
        font-weight: 500;
        opacity: 0.5;
    }

    .user-tags {
        display: flex;
        align-items: center;
        margin-top: 5px;
    }

    .user-tag {
        display: inline-block;
        color: rgb(242, 131, 11);
        background-color: rgb(255, 247, 240);
        padding: 1px 3px;
        border-radius: 2px;
        border-width: 1px;
        border-style: solid;
        border-color: rgb(250, 218, 185);

        line-height: 12px;
        font-size: 10px;
        font-weight: 600;
        text-transform: uppercase;
        letter-spacing: 0.17px;
        white-space: nowrap;
        text-overflow: ellipsis;
        overflow: hidden;

        i {
            margin-right: 5px;
            font-size: 9px;
            margin-left: 2px;
        }
        &.isFinished {
            color: rgb(16, 170, 64);
            border-color: rgb(16, 170, 64);
            background-color: rgb(238, 251, 243);
        }
    }
    span.user-response {
        margin: 7px 0 0;
        // border-radius: 25px;
        overflow: hidden;
        display: flex;
        align-items: flex-start;
        font-size: 14px;
        opacity: 0.5;
    }
    .left .col > span.user-channel {
        color: rgb(155, 156, 158);
        font-size: 13px;
        display: flex;
        align-items: center;
        i {
            font-size: 15px;
            margin-right: 7px;
        }
    }
    .last-response-time {
        margin-left: 5px;
    }
    .last-response-time,
    .icon-channel {
        color: rgb(155, 156, 158);
    }
    .icon-channel {
        width: 18px;
        text-align: center;
        // margin-top: 4px;
        font-size: 14px;
    }
    &.selected {
        background-color: $primary;
        border-color: $primary;
        color: white;
        .left .col > span {
            color: white !important;
        }
        .user-tag {
            // background-color: transparent;
            // border-color: transparent;
            // color: white !important;
        }
        .last-response-time,
        .icon-channel {
            color: rgba(255, 255, 255, 0.8);
        }
        span.user-response,
        .user-name {
            opacity: 1;
        }
        .fa-archive {
            color: white !important;
            opacity: 0.9;
        }
        .user-agent {
            color: white;
        }
    }

    .right {
        display: flex;
        flex-direction: column;
        align-items: center;
        i {
            // background-color: #00b653;
            // background-color: #feca57;
            border-radius: 50%;
            width: 15px;
            height: 15px;
            // color: #fff;
            display: flex;
            align-items: center;
            justify-content: center;
            font-size: 15px;
            margin-top: 4px;
        }
    }
    .circles {
        display: flex;
        align-items: center;
    }
    .user-agent {
        border-radius: 25px;
        border: 1px solid rgba(0, 0, 0, 0.09);
        padding: 2px;
        align-items: center;
        justify-content: center;
        height: 22px;
        box-sizing: border-box;
        display: flex;
        flex-direction: row;
        padding: 2px 5px;
        color: #9b9c9e;
        font-weight: 500;
        img {
            object-fit: cover;
            border-radius: 50%;
            width: 16px;
            // margin-left: 5px;
            height: 16px;
            z-index: 1;
            margin-right: 7px;
            // border: 2px solid #fff;
        }
        .cell-avatar {
            margin-right: 7px;
        }
    }

    .more-bottom {
        display: flex;
        align-items: center;
        justify-content: space-between;
        margin-top: 7px;
    }
    .what-channel {
        border-radius: 25px;
        background-color: rgba(0, 0, 0, 0.05);
        padding: 4px 7px;
        font-weight: 800;
        color: rgba(0, 0, 0, 0.3);
        font-size: 10px;
        text-transform: uppercase;
        font-family: Roboto;
        margin-right: 6px;
        display: flex;
    }
    &.hasNewMessages {
        .user-name {
            opacity: 0.8;
            font-weight: 700;
        }
        .user-response {
            opacity: 1;
            font-weight: 500;
        }
    }
}

.empty-inbox-panel {
    display: flex;
    justify-content: center;
    align-items: center;
    flex: 1;
    // background-color: #faf9f8;
    z-index: -1;
    .empty-inbox-inner {
        display: flex;
        flex-direction: column;
        align-items: center;
        text-align: center;
        // strong {
        //     color: #808080;
        // }
    }
    .empty-inbox-details {
        // margin-top: 30px;
        color: #959da5;
        margin-top: -15px;
    }
    .empty-top {
        font-weight: 600;
        font-size: 19px;
        line-height: 29px;
        color: #545c77;
        letter-spacing: 0;
        text-align: center;
    }
    .empty-bottom {
        // margin-top: 8px;
        font-weight: 400;
        font-size: 16px;
        color: rgba(84, 92, 119, 0.5);
        letter-spacing: 0;
        text-align: center;
        line-height: 27px;
        max-width: 80vw;
        margin: 5px auto auto;
        max-width: 350px;
    }
    .empty-small {
        font-size: 12px;
    }
    .empty-image {
        // background: url(../assets/icons/svg/emptyMessage.svg);
        background: url(../assets/images/empty-inbox.png);
        background-repeat: no-repeat;
        background-size: contain;
        width: 360px;
        height: 260px;
        // margin-bottom: 30px;
        // opacity: 0.2;
        // &.not-checked {
        //     background-image: url(../assets/icons/svg/emptyMailNotChecked.svg);
        // }
    }
}

.header-mock-shadow {
    height: 43px;
    width: 100%;
    border-bottom: 1px solid #edebe9;
    background-color: #f4f4f4;
}

.chat-actions-box .actions {
    .drop-trigger {
        margin-right: 7px;
    }
    .icon-trigger {
        width: 20px;
        height: 20px;
        font-size: 20px;
        padding: 4px;
        box-sizing: content-box;
    }
}

.chat-actions-box-inner > form {
    display: flex;
}

.isPrivate {
    .icon-trigger {
        color: #f2b161;
    }
    .drop-trigger:hover {
        background-color: rgba(196, 149, 48, 0.1);
        .icon-trigger {
            color: #f2b161;
        }
    }
}

.message.private,
.message.agentMessage {
    .message-box {
        flex-direction: row-reverse;
    }
    .message-content {
        flex-direction: row-reverse;
        margin-right: 8px;
    }
    .message-date {
        // opacity: 0;
        margin-left: 0;
        // margin-right: auto;
        // margin-right: 20px;
        margin-right: 0;
        flex-direction: row-reverse;
    }
    .message-author-avatar img {
        margin-right: 0;
    }
    &:hover {
        .message-date {
            opacity: 1;
        }
    }
}

.chat-header {
    // height: 61px;
    // border-bottom: 1px solid $border-color;
    width: 100%;

    .title-name {
        // white-space: nowrap;
        // text-overflow: ellipsis;
        // overflow: hidden;
        // color: rgb(62, 62, 64);
        // font-size: 18px;
        // font-weight: 700;
        // letter-spacing: 0.23px;
        font-size: 1rem;
        color: #202020;
        font-weight: 600;
        line-height: 1.8;
    }

    .icon-trigger {
        color: rgb(155, 156, 158);
    }
    .normal-trigger {
        height: 24px;
    }
}

.chat-header-inner {
    display: flex;
    flex-direction: column;
    flex: 1;
    .top {
        display: flex;
        // align-items: center;
        justify-content: space-between;
        flex-wrap: wrap;
        .left {
            // flex: 1;
            .actions {
                flex-wrap: wrap;
            }
        }
    }
    .actions {
        height: 100%;
    }
    .bottom {
        display: flex;
        align-items: center;
        // margin-top: 10px;
    }
    .right {
        display: flex;
        // align-items: center;
        max-height: 34px;
        flex: 0 0 auto;
    }
    & > .left {
        display: flex;
        flex-direction: column;
    }
    .drop-trigger {
        // background-color: transparent;
        margin-right: 12px;
        .icon-trigger {
            color: #808080;
        }
        &:hover .icon-trigger {
            color: #202020;
            // font-weight: 400;
        }
    }
    .normal-trigger {
        padding: 0px 10px 0px 10px;
        height: 26px;
        // border-color: transparent;
        // background-color: rgba(93, 105, 133, 0.1);
        i {
            margin-right: 7px;
        }
        // &:hover {
        //     background-color: #e2f0ff;
        // }
        .left,
        .name {
            font-size: 12px;
            color: #808080;
            font-weight: 400;
        }
        & > svg {
            display: none;
        }
    }
    .tag-box {
        flex-wrap: wrap;
        margin-top: 7px;
        .menu-drop {
            margin-bottom: 2px;
        }
    }
    .tag-box .drop-trigger {
        margin-right: 0;
        // margin-bottom: 7px;
    }
    .tag-box-item {
        margin-right: 4px;
    }
}

.chat-inner-layout {
    .tag-box {
        flex-wrap: wrap;
        .menu-drop {
            margin-top: 4px;
        }
    }
}

.drop-trigger.resolved {
    // background-color: #2ecc71;
    i {
        color: #2ecc71 !important;
        // color: #fff;
    }
    &:hover i {
        color: #27ae60;
    }
}
.drop-trigger.favorited {
    // background-color: #2ecc71;
    i {
        color: #ffbf43 !important;
        // color: #fff;
    }
    &:hover i {
        color: #ffbf43;
    }
}

.no-conversations,
.loadingCenter.conversation-loading {
    font-weight: 600;
    font-size: 16px;
    display: flex;
    align-items: center;
    justify-content: center;
    height: calc(100vh - 150px);
}

.no-conversations {
    color: #cdd0d4;
    display: flex;
    flex-direction: column;
    // opacity: 0.5;
}

.email-balloon-header {
    font-weight: 700;
    color: black;
    line-height: 21px;
    font-size: 13px;
    margin-bottom: 10px;
    .row span:first-child {
        width: 60px;
        display: inline-block;
    }
}

.chat.viewOnly {
    .messages-inner {
        padding: 0 15px;
    }
    .tag-box {
        pointer-events: none;
    }
    .chat-balloon {
        padding: 6px 9px;
    }
    .title-name {
        margin-right: 50px;
    }
    .chat-header {
        border-radius: 4px 4px 0 0;
    }
}

.isInbox {
    border-right: 0;
    .sidebar-content {
        border-right: 1px solid #edebe9;
    }
}

.modal-text-editor {
    .chat-actions-box-inner {
        border: 1px solid #dcdcdc;
        border-radius: 3px;
        & > form {
            flex-direction: column;
        }
    }
    .quill {
        border: 0;
        padding: 0;
    }
    .ql-editor {
        min-height: 100px;
        max-width: 100%;
        padding: 10px 10px;
    }
    .actions-space {
        border-top: 1px solid #dcdcdc;
        padding: 10px 17px;
        position: unset;
    }
    .chat-only {
        display: none;
    }
    .disabled .actions-space {
        display: none;
    }
    .disabled .ql-editor {
        cursor: default !important;
        background-color: #f8f8f8;
        color: rgba(33, 33, 33, 0.6);
    }
    .disabled .chat-actions-box-inner {
        border-color: rgb(243, 242, 241);
        border-radius: 0;
    }
    .disabled .quill .ql-editor.ql-blank::before {
        opacity: 0;
    }
}

.multi-page-modal .modal-text-editor .ql-editor {
    min-height: 166px;
}

.modal-text-editor-files {
    border: 1px solid #dcdcdc;
    border-radius: 3px 3px 0 0;
    border-bottom: 0;
    display: flex;
    align-items: center;
    padding: 5px 10px;
    flex-wrap: wrap;
    img {
        max-height: 25px;
        margin-right: 5px;
    }
    .attach-file-item {
        padding: 1px 12px;
        border: 1px solid rgba(0, 0, 0, 0.1);
        margin-bottom: 0;
        margin-right: 5px;
        i {
            font-size: 14px;
            margin-right: 8px;
        }
    }
}

canvas.sound-wave {
    width: 100%;
}

.conversation-load-more {
    height: 64px;

    .btn {
        justify-content: center;
        margin: 15px;
        width: calc(100% - 30px);
    }
    &.isFetching {
        height: 24px;
    }
}

.reply-message-editor {
    display: flex;
    align-items: center;
    border-top: 1px solid rgba(0,0,0,0.1);

    .chat-text {
        background-color: rgba(0,0,0,0.05);
        padding: 5px 15px;
        border-radius: 10px;
        margin: 20px;
        position: relative;
        flex: 1;
    }

    // .chat-text:before {
    //     content: " ";
    //     width: 7px;
    //     height: 100%;
    //     background-color: #c7e7c8;
    //     position: absolute;
    //     border-radius: 10px 0 0 10px;
    //     top: 0;
    //     left: 0;
    // }
    button {
        margin-right: 25px;
    }
    .ql-image {
        max-height: 100px;
    }
}

@media screen and (max-width: 1333px) {
    .chat .context-header {
        .btn-text span {
            display: none;
        }
        i {
            margin: 0 5px;
        }
        // .select-trigger .name {
        //     display: none;
        // }
    }
}

@media screen and (max-width: 1400px) {
    .chat .context-header {
        .select-trigger .name {
            display: none;
        }
    }
}

@media screen and (max-width: 1265px) {
    .ql-editor.ql-blank::before {
        max-width: 266px !important;
    }
}
