.help-banner {
    // background-color: #f6f6f6;
    // border: 1px solid rgba(0,0,0,0.1);
    // border: 1px solid rgba(0, 0, 0, 0.07);
    // background-color: rgba(0, 0, 0, 0.03);
    background-color: #edf5fe;
    padding: 30px;
    border-radius: 5px;
    margin: 25px 0 0;
    border: 1px solid rgba(0, 0, 0, 0.07);
    background-color: rgba(0, 0, 0, 0.02);
    padding: 30px;
    margin: 15px 30px 0px;
    border-radius: 5px;

    h1 {
        margin: 0 0 6px;
        font-weight: 600;
        font-size: 23px;
    }
    p {
        margin: 0 0 15px;
        line-height: 24px;
        margin-bottom: 10px;
        font-size: 15px;
        color: rgba(0, 0, 0, 0.5);
    }

    .btn {
        margin-right: 20px;
        padding-left: 18px;
        padding-right: 18px;
    }
}

.fixed-helper {
    max-width: 600px;
    margin: 30px auto;
    text-align: center;
    font-size: 16px;
    color: #9b9b9b;
    h1 {
        color: #383838;
        i {
            margin-right: 20px;
        }
    }
}

.fixed-separator {
    outline: 0;
    max-width: 80px;
    border: 0;
    border-top: 1px solid #e9e9e9;
    margin: 60px auto;
}

.settings-help {
    padding: 30px 20px;
    box-sizing: border-box;
    width: 315px;
    .top {
        display: flex;
        align-items: center;
        font-size: 29px;
        i {
            margin-right: 15px;
            color: $primary;
            border: 1px solid $primary;
            border-radius: 50%;
            padding: 5px;
            width: 18px;
            height: 18px;
            display: flex;
            align-items: center;
            justify-content: center;
            font-size: 18px;
        }
        h2 {
            font-size: 22px;
            // font-weight: 300;
        }
    }
    .settings-help-section {
        font-size: 17px;
        font-weight: 300;
        margin-top: 0;
        display: block;
        margin-bottom: 8px;
    }
    p {
        color: rgba(0, 0, 0, 0.6);
        line-height: 22px;
    }
}
