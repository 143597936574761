.customize-block {
    display: flex;
    .left {
        width: 200px;
    }

    .block-picker > div:first-child {
        display: none;
    }
}

.widget-preview {
    position: absolute;
    right: 40px;
    top: 85px;
    .widget-preview-inner {
        width: 400px;
        background-color: #f4f5f6;
        background-color: #f2f5f7;
        min-height: 200px;
        border-radius: 5px;
        box-shadow: 0 5px 40px rgba(0, 0, 0, 0.16) !important;
        transition: all 195ms ease-in;
        height: 100%;
        // background-image: url(https://assetscdn-web.freshchat.com/agent/static/assets/images/widget/texture_background_1-bdc7191884a15871ed640bcb0635e7e7.png);
        background-color: white;
        display: flex;
        flex-direction: column;
    }

    .widget-header {
        box-shadow: 0 0 4px 0 rgba(0, 0, 0, 0.15);
        background-color: #34495e;
        // padding: 15px 20px 25px;
        padding: 18px 20px 20px;
        border-radius: 5px 5px 0 0;
        color: white;

        .widget-go-back {
            margin-bottom: 3px;
            i {
                // transform: rotate(180deg);
                color: #fff;
            }
        }

        .widget-channel-name {
            font-size: 23px;
            font-weight: 600;
            margin-top: 3px;
        }

        .widget-channel-response-time {
            // margin-top: 3px;
            font-size: 12px;
            font-weight: 500;
            opacity: 0.5;
        }
    }
    .widget-chat {
        // padding: 185px 25px 100px 25px;
        overflow-y: auto;
        max-height: 450px;
        height: 100%;
        padding: 25px;

        // display: flex;
        // flex-direction: column;
        // justify-content: flex-end;
        box-sizing: border-box;
    }

    .send-box {
        background-color: #fff;
        transition: 0.6s;
        // position: absolute;
        // bottom: 8px;
        border-radius: 0 0 5px 5px;
        // left: 8px;
        // right: 8px;
        padding: 22px;
        // margin: 8px;
        border-top: 1px solid #e6e6e6;
        .send-input {
            display: flex;
            align-items: center;
            font-size: 14px;
            opacity: 0.6;
        }
    }
    .chat-balloon {
        margin-left: 100px;
        border-radius: 20px 20px 4px !important;
        background-color: #f2f4fb;
    }
}

.widget-user-msg {
    display: flex;
    justify-content: flex-end;
    flex-shrink: 0;
}

.widget-chat-inner {
    margin-top: 100%;
}

// .customize-wrap {
//     display: flex;
//     justify-content: space-between;
// }
