.editable-row {
    display: flex;
    align-items: center;
    margin-bottom: 2px;
    & > div:last-child {
        margin-left: 5px;
    }
    & > button:first-child {
        margin-left: auto;
        margin-right: 5px;
    }
}

.editable-property {
    padding: 0px 15px;
    &.editMode {
        padding: 6px 15px 10px !important;
    }
}

.uneditable-property {
    padding: 7px 15px !important;
}

.editable-property.fixedProperty {
    &.editMode {
        background-color: $primary-light-grey;
    }
    .editable-row {
        & > div:last-child {
            margin-left: auto;
        }
        .left {
            margin-top: -25px;
        }
    }
}
.editMode {
    // padding: 0 12px 10px;
    background-color: $primary-light-grey;
    border-radius: 4px;
}

.action-hover {
    background: #d4d6ea;
    // padding: 4px;
    border-radius: 2px;
    height: 21px;
    width: 21px;
    box-sizing: border-box;
    margin-top: -3px;
    opacity: 0;
    position: absolute;
    right: 0;
    i {
        font-size: 13px;
    }
    &.copied {
        background-color: #2ecc71;
        i {
            color: white;
        }
    }
}

.readable-row {
    position: relative;
}

.readable-row:hover {
    .action-hover {
        opacity: 1;
    }
}

.default-value-property {
    color: $secondary;
}

.user-card-name {
    // .editable-row .left {
    //     margin-right: 10px;
    // }
    // .fixedProperty.editMode {
    //     margin-left: 12px;
    // }
    // .fixedProperty.editMode {
    //     padding: 5px;
    // }
    // .editable-row:first-child {
    //     flex-direction: column;
    //     margin-bottom: 10px;
    // }
    // .text-space {
    //     margin-bottom: 0;
    // }
}

