/* Vertical
=========================*/

.vertical-tabs-space {
    // width: 100%;
    // max-width: 250px;
    // height: calc(100vh - 30px);
    position: relative;
    box-sizing: border-box;
}

.vertical-tabs {
    background-color: $sidebar_color;
    // padding-right: 25px;
    padding-top: 30px;
    padding: 0;
    // position: absolute;
    width: 100%;
    height: 100vh;
    box-sizing: border-box;
    // border-right: 1px solid rgba(0,0,0,0.05);
}

/* Horizontal
=========================*/

.horizontal-tabs {
    background-color: #fff;
    // background-color: #f8f9fb;
    padding: 0 17px;
    // box-shadow: 0 0 4px rgba(0, 0, 0, 0.12);
    display: flex;
    align-items: center;

    a {
        color: #2c3e50;
        opacity: 0.7;
        padding: 11px 0;
        font-weight: 600;
        text-transform: uppercase;
        font-size: 14px;
        margin-right: 35px;
        display: flex;
        align-items: center;
        i {
            margin-right: 5px;
            margin-right: 8px;
            font-size: 18px;
        }
        &.hover, &.active {
            opacity: 1;
        }
        &.active {
            border-bottom: 3px solid $primary;
        }
    }
}