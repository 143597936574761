.conve-filter-dropdown.conversations {
    display: flex;
    justify-content: space-between;
    padding: 0 10px;
    max-width: 355px;
}

.conve-filter-trigger {
    max-width: 240px;
    cursor: pointer;
    font-weight: 500;
    font-size: 14px;
    border-radius: 3px;
    padding: 10px;
    transition: 0.3s;
    height: 34px;
    overflow: hidden;
    display: flex;
    align-items: center;
    box-sizing: border-box;
    margin: 5px 10px 5px 0;

    &:hover {
        background-color: rgba(0, 0, 0, 0.05);
        transition: 0.3s;
        .badge-count {
            background-color: #e1e4e9;
            transition: 0.1s;
        }
    }

    .badge-count {
        margin-left: 10px;
    }

    // .count {
    //     background-color: #e6e6f1;
    //     padding: 4px 8px 3px;
    //     border-radius: 15px;
    //     color: #626d8f;
    //     margin-left: 10px;
    //     margin-right: 2px;
    //     font-size: 11px;
    //     font-weight: 600;
    //     line-height: 11px;
    //     text-align: center;
    //     display: inline-block;
    // }

    i {
        transition: all 0.2s ease-in 0s;
        width: 20px;
        text-align: center;
        font-size: 19px;
        line-height: 1;
        color: #8d8c8c;
    }
}

.conve-filter-dropdown .menu-dropdown .menu {
    left: -2px;
    width: 401px !important;
    top: 49px;
}

.conve-filter-dropdown.small {
    .conve-filter-trigger .name {
        margin-right: 5px;
    }
    .menu-dropdown .menu {
        left: -2px;
        width: 360px !important;
        top: 49px;
    }
}

.conve-filter-dropdown-inner {
    // height: 300px;
    width: 100%;
    padding-top: 10px;
}

.cf-dropdown-header {
    border-bottom: solid 1px #e0e4ea;
    padding: 15px 15px 0;
}

.cf-dropdown-list {
    display: flex;
    flex-direction: column;
    // a {
    //     padding: 14px 25px 14px 35px;
    //     font-weight: 400 !important;
    //     margin-bottom: 1px;
    //     &:hover,
    //     &.active {
    //         background-color: #f5f5f5;
    //     }
    // }
    i {
        margin-right: 15px;
        font-size: 20px;
        width: 25px;
        text-align: center;
    }
}

.my-segment-separator {
    text-transform: uppercase;
    font-size: 11px;
    font-weight: 700;
    color: #90a4ae;
    display: flex;
    align-items: center;
    padding-bottom: 10px;
    // border-bottom: solid 1px #eaeaea;
    margin: 20px 35px 0px;
    i {
        font-size: 14px;
        margin-right: 5px;
    }
}

.dropdown-footer-button {
    padding: 16px 20px;
    border-top: solid 1px #eaeaea;
    text-align: center;
    background-color: #f8f8f8;
    cursor: pointer;
    color: #5d657a;
    border-radius: 0 0 4px 4px;
    transition: 0.3s;
    font-size: 14px;
    width: 100%;
    margin-top: 20px;
    span {
        display: inline-flex;
        align-items: center;
        justify-items: center;
        text-align: center;
    }
    &:hover {
        background-color: #e1e4e9;
        transition: 0.3s;
        color: #2c3b48;
    }
    i {
        margin-right: 5px;
    }
}

.menu.show {
    .menu.show {
        left: calc(100% + 10px);
        .menu-inner:after, .menu-inner:before {
            display: none;
        }
    }
}