.dash-card {
    .box {
        padding: 0;
    }
    .title {
        padding: 13px 16px;
        font-size: 13px;
        border-bottom: 1px solid #f2f5f7;
        span {
            display: flex;
            align-items: center;
            position: relative;
            i {
                margin-right: 8px;
                font-size: 17px;
                color: $primary;
                margin-top: -2px;
            }
        }
        .dash-card-select {
            text-transform: none;
            position: absolute;
            right: 0;
        }
        .select-space {
            max-width: 250px;
        }
    }
    &.graph .title {
        border-bottom: none;
        padding: 19px;
        margin: 0;
    }
    .react-select__control {
        box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.06) !important;
    }
    // .box-space {
    //     padding: 20px;
    // }
}

.dash-stats {
    display: flex;
    .dash-stat {
        flex: 1;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-items: center;
        border-right: 1px dashed #e0e5ef;
        padding: 30px 0;
        margin-bottom: 25px;
        .stat-value {
            font-size: 26px;
            margin-bottom: 10px;
        }
        .stat-label {
            font-size: 12px;
            color: #6d7d88;
        }
    }
    .dash-stat:last-child {
        border-right: 0;
    }
}

.dashboard .ReactTable .rt-td {
    margin-bottom: 14px;
}

.dashboard .ReactTable .rt-th > div,
.reports .ReactTable .rt-th > div {
    padding-bottom: 17px;
    font-size: 15px;
}

.graph-members-dash{
    width: calc(100vw - 160px);
}