.chat-side-nav {
    // border-left: 1px solid transparent;
    border-left: 1px solid #edebe9;
    width: 50px;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding-top: 10px;
    // background-color: #faf9f8;
    &.active {
        background: #fff;
        border-left: 1px solid #edebe9;

    }
}

.chat-side-nav-item {
    width: 30px;
    height: 30px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 15px;
    outline: none;
    transition: transform 150ms ease 0s;
    margin-bottom: 15px;

    i {
        color: #9b9c9e;
        font-size: 19px;
    }
    &:hover {
        background-color: rgba(93, 105, 133, 0.1);
    }
    &.active {
        box-shadow: rgba(0, 0, 0, 0.3) 0px 2px 9px;

        &:hover {
            transform: scale(1.1);
        }
        i {
            color: white !important;
        }
    }
    .plugin-icon {
        font-weight: 800;
        font-style: normal;
        text-transform: uppercase;
    }
}
