.panel-detail {
    // padding-top: 80px;
    // border-left: 1px solid rgba(0,0,0,0.1);
    position: relative;
    display: flex;
    flex: 1;
    overflow: hidden;
}

.panel-closer-space {
    position: absolute;
    // left: -20px;
    top: 10px;
    left: 5px;
    z-index: 11;
}

.panel-closer {
    background-color: #fff;
    border-radius: 50%;
    // padding: 10px;
    // box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.2);
    height: 40px;
    width: 40px;
    box-sizing: border-box;
    display: flex;
    align-items: center;
    justify-content: center;
    i {
        font-size: 22px;
        color: rgba(0,0,0,0.10);
    }
}
