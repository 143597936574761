.ReactTable {
    border: none;
    border-radius: 5px;
}

.ReactTable .rt-thead.-header {
    box-shadow: none;
    // background-color: #fff;
    color: #888;
    // padding: 16px 0;
    font-size: 15px;
    font-weight: bold;
    // border-radius: 5px 5px 0 0;
    // border-bottom: 1px solid rgba(0,0,0,0.065);
    border-bottom: 1px solid rgb(234, 234, 234);
}

.ReactTable .rt-thead .rt-th {
    border-right: 1px solid rgba(0, 0, 0, 0);
}

.ReactTable .rt-td {
    // padding: 17px;
    font-size: 14px;
    border-right: none !important;
    // background: #FFF;
    padding: 10px 17px;
    // border-radius: 3px;
    // margin: 0 0 4px;
    position: relative;
    display: flex;
    align-items: center;
    &:first-child {
        padding-left: 15px;
    }
    a {
        // color: $primary;
        font-weight: 500;
    }
}

.ReactTable .-pagination {
    padding: 15px;
    box-shadow: none;
    border-top: none;
}

.rt-th {
    outline: none;
    & > div {
        // padding: 5px 16px 16px;
        // text-align: left;
        // padding-left: 12px;
        // padding-bottom: 5px;
        // font-size: 10px;
        // line-height: 20px;
        // font-weight: 800;
        // color: rgba(0, 0, 0, 0.25);
        // text-transform: uppercase;

        padding: 5px 16px 16px;
        text-align: left;
        padding-left: 12px;
        padding-bottom: 5px;
        font-size: 12px;
        line-height: 20px;
        font-weight: 400;
    }
}

.rt-resizable-header-content {
}

.ReactTable .rt-thead .rt-th.-sort-desc,
.ReactTable .rt-thead .rt-td.-sort-desc {
    // box-shadow: inset 0 -3px 0 0 rgba(255, 255, 255, 0.4);
    box-shadow: none;
}
.ReactTable .rt-thead .rt-th.-sort-asc,
.ReactTable .rt-thead .rt-td.-sort-asc {
    box-shadow: none;
    // box-shadow: inset 0 3px 0 0 rgba(255, 255, 255, 0.4);
}

// .rt-resizable-header-content {
//     text-align: left;
//     padding-left: 12px;
//     padding-bottom: 5px;
//     font-size: 14px;
//     line-height: 20px;
//     font-weight: 500;
// }

.table-header {
    display: flex;
    align-items: center;
    color: #888;
    fill: #888;
    i {
        margin-bottom: 0px;
        margin-right: 4px;
        font-size: 16px;
    }
    .caret-big {
        visibility: hidden;
        margin-left: 3px;
    }
}

.rt-th:hover a.table-header {
    color: $primary;
    span {
        text-decoration: underline;
    }
    svg {
        fill: $primary;
    }
}

a.table-header {
    pointer-events: none;
}

.-sort-asc a.table-header .caret-big {
    visibility: visible;
}
.-sort-desc a.table-header .caret-big {
    visibility: visible;
    transform: rotate(180deg);
}

/* No data
===================================*/

.no-data {
    padding: 40px;
}

.no-data-inner {
    display: flex;
    align-items: center;
    flex-direction: column;
    justify-content: center;

    img {
        max-height: 150px;
        // max-width: 100px;
    }

    h2 {
        opacity: 0.6;
        font-size: 15px;
    }
}

.ReactTable .rt-tbody {
    // margin-top: 20px;
}

.ReactTable .rt-tbody .rt-tr-group {
    // margin-bottom: 2px;
    border-bottom: 0;
    min-height: 55px;
    // border-radius: 4px;
    border-radius: 0;
    cursor: pointer;
    border-bottom: 1px solid rgb(244, 244, 244);
}

.table-badge {
    padding: 4px 15px;
    background: #ccc;
    // margin-bottom: 20px;
    color: #1f2b36;
    display: inline-block;
    line-height: 1;
    border-radius: 15px;
    margin-right: 20px;
    font-size: 14px;
}

.table-end {
    margin: 50px 30px 30px;
    text-align: center;
    .table-end-line {
        width: 50px;
        height: 1.5px;
        margin: 0 auto;
        border-radius: 2px;
    }
    .table-end-circle {
        border-radius: 50%;
        height: 8px;
        width: 8px;
        margin: 0 4px;
    }
}

.table-end-line,
.table-end-circle {
    background: $primary;
    opacity: 0.5;
    box-shadow: 0 1px 0 #fff;
    display: inline-block;
    vertical-align: middle;
}

.table-edit-row {
    display: none;
    font-size: 14px;
    color: #9ba2b4;
    align-items: center;
    i {
        font-size: 14px;
        color: #9ba2b4;
        margin-right: 5px;
    }
}

.danger .table-edit-row {
    display: flex;
}

.rt-tr-group:hover {
    // box-shadow: 0 2px 10px 0 rgba(0,0,0,.1);
    background-color: #f3f4f5;
    border-radius: 8px;
    // .rt-tr {
    //     box-shadow: 0 2px 10px 0 rgba(0,0,0,.1);
    //     box-shadow: 0 1px 1px rgba(0, 0, 0, 0.2);
    // }
    .table-edit-row {
        display: flex;
    }
    .table-edit-actions {
        button:first-child {
            margin-right: 5px;
        }
        display: flex;
    }
}

.table-icon-cell {
    display: flex;
    align-items: center;
    i {
        margin-right: 5px;
        font-size: 16px;
    }
}

.table-title {
    display: flex;
    align-items: center;
    // justify-content: space-between;
    // padding: 28px 45px 11px;
    padding: 10px 15px;
    border-bottom: 1px solid rgba(0, 0, 0, 0.065);
    height: 53px;
    box-sizing: border-box;
    margin-top: -4px;
    h1,
    h2 {
        margin: 0;
    }
    h1 {
        font-size: 17px;
        font-weight: 500;
        color: #383838;
    }
}

.table-title-badge {
    // background-color: #ffdbd7;
    color: rgba(0, 0, 0, 0.7);
    border-radius: 14px;
    min-width: 12px;
    padding: 3px 8px 0px;
    margin-left: 9px;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 12px;
    font-weight: 600;
    border: 1px solid rgba(0, 0, 0, 0.1);
    display: none;
}

.button-page-table-add {
    color: black !important;
    margin-left: auto !important;
    i {
        color: $primary-button !important;
    }
}

.table-icon {
    margin-right: 20px;
    font-size: 18px;
    // opacity: 0.4;
    color: $primary-button;
}

.inbox {
    .rt-td {
        padding: 20px 37px;
    }
    .rt-td:first-child {
        padding-left: 20px;
    }
}

.inbox {
    .ReactTable .rt-tbody .rt-tr-group {
        margin-bottom: 0px;
    }
}

.table-icon-description {
    display: flex;
    align-items: center;
    i {
        margin-right: 8px;
    }
}

.table-overflow-visible {
    .rt-td, .ReactTable .rt-table, .ReactTable .rt-tbody {
        overflow: visible;
    }
}