.filter-drop-inner {
    padding: 15px;
    white-space: normal;
}

.footer-actions {
    button {
        margin-right: 3px;
    }
    .small {
        padding: 6px 16px;
        font-size: 13px;
    }
}



.filter-dropdown-space{
    display: flex;
    align-items: center;
    flex-wrap: wrap;
}