.filter-tag {
    border-radius: 23px;
    color: $primary;
    padding: 0 12px;
    font-size: 14px;
    font-weight: 400;
    height: 28px;
    box-sizing: border-box;
    display: flex;
    align-items: center;
    margin: 5px 10px 5px 0;
    border: 1px solid rgba(0, 0, 0, 0.1);

    button {
        margin-left: 7px;
        border-radius: 50%;
        font-size: 0;
        // background-color: rgba(255,255,255,0.2);
        color: $primary;
        padding: 3px 5px 3px 3px;
        box-sizing: border-box;
        i {
            font-size: 13px;
            color: $primary;
            width: 12px;
            height: 12px;
        }
        &:hover {
            // background-color: #fff;
            background-color: #f4f4f4;
            color: $primary-grey2;
            transition: 0.1s;
            cursor: pointer;
        }
    }
}

.filters-tags {
    display: flex;
    margin-right: 10px;
    margin-top: 2px;
    flex-wrap: wrap;
}
