
/* Text */
/* Logo */
/* Sidenav */
/* Layout Header */
ul,
menu,
dir {
  display: block;
  list-style-type: none;
  -webkit-margin-before: 0em;
          margin-block-start: 0em;
  -webkit-margin-after: 0em;
          margin-block-end: 0em;
  -webkit-margin-start: 0px;
          margin-inline-start: 0px;
  -webkit-margin-end: 0px;
          margin-inline-end: 0px;
  -webkit-padding-start: 0px;
          padding-inline-start: 0px;
  padding: 0;
}

a {
  text-decoration: none;
  transition: fill 200ms cubic-bezier(0.25, 0.46, 0.45, 0.94) 0ms, border-color 200ms cubic-bezier(0.25, 0.46, 0.45, 0.94) 0ms, box-shadow 200ms cubic-bezier(0.25, 0.46, 0.45, 0.94) 0ms;
  color: #2a2a2a;
  cursor: pointer;
  display: inline-block;
}
a:hover {
  color: #2a2a2a;
}
a:hover svg {
  fill: #8aadfe;
}
a.color-link {
  color: #7d92eb;
  font-weight: 500;
  transition: transform 0.1s cubic-bezier(0, 0, 0, 1);
}
a.color-link:active {
  box-shadow: none;
  transform: scale(0.95);
}
a.color-link:hover {
  color: #8aadfe;
}

h2 {
  margin: 0;
}

h2.box-title {
  font-size: 22px;
  margin-bottom: 20px;
}

p {
  line-height: 1.42857143;
}

.vertical-center {
  display: flex;
  align-items: center;
}

.midcol {
  max-width: 1024px;
  width: 100%;
  margin: 0 auto;
  padding: 0 20px;
  box-sizing: border-box;
  position: relative;
}

.midcol-sm {
  width: 480px;
  margin: 0 auto;
}

.pad30 {
  padding: 30px;
}

.horizontal-center {
  display: flex;
  justify-items: center;
}

.flex {
  display: flex;
}

.oldschool {
  color: #4f8ed6;
}
.oldschool:hover {
  color: #4f8ed6;
  text-decoration: underline;
}

li {
  list-style: none;
}

.underline-link {
  text-decoration: underline;
}

.truncate {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

audio {
  outline: none;
}

.command-page-panel {
  overflow-y: auto !important;
}

.ellipsis {
  display: block;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}

h4 {
  margin: 0;
  margin-bottom: 5px;
  font-size: 25px;
}

h2 {
  font-size: 18px;
  line-height: 24px;
  font-weight: 400;
  color: #222;
}

p {
  margin: 0;
}

.btn-dark {
  --mdc-theme-primary: #363636;
}

.btn-light {
  --mdc-theme-primary: #dfddd8;
  --mdc-theme-on-primary: #2a2a2a;
}

.fluid .mdc-text-field, .fluid.mdc-select {
  width: 100%;
}

.mdc-text-field--focused:not(.mdc-text-field--disabled) .mdc-floating-label,
.mdc-select:not(.mdc-select--disabled) .mdc-select__native-control:focus ~ .mdc-floating-label {
  color: #abd733 !important;
}

label, div, .mdc-text-field {
  --mdc-theme-primary: #abd733;
}

.mdc-floating-label--float-above.shrink {
  z-index: 99;
  padding-right: 9px;
  margin-left: -5px;
  padding-left: 5px;
}

.mdc-text-field--outlined .mdc-floating-label--float-above.shrink {
  background-color: white;
}

/* Text
================================ */
.text-space,
.select-space {
  display: flex;
  flex-direction: column;
  margin-bottom: 15px;
  flex: 1 1;
}
.text-space .react-select__control,
.select-space .react-select__control {
  min-height: 35px !important;
}
.text-space .react-select__control--is-focused,
.select-space .react-select__control--is-focused {
  border-color: #7d92eb !important;
  box-shadow: 0 0 0 1px #7d92eb !important;
}
.text-space .react-select__option--is-focused,
.select-space .react-select__option--is-focused {
  background-color: #7d92eb !important;
  color: white !important;
}
.text-space.small .react-select__control--is-focused,
.select-space.small .react-select__control--is-focused {
  box-shadow: none !important;
  border: 1px solid #6641a6 !important;
}
.text-space.label,
.select-space.label {
  margin-bottom: 10px;
}
.text-space.flat,
.select-space.flat {
  margin-bottom: 0;
}
.text-space.maxWidth,
.select-space.maxWidth {
  width: 100%;
}
.text-space.material .kinput, .text-space.rounded .kinput,
.select-space.material .kinput,
.select-space.rounded .kinput {
  border-radius: 20px;
}
.text-space.transparent,
.select-space.transparent {
  background-color: transparent;
}

.text-space {
  max-width: 100%;
}

.label-input,
.checkbox-group-title {
  font-size: 0.875rem;
  color: #202020;
  font-weight: 600;
  line-height: 1.7;
  margin-bottom: 4px;
}

.kinput {
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex: 1 0 auto;
  background-color: rgba(9, 45, 66, 0.08);
  color: #4f545c;
  box-sizing: border-box;
  font-size: 16px;
  line-height: 1.42857;
  overflow-wrap: break-word;
  overflow: hidden;
  border-radius: 3px;
  border-style: solid;
  border-width: 1px;
  outline: none;
  max-width: 100%;
  min-height: 35px;
  height: 35px;
  position: relative;
}
.kinput:hover {
  border-color: #b9bbbe;
}
.kinput:focus-within {
  border-color: rgb(46, 204, 113) !important;
}
.kinput input {
  padding: 0 10px;
  height: 35px;
  box-sizing: border-box;
  color: inherit;
  cursor: inherit;
  font-family: inherit;
  font-size: 14px;
  width: 100%;
  background: white;
  border-width: 0px;
  border-style: none;
  border-style: initial;
  border-color: currentColor;
  border-color: initial;
  border-image: none;
  border-image: initial;
  outline: none;
}
.kinput input::-webkit-input-placeholder {
  color: #b9bbbe;
}
.kinput input::-ms-input-placeholder {
  color: #b9bbbe;
}
.kinput input::placeholder {
  color: #b9bbbe;
}
.kinput.textarea {
  height: auto;
  overflow-y: auto;
  padding: 7px 10px;
}
.kinput.textarea::-webkit-input-placeholder {
  opacity: 0.7;
}
.kinput.textarea::-ms-input-placeholder {
  opacity: 0.7;
}
.kinput.textarea::placeholder {
  opacity: 0.7;
}
.kinput .prefix {
  background-color: #eee;
  border-right: 1px solid #ccc;
  padding: 10px 12px;
  border-radius: 2px 0 0 2px;
  left: 0px;
  margin-left: -11px;
  margin-right: 9px;
}

.text-space.white .kinput {
  background-color: #fff;
  border-color: rgba(79, 84, 92, 0.3);
}

.text-space.grayL .kinput,
.select-space.grayL .react-select__control {
  background-color: rgba(255, 255, 255, 0.7);
  border-color: rgba(0, 0, 0, 0.15);
  color: #0a0a0a;
  border-width: 1px;
  box-shadow: none;
}
.text-space.grayL .kinput:hover,
.select-space.grayL .react-select__control:hover {
  border-color: #b9bbbe;
}
.text-space.grayL .kinput:focus-within,
.select-space.grayL .react-select__control:focus-within {
  background-color: rgba(255, 255, 255, 0.8);
  box-shadow: none !important;
}

.text-space.gray .kinput,
.select-space.gray .react-select__control {
  background-color: rgba(9, 45, 66, 0.08);
  border-color: transparent;
  color: #0a0a0a;
}
.text-space.gray .kinput:hover,
.select-space.gray .react-select__control:hover {
  border-color: #b9bbbe;
}
.text-space.gray .kinput:focus-within,
.select-space.gray .react-select__control:focus-within {
  border-color: #a5acb0;
  outline: none;
  box-shadow: 0 0 6px #cdd2d4 !important;
  background-color: rgba(255, 255, 255, 0.8);
}

.react-select__multi-value {
  border-radius: 25px !important;
  padding: 0 5px;
  background-color: #7d92eb !important;
  color: #fff !important;
}

.react-select__multi-value__label {
  color: #fff !important;
  font-size: 14px !important;
}

.react-select__multi-value__remove::hover {
  background-color: rgba(0, 0, 0, 0.05) !important;
  color: rgba(0, 0, 0, 0.7) !important;
}

.disabled .kinput {
  background-color: #eee !important;
}

.text-space.small .kinput {
  height: 34px;
  min-height: 34px;
}
.text-space.small .kinput input {
  font-size: 14px;
}

.input-left-icon {
  color: #798d99;
  margin-right: 10px;
  font-size: 16px;
}

.text-error {
  background: #faead0;
  font-size: 14px;
  border-color: #faead0;
  border-radius: 4px;
  padding: 0.5em 1em;
  margin-top: 5px;
}

.text-space.error .kinput {
  border-color: #f1c40f;
}

.input-space {
  display: flex;
  flex-direction: column;
  justify-content: center;
  flex: 1 1;
}

.optional-label {
  color: rgba(0, 0, 0, 0.3);
  margin-left: 5px;
  font-size: 11px;
}

.search-text-container {
  position: relative;
}

.search-text-list {
  position: absolute;
  box-sizing: border-box;
  top: 92%;
  background-color: #FFF;
  border-radius: 4px;
  box-shadow: 0 0 0 1px hsla(0, 0%, 0%, 0.1), 0 4px 11px hsla(0, 0%, 0%, 0.1);
  padding: 4px 0;
  margin-bottom: 8px;
  width: 100%;
  max-height: 250px;
  overflow-y: auto;
  z-index: 2;
}

.search-text-option {
  padding: 8px 12px;
  width: 100%;
  box-sizing: border-box;
  cursor: default;
}
.search-text-option:hover {
  background-color: #DEEBFF;
}

.list-item-container {
  display: flex;
  align-items: center;
}
.list-item-container div {
  margin-right: 10px;
}
.list-item-container img {
  width: 20px;
  height: 20px;
  margin-right: 10px;
  border-radius: 50%;
}

.list-item-content {
  display: flex;
  flex: 1 1;
  align-items: center;
  justify-content: space-between;
  grid-gap: 12px;
  gap: 12px;
  margin-right: 0;
}
.list-item-content span:first-child {
  flex: 1 1;
  overflow: hidden;
  text-overflow: ellipsis;
  -webkit-box-orient: vertical;
  display: -webkit-box;
  -webkit-line-clamp: 1;
}

/* Button
================================ */
button {
  background-color: transparent;
  border: 0;
  cursor: pointer;
}
button:hover, button:active {
  outline: none;
}

button[disabled],
html input[disabled] {
  cursor: default !important;
  background-color: #f8f8f8;
  color: rgba(33, 33, 33, 0.6);
}

button[disabled] {
  background-color: rgba(0, 0, 0, 0.1) !important;
  box-shadow: 0 1px 0 0 rgba(0, 0, 0, 0.1) !important;
}

input[type=date]:before {
  color: #b9bbbe;
  content: attr(placeholder) !important;
  margin-right: 5.5em;
}

input[type=date]:focus:before {
  content: "" !important;
  margin-right: 0;
}

.hasValue input[type=date]:before {
  content: "" !important;
  margin-right: 0;
}

.add-fields-space .input-left-icon {
  margin-top: 38px;
}

:selection {
  background: #d03e27;
}

::-moz-selection {
  background: #d03e27;
}

.form-mid {
  max-width: 800px;
}

/* Full form
===========================*/
.full-form .input-space {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}
.full-form .text-space {
  flex-direction: row;
  align-items: center;
  margin-bottom: 30px;
  max-width: 500px;
  width: 100%;
}
.full-form .label-input {
  margin-bottom: 0;
  margin-right: 20px;
  width: 120px;
  font-weight: 500;
  font-size: 15px;
  display: flex;
  flex-direction: column;
  justify-content: center;
}
.full-form .label-input span {
  color: #7f8fa4;
  font-size: 13px;
  font-weight: 400;
  margin-top: 3px;
}
.full-form .kinput {
  min-height: 46px;
  height: 46px;
  font-size: 16px;
  background-color: rgba(9, 45, 66, 0.08);
}
.full-form input {
  background: #f8fafc;
  height: 46px;
  padding: 0 22px;
  font-size: 15px;
}
.full-form .horizontal-center {
  margin-bottom: 10px;
  padding-bottom: 30px;
}
.full-form .user-avatar {
  margin: 0;
}
.full-form .input-description {
  margin-top: -10px;
  color: #7f8fa4;
  margin-bottom: 20px;
}

.input-description {
  color: #9b9b9b;
  margin-bottom: 15px;
}

.search-clear-btn {
  margin: 0 10px 1px 0;
}
.search-clear-btn i {
  font-size: 15px;
  color: rgb(155, 156, 158);
}

.react-select__dropdown-indicator {
  padding: 6px 8px !important;
}

.form-row {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.copyable-btn {
  position: absolute;
  right: 0;
  margin: 0 6px;
  display: none;
}
.copyable-btn button,
.copyable-btn a {
  padding: 4px 16px 3px;
  font-size: 13px;
  box-shadow: 0px 1px 1px 0px rgba(0, 0, 0, 0.25) !important;
}

.kinput:hover .copyable-btn {
  display: block;
}

.input-label {
  font-family: "Segoe UI", "Segoe UI Web (West European)", "Segoe UI", -apple-system, BlinkMacSystemFont, Roboto, "Helvetica Neue", sans-serif;
  -webkit-font-smoothing: antialiased;
  font-size: 14px;
  font-weight: 600;
  color: rgb(50, 49, 48);
  box-sizing: border-box;
  box-shadow: none;
  margin-top: 0px;
  margin-right: 0px;
  margin-bottom: 0px;
  margin-left: 0px;
  display: block;
  padding-top: 5px;
  padding-right: 0px;
  padding-bottom: 5px;
  padding-left: 0px;
  overflow-wrap: break-word;
}
.input-label.disabled {
  color: rgb(161, 159, 157);
}

/* Animations
===========================*/
@-webkit-keyframes rotation {
  from {
    -webkit-transform: rotate(0deg);
  }
  to {
    -webkit-transform: rotate(359deg);
  }
}
@keyframes rotation {
  from {
    -webkit-transform: rotate(0deg);
  }
  to {
    -webkit-transform: rotate(359deg);
  }
}
.test-toast {
  background-image: red;
}

.suffix {
  font-size: 14px;
  background: rgba(0, 0, 0, 0.1);
  position: relative;
  display: flex;
  align-items: center;
  height: 100%;
  padding: 0 10px;
}

.ico {
  font-family: icomoon;
  font-style: normal;
}

.ico-users:before {
  content: "\E985";
}

.ico-user:before {
  content: "\E992";
}

.ico-logo:before {
  content: "\E98A";
}

.ico-shield:before {
  content: "\E931";
}

.ico-throphy:before {
  content: "\E981";
}

.ico-hearth:before {
  content: "\E968";
}

.ico-tactic:before {
  content: "\E920";
}

.ico-paper:before {
  content: "\E948";
}

.ico-pad:before {
  content: "\E642";
}

.ico-play:before {
  content: "\E904";
}

.ico-settings:before {
  content: "\E905";
}

.ico-inbox:before {
  content: "\E95B";
}

.ico-circle-arrow:before {
  content: "\E984";
}

.ico-message:before {
  content: "\E927";
}

.ico-faq:before {
  content: "\E924";
}

.ico-anexo:before {
  content: "\E60A";
}

.ico-dashboard2:before {
  content: "\E99B";
}

.ico-dashboard3:before {
  content: "\E954";
}

.ico-dashboard:before {
  content: "\E93C";
}

.ico-reports:before {
  content: "\E938";
}

/*!
 * Font Awesome Pro 5.6.1 by @fontawesome - https://fontawesome.com
 * License - https://fontawesome.com/license (Commercial License)
 */
.fa,
.fas,
.far,
.fal,
.fab {
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  display: inline-block;
  font-style: normal;
  font-feature-settings: normal;
  font-variant: normal;
  text-rendering: auto;
  line-height: 1;
}

.fa-lg {
  font-size: 1.3333333333em;
  line-height: 0.75em;
  vertical-align: -0.0667em;
}

.fa-xs {
  font-size: 0.75em;
}

.fa-sm {
  font-size: 0.875em;
}

.fa-1x {
  font-size: 1em;
}

.fa-2x {
  font-size: 2em;
}

.fa-3x {
  font-size: 3em;
}

.fa-4x {
  font-size: 4em;
}

.fa-5x {
  font-size: 5em;
}

.fa-6x {
  font-size: 6em;
}

.fa-7x {
  font-size: 7em;
}

.fa-8x {
  font-size: 8em;
}

.fa-9x {
  font-size: 9em;
}

.fa-10x {
  font-size: 10em;
}

.fa-fw {
  text-align: center;
  width: 1.25em;
}

.fa-ul {
  list-style-type: none;
  margin-left: 2.5em;
  padding-left: 0;
}
.fa-ul > li {
  position: relative;
}

.fa-li {
  left: -2em;
  position: absolute;
  text-align: center;
  width: 2em;
  line-height: inherit;
}

.fa-border {
  border: solid 0.08em #eee;
  border-radius: 0.1em;
  padding: 0.2em 0.25em 0.15em;
}

.fa-pull-left {
  float: left;
}

.fa-pull-right {
  float: right;
}

.fa.fa-pull-left,
.fas.fa-pull-left,
.far.fa-pull-left,
.fal.fa-pull-left,
.fab.fa-pull-left {
  margin-right: 0.3em;
}
.fa.fa-pull-right,
.fas.fa-pull-right,
.far.fa-pull-right,
.fal.fa-pull-right,
.fab.fa-pull-right {
  margin-left: 0.3em;
}

.fa-spin {
  -webkit-animation: fa-spin 2s infinite linear;
          animation: fa-spin 2s infinite linear;
}

.fa-pulse {
  -webkit-animation: fa-spin 1s infinite steps(8);
          animation: fa-spin 1s infinite steps(8);
}

@-webkit-keyframes fa-spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

@keyframes fa-spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
.fa-rotate-90 {
  -ms-filter: "progid:DXImageTransform.Microsoft.BasicImage(rotation=1)";
  transform: rotate(90deg);
}

.fa-rotate-180 {
  -ms-filter: "progid:DXImageTransform.Microsoft.BasicImage(rotation=2)";
  transform: rotate(180deg);
}

.fa-rotate-270 {
  -ms-filter: "progid:DXImageTransform.Microsoft.BasicImage(rotation=3)";
  transform: rotate(270deg);
}

.fa-flip-horizontal {
  -ms-filter: "progid:DXImageTransform.Microsoft.BasicImage(rotation=0, mirror=1)";
  transform: scale(-1, 1);
}

.fa-flip-vertical {
  -ms-filter: "progid:DXImageTransform.Microsoft.BasicImage(rotation=2, mirror=1)";
  transform: scale(1, -1);
}

.fa-flip-horizontal.fa-flip-vertical {
  -ms-filter: "progid:DXImageTransform.Microsoft.BasicImage(rotation=2, mirror=1)";
  transform: scale(-1, -1);
}

:root .fa-rotate-90,
:root .fa-rotate-180,
:root .fa-rotate-270,
:root .fa-flip-horizontal,
:root .fa-flip-vertical {
  -webkit-filter: none;
          filter: none;
}

.fa-stack {
  display: inline-block;
  height: 2em;
  line-height: 2em;
  position: relative;
  vertical-align: middle;
  width: 2.5em;
}

.fa-stack-1x,
.fa-stack-2x {
  left: 0;
  position: absolute;
  text-align: center;
  width: 100%;
}

.fa-stack-1x {
  line-height: inherit;
}

.fa-stack-2x {
  font-size: 2em;
}

.fa-inverse {
  color: #fff;
}

/* Font Awesome uses the Unicode Private Use Area (PUA) to ensure screen
readers do not read off random characters that represent icons */
.fa-500px:before {
  content: "\F26E";
}

.fa-abacus:before {
  content: "\F640";
}

.fa-accessible-icon:before {
  content: "\F368";
}

.fa-accusoft:before {
  content: "\F369";
}

.fa-acorn:before {
  content: "\F6AE";
}

.fa-acquisitions-incorporated:before {
  content: "\F6AF";
}

.fa-ad:before {
  content: "\F641";
}

.fa-address-book:before {
  content: "\F2B9";
}

.fa-address-card:before {
  content: "\F2BB";
}

.fa-adjust:before {
  content: "\F042";
}

.fa-adn:before {
  content: "\F170";
}

.fa-adobe:before {
  content: "\F778";
}

.fa-adversal:before {
  content: "\F36A";
}

.fa-affiliatetheme:before {
  content: "\F36B";
}

.fa-air-freshener:before {
  content: "\F5D0";
}

.fa-alarm-clock:before {
  content: "\F34E";
}

.fa-algolia:before {
  content: "\F36C";
}

.fa-alicorn:before {
  content: "\F6B0";
}

.fa-align-center:before {
  content: "\F037";
}

.fa-align-justify:before {
  content: "\F039";
}

.fa-align-left:before {
  content: "\F036";
}

.fa-align-right:before {
  content: "\F038";
}

.fa-alipay:before {
  content: "\F642";
}

.fa-allergies:before {
  content: "\F461";
}

.fa-amazon:before {
  content: "\F270";
}

.fa-amazon-pay:before {
  content: "\F42C";
}

.fa-ambulance:before {
  content: "\F0F9";
}

.fa-american-sign-language-interpreting:before {
  content: "\F2A3";
}

.fa-amilia:before {
  content: "\F36D";
}

.fa-analytics:before {
  content: "\F643";
}

.fa-anchor:before {
  content: "\F13D";
}

.fa-android:before {
  content: "\F17B";
}

.fa-angel:before {
  content: "\F779";
}

.fa-angellist:before {
  content: "\F209";
}

.fa-angle-double-down:before {
  content: "\F103";
}

.fa-angle-double-left:before {
  content: "\F100";
}

.fa-angle-double-right:before {
  content: "\F101";
}

.fa-angle-double-up:before {
  content: "\F102";
}

.fa-angle-down:before {
  content: "\F107";
}

.fa-angle-left:before {
  content: "\F104";
}

.fa-angle-right:before {
  content: "\F105";
}

.fa-angle-up:before {
  content: "\F106";
}

.fa-angry:before {
  content: "\F556";
}

.fa-angrycreative:before {
  content: "\F36E";
}

.fa-angular:before {
  content: "\F420";
}

.fa-ankh:before {
  content: "\F644";
}

.fa-app-store:before {
  content: "\F36F";
}

.fa-app-store-ios:before {
  content: "\F370";
}

.fa-apper:before {
  content: "\F371";
}

.fa-apple:before {
  content: "\F179";
}

.fa-apple-alt:before {
  content: "\F5D1";
}

.fa-apple-crate:before {
  content: "\F6B1";
}

.fa-apple-pay:before {
  content: "\F415";
}

.fa-archive:before {
  content: "\F187";
}

.fa-archway:before {
  content: "\F557";
}

.fa-arrow-alt-circle-down:before {
  content: "\F358";
}

.fa-arrow-alt-circle-left:before {
  content: "\F359";
}

.fa-arrow-alt-circle-right:before {
  content: "\F35A";
}

.fa-arrow-alt-circle-up:before {
  content: "\F35B";
}

.fa-arrow-alt-down:before {
  content: "\F354";
}

.fa-arrow-alt-from-bottom:before {
  content: "\F346";
}

.fa-arrow-alt-from-left:before {
  content: "\F347";
}

.fa-arrow-alt-from-right:before {
  content: "\F348";
}

.fa-arrow-alt-from-top:before {
  content: "\F349";
}

.fa-arrow-alt-left:before {
  content: "\F355";
}

.fa-arrow-alt-right:before {
  content: "\F356";
}

.fa-arrow-alt-square-down:before {
  content: "\F350";
}

.fa-arrow-alt-square-left:before {
  content: "\F351";
}

.fa-arrow-alt-square-right:before {
  content: "\F352";
}

.fa-arrow-alt-square-up:before {
  content: "\F353";
}

.fa-arrow-alt-to-bottom:before {
  content: "\F34A";
}

.fa-arrow-alt-to-left:before {
  content: "\F34B";
}

.fa-arrow-alt-to-right:before {
  content: "\F34C";
}

.fa-arrow-alt-to-top:before {
  content: "\F34D";
}

.fa-arrow-alt-up:before {
  content: "\F357";
}

.fa-arrow-circle-down:before {
  content: "\F0AB";
}

.fa-arrow-circle-left:before {
  content: "\F0A8";
}

.fa-arrow-circle-right:before {
  content: "\F0A9";
}

.fa-arrow-circle-up:before {
  content: "\F0AA";
}

.fa-arrow-down:before {
  content: "\F063";
}

.fa-arrow-from-bottom:before {
  content: "\F342";
}

.fa-arrow-from-left:before {
  content: "\F343";
}

.fa-arrow-from-right:before {
  content: "\F344";
}

.fa-arrow-from-top:before {
  content: "\F345";
}

.fa-arrow-left:before {
  content: "\F060";
}

.fa-arrow-right:before {
  content: "\F061";
}

.fa-arrow-square-down:before {
  content: "\F339";
}

.fa-arrow-square-left:before {
  content: "\F33A";
}

.fa-arrow-square-right:before {
  content: "\F33B";
}

.fa-arrow-square-up:before {
  content: "\F33C";
}

.fa-arrow-to-bottom:before {
  content: "\F33D";
}

.fa-arrow-to-left:before {
  content: "\F33E";
}

.fa-arrow-to-right:before {
  content: "\F340";
}

.fa-arrow-to-top:before {
  content: "\F341";
}

.fa-arrow-up:before {
  content: "\F062";
}

.fa-arrows:before {
  content: "\F047";
}

.fa-arrows-alt:before {
  content: "\F0B2";
}

.fa-arrows-alt-h:before {
  content: "\F337";
}

.fa-arrows-alt-v:before {
  content: "\F338";
}

.fa-arrows-h:before {
  content: "\F07E";
}

.fa-arrows-v:before {
  content: "\F07D";
}

.fa-artstation:before {
  content: "\F77A";
}

.fa-assistive-listening-systems:before {
  content: "\F2A2";
}

.fa-asterisk:before {
  content: "\F069";
}

.fa-asymmetrik:before {
  content: "\F372";
}

.fa-at:before {
  content: "\F1FA";
}

.fa-atlas:before {
  content: "\F558";
}

.fa-atlassian:before {
  content: "\F77B";
}

.fa-atom:before {
  content: "\F5D2";
}

.fa-atom-alt:before {
  content: "\F5D3";
}

.fa-audible:before {
  content: "\F373";
}

.fa-audio-description:before {
  content: "\F29E";
}

.fa-autoprefixer:before {
  content: "\F41C";
}

.fa-avianex:before {
  content: "\F374";
}

.fa-aviato:before {
  content: "\F421";
}

.fa-award:before {
  content: "\F559";
}

.fa-aws:before {
  content: "\F375";
}

.fa-axe:before {
  content: "\F6B2";
}

.fa-axe-battle:before {
  content: "\F6B3";
}

.fa-baby:before {
  content: "\F77C";
}

.fa-baby-carriage:before {
  content: "\F77D";
}

.fa-backpack:before {
  content: "\F5D4";
}

.fa-backspace:before {
  content: "\F55A";
}

.fa-backward:before {
  content: "\F04A";
}

.fa-badge:before {
  content: "\F335";
}

.fa-badge-check:before {
  content: "\F336";
}

.fa-badge-dollar:before {
  content: "\F645";
}

.fa-badge-percent:before {
  content: "\F646";
}

.fa-badger-honey:before {
  content: "\F6B4";
}

.fa-balance-scale:before {
  content: "\F24E";
}

.fa-balance-scale-left:before {
  content: "\F515";
}

.fa-balance-scale-right:before {
  content: "\F516";
}

.fa-ball-pile:before {
  content: "\F77E";
}

.fa-ballot:before {
  content: "\F732";
}

.fa-ballot-check:before {
  content: "\F733";
}

.fa-ban:before {
  content: "\F05E";
}

.fa-band-aid:before {
  content: "\F462";
}

.fa-bandcamp:before {
  content: "\F2D5";
}

.fa-barcode:before {
  content: "\F02A";
}

.fa-barcode-alt:before {
  content: "\F463";
}

.fa-barcode-read:before {
  content: "\F464";
}

.fa-barcode-scan:before {
  content: "\F465";
}

.fa-bars:before {
  content: "\F0C9";
}

.fa-baseball:before {
  content: "\F432";
}

.fa-baseball-ball:before {
  content: "\F433";
}

.fa-basketball-ball:before {
  content: "\F434";
}

.fa-basketball-hoop:before {
  content: "\F435";
}

.fa-bat:before {
  content: "\F6B5";
}

.fa-bath:before {
  content: "\F2CD";
}

.fa-battery-bolt:before {
  content: "\F376";
}

.fa-battery-empty:before {
  content: "\F244";
}

.fa-battery-full:before {
  content: "\F240";
}

.fa-battery-half:before {
  content: "\F242";
}

.fa-battery-quarter:before {
  content: "\F243";
}

.fa-battery-slash:before {
  content: "\F377";
}

.fa-battery-three-quarters:before {
  content: "\F241";
}

.fa-bed:before {
  content: "\F236";
}

.fa-beer:before {
  content: "\F0FC";
}

.fa-behance:before {
  content: "\F1B4";
}

.fa-behance-square:before {
  content: "\F1B5";
}

.fa-bell:before {
  content: "\F0F3";
}

.fa-bell-school:before {
  content: "\F5D5";
}

.fa-bell-school-slash:before {
  content: "\F5D6";
}

.fa-bell-slash:before {
  content: "\F1F6";
}

.fa-bells:before {
  content: "\F77F";
}

.fa-bezier-curve:before {
  content: "\F55B";
}

.fa-bible:before {
  content: "\F647";
}

.fa-bicycle:before {
  content: "\F206";
}

.fa-bimobject:before {
  content: "\F378";
}

.fa-binoculars:before {
  content: "\F1E5";
}

.fa-biohazard:before {
  content: "\F780";
}

.fa-birthday-cake:before {
  content: "\F1FD";
}

.fa-bitbucket:before {
  content: "\F171";
}

.fa-bitcoin:before {
  content: "\F379";
}

.fa-bity:before {
  content: "\F37A";
}

.fa-black-tie:before {
  content: "\F27E";
}

.fa-blackberry:before {
  content: "\F37B";
}

.fa-blanket:before {
  content: "\F498";
}

.fa-blender:before {
  content: "\F517";
}

.fa-blender-phone:before {
  content: "\F6B6";
}

.fa-blind:before {
  content: "\F29D";
}

.fa-blog:before {
  content: "\F781";
}

.fa-blogger:before {
  content: "\F37C";
}

.fa-blogger-b:before {
  content: "\F37D";
}

.fa-bluetooth:before {
  content: "\F293";
}

.fa-bluetooth-b:before {
  content: "\F294";
}

.fa-bold:before {
  content: "\F032";
}

.fa-bolt:before {
  content: "\F0E7";
}

.fa-bomb:before {
  content: "\F1E2";
}

.fa-bone:before {
  content: "\F5D7";
}

.fa-bone-break:before {
  content: "\F5D8";
}

.fa-bong:before {
  content: "\F55C";
}

.fa-book:before {
  content: "\F02D";
}

.fa-book-alt:before {
  content: "\F5D9";
}

.fa-book-dead:before {
  content: "\F6B7";
}

.fa-book-heart:before {
  content: "\F499";
}

.fa-book-open:before {
  content: "\F518";
}

.fa-book-reader:before {
  content: "\F5DA";
}

.fa-book-spells:before {
  content: "\F6B8";
}

.fa-bookmark:before {
  content: "\F02E";
}

.fa-books:before {
  content: "\F5DB";
}

.fa-boot:before {
  content: "\F782";
}

.fa-booth-curtain:before {
  content: "\F734";
}

.fa-bow-arrow:before {
  content: "\F6B9";
}

.fa-bowling-ball:before {
  content: "\F436";
}

.fa-bowling-pins:before {
  content: "\F437";
}

.fa-box:before {
  content: "\F466";
}

.fa-box-alt:before {
  content: "\F49A";
}

.fa-box-ballot:before {
  content: "\F735";
}

.fa-box-check:before {
  content: "\F467";
}

.fa-box-fragile:before {
  content: "\F49B";
}

.fa-box-full:before {
  content: "\F49C";
}

.fa-box-heart:before {
  content: "\F49D";
}

.fa-box-open:before {
  content: "\F49E";
}

.fa-box-up:before {
  content: "\F49F";
}

.fa-box-usd:before {
  content: "\F4A0";
}

.fa-boxes:before {
  content: "\F468";
}

.fa-boxes-alt:before {
  content: "\F4A1";
}

.fa-boxing-glove:before {
  content: "\F438";
}

.fa-braille:before {
  content: "\F2A1";
}

.fa-brain:before {
  content: "\F5DC";
}

.fa-briefcase:before {
  content: "\F0B1";
}

.fa-briefcase-medical:before {
  content: "\F469";
}

.fa-broadcast-tower:before {
  content: "\F519";
}

.fa-broom:before {
  content: "\F51A";
}

.fa-browser:before {
  content: "\F37E";
}

.fa-brush:before {
  content: "\F55D";
}

.fa-btc:before {
  content: "\F15A";
}

.fa-bug:before {
  content: "\F188";
}

.fa-building:before {
  content: "\F1AD";
}

.fa-bullhorn:before {
  content: "\F0A1";
}

.fa-bullseye:before {
  content: "\F140";
}

.fa-bullseye-arrow:before {
  content: "\F648";
}

.fa-bullseye-pointer:before {
  content: "\F649";
}

.fa-burn:before {
  content: "\F46A";
}

.fa-buromobelexperte:before {
  content: "\F37F";
}

.fa-bus:before {
  content: "\F207";
}

.fa-bus-alt:before {
  content: "\F55E";
}

.fa-bus-school:before {
  content: "\F5DD";
}

.fa-business-time:before {
  content: "\F64A";
}

.fa-buysellads:before {
  content: "\F20D";
}

.fa-cabinet-filing:before {
  content: "\F64B";
}

.fa-calculator:before {
  content: "\F1EC";
}

.fa-calculator-alt:before {
  content: "\F64C";
}

.fa-calendar:before {
  content: "\F133";
}

.fa-calendar-alt:before {
  content: "\F073";
}

.fa-calendar-check:before {
  content: "\F274";
}

.fa-calendar-day:before {
  content: "\F783";
}

.fa-calendar-edit:before {
  content: "\F333";
}

.fa-calendar-exclamation:before {
  content: "\F334";
}

.fa-calendar-minus:before {
  content: "\F272";
}

.fa-calendar-plus:before {
  content: "\F271";
}

.fa-calendar-star:before {
  content: "\F736";
}

.fa-calendar-times:before {
  content: "\F273";
}

.fa-calendar-week:before {
  content: "\F784";
}

.fa-camera:before {
  content: "\F030";
}

.fa-camera-alt:before {
  content: "\F332";
}

.fa-camera-retro:before {
  content: "\F083";
}

.fa-campfire:before {
  content: "\F6BA";
}

.fa-campground:before {
  content: "\F6BB";
}

.fa-canadian-maple-leaf:before {
  content: "\F785";
}

.fa-candle-holder:before {
  content: "\F6BC";
}

.fa-candy-cane:before {
  content: "\F786";
}

.fa-candy-corn:before {
  content: "\F6BD";
}

.fa-cannabis:before {
  content: "\F55F";
}

.fa-capsules:before {
  content: "\F46B";
}

.fa-car:before {
  content: "\F1B9";
}

.fa-car-alt:before {
  content: "\F5DE";
}

.fa-car-battery:before {
  content: "\F5DF";
}

.fa-car-bump:before {
  content: "\F5E0";
}

.fa-car-crash:before {
  content: "\F5E1";
}

.fa-car-garage:before {
  content: "\F5E2";
}

.fa-car-mechanic:before {
  content: "\F5E3";
}

.fa-car-side:before {
  content: "\F5E4";
}

.fa-car-tilt:before {
  content: "\F5E5";
}

.fa-car-wash:before {
  content: "\F5E6";
}

.fa-caret-circle-down:before {
  content: "\F32D";
}

.fa-caret-circle-left:before {
  content: "\F32E";
}

.fa-caret-circle-right:before {
  content: "\F330";
}

.fa-caret-circle-up:before {
  content: "\F331";
}

.fa-caret-down:before {
  content: "\F0D7";
}

.fa-caret-left:before {
  content: "\F0D9";
}

.fa-caret-right:before {
  content: "\F0DA";
}

.fa-caret-square-down:before {
  content: "\F150";
}

.fa-caret-square-left:before {
  content: "\F191";
}

.fa-caret-square-right:before {
  content: "\F152";
}

.fa-caret-square-up:before {
  content: "\F151";
}

.fa-caret-up:before {
  content: "\F0D8";
}

.fa-carrot:before {
  content: "\F787";
}

.fa-cart-arrow-down:before {
  content: "\F218";
}

.fa-cart-plus:before {
  content: "\F217";
}

.fa-cash-register:before {
  content: "\F788";
}

.fa-cat:before {
  content: "\F6BE";
}

.fa-cauldron:before {
  content: "\F6BF";
}

.fa-cc-amazon-pay:before {
  content: "\F42D";
}

.fa-cc-amex:before {
  content: "\F1F3";
}

.fa-cc-apple-pay:before {
  content: "\F416";
}

.fa-cc-diners-club:before {
  content: "\F24C";
}

.fa-cc-discover:before {
  content: "\F1F2";
}

.fa-cc-jcb:before {
  content: "\F24B";
}

.fa-cc-mastercard:before {
  content: "\F1F1";
}

.fa-cc-paypal:before {
  content: "\F1F4";
}

.fa-cc-stripe:before {
  content: "\F1F5";
}

.fa-cc-visa:before {
  content: "\F1F0";
}

.fa-centercode:before {
  content: "\F380";
}

.fa-centos:before {
  content: "\F789";
}

.fa-certificate:before {
  content: "\F0A3";
}

.fa-chair:before {
  content: "\F6C0";
}

.fa-chair-office:before {
  content: "\F6C1";
}

.fa-chalkboard:before {
  content: "\F51B";
}

.fa-chalkboard-teacher:before {
  content: "\F51C";
}

.fa-charging-station:before {
  content: "\F5E7";
}

.fa-chart-area:before {
  content: "\F1FE";
}

.fa-chart-bar:before {
  content: "\F080";
}

.fa-chart-line:before {
  content: "\F201";
}

.fa-chart-line-down:before {
  content: "\F64D";
}

.fa-chart-network:before {
  content: "\F78A";
}

.fa-chart-pie:before {
  content: "\F200";
}

.fa-chart-pie-alt:before {
  content: "\F64E";
}

.fa-check:before {
  content: "\F00C";
}

.fa-check-circle:before {
  content: "\F058";
}

.fa-check-double:before {
  content: "\F560";
}

.fa-check-square:before {
  content: "\F14A";
}

.fa-chess:before {
  content: "\F439";
}

.fa-chess-bishop:before {
  content: "\F43A";
}

.fa-chess-bishop-alt:before {
  content: "\F43B";
}

.fa-chess-board:before {
  content: "\F43C";
}

.fa-chess-clock:before {
  content: "\F43D";
}

.fa-chess-clock-alt:before {
  content: "\F43E";
}

.fa-chess-king:before {
  content: "\F43F";
}

.fa-chess-king-alt:before {
  content: "\F440";
}

.fa-chess-knight:before {
  content: "\F441";
}

.fa-chess-knight-alt:before {
  content: "\F442";
}

.fa-chess-pawn:before {
  content: "\F443";
}

.fa-chess-pawn-alt:before {
  content: "\F444";
}

.fa-chess-queen:before {
  content: "\F445";
}

.fa-chess-queen-alt:before {
  content: "\F446";
}

.fa-chess-rook:before {
  content: "\F447";
}

.fa-chess-rook-alt:before {
  content: "\F448";
}

.fa-chevron-circle-down:before {
  content: "\F13A";
}

.fa-chevron-circle-left:before {
  content: "\F137";
}

.fa-chevron-circle-right:before {
  content: "\F138";
}

.fa-chevron-circle-up:before {
  content: "\F139";
}

.fa-chevron-double-down:before {
  content: "\F322";
}

.fa-chevron-double-left:before {
  content: "\F323";
}

.fa-chevron-double-right:before {
  content: "\F324";
}

.fa-chevron-double-up:before {
  content: "\F325";
}

.fa-chevron-down:before {
  content: "\F078";
}

.fa-chevron-left:before {
  content: "\F053";
}

.fa-chevron-right:before {
  content: "\F054";
}

.fa-chevron-square-down:before {
  content: "\F329";
}

.fa-chevron-square-left:before {
  content: "\F32A";
}

.fa-chevron-square-right:before {
  content: "\F32B";
}

.fa-chevron-square-up:before {
  content: "\F32C";
}

.fa-chevron-up:before {
  content: "\F077";
}

.fa-child:before {
  content: "\F1AE";
}

.fa-chimney:before {
  content: "\F78B";
}

.fa-chrome:before {
  content: "\F268";
}

.fa-church:before {
  content: "\F51D";
}

.fa-circle:before {
  content: "\F111";
}

.fa-circle-notch:before {
  content: "\F1CE";
}

.fa-city:before {
  content: "\F64F";
}

.fa-claw-marks:before {
  content: "\F6C2";
}

.fa-clipboard:before {
  content: "\F328";
}

.fa-clipboard-check:before {
  content: "\F46C";
}

.fa-clipboard-list:before {
  content: "\F46D";
}

.fa-clipboard-list-check:before {
  content: "\F737";
}

.fa-clipboard-prescription:before {
  content: "\F5E8";
}

.fa-clock:before {
  content: "\F017";
}

.fa-clone:before {
  content: "\F24D";
}

.fa-closed-captioning:before {
  content: "\F20A";
}

.fa-cloud:before {
  content: "\F0C2";
}

.fa-cloud-download:before {
  content: "\F0ED";
}

.fa-cloud-download-alt:before {
  content: "\F381";
}

.fa-cloud-drizzle:before {
  content: "\F738";
}

.fa-cloud-hail:before {
  content: "\F739";
}

.fa-cloud-hail-mixed:before {
  content: "\F73A";
}

.fa-cloud-meatball:before {
  content: "\F73B";
}

.fa-cloud-moon:before {
  content: "\F6C3";
}

.fa-cloud-moon-rain:before {
  content: "\F73C";
}

.fa-cloud-rain:before {
  content: "\F73D";
}

.fa-cloud-rainbow:before {
  content: "\F73E";
}

.fa-cloud-showers:before {
  content: "\F73F";
}

.fa-cloud-showers-heavy:before {
  content: "\F740";
}

.fa-cloud-sleet:before {
  content: "\F741";
}

.fa-cloud-snow:before {
  content: "\F742";
}

.fa-cloud-sun:before {
  content: "\F6C4";
}

.fa-cloud-sun-rain:before {
  content: "\F743";
}

.fa-cloud-upload:before {
  content: "\F0EE";
}

.fa-cloud-upload-alt:before {
  content: "\F382";
}

.fa-clouds:before {
  content: "\F744";
}

.fa-clouds-moon:before {
  content: "\F745";
}

.fa-clouds-sun:before {
  content: "\F746";
}

.fa-cloudscale:before {
  content: "\F383";
}

.fa-cloudsmith:before {
  content: "\F384";
}

.fa-cloudversify:before {
  content: "\F385";
}

.fa-club:before {
  content: "\F327";
}

.fa-cocktail:before {
  content: "\F561";
}

.fa-code:before {
  content: "\F121";
}

.fa-code-branch:before {
  content: "\F126";
}

.fa-code-commit:before {
  content: "\F386";
}

.fa-code-merge:before {
  content: "\F387";
}

.fa-codepen:before {
  content: "\F1CB";
}

.fa-codiepie:before {
  content: "\F284";
}

.fa-coffee:before {
  content: "\F0F4";
}

.fa-coffee-togo:before {
  content: "\F6C5";
}

.fa-coffin:before {
  content: "\F6C6";
}

.fa-cog:before {
  content: "\F013";
}

.fa-cogs:before {
  content: "\F085";
}

.fa-coins:before {
  content: "\F51E";
}

.fa-columns:before {
  content: "\F0DB";
}

.fa-comment:before {
  content: "\F075";
}

.fa-comment-alt:before {
  content: "\F27A";
}

.fa-comment-alt-check:before {
  content: "\F4A2";
}

.fa-comment-alt-dollar:before {
  content: "\F650";
}

.fa-comment-alt-dots:before {
  content: "\F4A3";
}

.fa-comment-alt-edit:before {
  content: "\F4A4";
}

.fa-comment-alt-exclamation:before {
  content: "\F4A5";
}

.fa-comment-alt-lines:before {
  content: "\F4A6";
}

.fa-comment-alt-minus:before {
  content: "\F4A7";
}

.fa-comment-alt-plus:before {
  content: "\F4A8";
}

.fa-comment-alt-slash:before {
  content: "\F4A9";
}

.fa-comment-alt-smile:before {
  content: "\F4AA";
}

.fa-comment-alt-times:before {
  content: "\F4AB";
}

.fa-comment-check:before {
  content: "\F4AC";
}

.fa-comment-dollar:before {
  content: "\F651";
}

.fa-comment-dots:before {
  content: "\F4AD";
}

.fa-comment-edit:before {
  content: "\F4AE";
}

.fa-comment-exclamation:before {
  content: "\F4AF";
}

.fa-comment-lines:before {
  content: "\F4B0";
}

.fa-comment-minus:before {
  content: "\F4B1";
}

.fa-comment-plus:before {
  content: "\F4B2";
}

.fa-comment-slash:before {
  content: "\F4B3";
}

.fa-comment-smile:before {
  content: "\F4B4";
}

.fa-comment-times:before {
  content: "\F4B5";
}

.fa-comments:before {
  content: "\F086";
}

.fa-comments-alt:before {
  content: "\F4B6";
}

.fa-comments-alt-dollar:before {
  content: "\F652";
}

.fa-comments-dollar:before {
  content: "\F653";
}

.fa-compact-disc:before {
  content: "\F51F";
}

.fa-compass:before {
  content: "\F14E";
}

.fa-compass-slash:before {
  content: "\F5E9";
}

.fa-compress:before {
  content: "\F066";
}

.fa-compress-alt:before {
  content: "\F422";
}

.fa-compress-arrows-alt:before {
  content: "\F78C";
}

.fa-compress-wide:before {
  content: "\F326";
}

.fa-concierge-bell:before {
  content: "\F562";
}

.fa-confluence:before {
  content: "\F78D";
}

.fa-connectdevelop:before {
  content: "\F20E";
}

.fa-container-storage:before {
  content: "\F4B7";
}

.fa-contao:before {
  content: "\F26D";
}

.fa-conveyor-belt:before {
  content: "\F46E";
}

.fa-conveyor-belt-alt:before {
  content: "\F46F";
}

.fa-cookie:before {
  content: "\F563";
}

.fa-cookie-bite:before {
  content: "\F564";
}

.fa-copy:before {
  content: "\F0C5";
}

.fa-copyright:before {
  content: "\F1F9";
}

.fa-corn:before {
  content: "\F6C7";
}

.fa-couch:before {
  content: "\F4B8";
}

.fa-cow:before {
  content: "\F6C8";
}

.fa-cpanel:before {
  content: "\F388";
}

.fa-creative-commons:before {
  content: "\F25E";
}

.fa-creative-commons-by:before {
  content: "\F4E7";
}

.fa-creative-commons-nc:before {
  content: "\F4E8";
}

.fa-creative-commons-nc-eu:before {
  content: "\F4E9";
}

.fa-creative-commons-nc-jp:before {
  content: "\F4EA";
}

.fa-creative-commons-nd:before {
  content: "\F4EB";
}

.fa-creative-commons-pd:before {
  content: "\F4EC";
}

.fa-creative-commons-pd-alt:before {
  content: "\F4ED";
}

.fa-creative-commons-remix:before {
  content: "\F4EE";
}

.fa-creative-commons-sa:before {
  content: "\F4EF";
}

.fa-creative-commons-sampling:before {
  content: "\F4F0";
}

.fa-creative-commons-sampling-plus:before {
  content: "\F4F1";
}

.fa-creative-commons-share:before {
  content: "\F4F2";
}

.fa-creative-commons-zero:before {
  content: "\F4F3";
}

.fa-credit-card:before {
  content: "\F09D";
}

.fa-credit-card-blank:before {
  content: "\F389";
}

.fa-credit-card-front:before {
  content: "\F38A";
}

.fa-cricket:before {
  content: "\F449";
}

.fa-critical-role:before {
  content: "\F6C9";
}

.fa-crop:before {
  content: "\F125";
}

.fa-crop-alt:before {
  content: "\F565";
}

.fa-cross:before {
  content: "\F654";
}

.fa-crosshairs:before {
  content: "\F05B";
}

.fa-crow:before {
  content: "\F520";
}

.fa-crown:before {
  content: "\F521";
}

.fa-css3:before {
  content: "\F13C";
}

.fa-css3-alt:before {
  content: "\F38B";
}

.fa-cube:before {
  content: "\F1B2";
}

.fa-cubes:before {
  content: "\F1B3";
}

.fa-curling:before {
  content: "\F44A";
}

.fa-cut:before {
  content: "\F0C4";
}

.fa-cuttlefish:before {
  content: "\F38C";
}

.fa-d-and-d:before {
  content: "\F38D";
}

.fa-d-and-d-beyond:before {
  content: "\F6CA";
}

.fa-dagger:before {
  content: "\F6CB";
}

.fa-dashcube:before {
  content: "\F210";
}

.fa-database:before {
  content: "\F1C0";
}

.fa-deaf:before {
  content: "\F2A4";
}

.fa-deer:before {
  content: "\F78E";
}

.fa-deer-rudolph:before {
  content: "\F78F";
}

.fa-delicious:before {
  content: "\F1A5";
}

.fa-democrat:before {
  content: "\F747";
}

.fa-deploydog:before {
  content: "\F38E";
}

.fa-deskpro:before {
  content: "\F38F";
}

.fa-desktop:before {
  content: "\F108";
}

.fa-desktop-alt:before {
  content: "\F390";
}

.fa-dev:before {
  content: "\F6CC";
}

.fa-deviantart:before {
  content: "\F1BD";
}

.fa-dewpoint:before {
  content: "\F748";
}

.fa-dharmachakra:before {
  content: "\F655";
}

.fa-dhl:before {
  content: "\F790";
}

.fa-diagnoses:before {
  content: "\F470";
}

.fa-diamond:before {
  content: "\F219";
}

.fa-diaspora:before {
  content: "\F791";
}

.fa-dice:before {
  content: "\F522";
}

.fa-dice-d10:before {
  content: "\F6CD";
}

.fa-dice-d12:before {
  content: "\F6CE";
}

.fa-dice-d20:before {
  content: "\F6CF";
}

.fa-dice-d4:before {
  content: "\F6D0";
}

.fa-dice-d6:before {
  content: "\F6D1";
}

.fa-dice-d8:before {
  content: "\F6D2";
}

.fa-dice-five:before {
  content: "\F523";
}

.fa-dice-four:before {
  content: "\F524";
}

.fa-dice-one:before {
  content: "\F525";
}

.fa-dice-six:before {
  content: "\F526";
}

.fa-dice-three:before {
  content: "\F527";
}

.fa-dice-two:before {
  content: "\F528";
}

.fa-digg:before {
  content: "\F1A6";
}

.fa-digital-ocean:before {
  content: "\F391";
}

.fa-digital-tachograph:before {
  content: "\F566";
}

.fa-diploma:before {
  content: "\F5EA";
}

.fa-directions:before {
  content: "\F5EB";
}

.fa-discord:before {
  content: "\F392";
}

.fa-discourse:before {
  content: "\F393";
}

.fa-divide:before {
  content: "\F529";
}

.fa-dizzy:before {
  content: "\F567";
}

.fa-dna:before {
  content: "\F471";
}

.fa-do-not-enter:before {
  content: "\F5EC";
}

.fa-dochub:before {
  content: "\F394";
}

.fa-docker:before {
  content: "\F395";
}

.fa-dog:before {
  content: "\F6D3";
}

.fa-dog-leashed:before {
  content: "\F6D4";
}

.fa-dollar-sign:before {
  content: "\F155";
}

.fa-dolly:before {
  content: "\F472";
}

.fa-dolly-empty:before {
  content: "\F473";
}

.fa-dolly-flatbed:before {
  content: "\F474";
}

.fa-dolly-flatbed-alt:before {
  content: "\F475";
}

.fa-dolly-flatbed-empty:before {
  content: "\F476";
}

.fa-donate:before {
  content: "\F4B9";
}

.fa-door-closed:before {
  content: "\F52A";
}

.fa-door-open:before {
  content: "\F52B";
}

.fa-dot-circle:before {
  content: "\F192";
}

.fa-dove:before {
  content: "\F4BA";
}

.fa-download:before {
  content: "\F019";
}

.fa-draft2digital:before {
  content: "\F396";
}

.fa-drafting-compass:before {
  content: "\F568";
}

.fa-dragon:before {
  content: "\F6D5";
}

.fa-draw-circle:before {
  content: "\F5ED";
}

.fa-draw-polygon:before {
  content: "\F5EE";
}

.fa-draw-square:before {
  content: "\F5EF";
}

.fa-dreidel:before {
  content: "\F792";
}

.fa-dribbble:before {
  content: "\F17D";
}

.fa-dribbble-square:before {
  content: "\F397";
}

.fa-dropbox:before {
  content: "\F16B";
}

.fa-drum:before {
  content: "\F569";
}

.fa-drum-steelpan:before {
  content: "\F56A";
}

.fa-drumstick:before {
  content: "\F6D6";
}

.fa-drumstick-bite:before {
  content: "\F6D7";
}

.fa-drupal:before {
  content: "\F1A9";
}

.fa-duck:before {
  content: "\F6D8";
}

.fa-dumbbell:before {
  content: "\F44B";
}

.fa-dumpster:before {
  content: "\F793";
}

.fa-dumpster-fire:before {
  content: "\F794";
}

.fa-dungeon:before {
  content: "\F6D9";
}

.fa-dyalog:before {
  content: "\F399";
}

.fa-ear:before {
  content: "\F5F0";
}

.fa-ear-muffs:before {
  content: "\F795";
}

.fa-earlybirds:before {
  content: "\F39A";
}

.fa-ebay:before {
  content: "\F4F4";
}

.fa-eclipse:before {
  content: "\F749";
}

.fa-eclipse-alt:before {
  content: "\F74A";
}

.fa-edge:before {
  content: "\F282";
}

.fa-edit:before {
  content: "\F044";
}

.fa-eject:before {
  content: "\F052";
}

.fa-elementor:before {
  content: "\F430";
}

.fa-elephant:before {
  content: "\F6DA";
}

.fa-ellipsis-h:before {
  content: "\F141";
}

.fa-ellipsis-h-alt:before {
  content: "\F39B";
}

.fa-ellipsis-v:before {
  content: "\F142";
}

.fa-ellipsis-v-alt:before {
  content: "\F39C";
}

.fa-ello:before {
  content: "\F5F1";
}

.fa-ember:before {
  content: "\F423";
}

.fa-empire:before {
  content: "\F1D1";
}

.fa-empty-set:before {
  content: "\F656";
}

.fa-engine-warning:before {
  content: "\F5F2";
}

.fa-envelope:before {
  content: "\F0E0";
}

.fa-envelope-open:before {
  content: "\F2B6";
}

.fa-envelope-open-dollar:before {
  content: "\F657";
}

.fa-envelope-open-text:before {
  content: "\F658";
}

.fa-envelope-square:before {
  content: "\F199";
}

.fa-envira:before {
  content: "\F299";
}

.fa-equals:before {
  content: "\F52C";
}

.fa-eraser:before {
  content: "\F12D";
}

.fa-erlang:before {
  content: "\F39D";
}

.fa-ethereum:before {
  content: "\F42E";
}

.fa-ethernet:before {
  content: "\F796";
}

.fa-etsy:before {
  content: "\F2D7";
}

.fa-euro-sign:before {
  content: "\F153";
}

.fa-exchange:before {
  content: "\F0EC";
}

.fa-exchange-alt:before {
  content: "\F362";
}

.fa-exclamation:before {
  content: "\F12A";
}

.fa-exclamation-circle:before {
  content: "\F06A";
}

.fa-exclamation-square:before {
  content: "\F321";
}

.fa-exclamation-triangle:before {
  content: "\F071";
}

.fa-expand:before {
  content: "\F065";
}

.fa-expand-alt:before {
  content: "\F424";
}

.fa-expand-arrows:before {
  content: "\F31D";
}

.fa-expand-arrows-alt:before {
  content: "\F31E";
}

.fa-expand-wide:before {
  content: "\F320";
}

.fa-expeditedssl:before {
  content: "\F23E";
}

.fa-external-link:before {
  content: "\F08E";
}

.fa-external-link-alt:before {
  content: "\F35D";
}

.fa-external-link-square:before {
  content: "\F14C";
}

.fa-external-link-square-alt:before {
  content: "\F360";
}

.fa-eye:before {
  content: "\F06E";
}

.fa-eye-dropper:before {
  content: "\F1FB";
}

.fa-eye-evil:before {
  content: "\F6DB";
}

.fa-eye-slash:before {
  content: "\F070";
}

.fa-facebook:before {
  content: "\F09A";
}

.fa-facebook-f:before {
  content: "\F39E";
}

.fa-facebook-messenger:before {
  content: "\F39F";
}

.fa-facebook-square:before {
  content: "\F082";
}

.fa-fantasy-flight-games:before {
  content: "\F6DC";
}

.fa-fast-backward:before {
  content: "\F049";
}

.fa-fast-forward:before {
  content: "\F050";
}

.fa-fax:before {
  content: "\F1AC";
}

.fa-feather:before {
  content: "\F52D";
}

.fa-feather-alt:before {
  content: "\F56B";
}

.fa-fedex:before {
  content: "\F797";
}

.fa-fedora:before {
  content: "\F798";
}

.fa-female:before {
  content: "\F182";
}

.fa-field-hockey:before {
  content: "\F44C";
}

.fa-fighter-jet:before {
  content: "\F0FB";
}

.fa-figma:before {
  content: "\F799";
}

.fa-file:before {
  content: "\F15B";
}

.fa-file-alt:before {
  content: "\F15C";
}

.fa-file-archive:before {
  content: "\F1C6";
}

.fa-file-audio:before {
  content: "\F1C7";
}

.fa-file-certificate:before {
  content: "\F5F3";
}

.fa-file-chart-line:before {
  content: "\F659";
}

.fa-file-chart-pie:before {
  content: "\F65A";
}

.fa-file-check:before {
  content: "\F316";
}

.fa-file-code:before {
  content: "\F1C9";
}

.fa-file-contract:before {
  content: "\F56C";
}

.fa-file-csv:before {
  content: "\F6DD";
}

.fa-file-download:before {
  content: "\F56D";
}

.fa-file-edit:before {
  content: "\F31C";
}

.fa-file-excel:before {
  content: "\F1C3";
}

.fa-file-exclamation:before {
  content: "\F31A";
}

.fa-file-export:before {
  content: "\F56E";
}

.fa-file-image:before {
  content: "\F1C5";
}

.fa-file-import:before {
  content: "\F56F";
}

.fa-file-invoice:before {
  content: "\F570";
}

.fa-file-invoice-dollar:before {
  content: "\F571";
}

.fa-file-medical:before {
  content: "\F477";
}

.fa-file-medical-alt:before {
  content: "\F478";
}

.fa-file-minus:before {
  content: "\F318";
}

.fa-file-pdf:before {
  content: "\F1C1";
}

.fa-file-plus:before {
  content: "\F319";
}

.fa-file-powerpoint:before {
  content: "\F1C4";
}

.fa-file-prescription:before {
  content: "\F572";
}

.fa-file-signature:before {
  content: "\F573";
}

.fa-file-spreadsheet:before {
  content: "\F65B";
}

.fa-file-times:before {
  content: "\F317";
}

.fa-file-upload:before {
  content: "\F574";
}

.fa-file-user:before {
  content: "\F65C";
}

.fa-file-video:before {
  content: "\F1C8";
}

.fa-file-word:before {
  content: "\F1C2";
}

.fa-fill:before {
  content: "\F575";
}

.fa-fill-drip:before {
  content: "\F576";
}

.fa-film:before {
  content: "\F008";
}

.fa-film-alt:before {
  content: "\F3A0";
}

.fa-filter:before {
  content: "\F0B0";
}

.fa-fingerprint:before {
  content: "\F577";
}

.fa-fire:before {
  content: "\F06D";
}

.fa-fire-extinguisher:before {
  content: "\F134";
}

.fa-fire-smoke:before {
  content: "\F74B";
}

.fa-firefox:before {
  content: "\F269";
}

.fa-fireplace:before {
  content: "\F79A";
}

.fa-first-aid:before {
  content: "\F479";
}

.fa-first-order:before {
  content: "\F2B0";
}

.fa-first-order-alt:before {
  content: "\F50A";
}

.fa-firstdraft:before {
  content: "\F3A1";
}

.fa-fish:before {
  content: "\F578";
}

.fa-fist-raised:before {
  content: "\F6DE";
}

.fa-flag:before {
  content: "\F024";
}

.fa-flag-alt:before {
  content: "\F74C";
}

.fa-flag-checkered:before {
  content: "\F11E";
}

.fa-flag-usa:before {
  content: "\F74D";
}

.fa-flame:before {
  content: "\F6DF";
}

.fa-flask:before {
  content: "\F0C3";
}

.fa-flask-poison:before {
  content: "\F6E0";
}

.fa-flask-potion:before {
  content: "\F6E1";
}

.fa-flickr:before {
  content: "\F16E";
}

.fa-flipboard:before {
  content: "\F44D";
}

.fa-flushed:before {
  content: "\F579";
}

.fa-fly:before {
  content: "\F417";
}

.fa-fog:before {
  content: "\F74E";
}

.fa-folder:before {
  content: "\F07B";
}

.fa-folder-minus:before {
  content: "\F65D";
}

.fa-folder-open:before {
  content: "\F07C";
}

.fa-folder-plus:before {
  content: "\F65E";
}

.fa-folder-times:before {
  content: "\F65F";
}

.fa-folders:before {
  content: "\F660";
}

.fa-font:before {
  content: "\F031";
}

.fa-font-awesome:before {
  content: "\F2B4";
}

.fa-font-awesome-alt:before {
  content: "\F35C";
}

.fa-font-awesome-flag:before {
  content: "\F425";
}

.fa-font-awesome-logo-full:before {
  content: "\F4E6";
}

.fa-fonticons:before {
  content: "\F280";
}

.fa-fonticons-fi:before {
  content: "\F3A2";
}

.fa-football-ball:before {
  content: "\F44E";
}

.fa-football-helmet:before {
  content: "\F44F";
}

.fa-forklift:before {
  content: "\F47A";
}

.fa-fort-awesome:before {
  content: "\F286";
}

.fa-fort-awesome-alt:before {
  content: "\F3A3";
}

.fa-forumbee:before {
  content: "\F211";
}

.fa-forward:before {
  content: "\F04E";
}

.fa-foursquare:before {
  content: "\F180";
}

.fa-fragile:before {
  content: "\F4BB";
}

.fa-free-code-camp:before {
  content: "\F2C5";
}

.fa-freebsd:before {
  content: "\F3A4";
}

.fa-frog:before {
  content: "\F52E";
}

.fa-frosty-head:before {
  content: "\F79B";
}

.fa-frown:before {
  content: "\F119";
}

.fa-frown-open:before {
  content: "\F57A";
}

.fa-fulcrum:before {
  content: "\F50B";
}

.fa-function:before {
  content: "\F661";
}

.fa-funnel-dollar:before {
  content: "\F662";
}

.fa-futbol:before {
  content: "\F1E3";
}

.fa-galactic-republic:before {
  content: "\F50C";
}

.fa-galactic-senate:before {
  content: "\F50D";
}

.fa-gamepad:before {
  content: "\F11B";
}

.fa-gas-pump:before {
  content: "\F52F";
}

.fa-gas-pump-slash:before {
  content: "\F5F4";
}

.fa-gavel:before {
  content: "\F0E3";
}

.fa-gem:before {
  content: "\F3A5";
}

.fa-genderless:before {
  content: "\F22D";
}

.fa-get-pocket:before {
  content: "\F265";
}

.fa-gg:before {
  content: "\F260";
}

.fa-gg-circle:before {
  content: "\F261";
}

.fa-ghost:before {
  content: "\F6E2";
}

.fa-gift:before {
  content: "\F06B";
}

.fa-gift-card:before {
  content: "\F663";
}

.fa-gifts:before {
  content: "\F79C";
}

.fa-gingerbread-man:before {
  content: "\F79D";
}

.fa-git:before {
  content: "\F1D3";
}

.fa-git-square:before {
  content: "\F1D2";
}

.fa-github:before {
  content: "\F09B";
}

.fa-github-alt:before {
  content: "\F113";
}

.fa-github-square:before {
  content: "\F092";
}

.fa-gitkraken:before {
  content: "\F3A6";
}

.fa-gitlab:before {
  content: "\F296";
}

.fa-gitter:before {
  content: "\F426";
}

.fa-glass-champagne:before {
  content: "\F79E";
}

.fa-glass-cheers:before {
  content: "\F79F";
}

.fa-glass-martini:before {
  content: "\F000";
}

.fa-glass-martini-alt:before {
  content: "\F57B";
}

.fa-glass-whiskey:before {
  content: "\F7A0";
}

.fa-glass-whiskey-rocks:before {
  content: "\F7A1";
}

.fa-glasses:before {
  content: "\F530";
}

.fa-glasses-alt:before {
  content: "\F5F5";
}

.fa-glide:before {
  content: "\F2A5";
}

.fa-glide-g:before {
  content: "\F2A6";
}

.fa-globe:before {
  content: "\F0AC";
}

.fa-globe-africa:before {
  content: "\F57C";
}

.fa-globe-americas:before {
  content: "\F57D";
}

.fa-globe-asia:before {
  content: "\F57E";
}

.fa-globe-europe:before {
  content: "\F7A2";
}

.fa-globe-snow:before {
  content: "\F7A3";
}

.fa-globe-stand:before {
  content: "\F5F6";
}

.fa-gofore:before {
  content: "\F3A7";
}

.fa-golf-ball:before {
  content: "\F450";
}

.fa-golf-club:before {
  content: "\F451";
}

.fa-goodreads:before {
  content: "\F3A8";
}

.fa-goodreads-g:before {
  content: "\F3A9";
}

.fa-google:before {
  content: "\F1A0";
}

.fa-google-drive:before {
  content: "\F3AA";
}

.fa-google-play:before {
  content: "\F3AB";
}

.fa-google-plus:before {
  content: "\F2B3";
}

.fa-google-plus-g:before {
  content: "\F0D5";
}

.fa-google-plus-square:before {
  content: "\F0D4";
}

.fa-google-wallet:before {
  content: "\F1EE";
}

.fa-gopuram:before {
  content: "\F664";
}

.fa-graduation-cap:before {
  content: "\F19D";
}

.fa-gratipay:before {
  content: "\F184";
}

.fa-grav:before {
  content: "\F2D6";
}

.fa-greater-than:before {
  content: "\F531";
}

.fa-greater-than-equal:before {
  content: "\F532";
}

.fa-grimace:before {
  content: "\F57F";
}

.fa-grin:before {
  content: "\F580";
}

.fa-grin-alt:before {
  content: "\F581";
}

.fa-grin-beam:before {
  content: "\F582";
}

.fa-grin-beam-sweat:before {
  content: "\F583";
}

.fa-grin-hearts:before {
  content: "\F584";
}

.fa-grin-squint:before {
  content: "\F585";
}

.fa-grin-squint-tears:before {
  content: "\F586";
}

.fa-grin-stars:before {
  content: "\F587";
}

.fa-grin-tears:before {
  content: "\F588";
}

.fa-grin-tongue:before {
  content: "\F589";
}

.fa-grin-tongue-squint:before {
  content: "\F58A";
}

.fa-grin-tongue-wink:before {
  content: "\F58B";
}

.fa-grin-wink:before {
  content: "\F58C";
}

.fa-grip-horizontal:before {
  content: "\F58D";
}

.fa-grip-lines:before {
  content: "\F7A4";
}

.fa-grip-lines-vertical:before {
  content: "\F7A5";
}

.fa-grip-vertical:before {
  content: "\F58E";
}

.fa-gripfire:before {
  content: "\F3AC";
}

.fa-grunt:before {
  content: "\F3AD";
}

.fa-guitar:before {
  content: "\F7A6";
}

.fa-gulp:before {
  content: "\F3AE";
}

.fa-h-square:before {
  content: "\F0FD";
}

.fa-h1:before {
  content: "\F313";
}

.fa-h2:before {
  content: "\F314";
}

.fa-h3:before {
  content: "\F315";
}

.fa-hacker-news:before {
  content: "\F1D4";
}

.fa-hacker-news-square:before {
  content: "\F3AF";
}

.fa-hackerrank:before {
  content: "\F5F7";
}

.fa-hammer:before {
  content: "\F6E3";
}

.fa-hammer-war:before {
  content: "\F6E4";
}

.fa-hamsa:before {
  content: "\F665";
}

.fa-hand-heart:before {
  content: "\F4BC";
}

.fa-hand-holding:before {
  content: "\F4BD";
}

.fa-hand-holding-box:before {
  content: "\F47B";
}

.fa-hand-holding-heart:before {
  content: "\F4BE";
}

.fa-hand-holding-magic:before {
  content: "\F6E5";
}

.fa-hand-holding-seedling:before {
  content: "\F4BF";
}

.fa-hand-holding-usd:before {
  content: "\F4C0";
}

.fa-hand-holding-water:before {
  content: "\F4C1";
}

.fa-hand-lizard:before {
  content: "\F258";
}

.fa-hand-paper:before {
  content: "\F256";
}

.fa-hand-peace:before {
  content: "\F25B";
}

.fa-hand-point-down:before {
  content: "\F0A7";
}

.fa-hand-point-left:before {
  content: "\F0A5";
}

.fa-hand-point-right:before {
  content: "\F0A4";
}

.fa-hand-point-up:before {
  content: "\F0A6";
}

.fa-hand-pointer:before {
  content: "\F25A";
}

.fa-hand-receiving:before {
  content: "\F47C";
}

.fa-hand-rock:before {
  content: "\F255";
}

.fa-hand-scissors:before {
  content: "\F257";
}

.fa-hand-spock:before {
  content: "\F259";
}

.fa-hands:before {
  content: "\F4C2";
}

.fa-hands-heart:before {
  content: "\F4C3";
}

.fa-hands-helping:before {
  content: "\F4C4";
}

.fa-hands-usd:before {
  content: "\F4C5";
}

.fa-handshake:before {
  content: "\F2B5";
}

.fa-handshake-alt:before {
  content: "\F4C6";
}

.fa-hanukiah:before {
  content: "\F6E6";
}

.fa-hashtag:before {
  content: "\F292";
}

.fa-hat-santa:before {
  content: "\F7A7";
}

.fa-hat-winter:before {
  content: "\F7A8";
}

.fa-hat-witch:before {
  content: "\F6E7";
}

.fa-hat-wizard:before {
  content: "\F6E8";
}

.fa-haykal:before {
  content: "\F666";
}

.fa-hdd:before {
  content: "\F0A0";
}

.fa-head-side:before {
  content: "\F6E9";
}

.fa-head-vr:before {
  content: "\F6EA";
}

.fa-heading:before {
  content: "\F1DC";
}

.fa-headphones:before {
  content: "\F025";
}

.fa-headphones-alt:before {
  content: "\F58F";
}

.fa-headset:before {
  content: "\F590";
}

.fa-heart:before {
  content: "\F004";
}

.fa-heart-broken:before {
  content: "\F7A9";
}

.fa-heart-circle:before {
  content: "\F4C7";
}

.fa-heart-rate:before {
  content: "\F5F8";
}

.fa-heart-square:before {
  content: "\F4C8";
}

.fa-heartbeat:before {
  content: "\F21E";
}

.fa-helicopter:before {
  content: "\F533";
}

.fa-helmet-battle:before {
  content: "\F6EB";
}

.fa-hexagon:before {
  content: "\F312";
}

.fa-highlighter:before {
  content: "\F591";
}

.fa-hiking:before {
  content: "\F6EC";
}

.fa-hippo:before {
  content: "\F6ED";
}

.fa-hips:before {
  content: "\F452";
}

.fa-hire-a-helper:before {
  content: "\F3B0";
}

.fa-history:before {
  content: "\F1DA";
}

.fa-hockey-mask:before {
  content: "\F6EE";
}

.fa-hockey-puck:before {
  content: "\F453";
}

.fa-hockey-sticks:before {
  content: "\F454";
}

.fa-holly-berry:before {
  content: "\F7AA";
}

.fa-home:before {
  content: "\F015";
}

.fa-home-heart:before {
  content: "\F4C9";
}

.fa-hood-cloak:before {
  content: "\F6EF";
}

.fa-hooli:before {
  content: "\F427";
}

.fa-hornbill:before {
  content: "\F592";
}

.fa-horse:before {
  content: "\F6F0";
}

.fa-horse-head:before {
  content: "\F7AB";
}

.fa-hospital:before {
  content: "\F0F8";
}

.fa-hospital-alt:before {
  content: "\F47D";
}

.fa-hospital-symbol:before {
  content: "\F47E";
}

.fa-hot-tub:before {
  content: "\F593";
}

.fa-hotel:before {
  content: "\F594";
}

.fa-hotjar:before {
  content: "\F3B1";
}

.fa-hourglass:before {
  content: "\F254";
}

.fa-hourglass-end:before {
  content: "\F253";
}

.fa-hourglass-half:before {
  content: "\F252";
}

.fa-hourglass-start:before {
  content: "\F251";
}

.fa-house-damage:before {
  content: "\F6F1";
}

.fa-house-flood:before {
  content: "\F74F";
}

.fa-houzz:before {
  content: "\F27C";
}

.fa-hryvnia:before {
  content: "\F6F2";
}

.fa-html5:before {
  content: "\F13B";
}

.fa-hubspot:before {
  content: "\F3B2";
}

.fa-humidity:before {
  content: "\F750";
}

.fa-hurricane:before {
  content: "\F751";
}

.fa-i-cursor:before {
  content: "\F246";
}

.fa-ice-skate:before {
  content: "\F7AC";
}

.fa-icicles:before {
  content: "\F7AD";
}

.fa-id-badge:before {
  content: "\F2C1";
}

.fa-id-card:before {
  content: "\F2C2";
}

.fa-id-card-alt:before {
  content: "\F47F";
}

.fa-igloo:before {
  content: "\F7AE";
}

.fa-image:before {
  content: "\F03E";
}

.fa-images:before {
  content: "\F302";
}

.fa-imdb:before {
  content: "\F2D8";
}

.fa-inbox:before {
  content: "\F01C";
}

.fa-inbox-in:before {
  content: "\F310";
}

.fa-inbox-out:before {
  content: "\F311";
}

.fa-indent:before {
  content: "\F03C";
}

.fa-industry:before {
  content: "\F275";
}

.fa-industry-alt:before {
  content: "\F3B3";
}

.fa-infinity:before {
  content: "\F534";
}

.fa-info:before {
  content: "\F129";
}

.fa-info-circle:before {
  content: "\F05A";
}

.fa-info-square:before {
  content: "\F30F";
}

.fa-inhaler:before {
  content: "\F5F9";
}

.fa-instagram:before {
  content: "\F16D";
}

.fa-integral:before {
  content: "\F667";
}

.fa-intercom:before {
  content: "\F7AF";
}

.fa-internet-explorer:before {
  content: "\F26B";
}

.fa-intersection:before {
  content: "\F668";
}

.fa-inventory:before {
  content: "\F480";
}

.fa-invision:before {
  content: "\F7B0";
}

.fa-ioxhost:before {
  content: "\F208";
}

.fa-italic:before {
  content: "\F033";
}

.fa-itunes:before {
  content: "\F3B4";
}

.fa-itunes-note:before {
  content: "\F3B5";
}

.fa-jack-o-lantern:before {
  content: "\F30E";
}

.fa-java:before {
  content: "\F4E4";
}

.fa-jedi:before {
  content: "\F669";
}

.fa-jedi-order:before {
  content: "\F50E";
}

.fa-jenkins:before {
  content: "\F3B6";
}

.fa-jira:before {
  content: "\F7B1";
}

.fa-joget:before {
  content: "\F3B7";
}

.fa-joint:before {
  content: "\F595";
}

.fa-joomla:before {
  content: "\F1AA";
}

.fa-journal-whills:before {
  content: "\F66A";
}

.fa-js:before {
  content: "\F3B8";
}

.fa-js-square:before {
  content: "\F3B9";
}

.fa-jsfiddle:before {
  content: "\F1CC";
}

.fa-kaaba:before {
  content: "\F66B";
}

.fa-kaggle:before {
  content: "\F5FA";
}

.fa-key:before {
  content: "\F084";
}

.fa-key-skeleton:before {
  content: "\F6F3";
}

.fa-keybase:before {
  content: "\F4F5";
}

.fa-keyboard:before {
  content: "\F11C";
}

.fa-keycdn:before {
  content: "\F3BA";
}

.fa-keynote:before {
  content: "\F66C";
}

.fa-khanda:before {
  content: "\F66D";
}

.fa-kickstarter:before {
  content: "\F3BB";
}

.fa-kickstarter-k:before {
  content: "\F3BC";
}

.fa-kidneys:before {
  content: "\F5FB";
}

.fa-kiss:before {
  content: "\F596";
}

.fa-kiss-beam:before {
  content: "\F597";
}

.fa-kiss-wink-heart:before {
  content: "\F598";
}

.fa-kite:before {
  content: "\F6F4";
}

.fa-kiwi-bird:before {
  content: "\F535";
}

.fa-knife-kitchen:before {
  content: "\F6F5";
}

.fa-korvue:before {
  content: "\F42F";
}

.fa-lambda:before {
  content: "\F66E";
}

.fa-lamp:before {
  content: "\F4CA";
}

.fa-landmark:before {
  content: "\F66F";
}

.fa-landmark-alt:before {
  content: "\F752";
}

.fa-language:before {
  content: "\F1AB";
}

.fa-laptop:before {
  content: "\F109";
}

.fa-laptop-code:before {
  content: "\F5FC";
}

.fa-laravel:before {
  content: "\F3BD";
}

.fa-lastfm:before {
  content: "\F202";
}

.fa-lastfm-square:before {
  content: "\F203";
}

.fa-laugh:before {
  content: "\F599";
}

.fa-laugh-beam:before {
  content: "\F59A";
}

.fa-laugh-squint:before {
  content: "\F59B";
}

.fa-laugh-wink:before {
  content: "\F59C";
}

.fa-layer-group:before {
  content: "\F5FD";
}

.fa-layer-minus:before {
  content: "\F5FE";
}

.fa-layer-plus:before {
  content: "\F5FF";
}

.fa-leaf:before {
  content: "\F06C";
}

.fa-leaf-heart:before {
  content: "\F4CB";
}

.fa-leaf-maple:before {
  content: "\F6F6";
}

.fa-leaf-oak:before {
  content: "\F6F7";
}

.fa-leanpub:before {
  content: "\F212";
}

.fa-lemon:before {
  content: "\F094";
}

.fa-less:before {
  content: "\F41D";
}

.fa-less-than:before {
  content: "\F536";
}

.fa-less-than-equal:before {
  content: "\F537";
}

.fa-level-down:before {
  content: "\F149";
}

.fa-level-down-alt:before {
  content: "\F3BE";
}

.fa-level-up:before {
  content: "\F148";
}

.fa-level-up-alt:before {
  content: "\F3BF";
}

.fa-life-ring:before {
  content: "\F1CD";
}

.fa-lightbulb:before {
  content: "\F0EB";
}

.fa-lightbulb-dollar:before {
  content: "\F670";
}

.fa-lightbulb-exclamation:before {
  content: "\F671";
}

.fa-lightbulb-on:before {
  content: "\F672";
}

.fa-lightbulb-slash:before {
  content: "\F673";
}

.fa-lights-holiday:before {
  content: "\F7B2";
}

.fa-line:before {
  content: "\F3C0";
}

.fa-link:before {
  content: "\F0C1";
}

.fa-linkedin:before {
  content: "\F08C";
}

.fa-linkedin-in:before {
  content: "\F0E1";
}

.fa-linode:before {
  content: "\F2B8";
}

.fa-linux:before {
  content: "\F17C";
}

.fa-lips:before {
  content: "\F600";
}

.fa-lira-sign:before {
  content: "\F195";
}

.fa-list:before {
  content: "\F03A";
}

.fa-list-alt:before {
  content: "\F022";
}

.fa-list-ol:before {
  content: "\F0CB";
}

.fa-list-ul:before {
  content: "\F0CA";
}

.fa-location:before {
  content: "\F601";
}

.fa-location-arrow:before {
  content: "\F124";
}

.fa-location-circle:before {
  content: "\F602";
}

.fa-location-slash:before {
  content: "\F603";
}

.fa-lock:before {
  content: "\F023";
}

.fa-lock-alt:before {
  content: "\F30D";
}

.fa-lock-open:before {
  content: "\F3C1";
}

.fa-lock-open-alt:before {
  content: "\F3C2";
}

.fa-long-arrow-alt-down:before {
  content: "\F309";
}

.fa-long-arrow-alt-left:before {
  content: "\F30A";
}

.fa-long-arrow-alt-right:before {
  content: "\F30B";
}

.fa-long-arrow-alt-up:before {
  content: "\F30C";
}

.fa-long-arrow-down:before {
  content: "\F175";
}

.fa-long-arrow-left:before {
  content: "\F177";
}

.fa-long-arrow-right:before {
  content: "\F178";
}

.fa-long-arrow-up:before {
  content: "\F176";
}

.fa-loveseat:before {
  content: "\F4CC";
}

.fa-low-vision:before {
  content: "\F2A8";
}

.fa-luchador:before {
  content: "\F455";
}

.fa-luggage-cart:before {
  content: "\F59D";
}

.fa-lungs:before {
  content: "\F604";
}

.fa-lyft:before {
  content: "\F3C3";
}

.fa-mace:before {
  content: "\F6F8";
}

.fa-magento:before {
  content: "\F3C4";
}

.fa-magic:before {
  content: "\F0D0";
}

.fa-magnet:before {
  content: "\F076";
}

.fa-mail-bulk:before {
  content: "\F674";
}

.fa-mailchimp:before {
  content: "\F59E";
}

.fa-male:before {
  content: "\F183";
}

.fa-mandalorian:before {
  content: "\F50F";
}

.fa-mandolin:before {
  content: "\F6F9";
}

.fa-map:before {
  content: "\F279";
}

.fa-map-marked:before {
  content: "\F59F";
}

.fa-map-marked-alt:before {
  content: "\F5A0";
}

.fa-map-marker:before {
  content: "\F041";
}

.fa-map-marker-alt:before {
  content: "\F3C5";
}

.fa-map-marker-alt-slash:before {
  content: "\F605";
}

.fa-map-marker-check:before {
  content: "\F606";
}

.fa-map-marker-edit:before {
  content: "\F607";
}

.fa-map-marker-exclamation:before {
  content: "\F608";
}

.fa-map-marker-minus:before {
  content: "\F609";
}

.fa-map-marker-plus:before {
  content: "\F60A";
}

.fa-map-marker-question:before {
  content: "\F60B";
}

.fa-map-marker-slash:before {
  content: "\F60C";
}

.fa-map-marker-smile:before {
  content: "\F60D";
}

.fa-map-marker-times:before {
  content: "\F60E";
}

.fa-map-pin:before {
  content: "\F276";
}

.fa-map-signs:before {
  content: "\F277";
}

.fa-markdown:before {
  content: "\F60F";
}

.fa-marker:before {
  content: "\F5A1";
}

.fa-mars:before {
  content: "\F222";
}

.fa-mars-double:before {
  content: "\F227";
}

.fa-mars-stroke:before {
  content: "\F229";
}

.fa-mars-stroke-h:before {
  content: "\F22B";
}

.fa-mars-stroke-v:before {
  content: "\F22A";
}

.fa-mask:before {
  content: "\F6FA";
}

.fa-mastodon:before {
  content: "\F4F6";
}

.fa-maxcdn:before {
  content: "\F136";
}

.fa-medal:before {
  content: "\F5A2";
}

.fa-medapps:before {
  content: "\F3C6";
}

.fa-medium:before {
  content: "\F23A";
}

.fa-medium-m:before {
  content: "\F3C7";
}

.fa-medkit:before {
  content: "\F0FA";
}

.fa-medrt:before {
  content: "\F3C8";
}

.fa-meetup:before {
  content: "\F2E0";
}

.fa-megaphone:before {
  content: "\F675";
}

.fa-megaport:before {
  content: "\F5A3";
}

.fa-meh:before {
  content: "\F11A";
}

.fa-meh-blank:before {
  content: "\F5A4";
}

.fa-meh-rolling-eyes:before {
  content: "\F5A5";
}

.fa-memory:before {
  content: "\F538";
}

.fa-mendeley:before {
  content: "\F7B3";
}

.fa-menorah:before {
  content: "\F676";
}

.fa-mercury:before {
  content: "\F223";
}

.fa-meteor:before {
  content: "\F753";
}

.fa-microchip:before {
  content: "\F2DB";
}

.fa-microphone:before {
  content: "\F130";
}

.fa-microphone-alt:before {
  content: "\F3C9";
}

.fa-microphone-alt-slash:before {
  content: "\F539";
}

.fa-microphone-slash:before {
  content: "\F131";
}

.fa-microscope:before {
  content: "\F610";
}

.fa-microsoft:before {
  content: "\F3CA";
}

.fa-mind-share:before {
  content: "\F677";
}

.fa-minus:before {
  content: "\F068";
}

.fa-minus-circle:before {
  content: "\F056";
}

.fa-minus-hexagon:before {
  content: "\F307";
}

.fa-minus-octagon:before {
  content: "\F308";
}

.fa-minus-square:before {
  content: "\F146";
}

.fa-mistletoe:before {
  content: "\F7B4";
}

.fa-mitten:before {
  content: "\F7B5";
}

.fa-mix:before {
  content: "\F3CB";
}

.fa-mixcloud:before {
  content: "\F289";
}

.fa-mizuni:before {
  content: "\F3CC";
}

.fa-mobile:before {
  content: "\F10B";
}

.fa-mobile-alt:before {
  content: "\F3CD";
}

.fa-mobile-android:before {
  content: "\F3CE";
}

.fa-mobile-android-alt:before {
  content: "\F3CF";
}

.fa-modx:before {
  content: "\F285";
}

.fa-monero:before {
  content: "\F3D0";
}

.fa-money-bill:before {
  content: "\F0D6";
}

.fa-money-bill-alt:before {
  content: "\F3D1";
}

.fa-money-bill-wave:before {
  content: "\F53A";
}

.fa-money-bill-wave-alt:before {
  content: "\F53B";
}

.fa-money-check:before {
  content: "\F53C";
}

.fa-money-check-alt:before {
  content: "\F53D";
}

.fa-monitor-heart-rate:before {
  content: "\F611";
}

.fa-monkey:before {
  content: "\F6FB";
}

.fa-monument:before {
  content: "\F5A6";
}

.fa-moon:before {
  content: "\F186";
}

.fa-moon-cloud:before {
  content: "\F754";
}

.fa-moon-stars:before {
  content: "\F755";
}

.fa-mortar-pestle:before {
  content: "\F5A7";
}

.fa-mosque:before {
  content: "\F678";
}

.fa-motorcycle:before {
  content: "\F21C";
}

.fa-mountain:before {
  content: "\F6FC";
}

.fa-mountains:before {
  content: "\F6FD";
}

.fa-mouse-pointer:before {
  content: "\F245";
}

.fa-mug-hot:before {
  content: "\F7B6";
}

.fa-mug-marshmallows:before {
  content: "\F7B7";
}

.fa-music:before {
  content: "\F001";
}

.fa-napster:before {
  content: "\F3D2";
}

.fa-narwhal:before {
  content: "\F6FE";
}

.fa-neos:before {
  content: "\F612";
}

.fa-network-wired:before {
  content: "\F6FF";
}

.fa-neuter:before {
  content: "\F22C";
}

.fa-newspaper:before {
  content: "\F1EA";
}

.fa-nimblr:before {
  content: "\F5A8";
}

.fa-nintendo-switch:before {
  content: "\F418";
}

.fa-node:before {
  content: "\F419";
}

.fa-node-js:before {
  content: "\F3D3";
}

.fa-not-equal:before {
  content: "\F53E";
}

.fa-notes-medical:before {
  content: "\F481";
}

.fa-npm:before {
  content: "\F3D4";
}

.fa-ns8:before {
  content: "\F3D5";
}

.fa-nutritionix:before {
  content: "\F3D6";
}

.fa-object-group:before {
  content: "\F247";
}

.fa-object-ungroup:before {
  content: "\F248";
}

.fa-octagon:before {
  content: "\F306";
}

.fa-odnoklassniki:before {
  content: "\F263";
}

.fa-odnoklassniki-square:before {
  content: "\F264";
}

.fa-oil-can:before {
  content: "\F613";
}

.fa-oil-temp:before {
  content: "\F614";
}

.fa-old-republic:before {
  content: "\F510";
}

.fa-om:before {
  content: "\F679";
}

.fa-omega:before {
  content: "\F67A";
}

.fa-opencart:before {
  content: "\F23D";
}

.fa-openid:before {
  content: "\F19B";
}

.fa-opera:before {
  content: "\F26A";
}

.fa-optin-monster:before {
  content: "\F23C";
}

.fa-ornament:before {
  content: "\F7B8";
}

.fa-osi:before {
  content: "\F41A";
}

.fa-otter:before {
  content: "\F700";
}

.fa-outdent:before {
  content: "\F03B";
}

.fa-page4:before {
  content: "\F3D7";
}

.fa-pagelines:before {
  content: "\F18C";
}

.fa-paint-brush:before {
  content: "\F1FC";
}

.fa-paint-brush-alt:before {
  content: "\F5A9";
}

.fa-paint-roller:before {
  content: "\F5AA";
}

.fa-palette:before {
  content: "\F53F";
}

.fa-palfed:before {
  content: "\F3D8";
}

.fa-pallet:before {
  content: "\F482";
}

.fa-pallet-alt:before {
  content: "\F483";
}

.fa-paper-plane:before {
  content: "\F1D8";
}

.fa-paperclip:before {
  content: "\F0C6";
}

.fa-parachute-box:before {
  content: "\F4CD";
}

.fa-paragraph:before {
  content: "\F1DD";
}

.fa-parking:before {
  content: "\F540";
}

.fa-parking-circle:before {
  content: "\F615";
}

.fa-parking-circle-slash:before {
  content: "\F616";
}

.fa-parking-slash:before {
  content: "\F617";
}

.fa-passport:before {
  content: "\F5AB";
}

.fa-pastafarianism:before {
  content: "\F67B";
}

.fa-paste:before {
  content: "\F0EA";
}

.fa-patreon:before {
  content: "\F3D9";
}

.fa-pause:before {
  content: "\F04C";
}

.fa-pause-circle:before {
  content: "\F28B";
}

.fa-paw:before {
  content: "\F1B0";
}

.fa-paw-alt:before {
  content: "\F701";
}

.fa-paw-claws:before {
  content: "\F702";
}

.fa-paypal:before {
  content: "\F1ED";
}

.fa-peace:before {
  content: "\F67C";
}

.fa-pegasus:before {
  content: "\F703";
}

.fa-pen:before {
  content: "\F304";
}

.fa-pen-alt:before {
  content: "\F305";
}

.fa-pen-fancy:before {
  content: "\F5AC";
}

.fa-pen-nib:before {
  content: "\F5AD";
}

.fa-pen-square:before {
  content: "\F14B";
}

.fa-pencil:before {
  content: "\F040";
}

.fa-pencil-alt:before {
  content: "\F303";
}

.fa-pencil-paintbrush:before {
  content: "\F618";
}

.fa-pencil-ruler:before {
  content: "\F5AE";
}

.fa-pennant:before {
  content: "\F456";
}

.fa-penny-arcade:before {
  content: "\F704";
}

.fa-people-carry:before {
  content: "\F4CE";
}

.fa-percent:before {
  content: "\F295";
}

.fa-percentage:before {
  content: "\F541";
}

.fa-periscope:before {
  content: "\F3DA";
}

.fa-person-booth:before {
  content: "\F756";
}

.fa-person-carry:before {
  content: "\F4CF";
}

.fa-person-dolly:before {
  content: "\F4D0";
}

.fa-person-dolly-empty:before {
  content: "\F4D1";
}

.fa-person-sign:before {
  content: "\F757";
}

.fa-phabricator:before {
  content: "\F3DB";
}

.fa-phoenix-framework:before {
  content: "\F3DC";
}

.fa-phoenix-squadron:before {
  content: "\F511";
}

.fa-phone:before {
  content: "\F095";
}

.fa-phone-office:before {
  content: "\F67D";
}

.fa-phone-plus:before {
  content: "\F4D2";
}

.fa-phone-slash:before {
  content: "\F3DD";
}

.fa-phone-square:before {
  content: "\F098";
}

.fa-phone-volume:before {
  content: "\F2A0";
}

.fa-php:before {
  content: "\F457";
}

.fa-pi:before {
  content: "\F67E";
}

.fa-pie:before {
  content: "\F705";
}

.fa-pied-piper:before {
  content: "\F2AE";
}

.fa-pied-piper-alt:before {
  content: "\F1A8";
}

.fa-pied-piper-hat:before {
  content: "\F4E5";
}

.fa-pied-piper-pp:before {
  content: "\F1A7";
}

.fa-pig:before {
  content: "\F706";
}

.fa-piggy-bank:before {
  content: "\F4D3";
}

.fa-pills:before {
  content: "\F484";
}

.fa-pinterest:before {
  content: "\F0D2";
}

.fa-pinterest-p:before {
  content: "\F231";
}

.fa-pinterest-square:before {
  content: "\F0D3";
}

.fa-place-of-worship:before {
  content: "\F67F";
}

.fa-plane:before {
  content: "\F072";
}

.fa-plane-alt:before {
  content: "\F3DE";
}

.fa-plane-arrival:before {
  content: "\F5AF";
}

.fa-plane-departure:before {
  content: "\F5B0";
}

.fa-play:before {
  content: "\F04B";
}

.fa-play-circle:before {
  content: "\F144";
}

.fa-playstation:before {
  content: "\F3DF";
}

.fa-plug:before {
  content: "\F1E6";
}

.fa-plus:before {
  content: "\F067";
}

.fa-plus-circle:before {
  content: "\F055";
}

.fa-plus-hexagon:before {
  content: "\F300";
}

.fa-plus-octagon:before {
  content: "\F301";
}

.fa-plus-square:before {
  content: "\F0FE";
}

.fa-podcast:before {
  content: "\F2CE";
}

.fa-podium:before {
  content: "\F680";
}

.fa-podium-star:before {
  content: "\F758";
}

.fa-poll:before {
  content: "\F681";
}

.fa-poll-h:before {
  content: "\F682";
}

.fa-poll-people:before {
  content: "\F759";
}

.fa-poo:before {
  content: "\F2FE";
}

.fa-poo-storm:before {
  content: "\F75A";
}

.fa-poop:before {
  content: "\F619";
}

.fa-portrait:before {
  content: "\F3E0";
}

.fa-pound-sign:before {
  content: "\F154";
}

.fa-power-off:before {
  content: "\F011";
}

.fa-pray:before {
  content: "\F683";
}

.fa-praying-hands:before {
  content: "\F684";
}

.fa-prescription:before {
  content: "\F5B1";
}

.fa-prescription-bottle:before {
  content: "\F485";
}

.fa-prescription-bottle-alt:before {
  content: "\F486";
}

.fa-presentation:before {
  content: "\F685";
}

.fa-print:before {
  content: "\F02F";
}

.fa-print-slash:before {
  content: "\F686";
}

.fa-procedures:before {
  content: "\F487";
}

.fa-product-hunt:before {
  content: "\F288";
}

.fa-project-diagram:before {
  content: "\F542";
}

.fa-pumpkin:before {
  content: "\F707";
}

.fa-pushed:before {
  content: "\F3E1";
}

.fa-puzzle-piece:before {
  content: "\F12E";
}

.fa-python:before {
  content: "\F3E2";
}

.fa-qq:before {
  content: "\F1D6";
}

.fa-qrcode:before {
  content: "\F029";
}

.fa-question:before {
  content: "\F128";
}

.fa-question-circle:before {
  content: "\F059";
}

.fa-question-square:before {
  content: "\F2FD";
}

.fa-quidditch:before {
  content: "\F458";
}

.fa-quinscape:before {
  content: "\F459";
}

.fa-quora:before {
  content: "\F2C4";
}

.fa-quote-left:before {
  content: "\F10D";
}

.fa-quote-right:before {
  content: "\F10E";
}

.fa-quran:before {
  content: "\F687";
}

.fa-r-project:before {
  content: "\F4F7";
}

.fa-rabbit:before {
  content: "\F708";
}

.fa-rabbit-fast:before {
  content: "\F709";
}

.fa-racquet:before {
  content: "\F45A";
}

.fa-radiation:before {
  content: "\F7B9";
}

.fa-radiation-alt:before {
  content: "\F7BA";
}

.fa-rainbow:before {
  content: "\F75B";
}

.fa-raindrops:before {
  content: "\F75C";
}

.fa-ram:before {
  content: "\F70A";
}

.fa-ramp-loading:before {
  content: "\F4D4";
}

.fa-random:before {
  content: "\F074";
}

.fa-raspberry-pi:before {
  content: "\F7BB";
}

.fa-ravelry:before {
  content: "\F2D9";
}

.fa-react:before {
  content: "\F41B";
}

.fa-reacteurope:before {
  content: "\F75D";
}

.fa-readme:before {
  content: "\F4D5";
}

.fa-rebel:before {
  content: "\F1D0";
}

.fa-receipt:before {
  content: "\F543";
}

.fa-rectangle-landscape:before {
  content: "\F2FA";
}

.fa-rectangle-portrait:before {
  content: "\F2FB";
}

.fa-rectangle-wide:before {
  content: "\F2FC";
}

.fa-recycle:before {
  content: "\F1B8";
}

.fa-red-river:before {
  content: "\F3E3";
}

.fa-reddit:before {
  content: "\F1A1";
}

.fa-reddit-alien:before {
  content: "\F281";
}

.fa-reddit-square:before {
  content: "\F1A2";
}

.fa-redhat:before {
  content: "\F7BC";
}

.fa-redo:before {
  content: "\F01E";
}

.fa-redo-alt:before {
  content: "\F2F9";
}

.fa-registered:before {
  content: "\F25D";
}

.fa-renren:before {
  content: "\F18B";
}

.fa-repeat:before {
  content: "\F363";
}

.fa-repeat-1:before {
  content: "\F365";
}

.fa-repeat-1-alt:before {
  content: "\F366";
}

.fa-repeat-alt:before {
  content: "\F364";
}

.fa-reply:before {
  content: "\F3E5";
}

.fa-reply-all:before {
  content: "\F122";
}

.fa-replyd:before {
  content: "\F3E6";
}

.fa-republican:before {
  content: "\F75E";
}

.fa-researchgate:before {
  content: "\F4F8";
}

.fa-resolving:before {
  content: "\F3E7";
}

.fa-restroom:before {
  content: "\F7BD";
}

.fa-retweet:before {
  content: "\F079";
}

.fa-retweet-alt:before {
  content: "\F361";
}

.fa-rev:before {
  content: "\F5B2";
}

.fa-ribbon:before {
  content: "\F4D6";
}

.fa-ring:before {
  content: "\F70B";
}

.fa-road:before {
  content: "\F018";
}

.fa-robot:before {
  content: "\F544";
}

.fa-rocket:before {
  content: "\F135";
}

.fa-rocketchat:before {
  content: "\F3E8";
}

.fa-rockrms:before {
  content: "\F3E9";
}

.fa-route:before {
  content: "\F4D7";
}

.fa-route-highway:before {
  content: "\F61A";
}

.fa-route-interstate:before {
  content: "\F61B";
}

.fa-rss:before {
  content: "\F09E";
}

.fa-rss-square:before {
  content: "\F143";
}

.fa-ruble-sign:before {
  content: "\F158";
}

.fa-ruler:before {
  content: "\F545";
}

.fa-ruler-combined:before {
  content: "\F546";
}

.fa-ruler-horizontal:before {
  content: "\F547";
}

.fa-ruler-triangle:before {
  content: "\F61C";
}

.fa-ruler-vertical:before {
  content: "\F548";
}

.fa-running:before {
  content: "\F70C";
}

.fa-rupee-sign:before {
  content: "\F156";
}

.fa-rv:before {
  content: "\F7BE";
}

.fa-sad-cry:before {
  content: "\F5B3";
}

.fa-sad-tear:before {
  content: "\F5B4";
}

.fa-safari:before {
  content: "\F267";
}

.fa-sass:before {
  content: "\F41E";
}

.fa-satellite:before {
  content: "\F7BF";
}

.fa-satellite-dish:before {
  content: "\F7C0";
}

.fa-save:before {
  content: "\F0C7";
}

.fa-scalpel:before {
  content: "\F61D";
}

.fa-scalpel-path:before {
  content: "\F61E";
}

.fa-scanner:before {
  content: "\F488";
}

.fa-scanner-keyboard:before {
  content: "\F489";
}

.fa-scanner-touchscreen:before {
  content: "\F48A";
}

.fa-scarecrow:before {
  content: "\F70D";
}

.fa-scarf:before {
  content: "\F7C1";
}

.fa-schlix:before {
  content: "\F3EA";
}

.fa-school:before {
  content: "\F549";
}

.fa-screwdriver:before {
  content: "\F54A";
}

.fa-scribd:before {
  content: "\F28A";
}

.fa-scroll:before {
  content: "\F70E";
}

.fa-scroll-old:before {
  content: "\F70F";
}

.fa-scrubber:before {
  content: "\F2F8";
}

.fa-scythe:before {
  content: "\F710";
}

.fa-sd-card:before {
  content: "\F7C2";
}

.fa-search:before {
  content: "\F002";
}

.fa-search-dollar:before {
  content: "\F688";
}

.fa-search-location:before {
  content: "\F689";
}

.fa-search-minus:before {
  content: "\F010";
}

.fa-search-plus:before {
  content: "\F00E";
}

.fa-searchengin:before {
  content: "\F3EB";
}

.fa-seedling:before {
  content: "\F4D8";
}

.fa-sellcast:before {
  content: "\F2DA";
}

.fa-sellsy:before {
  content: "\F213";
}

.fa-server:before {
  content: "\F233";
}

.fa-servicestack:before {
  content: "\F3EC";
}

.fa-shapes:before {
  content: "\F61F";
}

.fa-share:before {
  content: "\F064";
}

.fa-share-all:before {
  content: "\F367";
}

.fa-share-alt:before {
  content: "\F1E0";
}

.fa-share-alt-square:before {
  content: "\F1E1";
}

.fa-share-square:before {
  content: "\F14D";
}

.fa-sheep:before {
  content: "\F711";
}

.fa-shekel-sign:before {
  content: "\F20B";
}

.fa-shield:before {
  content: "\F132";
}

.fa-shield-alt:before {
  content: "\F3ED";
}

.fa-shield-check:before {
  content: "\F2F7";
}

.fa-shield-cross:before {
  content: "\F712";
}

.fa-ship:before {
  content: "\F21A";
}

.fa-shipping-fast:before {
  content: "\F48B";
}

.fa-shipping-timed:before {
  content: "\F48C";
}

.fa-shirtsinbulk:before {
  content: "\F214";
}

.fa-shoe-prints:before {
  content: "\F54B";
}

.fa-shopping-bag:before {
  content: "\F290";
}

.fa-shopping-basket:before {
  content: "\F291";
}

.fa-shopping-cart:before {
  content: "\F07A";
}

.fa-shopware:before {
  content: "\F5B5";
}

.fa-shovel:before {
  content: "\F713";
}

.fa-shovel-snow:before {
  content: "\F7C3";
}

.fa-shower:before {
  content: "\F2CC";
}

.fa-shredder:before {
  content: "\F68A";
}

.fa-shuttle-van:before {
  content: "\F5B6";
}

.fa-shuttlecock:before {
  content: "\F45B";
}

.fa-sigma:before {
  content: "\F68B";
}

.fa-sign:before {
  content: "\F4D9";
}

.fa-sign-in:before {
  content: "\F090";
}

.fa-sign-in-alt:before {
  content: "\F2F6";
}

.fa-sign-language:before {
  content: "\F2A7";
}

.fa-sign-out:before {
  content: "\F08B";
}

.fa-sign-out-alt:before {
  content: "\F2F5";
}

.fa-signal:before {
  content: "\F012";
}

.fa-signal-1:before {
  content: "\F68C";
}

.fa-signal-2:before {
  content: "\F68D";
}

.fa-signal-3:before {
  content: "\F68E";
}

.fa-signal-4:before {
  content: "\F68F";
}

.fa-signal-alt:before {
  content: "\F690";
}

.fa-signal-alt-1:before {
  content: "\F691";
}

.fa-signal-alt-2:before {
  content: "\F692";
}

.fa-signal-alt-3:before {
  content: "\F693";
}

.fa-signal-alt-slash:before {
  content: "\F694";
}

.fa-signal-slash:before {
  content: "\F695";
}

.fa-signature:before {
  content: "\F5B7";
}

.fa-sim-card:before {
  content: "\F7C4";
}

.fa-simplybuilt:before {
  content: "\F215";
}

.fa-sistrix:before {
  content: "\F3EE";
}

.fa-sitemap:before {
  content: "\F0E8";
}

.fa-sith:before {
  content: "\F512";
}

.fa-skating:before {
  content: "\F7C5";
}

.fa-skeleton:before {
  content: "\F620";
}

.fa-sketch:before {
  content: "\F7C6";
}

.fa-ski-jump:before {
  content: "\F7C7";
}

.fa-ski-lift:before {
  content: "\F7C8";
}

.fa-skiing:before {
  content: "\F7C9";
}

.fa-skiing-nordic:before {
  content: "\F7CA";
}

.fa-skull:before {
  content: "\F54C";
}

.fa-skull-crossbones:before {
  content: "\F714";
}

.fa-skyatlas:before {
  content: "\F216";
}

.fa-skype:before {
  content: "\F17E";
}

.fa-slack:before {
  content: "\F198";
}

.fa-slack-hash:before {
  content: "\F3EF";
}

.fa-slash:before {
  content: "\F715";
}

.fa-sledding:before {
  content: "\F7CB";
}

.fa-sleigh:before {
  content: "\F7CC";
}

.fa-sliders-h:before {
  content: "\F1DE";
}

.fa-sliders-h-square:before {
  content: "\F3F0";
}

.fa-sliders-v:before {
  content: "\F3F1";
}

.fa-sliders-v-square:before {
  content: "\F3F2";
}

.fa-slideshare:before {
  content: "\F1E7";
}

.fa-smile:before {
  content: "\F118";
}

.fa-smile-beam:before {
  content: "\F5B8";
}

.fa-smile-plus:before {
  content: "\F5B9";
}

.fa-smile-wink:before {
  content: "\F4DA";
}

.fa-smog:before {
  content: "\F75F";
}

.fa-smoke:before {
  content: "\F760";
}

.fa-smoking:before {
  content: "\F48D";
}

.fa-smoking-ban:before {
  content: "\F54D";
}

.fa-sms:before {
  content: "\F7CD";
}

.fa-snake:before {
  content: "\F716";
}

.fa-snapchat:before {
  content: "\F2AB";
}

.fa-snapchat-ghost:before {
  content: "\F2AC";
}

.fa-snapchat-square:before {
  content: "\F2AD";
}

.fa-snow-blowing:before {
  content: "\F761";
}

.fa-snowboarding:before {
  content: "\F7CE";
}

.fa-snowflake:before {
  content: "\F2DC";
}

.fa-snowflakes:before {
  content: "\F7CF";
}

.fa-snowman:before {
  content: "\F7D0";
}

.fa-snowmobile:before {
  content: "\F7D1";
}

.fa-snowplow:before {
  content: "\F7D2";
}

.fa-socks:before {
  content: "\F696";
}

.fa-solar-panel:before {
  content: "\F5BA";
}

.fa-sort:before {
  content: "\F0DC";
}

.fa-sort-alpha-down:before {
  content: "\F15D";
}

.fa-sort-alpha-up:before {
  content: "\F15E";
}

.fa-sort-amount-down:before {
  content: "\F160";
}

.fa-sort-amount-up:before {
  content: "\F161";
}

.fa-sort-down:before {
  content: "\F0DD";
}

.fa-sort-numeric-down:before {
  content: "\F162";
}

.fa-sort-numeric-up:before {
  content: "\F163";
}

.fa-sort-up:before {
  content: "\F0DE";
}

.fa-soundcloud:before {
  content: "\F1BE";
}

.fa-sourcetree:before {
  content: "\F7D3";
}

.fa-spa:before {
  content: "\F5BB";
}

.fa-space-shuttle:before {
  content: "\F197";
}

.fa-spade:before {
  content: "\F2F4";
}

.fa-speakap:before {
  content: "\F3F3";
}

.fa-spider:before {
  content: "\F717";
}

.fa-spider-black-widow:before {
  content: "\F718";
}

.fa-spider-web:before {
  content: "\F719";
}

.fa-spinner:before {
  content: "\F110";
}

.fa-spinner-third:before {
  content: "\F3F4";
}

.fa-splotch:before {
  content: "\F5BC";
}

.fa-spotify:before {
  content: "\F1BC";
}

.fa-spray-can:before {
  content: "\F5BD";
}

.fa-square:before {
  content: "\F0C8";
}

.fa-square-full:before {
  content: "\F45C";
}

.fa-square-root:before {
  content: "\F697";
}

.fa-square-root-alt:before {
  content: "\F698";
}

.fa-squarespace:before {
  content: "\F5BE";
}

.fa-squirrel:before {
  content: "\F71A";
}

.fa-stack-exchange:before {
  content: "\F18D";
}

.fa-stack-overflow:before {
  content: "\F16C";
}

.fa-staff:before {
  content: "\F71B";
}

.fa-stamp:before {
  content: "\F5BF";
}

.fa-star:before {
  content: "\F005";
}

.fa-star-and-crescent:before {
  content: "\F699";
}

.fa-star-christmas:before {
  content: "\F7D4";
}

.fa-star-exclamation:before {
  content: "\F2F3";
}

.fa-star-half:before {
  content: "\F089";
}

.fa-star-half-alt:before {
  content: "\F5C0";
}

.fa-star-of-david:before {
  content: "\F69A";
}

.fa-star-of-life:before {
  content: "\F621";
}

.fa-stars:before {
  content: "\F762";
}

.fa-staylinked:before {
  content: "\F3F5";
}

.fa-steam:before {
  content: "\F1B6";
}

.fa-steam-square:before {
  content: "\F1B7";
}

.fa-steam-symbol:before {
  content: "\F3F6";
}

.fa-steering-wheel:before {
  content: "\F622";
}

.fa-step-backward:before {
  content: "\F048";
}

.fa-step-forward:before {
  content: "\F051";
}

.fa-stethoscope:before {
  content: "\F0F1";
}

.fa-sticker-mule:before {
  content: "\F3F7";
}

.fa-sticky-note:before {
  content: "\F249";
}

.fa-stocking:before {
  content: "\F7D5";
}

.fa-stomach:before {
  content: "\F623";
}

.fa-stop:before {
  content: "\F04D";
}

.fa-stop-circle:before {
  content: "\F28D";
}

.fa-stopwatch:before {
  content: "\F2F2";
}

.fa-store:before {
  content: "\F54E";
}

.fa-store-alt:before {
  content: "\F54F";
}

.fa-strava:before {
  content: "\F428";
}

.fa-stream:before {
  content: "\F550";
}

.fa-street-view:before {
  content: "\F21D";
}

.fa-strikethrough:before {
  content: "\F0CC";
}

.fa-stripe:before {
  content: "\F429";
}

.fa-stripe-s:before {
  content: "\F42A";
}

.fa-stroopwafel:before {
  content: "\F551";
}

.fa-studiovinari:before {
  content: "\F3F8";
}

.fa-stumbleupon:before {
  content: "\F1A4";
}

.fa-stumbleupon-circle:before {
  content: "\F1A3";
}

.fa-subscript:before {
  content: "\F12C";
}

.fa-subway:before {
  content: "\F239";
}

.fa-suitcase:before {
  content: "\F0F2";
}

.fa-suitcase-rolling:before {
  content: "\F5C1";
}

.fa-sun:before {
  content: "\F185";
}

.fa-sun-cloud:before {
  content: "\F763";
}

.fa-sun-dust:before {
  content: "\F764";
}

.fa-sun-haze:before {
  content: "\F765";
}

.fa-sunrise:before {
  content: "\F766";
}

.fa-sunset:before {
  content: "\F767";
}

.fa-superpowers:before {
  content: "\F2DD";
}

.fa-superscript:before {
  content: "\F12B";
}

.fa-supple:before {
  content: "\F3F9";
}

.fa-surprise:before {
  content: "\F5C2";
}

.fa-suse:before {
  content: "\F7D6";
}

.fa-swatchbook:before {
  content: "\F5C3";
}

.fa-swimmer:before {
  content: "\F5C4";
}

.fa-swimming-pool:before {
  content: "\F5C5";
}

.fa-sword:before {
  content: "\F71C";
}

.fa-swords:before {
  content: "\F71D";
}

.fa-synagogue:before {
  content: "\F69B";
}

.fa-sync:before {
  content: "\F021";
}

.fa-sync-alt:before {
  content: "\F2F1";
}

.fa-syringe:before {
  content: "\F48E";
}

.fa-table:before {
  content: "\F0CE";
}

.fa-table-tennis:before {
  content: "\F45D";
}

.fa-tablet:before {
  content: "\F10A";
}

.fa-tablet-alt:before {
  content: "\F3FA";
}

.fa-tablet-android:before {
  content: "\F3FB";
}

.fa-tablet-android-alt:before {
  content: "\F3FC";
}

.fa-tablet-rugged:before {
  content: "\F48F";
}

.fa-tablets:before {
  content: "\F490";
}

.fa-tachometer:before {
  content: "\F0E4";
}

.fa-tachometer-alt:before {
  content: "\F3FD";
}

.fa-tachometer-alt-average:before {
  content: "\F624";
}

.fa-tachometer-alt-fast:before {
  content: "\F625";
}

.fa-tachometer-alt-fastest:before {
  content: "\F626";
}

.fa-tachometer-alt-slow:before {
  content: "\F627";
}

.fa-tachometer-alt-slowest:before {
  content: "\F628";
}

.fa-tachometer-average:before {
  content: "\F629";
}

.fa-tachometer-fast:before {
  content: "\F62A";
}

.fa-tachometer-fastest:before {
  content: "\F62B";
}

.fa-tachometer-slow:before {
  content: "\F62C";
}

.fa-tachometer-slowest:before {
  content: "\F62D";
}

.fa-tag:before {
  content: "\F02B";
}

.fa-tags:before {
  content: "\F02C";
}

.fa-tally:before {
  content: "\F69C";
}

.fa-tape:before {
  content: "\F4DB";
}

.fa-tasks:before {
  content: "\F0AE";
}

.fa-taxi:before {
  content: "\F1BA";
}

.fa-teamspeak:before {
  content: "\F4F9";
}

.fa-teeth:before {
  content: "\F62E";
}

.fa-teeth-open:before {
  content: "\F62F";
}

.fa-telegram:before {
  content: "\F2C6";
}

.fa-telegram-plane:before {
  content: "\F3FE";
}

.fa-temperature-frigid:before {
  content: "\F768";
}

.fa-temperature-high:before {
  content: "\F769";
}

.fa-temperature-hot:before {
  content: "\F76A";
}

.fa-temperature-low:before {
  content: "\F76B";
}

.fa-tencent-weibo:before {
  content: "\F1D5";
}

.fa-tenge:before {
  content: "\F7D7";
}

.fa-tennis-ball:before {
  content: "\F45E";
}

.fa-terminal:before {
  content: "\F120";
}

.fa-text-height:before {
  content: "\F034";
}

.fa-text-width:before {
  content: "\F035";
}

.fa-th:before {
  content: "\F00A";
}

.fa-th-large:before {
  content: "\F009";
}

.fa-th-list:before {
  content: "\F00B";
}

.fa-the-red-yeti:before {
  content: "\F69D";
}

.fa-theater-masks:before {
  content: "\F630";
}

.fa-themeco:before {
  content: "\F5C6";
}

.fa-themeisle:before {
  content: "\F2B2";
}

.fa-thermometer:before {
  content: "\F491";
}

.fa-thermometer-empty:before {
  content: "\F2CB";
}

.fa-thermometer-full:before {
  content: "\F2C7";
}

.fa-thermometer-half:before {
  content: "\F2C9";
}

.fa-thermometer-quarter:before {
  content: "\F2CA";
}

.fa-thermometer-three-quarters:before {
  content: "\F2C8";
}

.fa-theta:before {
  content: "\F69E";
}

.fa-think-peaks:before {
  content: "\F731";
}

.fa-thumbs-down:before {
  content: "\F165";
}

.fa-thumbs-up:before {
  content: "\F164";
}

.fa-thumbtack:before {
  content: "\F08D";
}

.fa-thunderstorm:before {
  content: "\F76C";
}

.fa-thunderstorm-moon:before {
  content: "\F76D";
}

.fa-thunderstorm-sun:before {
  content: "\F76E";
}

.fa-ticket:before {
  content: "\F145";
}

.fa-ticket-alt:before {
  content: "\F3FF";
}

.fa-tilde:before {
  content: "\F69F";
}

.fa-times:before {
  content: "\F00D";
}

.fa-times-circle:before {
  content: "\F057";
}

.fa-times-hexagon:before {
  content: "\F2EE";
}

.fa-times-octagon:before {
  content: "\F2F0";
}

.fa-times-square:before {
  content: "\F2D3";
}

.fa-tint:before {
  content: "\F043";
}

.fa-tint-slash:before {
  content: "\F5C7";
}

.fa-tire:before {
  content: "\F631";
}

.fa-tire-flat:before {
  content: "\F632";
}

.fa-tire-pressure-warning:before {
  content: "\F633";
}

.fa-tire-rugged:before {
  content: "\F634";
}

.fa-tired:before {
  content: "\F5C8";
}

.fa-toggle-off:before {
  content: "\F204";
}

.fa-toggle-on:before {
  content: "\F205";
}

.fa-toilet:before {
  content: "\F7D8";
}

.fa-toilet-paper:before {
  content: "\F71E";
}

.fa-toilet-paper-alt:before {
  content: "\F71F";
}

.fa-tombstone:before {
  content: "\F720";
}

.fa-tombstone-alt:before {
  content: "\F721";
}

.fa-toolbox:before {
  content: "\F552";
}

.fa-tools:before {
  content: "\F7D9";
}

.fa-tooth:before {
  content: "\F5C9";
}

.fa-toothbrush:before {
  content: "\F635";
}

.fa-torah:before {
  content: "\F6A0";
}

.fa-torii-gate:before {
  content: "\F6A1";
}

.fa-tornado:before {
  content: "\F76F";
}

.fa-tractor:before {
  content: "\F722";
}

.fa-trade-federation:before {
  content: "\F513";
}

.fa-trademark:before {
  content: "\F25C";
}

.fa-traffic-cone:before {
  content: "\F636";
}

.fa-traffic-light:before {
  content: "\F637";
}

.fa-traffic-light-go:before {
  content: "\F638";
}

.fa-traffic-light-slow:before {
  content: "\F639";
}

.fa-traffic-light-stop:before {
  content: "\F63A";
}

.fa-train:before {
  content: "\F238";
}

.fa-tram:before {
  content: "\F7DA";
}

.fa-transgender:before {
  content: "\F224";
}

.fa-transgender-alt:before {
  content: "\F225";
}

.fa-trash:before {
  content: "\F1F8";
}

.fa-trash-alt:before {
  content: "\F2ED";
}

.fa-treasure-chest:before {
  content: "\F723";
}

.fa-tree:before {
  content: "\F1BB";
}

.fa-tree-alt:before {
  content: "\F400";
}

.fa-tree-christmas:before {
  content: "\F7DB";
}

.fa-tree-decorated:before {
  content: "\F7DC";
}

.fa-tree-large:before {
  content: "\F7DD";
}

.fa-trees:before {
  content: "\F724";
}

.fa-trello:before {
  content: "\F181";
}

.fa-triangle:before {
  content: "\F2EC";
}

.fa-tripadvisor:before {
  content: "\F262";
}

.fa-trophy:before {
  content: "\F091";
}

.fa-trophy-alt:before {
  content: "\F2EB";
}

.fa-truck:before {
  content: "\F0D1";
}

.fa-truck-container:before {
  content: "\F4DC";
}

.fa-truck-couch:before {
  content: "\F4DD";
}

.fa-truck-loading:before {
  content: "\F4DE";
}

.fa-truck-monster:before {
  content: "\F63B";
}

.fa-truck-moving:before {
  content: "\F4DF";
}

.fa-truck-pickup:before {
  content: "\F63C";
}

.fa-truck-plow:before {
  content: "\F7DE";
}

.fa-truck-ramp:before {
  content: "\F4E0";
}

.fa-tshirt:before {
  content: "\F553";
}

.fa-tty:before {
  content: "\F1E4";
}

.fa-tumblr:before {
  content: "\F173";
}

.fa-tumblr-square:before {
  content: "\F174";
}

.fa-turkey:before {
  content: "\F725";
}

.fa-turtle:before {
  content: "\F726";
}

.fa-tv:before {
  content: "\F26C";
}

.fa-tv-retro:before {
  content: "\F401";
}

.fa-twitch:before {
  content: "\F1E8";
}

.fa-twitter:before {
  content: "\F099";
}

.fa-twitter-square:before {
  content: "\F081";
}

.fa-typo3:before {
  content: "\F42B";
}

.fa-uber:before {
  content: "\F402";
}

.fa-roboto:before {
  content: "\F7DF";
}

.fa-uikit:before {
  content: "\F403";
}

.fa-umbrella:before {
  content: "\F0E9";
}

.fa-umbrella-beach:before {
  content: "\F5CA";
}

.fa-underline:before {
  content: "\F0CD";
}

.fa-undo:before {
  content: "\F0E2";
}

.fa-undo-alt:before {
  content: "\F2EA";
}

.fa-unicorn:before {
  content: "\F727";
}

.fa-union:before {
  content: "\F6A2";
}

.fa-uniregistry:before {
  content: "\F404";
}

.fa-universal-access:before {
  content: "\F29A";
}

.fa-university:before {
  content: "\F19C";
}

.fa-unlink:before {
  content: "\F127";
}

.fa-unlock:before {
  content: "\F09C";
}

.fa-unlock-alt:before {
  content: "\F13E";
}

.fa-untappd:before {
  content: "\F405";
}

.fa-upload:before {
  content: "\F093";
}

.fa-ups:before {
  content: "\F7E0";
}

.fa-usb:before {
  content: "\F287";
}

.fa-usd-circle:before {
  content: "\F2E8";
}

.fa-usd-square:before {
  content: "\F2E9";
}

.fa-user:before {
  content: "\F007";
}

.fa-user-alt:before {
  content: "\F406";
}

.fa-user-alt-slash:before {
  content: "\F4FA";
}

.fa-user-astronaut:before {
  content: "\F4FB";
}

.fa-user-chart:before {
  content: "\F6A3";
}

.fa-user-check:before {
  content: "\F4FC";
}

.fa-user-circle:before {
  content: "\F2BD";
}

.fa-user-clock:before {
  content: "\F4FD";
}

.fa-user-cog:before {
  content: "\F4FE";
}

.fa-user-crown:before {
  content: "\F6A4";
}

.fa-user-edit:before {
  content: "\F4FF";
}

.fa-user-friends:before {
  content: "\F500";
}

.fa-user-graduate:before {
  content: "\F501";
}

.fa-user-injured:before {
  content: "\F728";
}

.fa-user-lock:before {
  content: "\F502";
}

.fa-user-md:before {
  content: "\F0F0";
}

.fa-user-minus:before {
  content: "\F503";
}

.fa-user-ninja:before {
  content: "\F504";
}

.fa-user-plus:before {
  content: "\F234";
}

.fa-user-secret:before {
  content: "\F21B";
}

.fa-user-shield:before {
  content: "\F505";
}

.fa-user-slash:before {
  content: "\F506";
}

.fa-user-tag:before {
  content: "\F507";
}

.fa-user-tie:before {
  content: "\F508";
}

.fa-user-times:before {
  content: "\F235";
}

.fa-users:before {
  content: "\F0C0";
}

.fa-users-class:before {
  content: "\F63D";
}

.fa-users-cog:before {
  content: "\F509";
}

.fa-users-crown:before {
  content: "\F6A5";
}

.fa-usps:before {
  content: "\F7E1";
}

.fa-ussunnah:before {
  content: "\F407";
}

.fa-utensil-fork:before {
  content: "\F2E3";
}

.fa-utensil-knife:before {
  content: "\F2E4";
}

.fa-utensil-spoon:before {
  content: "\F2E5";
}

.fa-utensils:before {
  content: "\F2E7";
}

.fa-utensils-alt:before {
  content: "\F2E6";
}

.fa-vaadin:before {
  content: "\F408";
}

.fa-value-absolute:before {
  content: "\F6A6";
}

.fa-vector-square:before {
  content: "\F5CB";
}

.fa-venus:before {
  content: "\F221";
}

.fa-venus-double:before {
  content: "\F226";
}

.fa-venus-mars:before {
  content: "\F228";
}

.fa-viacoin:before {
  content: "\F237";
}

.fa-viadeo:before {
  content: "\F2A9";
}

.fa-viadeo-square:before {
  content: "\F2AA";
}

.fa-vial:before {
  content: "\F492";
}

.fa-vials:before {
  content: "\F493";
}

.fa-viber:before {
  content: "\F409";
}

.fa-video:before {
  content: "\F03D";
}

.fa-video-plus:before {
  content: "\F4E1";
}

.fa-video-slash:before {
  content: "\F4E2";
}

.fa-vihara:before {
  content: "\F6A7";
}

.fa-vimeo:before {
  content: "\F40A";
}

.fa-vimeo-square:before {
  content: "\F194";
}

.fa-vimeo-v:before {
  content: "\F27D";
}

.fa-vine:before {
  content: "\F1CA";
}

.fa-vk:before {
  content: "\F189";
}

.fa-vnv:before {
  content: "\F40B";
}

.fa-volcano:before {
  content: "\F770";
}

.fa-volleyball-ball:before {
  content: "\F45F";
}

.fa-volume:before {
  content: "\F6A8";
}

.fa-volume-down:before {
  content: "\F027";
}

.fa-volume-mute:before {
  content: "\F6A9";
}

.fa-volume-off:before {
  content: "\F026";
}

.fa-volume-slash:before {
  content: "\F2E2";
}

.fa-volume-up:before {
  content: "\F028";
}

.fa-vote-nay:before {
  content: "\F771";
}

.fa-vote-yea:before {
  content: "\F772";
}

.fa-vr-cardboard:before {
  content: "\F729";
}

.fa-vuejs:before {
  content: "\F41F";
}

.fa-walking:before {
  content: "\F554";
}

.fa-wallet:before {
  content: "\F555";
}

.fa-wand:before {
  content: "\F72A";
}

.fa-wand-magic:before {
  content: "\F72B";
}

.fa-warehouse:before {
  content: "\F494";
}

.fa-warehouse-alt:before {
  content: "\F495";
}

.fa-watch:before {
  content: "\F2E1";
}

.fa-watch-fitness:before {
  content: "\F63E";
}

.fa-water:before {
  content: "\F773";
}

.fa-water-lower:before {
  content: "\F774";
}

.fa-water-rise:before {
  content: "\F775";
}

.fa-weebly:before {
  content: "\F5CC";
}

.fa-weibo:before {
  content: "\F18A";
}

.fa-weight:before {
  content: "\F496";
}

.fa-weight-hanging:before {
  content: "\F5CD";
}

.fa-weixin:before {
  content: "\F1D7";
}

.fa-whale:before {
  content: "\F72C";
}

.fa-whatsapp:before {
  content: "\F232";
}

.fa-whatsapp-square:before {
  content: "\F40C";
}

.fa-wheat:before {
  content: "\F72D";
}

.fa-wheelchair:before {
  content: "\F193";
}

.fa-whistle:before {
  content: "\F460";
}

.fa-whmcs:before {
  content: "\F40D";
}

.fa-wifi:before {
  content: "\F1EB";
}

.fa-wifi-1:before {
  content: "\F6AA";
}

.fa-wifi-2:before {
  content: "\F6AB";
}

.fa-wifi-slash:before {
  content: "\F6AC";
}

.fa-wikipedia-w:before {
  content: "\F266";
}

.fa-wind:before {
  content: "\F72E";
}

.fa-wind-warning:before {
  content: "\F776";
}

.fa-window:before {
  content: "\F40E";
}

.fa-window-alt:before {
  content: "\F40F";
}

.fa-window-close:before {
  content: "\F410";
}

.fa-window-maximize:before {
  content: "\F2D0";
}

.fa-window-minimize:before {
  content: "\F2D1";
}

.fa-window-restore:before {
  content: "\F2D2";
}

.fa-windows:before {
  content: "\F17A";
}

.fa-windsock:before {
  content: "\F777";
}

.fa-wine-bottle:before {
  content: "\F72F";
}

.fa-wine-glass:before {
  content: "\F4E3";
}

.fa-wine-glass-alt:before {
  content: "\F5CE";
}

.fa-wix:before {
  content: "\F5CF";
}

.fa-wizards-of-the-coast:before {
  content: "\F730";
}

.fa-wolf-pack-battalion:before {
  content: "\F514";
}

.fa-won-sign:before {
  content: "\F159";
}

.fa-wordpress:before {
  content: "\F19A";
}

.fa-wordpress-simple:before {
  content: "\F411";
}

.fa-wpbeginner:before {
  content: "\F297";
}

.fa-wpexplorer:before {
  content: "\F2DE";
}

.fa-wpforms:before {
  content: "\F298";
}

.fa-wpressr:before {
  content: "\F3E4";
}

.fa-wreath:before {
  content: "\F7E2";
}

.fa-wrench:before {
  content: "\F0AD";
}

.fa-x-ray:before {
  content: "\F497";
}

.fa-xbox:before {
  content: "\F412";
}

.fa-xing:before {
  content: "\F168";
}

.fa-xing-square:before {
  content: "\F169";
}

.fa-y-combinator:before {
  content: "\F23B";
}

.fa-yahoo:before {
  content: "\F19E";
}

.fa-yandex:before {
  content: "\F413";
}

.fa-yandex-international:before {
  content: "\F414";
}

.fa-yarn:before {
  content: "\F7E3";
}

.fa-yelp:before {
  content: "\F1E9";
}

.fa-yen-sign:before {
  content: "\F157";
}

.fa-yin-yang:before {
  content: "\F6AD";
}

.fa-yoast:before {
  content: "\F2B1";
}

.fa-youtube:before {
  content: "\F167";
}

.fa-youtube-square:before {
  content: "\F431";
}

.fa-zhihu:before {
  content: "\F63F";
}

.sr-only {
  border: 0;
  clip: rect(0, 0, 0, 0);
  height: 1px;
  margin: -1px;
  overflow: hidden;
  padding: 0;
  position: absolute;
  width: 1px;
}

.sr-only-focusable:active, .sr-only-focusable:focus {
  clip: auto;
  height: auto;
  margin: 0;
  overflow: visible;
  position: static;
  width: auto;
}

/*!
 * Font Awesome Pro 5.6.1 by @fontawesome - https://fontawesome.com
 * License - https://fontawesome.com/license (Commercial License)
 */
@font-face {
  font-family: "Font Awesome 5 Pro";
  font-style: normal;
  font-weight: 400;
  src: url(/static/media/fa-regular-400.1ec1fe25.eot);
  src: url(/static/media/fa-regular-400.1ec1fe25.eot?#iefix) format("embedded-opentype"), url(/static/media/fa-regular-400.5987f69f.woff2) format("woff2"), url(/static/media/fa-regular-400.3321ebbc.woff) format("woff"), url(/static/media/fa-regular-400.f382815d.ttf) format("truetype"), url(/static/media/fa-regular-400.dcf7596f.svg#fontawesome) format("svg");
}
.far {
  font-family: "Font Awesome 5 Pro";
  font-weight: 400;
}

/*!
 * Font Awesome Pro 5.6.1 by @fontawesome - https://fontawesome.com
 * License - https://fontawesome.com/license (Commercial License)
 */
@font-face {
  font-family: "Font Awesome 5 Brands";
  font-style: normal;
  font-weight: normal;
  src: url(/static/media/fa-brands-400.a96ab80f.eot);
  src: url(/static/media/fa-brands-400.a96ab80f.eot?#iefix) format("embedded-opentype"), url(/static/media/fa-brands-400.80109f5b.woff2) format("woff2"), url(/static/media/fa-brands-400.aa4d156f.woff) format("woff"), url(/static/media/fa-brands-400.31cf7166.ttf) format("truetype"), url(/static/media/fa-brands-400.e64b5c75.svg#fontawesome) format("svg");
}
.fab {
  font-family: "Font Awesome 5 Brands";
}

/*!
 * Font Awesome Pro 5.6.1 by @fontawesome - https://fontawesome.com
 * License - https://fontawesome.com/license (Commercial License)
 */
@font-face {
  font-family: "Font Awesome 5 Pro";
  font-style: normal;
  font-weight: 300;
  src: url(/static/media/fa-light-300.ac08735e.eot);
  src: url(/static/media/fa-light-300.ac08735e.eot?#iefix) format("embedded-opentype"), url(/static/media/fa-light-300.4044d63c.woff2) format("woff2"), url(/static/media/fa-light-300.923ab66a.woff) format("woff"), url(/static/media/fa-light-300.04c143a0.ttf) format("truetype"), url(/static/media/fa-light-300.c49eb736.svg#fontawesome) format("svg");
}
.fal {
  font-family: "Font Awesome 5 Pro";
  font-weight: 300;
}

/*!
 * Font Awesome Pro 5.6.1 by @fontawesome - https://fontawesome.com
 * License - https://fontawesome.com/license (Commercial License)
 */
@font-face {
  font-family: "Font Awesome 5 Pro";
  font-style: normal;
  font-weight: 900;
  src: url(/static/media/fa-solid-900.c38e404e.eot);
  src: url(/static/media/fa-solid-900.c38e404e.eot?#iefix) format("embedded-opentype"), url(/static/media/fa-solid-900.9d73d3a8.woff2) format("woff2"), url(/static/media/fa-solid-900.4647e3c7.woff) format("woff"), url(/static/media/fa-solid-900.4afb8330.ttf) format("truetype"), url(/static/media/fa-solid-900.8f04a286.svg#fontawesome) format("svg");
}
.fa,
.fas {
  font-family: "Font Awesome 5 Pro";
  font-weight: 900;
}

.basic-layout {
  display: flex;
  flex-direction: column;
  overflow: hidden;
  height: 100vh;
}

.basic-layout-content {
  display: flex;
  flex: 1 1;
  height: calc(100vh - 40px);
}

.page {
  position: relative;
}

.layout-header,
.sidebar {
  transition: left 400ms cubic-bezier(0.25, 0.46, 0.45, 0.94) 0ms, right 400ms cubic-bezier(0.25, 0.46, 0.45, 0.94) 0ms;
}

.layout-header-space {
  height: 55px;
  position: relative;
}

.layout-header {
  height: 55px;
  left: 48px;
  color: #2a2a2a;
  top: 0;
  right: 0;
  z-index: 5;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 3px 20px;
  position: fixed;
  background: #fff;
  box-sizing: border-box;
}

.layout-header-space.settings .layout-header {
  margin-top: 0;
  padding-left: 0;
  left: 350px;
  max-width: 750px;
  box-sizing: content-box;
  padding-top: 4px;
  padding-bottom: 0px;
}
.layout-header-space.settings .layout-header h3 {
  font-size: 16px;
}

.closed .layout-top {
  left: 64px;
}

.bordered .layout-top {
  border-bottom: 1px solid #ebebeb;
  padding: 3px 50px 3px 30px;
}
.bordered .panel-content:before {
  display: none !important;
}
.bordered .panel-content {
  margin: 100px auto;
}

.layout-main {
  background: #fff;
  position: relative;
  flex: 1 1;
  z-index: 5;
  overflow: auto;
}
.layout-main.email-unverified .resizer {
  max-height: calc(100vh - 80px) !important;
}
.layout-main.email-unverified .page-layout {
  height: calc(100vh - 80px);
}

/* Company info
==============================*/
.layout-header .company-info {
  display: flex;
  align-items: center;
}
.layout-header .info-details {
  display: flex;
  flex-direction: column;
  text-align: right;
}
.layout-header .company-name {
  font-weight: 500;
}
.layout-header .permission-name {
  color: rgba(0, 0, 0, 0.4);
}
.layout-header .permission-name i {
  margin-right: 8px;
  color: rgba(0, 0, 0, 0.3);
  font-size: 12px;
}
.layout-header .company-avatar {
  display: flex;
  align-items: center;
}
.layout-header .company-avatar img {
  border-radius: 50%;
  object-fit: cover;
  width: 40px;
  height: 40px;
  margin-left: 10px;
}

/* Page Info
==============================*/
.page-info {
  display: flex;
  align-items: center;
}
.page-info h3 {
  font-size: 18px;
  font-weight: 400;
}

.page-info span {
  font-weight: 700;
}

.page-info i {
  font-size: 18px;
  margin-top: 1px;
}

.goBack {
  margin-right: 15px;
}
.goBack.active {
  display: none;
}

.goBack.active ~ .page-info i {
  display: inline-block;
}
.goBack.active ~ .page-info span {
  margin-left: 10px;
}

.go-back-arrow {
  margin-right: 5px;
}
.go-back-arrow i {
  padding: 0px 6px;
  color: #222 !important;
}

.page-actions {
  display: flex;
}
.page-actions > button, .page-actions > a, .page-actions > div {
  margin-left: 10px;
}

.email-confirm {
  background-color: #3267bc;
  text-align: center;
  color: #fff;
  font-size: 14px;
  padding: 3px;
}
.email-confirm > button, .email-confirm > a {
  background-color: transparent;
  text-align: center;
  color: #fff;
  font-size: 15px;
  font-weight: bold;
  padding: 7px;
  text-decoration: underline;
}

/* Side Layout
==============================*/
.side-layout {
  display: flex;
}

.side-layout-content {
  flex: 1 1;
}

.panel-filters {
  margin-top: 76px;
  margin-bottom: 25px;
  padding: 0 30px;
}

.panel-content:before {
  content: "";
  height: 1px;
  display: block;
  background-color: rgba(0, 0, 0, 0.1);
  margin: 5px 0 25px;
}

.panel-content {
  margin: 20px auto;
  width: 100%;
  box-sizing: border-box;
  padding: 0 30px;
}

.page-layout {
  display: flex;
  padding-bottom: 30px;
  box-sizing: border-box;
  height: calc(100vh - 40px);
}

.page-inner {
  margin: 25px 30px 25px 50px;
  box-sizing: border-box;
}

.page-layout.dashboard {
  padding-bottom: 0;
  padding: 8px;
  min-height: calc(100vh - 55px);
}
.page-layout.dashboard .page-inner {
  width: auto;
}

.mdc-dialog {
  z-index: 99 !important;
  padding-top: 30px !important;
  align-items: flex-start !important;
}

.mdc-dialog__actions {
  padding: 20px 0px 10px !important;
  justify-content: flex-start !important;
  border-top: none !important;
}
.mdc-dialog__actions button {
  margin-right: 10px !important;
}

.mdc-dialog__content {
  font-family: "sofia", "Roboto", sans-serif !important;
  padding: 0 !important;
  overflow: unset !important;
}

.mdc-dialog__surface {
  overflow-y: visible;
}

.dialog-content {
  padding: 10px 20px 20px !important;
}

.mdc-dialog .mdc-dialog__title {
  color: rgba(0, 0, 0, 0.87);
  font-size: 14px;
  font-weight: 600;
  font-family: "sofia", "Roboto", sans-serif;
  padding-left: 24px;
  text-transform: uppercase;
}

.dialog-inner {
  padding: 16px 30px 22px;
  box-sizing: border-box;
}

.mdc-dialog .mdc-dialog__scrim {
  background-color: rgba(45, 51, 57, 0.6) !important;
}

/* Components Forms */
.checkbox-space {
  display: flex;
  font-size: 14px;
  margin-bottom: 7px;
}
.checkbox-space.disabled {
  opacity: 0.3;
  pointer-events: none;
}

.checkbox-box {
  cursor: pointer;
  width: 18px;
  height: 18px;
  margin-right: 10px;
  float: left;
  position: relative;
  transition: all 0.25s cubic-bezier(0, 0, 0, 1);
}

.checkbox-description {
  line-height: 21px;
  cursor: pointer;
}

.checkbox-group .checkbox-space {
  margin-bottom: 10px;
  margin-left: 10px;
}
.checkbox-group .help-icon {
  margin-left: 3px;
}

.rc-checkbox-input, .rc-checkbox-inner {
  width: 18px;
  height: 18px;
}

.rc-checkbox-inner {
  transition: none;
  margin: 0;
}
.rc-checkbox-inner:after {
  left: 6px !important;
  top: 2px !important;
}

.rc-checkbox-checked .rc-checkbox-inner {
  border-color: #36b37e;
  background-color: #36b37e;
}

.normal-switch {
  display: flex;
  align-items: center;
}
.normal-switch .label-input {
  margin-bottom: 0;
  margin-left: 10px;
}

.react-switch-handle {
  box-shadow: 0 1px 6px 0 rgba(0, 0, 0, 0.25);
}

.switch-field {
  display: flex;
  align-items: center;
}
.switch-field .sf-left {
  flex: 1 1;
  display: flex;
  flex-direction: column;
  line-height: 18px;
}
.switch-field .sf-label {
  font-size: 16px;
}
.switch-field .sf-description {
  color: #9b9c9e;
}

.btn {
  position: relative;
  font-size: 14px;
  line-height: 18px;
  font-weight: 500;
  border: 1px solid;
  border-color: rgba(0, 0, 0, 0.2);
  border-radius: 4px;
  padding: 7px 16px 7px;
  display: inline-flex;
  text-decoration: none;
  cursor: pointer;
  -webkit-user-select: none;
     -moz-user-select: none;
      -ms-user-select: none;
          user-select: none;
  background-color: #fff;
  color: #222;
  align-items: center;
  box-sizing: border-box;
  text-shadow: none;
  transition: all 0.1s cubic-bezier(0, 0, 0, 1), visibility 1ms;
  box-shadow: none !important;
  /* Light Version */
}
.btn.light {
  color: #505f79;
  background: #f4f5f7;
  border: none;
  height: 32px;
  font-weight: 400;
}
.btn.light:hover {
  background: #ebecf0;
  color: #505f79;
  border: none;
}
.btn i {
  font-size: 17px;
  margin-right: 8px;
}
.btn .btn-icon-right {
  margin-left: auto;
  position: absolute;
  right: 10px;
  margin: 0;
  color: #2a2a2a;
}
.btn:hover {
  background-color: #f4f4f4;
  color: rgba(0, 0, 0, 0.9);
}
.btn:active {
  box-shadow: none;
  transform: scale(0.95);
}
.btn.only-icon {
  padding: 5px 12px 5px;
}
.btn.only-icon i {
  margin: 0;
}
.btn.only-icon.danger i {
  color: #e74c3c;
}
.btn.only-icon.simple {
  background: transparent;
  border: none;
}
.btn.only-icon.simple i {
  color: rgba(0, 0, 0, 0.45);
}
.btn.only-icon.simple:hover i {
  color: rgba(0, 0, 0, 0.55);
}
.btn.big {
  padding: 9px 23px !important;
}
.btn .loading-icon {
  display: none;
}
.btn.small {
  padding: 4px 11px;
  font-size: 12px;
  font-weight: 400;
}
.btn.small i {
  font-size: 13px;
}
.btn.listItem {
  width: 100%;
  border: none;
  padding: 7px 10px;
  font-size: 15px;
  font-weight: 400;
}
.btn.listItem i {
  margin-right: 15px;
}
.btn.full {
  width: 100%;
  flex: 1 1;
  justify-content: center;
}

body:not(.user-is-tabbing) button:focus,
body:not(.user-is-tabbing) input:focus,
body:not(.user-is-tabbing) select:focus,
body:not(.user-is-tabbing) textarea:focus {
  outline: none;
}

/* 
* Icon buttons
*/
.btn.only-icon.round {
  border-radius: 50%;
  border: none;
  background-color: transparent;
  width: 25px;
  height: 25px;
  padding: 0;
  display: flex;
  align-items: center;
  justify-content: center;
}
.btn.only-icon.round i {
  color: #798d99;
}
.btn.only-icon.round:hover {
  background-color: rgba(9, 45, 66, 0.13);
}
.btn.only-icon.round:hover i {
  color: #092d42;
}

/* 
* Themed buttons
*/
.btn.primary {
  background-color: #7d92eb;
  box-shadow: rgba(0, 0, 0, 0.15) 0px 1px 3px 0px;
  border: none;
  color: white;
}
.btn.primary:hover, .btn.primary:active {
  background-color: #8aadfe;
}

.btn.danger {
  background-color: #eb5a46;
  color: #fff;
  border: none;
  box-shadow: 0 1px 0 0 #b04632;
}
.btn.danger:hover {
  background-color: #cf513d;
}

.btn.success {
  background-color: #2ecc71;
  color: #fff;
  border: none;
  box-shadow: 0 1px 0 0 #27ae60;
}
.btn.success:hover {
  background-color: #27ae60;
}

.btn.cancel {
  border: 1px solid transparent;
  color: rgb(80, 95, 121) !important;
  font-weight: 400;
  background-color: transparent;
}
.btn.cancel:hover, .btn.cancel:active {
  background: #ebecf0;
  color: #505f79;
}
.btn.cancel:focus {
  box-shadow: 0 0 0 3px rgba(40, 110, 250, 0.2);
}

.btn.gray {
  background-color: #dfe3e6;
  box-shadow: 0 1px 0 0 #c2ccd1;
  border: none;
  height: 32px;
  font-weight: 600;
  padding: 8px 17px;
}
.btn.gray:hover {
  background-color: #c2ccd1;
  box-shadow: 0 1px 0 0 #b3bec4;
  border: none;
}
.btn.gray:active {
  background-color: #17394d;
  box-shadow: 0 1px 0 0 #092d42;
  border: none;
  color: #fff;
}
.btn.gray i {
  color: #798d99;
  font-size: 15px;
  margin-right: 10px;
  margin-left: -5px;
  margin-top: -2px;
}

.btn.ios {
  background-color: transparent;
  box-shadow: none;
  border-radius: 20px;
}
.btn.ios:hover {
  background-color: #f6f6f6;
  box-shadow: 1px 1px 1px 1px #e1e1e1;
  border-color: transparent;
}
.btn.ios:active {
  background-color: #f6f6f6;
  box-shadow: 0 1px 0 0 #d7d7d7;
  border: none;
  color: #fff;
}

.btn.action {
  font-weight: 400;
  background-color: #f2f2f2;
  border-color: #f2f2f2;
  color: #6b7075 !important;
}
.btn.action i {
  color: #6b7075;
}
.btn.action:hover, .btn.action:active {
  background-color: #d3d8e0;
  border-color: #d3d8e0;
  color: #1f2b36;
}
.btn.action.only-icon {
  padding: 8px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.btn.action.only-icon i {
  font-size: 17px;
}

.btn-text {
  display: flex;
  align-items: center;
}

.btn.loading {
  pointer-events: none;
  background-color: rgba(0, 0, 0, 0.1);
  box-shadow: 0 1px 0 0 rgba(0, 0, 0, 0.2);
}
.btn.loading .loading-icon {
  position: absolute;
  left: 0;
  right: 0;
  display: block;
  -webkit-animation: rotation 0.7s infinite linear;
          animation: rotation 0.7s infinite linear;
  width: 20px;
  margin: 0 auto;
  font-size: 15px;
}
.btn.loading .btn-text {
  visibility: hidden;
}

.dropzone {
  width: 100%;
  min-height: 183px;
  border: 2px dashed rgba(0, 0, 0, 0.1);
  border-radius: 8px;
  display: flex;
  align-items: center;
  justify-content: center;
  outline: none;
  padding: 12px;
  box-sizing: border-box;
  margin-bottom: 10px;
}
.dropzone .drag-drop {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
.dropzone .drag-drop i {
  font-size: 38px;
  margin: 16px;
  line-height: 20px;
  color: rgba(0, 0, 0, 0.3);
}
.dropzone .drag-drop h1 {
  font-size: 18px;
  margin: 10px 0 0 0;
}
.dropzone .drag-drop span {
  color: rgba(0, 0, 0, 0.6);
  font-size: 14px;
}
.dropzone .drop-rows {
  width: 100%;
}
.dropzone .drop-row {
  display: flex;
  align-items: center;
  width: 100%;
  border: 1px solid rgba(0, 0, 0, 0.1);
  box-sizing: border-box;
  padding: 12px 15px;
  border-radius: 6px;
  margin-bottom: 10px;
}
.dropzone .drop-row > i {
  font-size: 27px;
  color: rgba(0, 0, 0, 0.3);
}
.dropzone .drop-row .details {
  display: flex;
  flex-direction: column;
  flex: 1 1;
  margin-left: 15px;
}
.dropzone .drop-row .details > span:first-child {
  font-size: 14px;
  font-weight: 600;
}
.dropzone .drop-row .details > span:last-child {
  color: rgba(0, 0, 0, 0.6);
  font-size: 13px;
}

/* Components */
.page-table {
  height: 100%;
  min-height: 100%;
  display: flex;
  flex-direction: column;
  box-sizing: border-box;
}

.page-table-content {
  flex: 1 1;
  height: 100%;
  box-sizing: border-box;
  display: flex;
  max-height: calc(100vh - 80px);
}
.page-table-content > div, .page-table-content > section {
  padding-bottom: 100px;
}

.page-table-title {
  max-width: 700px;
  padding: 23px 32px;
}
.page-table-title h1 {
  font-size: 28px;
  font-weight: 300;
  margin: 0;
}
.page-table-title p {
  margin: 15px 0 0;
  font-size: 16px;
  color: #9b9b9b;
}

.page-table-table {
  padding: 0 32px;
}

.page-table-content-left {
  flex: 1 1;
}

.page-table.openSidePanel .page-table-content-left {
  overflow-y: scroll;
}

.sidenav-space {
  width: 48px;
  height: 100vh;
  background: #30374d;
}

.sidenav {
  color: #ffffff;
  top: 0;
  left: 0;
  height: 100%;
  z-index: 11;
  overflow: visible;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  background: #30374d;
  position: relative;
}
.sidenav i {
  font-size: 24px;
  color: rgb(222, 235, 255);
}
.sidenav .bottom {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin-bottom: 15px;
}

.nav-logo-space,
.nav-user-space {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 55px;
}
.nav-logo-space a,
.nav-user-space a {
  border-radius: 50%;
  padding: 6px;
  text-align: center;
}
.nav-logo-space img,
.nav-user-space img {
  width: 30px;
  height: 30px;
  object-fit: cover;
  -webkit-backface-visibility: hidden;
          backface-visibility: hidden;
}
.nav-logo-space .div-img,
.nav-user-space .div-img {
  height: 32px;
  width: 32px;
  border-radius: 50%;
  box-sizing: border-box;
  background-image: url(/static/media/agent.8e2d2102.svg);
  background-size: contain;
}
.nav-user-space {
  width: 34px;
  height: 34px;
  border-radius: 50%;
  transition: height 85ms;
  position: relative;
  z-index: 999;
}
.nav-user-space img {
  width: 28px !important;
  height: 28px !important;
}
.nav-user-space:hover {
  background-color: rgba(9, 30, 66, 0.42);
}

.nav-logo-space {
  margin-bottom: 20px;
  margin-top: 7px;
}
.nav-logo-space a {
  transition: all 0.25s cubic-bezier(0, 0, 0, 1);
}
.nav-logo-space:active a {
  transform: scale(0.9);
}
.nav-logo-space path:first-child {
  fill: #0fccce;
}

.nav-user-space img {
  left: 0;
  height: 32px;
  width: 32px;
  border-radius: 50%;
  box-sizing: border-box;
  object-fit: cover;
}

.sidenav-item {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 4px;
}
.sidenav-item a {
  display: flex;
  justify-content: center;
  align-items: center;
  color: white;
  border-radius: 50%;
  padding: 10px;
  width: 40px;
  height: 40px;
  box-sizing: border-box;
}
.sidenav-item a svg {
  transition: fill 200ms cubic-bezier(0.25, 0.46, 0.45, 0.94) 0ms, border-color 400ms cubic-bezier(0.25, 0.46, 0.45, 0.94) 0ms, color 400ms cubic-bezier(0.25, 0.46, 0.45, 0.94) 0ms, box-shadow 400ms cubic-bezier(0.25, 0.46, 0.45, 0.94) 0ms;
}
.sidenav-item a i {
  transition: all 0.25s cubic-bezier(0, 0, 0, 1);
  font-size: 20px;
}
.sidenav-item a:hover {
  background-color: rgba(9, 30, 66, 0.42);
}
.sidenav-item a:hover svg {
  fill: #00d6d0;
}
.sidenav-item a:hover i {
  opacity: 1;
  color: white;
}
.sidenav-item a.active {
  background-color: rgba(9, 30, 66, 0.42);
}
.sidenav-item a.active i {
  opacity: 1;
  color: white;
  width: 24px;
  height: 24px;
  text-align: center;
  display: flex;
  align-items: center;
}
.sidenav-item a:active i {
  transform: scale(0.8);
}
.sidenav-item path {
  cursor: pointer;
}
.sidenav-item a:hover .purple,
.sidenav-item .fa.purple {
  color: #6c5ce7 !important;
}

.bottom .sidenav-item {
  margin-bottom: 8px;
}
.bottom .sidenav-item a {
  width: 32px;
  height: 32px;
}
.bottom .sidenav-item a i {
  font-size: 18px;
}

.nav-tooltip {
  opacity: 0.1 !important;
  transition: opacity 0.3s ease-in-out, margin-left 0.3s ease-in-out, visibility 0.3s ease-in-out;
  margin-left: 20px !important;
  box-shadow: 0 4px 14px 0 rgba(0, 0, 0, 0.2), 0 0 0 1px rgba(0, 0, 0, 0.05) !important;
  background-color: white !important;
  color: #222 !important;
  font-size: 14px;
  font-weight: 500;
  padding: 6px 15px;
}
.nav-tooltip:after {
  border-right-color: #fff !important;
  border-top: 8px solid transparent !important;
  border-bottom: 8px solid transparent !important;
  left: -6px !important;
  margin-top: -8px !important;
}
.nav-tooltip:before {
  border-right: 7px solid rgba(0, 0, 0, 0.09) !important;
  border-top: 9px solid transparent !important;
  border-bottom: 9px solid transparent !important;
  left: -7px !important;
  margin-top: -9px !important;
}
.nav-tooltip.show {
  margin-left: 10px !important;
  opacity: 1 !important;
}

.conve-filter-dropdown.conversations {
  display: flex;
  justify-content: space-between;
  padding: 0 10px;
  max-width: 355px;
}

.conve-filter-trigger {
  max-width: 240px;
  cursor: pointer;
  font-weight: 500;
  font-size: 14px;
  border-radius: 3px;
  padding: 10px;
  transition: 0.3s;
  height: 34px;
  overflow: hidden;
  display: flex;
  align-items: center;
  box-sizing: border-box;
  margin: 5px 10px 5px 0;
}
.conve-filter-trigger:hover {
  background-color: rgba(0, 0, 0, 0.05);
  transition: 0.3s;
}
.conve-filter-trigger:hover .badge-count {
  background-color: #e1e4e9;
  transition: 0.1s;
}
.conve-filter-trigger .badge-count {
  margin-left: 10px;
}
.conve-filter-trigger i {
  transition: all 0.2s ease-in 0s;
  width: 20px;
  text-align: center;
  font-size: 19px;
  line-height: 1;
  color: #8d8c8c;
}

.conve-filter-dropdown .menu-dropdown .menu {
  left: -2px;
  width: 401px !important;
  top: 49px;
}

.conve-filter-dropdown.small .conve-filter-trigger .name {
  margin-right: 5px;
}
.conve-filter-dropdown.small .menu-dropdown .menu {
  left: -2px;
  width: 360px !important;
  top: 49px;
}

.conve-filter-dropdown-inner {
  width: 100%;
  padding-top: 10px;
}

.cf-dropdown-header {
  border-bottom: solid 1px #e0e4ea;
  padding: 15px 15px 0;
}

.cf-dropdown-list {
  display: flex;
  flex-direction: column;
}
.cf-dropdown-list i {
  margin-right: 15px;
  font-size: 20px;
  width: 25px;
  text-align: center;
}

.my-segment-separator {
  text-transform: uppercase;
  font-size: 11px;
  font-weight: 700;
  color: #90a4ae;
  display: flex;
  align-items: center;
  padding-bottom: 10px;
  margin: 20px 35px 0px;
}
.my-segment-separator i {
  font-size: 14px;
  margin-right: 5px;
}

.dropdown-footer-button {
  padding: 16px 20px;
  border-top: solid 1px #eaeaea;
  text-align: center;
  background-color: #f8f8f8;
  cursor: pointer;
  color: #5d657a;
  border-radius: 0 0 4px 4px;
  transition: 0.3s;
  font-size: 14px;
  width: 100%;
  margin-top: 20px;
}
.dropdown-footer-button span {
  display: inline-flex;
  align-items: center;
  justify-items: center;
  text-align: center;
}
.dropdown-footer-button:hover {
  background-color: #e1e4e9;
  transition: 0.3s;
  color: #2c3b48;
}
.dropdown-footer-button i {
  margin-right: 5px;
}

.menu.show .menu.show {
  left: calc(100% + 10px);
}
.menu.show .menu.show .menu-inner:after, .menu.show .menu.show .menu-inner:before {
  display: none;
}

.Selectable .DayPicker-Day--selected:not(.DayPicker-Day--start):not(.DayPicker-Day--end):not(.DayPicker-Day--outside) {
  background-color: #eff1f3 !important;
  color: #7d92eb;
}

.Selectable .DayPicker-Day {
  border-radius: 0 !important;
}

.Selectable .DayPicker-Day--start {
  border-top-left-radius: 50% !important;
  border-bottom-left-radius: 50% !important;
}

.Selectable .DayPicker-Day--end {
  border-top-right-radius: 50% !important;
  border-bottom-right-radius: 50% !important;
}

.DayPicker-wrapper {
  outline: none;
}

.DayPicker-Day--selected:not(.DayPicker-Day--disabled):not(.DayPicker-Day--outside) {
  background-color: #7d92eb !important;
}

.DayPicker:not(.DayPicker--interactionDisabled) .DayPicker-Day:not(.DayPicker-Day--disabled):not(.DayPicker-Day--selected):not(.DayPicker-Day--outside):hover {
  background-color: #eff1f3 !important;
}

.range-description {
  display: flex;
  align-items: center;
}
.range-description i {
  font-size: 18px;
  margin-left: 2px;
}

.data-strong {
  font-weight: 500;
}

.RangeExample {
  display: flex;
  flex-direction: column;
}

.DayPicker.Selectable {
  border-top: 1px solid rgba(0, 0, 0, 0.1);
  border-bottom: 1px solid rgba(0, 0, 0, 0.1);
  margin: 13px 0;
  padding: 2px 0;
}

.filter-tag {
  border-radius: 23px;
  color: #7d92eb;
  padding: 0 12px;
  font-size: 14px;
  font-weight: 400;
  height: 28px;
  box-sizing: border-box;
  display: flex;
  align-items: center;
  margin: 5px 10px 5px 0;
  border: 1px solid rgba(0, 0, 0, 0.1);
}
.filter-tag button {
  margin-left: 7px;
  border-radius: 50%;
  font-size: 0;
  color: #7d92eb;
  padding: 3px 5px 3px 3px;
  box-sizing: border-box;
}
.filter-tag button i {
  font-size: 13px;
  color: #7d92eb;
  width: 12px;
  height: 12px;
}
.filter-tag button:hover {
  background-color: #f4f4f4;
  color: #5e5e5e;
  transition: 0.1s;
  cursor: pointer;
}

.filters-tags {
  display: flex;
  margin-right: 10px;
  margin-top: 2px;
  flex-wrap: wrap;
}

/* Help Icon
===============================*/
.help-icon {
  display: flex;
  align-items: center;
  margin-left: 3px;
}
.help-icon i {
  font-size: 15px;
}

.icon-middle {
  display: flex;
  align-items: center;
}
.icon-middle i {
  font-size: 16px;
  margin-right: 8px;
}

/* Actions
===============================*/
.bottom-actions {
  display: flex;
  align-items: center;
  justify-content: flex-end;
}
.bottom-actions button {
  margin-left: 10px;
}
.bottom-actions.inverse {
  flex-direction: row-reverse;
}

/* ScriptBox
===============================*/
.script-box {
  border-radius: 3px;
  border: 1px solid rgba(79, 84, 92, 0.3);
  overflow: hidden;
  font-size: 14px;
}

/* Invisible
===============================*/
.invisible {
  visibility: hidden;
}

/* Badge
================================ */
.badge-count {
  background-color: #f0f0f0;
  padding: 4px 8px 3px;
  border-radius: 15px;
  color: #2a2a2a;
  margin-right: 2px;
  font-size: 11px;
  font-weight: 600;
  line-height: 11px;
  text-align: center;
  display: inline-block;
}

/* Status
===============================*/
.mailing-status {
  display: flex;
  align-items: center;
}

.mailing-status .circle-status {
  height: 12px;
  width: 12px;
  border-radius: 50%;
  margin-right: 7px;
  display: inline-block;
}

.mailing-status.Enviando {
  color: #e67e22;
}
.mailing-status.Enviando .circle-status {
  background-color: #e67e22;
}

/* Import
===============================*/
.filename {
  position: relative;
  color: #5d657a;
  border-radius: 24px;
  background-color: #d5e0ef;
  font-weight: 500;
  padding: 3px 15px;
  margin: 0 27px;
  font-size: 12px;
  margin-left: 16px;
  display: flex;
  align-items: center;
}
.filename button {
  padding: 2px;
  display: flex;
  align-items: center;
  border-radius: 50%;
  margin-left: 5px;
}
.filename button i {
  font-size: 13px;
}
.filename button:hover {
  background-color: rgba(0, 0, 0, 0.1);
}

.import-file-row {
  display: flex;
  align-items: center;
}

/* DEV
========================*/
.dev-dropdown .btn i {
  font-size: 20px;
  width: 19px;
  height: 19px;
  display: inline-block;
  color: #e74c3c !important;
  margin-left: 5px;
}
.dev-dropdown .script-box {
  z-index: 0;
}

.dev-method {
  width: 100%;
  display: flex;
}
.dev-method span {
  margin-right: 10px;
}
.dev-method span:first-child {
  background-color: #6f7dd8;
  color: white;
  border-radius: 5px;
  font-size: 10px;
  padding: 2px 7px;
}
.dev-method span:last-child {
  display: inline-block;
  margin-left: auto;
  opacity: 0.6;
}

.dev-space {
  position: fixed;
  left: 20px;
  bottom: 16px;
  z-index: 99;
  display: flex;
  align-items: center;
}

.dev-current-api {
  background-color: #e74c3c;
  padding: 1px 10px;
  border-radius: 12px;
}
.dev-current-api i {
  color: #e74c3c;
  margin-right: -14px;
  font-size: 17px;
  top: 2px;
  position: relative;
}

/* Loading Icon
========================== */
.icon-loader {
  -webkit-animation: rotation 0.5s infinite linear;
          animation: rotation 0.5s infinite linear;
  margin-left: 18px;
  font-size: 15px;
  color: #798d99;
}

.agent-avatar {
  display: flex;
  align-items: center;
}
.agent-avatar img {
  object-fit: cover;
  border-radius: 50%;
}

/* Context BG
=================================== */
.contextual-bg {
  padding: 15px;
  background-color: #f2dede;
  margin-bottom: 20px;
}
.contextual-bg h3 {
  margin: 0;
}

.contextual-bg.success {
  background-color: #7d92eb;
  color: white;
}

/* Vertical
=========================*/
.vertical-tabs-space {
  position: relative;
  box-sizing: border-box;
}

.vertical-tabs {
  background-color: rgb(247, 248, 250);
  padding-top: 30px;
  padding: 0;
  width: 100%;
  height: 100vh;
  box-sizing: border-box;
}

/* Horizontal
=========================*/
.horizontal-tabs {
  background-color: #fff;
  padding: 0 17px;
  display: flex;
  align-items: center;
}
.horizontal-tabs a {
  color: #2c3e50;
  opacity: 0.7;
  padding: 11px 0;
  font-weight: 600;
  text-transform: uppercase;
  font-size: 14px;
  margin-right: 35px;
  display: flex;
  align-items: center;
}
.horizontal-tabs a i {
  margin-right: 5px;
  margin-right: 8px;
  font-size: 18px;
}
.horizontal-tabs a.hover, .horizontal-tabs a.active {
  opacity: 1;
}
.horizontal-tabs a.active {
  border-bottom: 3px solid #7d92eb;
}

.editable-row {
  display: flex;
  align-items: center;
  margin-bottom: 2px;
}
.editable-row > div:last-child {
  margin-left: 5px;
}
.editable-row > button:first-child {
  margin-left: auto;
  margin-right: 5px;
}

.editable-property {
  padding: 0px 15px;
}
.editable-property.editMode {
  padding: 6px 15px 10px !important;
}

.uneditable-property {
  padding: 7px 15px !important;
}

.editable-property.fixedProperty.editMode {
  background-color: #eff1f3;
}
.editable-property.fixedProperty .editable-row > div:last-child {
  margin-left: auto;
}
.editable-property.fixedProperty .editable-row .left {
  margin-top: -25px;
}

.editMode {
  background-color: #eff1f3;
  border-radius: 4px;
}

.action-hover {
  background: #d4d6ea;
  border-radius: 2px;
  height: 21px;
  width: 21px;
  box-sizing: border-box;
  margin-top: -3px;
  opacity: 0;
  position: absolute;
  right: 0;
}
.action-hover i {
  font-size: 13px;
}
.action-hover.copied {
  background-color: #2ecc71;
}
.action-hover.copied i {
  color: white;
}

.readable-row {
  position: relative;
}

.readable-row:hover .action-hover {
  opacity: 1;
}

.default-value-property {
  color: #E2F4FB;
}

.box {
  margin-bottom: 15px;
  box-sizing: border-box;
  border: 1px solid #e8e8e8;
  box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.06);
  border-radius: 12px;
  padding: 23px 28px;
}
.box p {
  font-size: 14px;
}
.box .title {
  margin: 0 0 20px;
  font-size: 14px;
  font-weight: 700;
  border-bottom: 1px dotted #CCC;
  padding-bottom: 12px;
  text-transform: uppercase;
}
.box.grey {
  background-color: #f9fafa;
}
.box.fullheight {
  height: 100%;
  margin-bottom: 0;
}
.box.nopad {
  padding: 0;
}

.box-actions {
  margin-top: 35px;
}

.box-space {
  height: 100%;
}

.modal-overlay {
  align-items: flex-start;
  justify-content: center;
  background-color: rgba(0, 0, 0, 0.3);
  display: none;
  height: 100%;
  width: 100%;
  left: 0;
  top: 0;
  overflow-y: auto;
  overflow-x: hidden;
  position: fixed;
  z-index: 20;
  overflow-x: hidden;
}

.modal-space.open .modal-overlay {
  display: flex;
}

.modal-box {
  background-color: #fff;
  border-radius: 4px;
  margin: auto 0;
  position: relative;
  width: 480px;
  z-index: 25;
  box-shadow: rgba(0, 0, 0, 0.4) 0px 0px 5px 0px;
}
.modal-box.side {
  min-height: 100vh;
  margin: 0 0 0 auto;
  border-radius: 0;
  width: 50vw;
}

.modal-actions {
  display: flex;
  justify-content: flex-end;
  padding: 20px 25px;
  background-color: white;
  border-top: 1px solid #ececec;
}

.modal-title {
  font-size: 20px;
  font-weight: 700;
  padding: 5px 0 15px;
}

.modal-closer.btn.only-icon {
  position: absolute;
  right: 15px;
  top: 10px;
  z-index: 9;
  padding: 4px;
  width: auto;
  height: auto;
}
.modal-closer.btn.only-icon i {
  font-size: 22px;
  width: 22px;
  height: 22px;
}

.modal-overlay-clickable {
  height: 100%;
  width: 100%;
  position: fixed;
  z-index: 0;
}

.subactions {
  display: flex;
  align-items: center;
  padding: 18px;
  border: 1px dashed rgba(0, 0, 0, 0.15);
  border-radius: 5px;
  margin-bottom: 15px;
}
.subactions i {
  font-size: 23px;
  margin-right: 10px;
  color: rgba(0, 0, 0, 0.5);
}

.modal-header {
  display: flex;
  align-items: center;
  background-color: #f9f9f9;
  border-top-left-radius: 4px;
  border-top-right-radius: 4px;
  border-bottom: 1px solid #fafafa;
  min-height: 47px;
}
.modal-header .modal-header-title {
  margin: 0 0 0 25px;
  padding: 10px 0;
  font-size: 0.875rem;
  color: #202020;
  font-weight: 700;
  line-height: 1.7;
}

.modal-body {
  padding: 20px 25px 25px 25px;
  overflow-y: auto;
}

.modal-footer {
  display: flex;
  justify-content: flex-end;
  padding: 20px 25px;
  border-top: 1px solid #ececec;
  border-bottom-left-radius: 4px;
  border-bottom-right-radius: 4px;
}

.modal-wide .modal-box {
  width: 800px;
}

.multi-page-modal {
  display: flex;
  height: 560px;
}
.multi-page-modal .modal-body {
  padding: 55px 40px;
}

.modal-side {
  width: 300px;
  box-sizing: border-box;
  padding: 20px 25px;
  background-color: rgb(243, 242, 241);
}
.modal-side h2 {
  font-size: 28px;
  font-weight: 600;
  padding-bottom: 40px;
  padding-top: 30px;
  line-height: 1.2;
}
.modal-side p {
  font-size: 14px;
  font-weight: 400;
  margin-top: 0px;
  white-space: pre-wrap;
}
.modal-side img {
  height: 100px;
  margin: 50px auto 50px;
  left: 0;
  display: block;
  right: 0;
}

.modal-page-indicators {
  display: flex;
  align-items: center;
  justify-content: center;
}

.mp-indicator {
  border-radius: 50%;
  background-color: rgba(0, 0, 0, 0.2);
  width: 7px;
  height: 7px;
  margin: 0 5px;
}
.mp-indicator.active {
  width: 10px;
  height: 10px;
  background: #7d92eb;
}

.user-avatar {
  background-size: cover;
  background-position: 50%;
  border: none;
  height: 100px;
  width: 100px;
  position: relative;
  display: flex;
  border-radius: 50%;
  transition: box-shadow 0.1s;
}
.user-avatar .avatar-upload-indicator {
  background-position: 50%;
  background-repeat: no-repeat;
  border-radius: 50%;
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.2);
  height: 28px;
  left: auto;
  position: absolute;
  right: 0;
  top: 0;
  width: 28px;
  background-color: #dcddde;
  background-image: url(https://discordapp.com/assets/d5c25e76af04cea8997e4a060572feae.svg);
}
.user-avatar input {
  position: absolute;
  opacity: 0;
  width: 100%;
  cursor: pointer;
  height: 100%;
  z-index: 2;
  top: 0;
}
.user-avatar .overlay {
  background-color: rgba(0, 0, 0, 0.6);
  height: 100%;
  width: 100%;
  position: absolute;
  top: 0;
  left: 0;
  border-radius: 50%;
  color: white;
  opacity: 0;
}
.user-avatar .overlay i {
  margin-bottom: 3px;
}
.user-avatar .overlay-inner {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  height: 100%;
}
.user-avatar:hover .overlay {
  opacity: 1;
}

.profile .user-avatar {
  margin: 0;
}

.input-none {
  display: none;
}

.file-input-menu {
  position: absolute;
  width: 100%;
  left: 0;
  margin-top: -2px;
  height: calc(100% + 6px);
  opacity: 0;
  cursor: pointer !important;
  overflow: hidden;
  font-size: 0px;
}

.menu-open .user-avatar {
  box-shadow: inset 0 0 120px rgba(0, 0, 0, 0.75);
}
.menu-open .user-avatar i {
  visibility: visible;
}

.cell-avatar {
  width: 35px;
  height: 35px;
  object-fit: cover;
  border-radius: 50%;
  margin-right: 20px;
  background-color: #f3f4f5;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 15px;
  color: rgba(0, 0, 0, 0.3);
}

.default-letter {
  color: rgba(0, 0, 0, 0.5);
  font-weight: 500;
  font-size: 19px;
}

.no-pointer {
  cursor: none;
}

.avatar-space {
  display: flex;
  align-items: center;
  border: 2px dashed rgba(0, 0, 0, 0.1);
  padding: 20px;
  margin-top: 10px;
}

.filter__single-value, .filter__placeholder {
  position: relative !important;
  overflow: visible !important;
  margin-top: 17px !important;
  display: block !important;
  font-size: 14px !important;
  font-weight: 500 !important;
  display: flex !important;
  align-items: center;
}
.filter__single-value i, .filter__placeholder i {
  font-size: 16px;
  margin-right: 7px;
  margin-left: 2px;
  margin-top: 2px;
}

.filter__control {
  min-height: 36px !important;
  background-color: #ebebeb !important;
  border: 1px solid transparent !important;
  color: #222 !important;
  border-radius: 4px !important;
  cursor: pointer !important;
  transition: box-shadow linear 40ms, border-color linear 0.2s !important;
  -webkit-backface-visibility: hidden;
  transform: translateZ(0);
}
.filter__control:hover, .filter__control.filter__control--is-focused {
  background-color: #e8f0ff !important;
}
.filter__control:hover .filter__single-value, .filter__control.filter__control--is-focused .filter__single-value {
  color: #7d92eb;
}
.filter__control:hover .filter__placeholder, .filter__control.filter__control--is-focused .filter__placeholder {
  color: #7d92eb !important;
}
.filter__control.filter__control--is-focused {
  border-color: #7d92eb !important;
  box-shadow: none !important;
}

.filter__value-container {
  padding-bottom: 0 !important;
  padding-top: 1px !important;
  padding-right: 0 !important;
}

.filter__dropdown-indicator, .filter__indicator-separator {
  display: none !important;
}

.filter__indicators {
  opacity: 0;
}
.filter__indicators svg {
  fill: #888 !important;
  height: 16px !important;
  width: 16px !important;
}

.filter__control:hover .filter__indicators {
  opacity: 1;
}

.filter__indicator {
  padding-left: 0 !important;
}

.filter__menu {
  width: auto !important;
  box-shadow: 0 4px 14px 0 rgba(0, 0, 0, 0.2), 0 0 0 1px rgba(0, 0, 0, 0.05);
  font-size: 14px !important;
  font-weight: 500 !important;
  min-width: 100%;
  -webkit-animation-name: showMenu;
          animation-name: showMenu;
  -webkit-animation-duration: 150ms;
          animation-duration: 150ms;
  border: 1px solid #D3D8E0;
  box-shadow: 0 3px 10px 0 rgba(0, 0, 0, 0.2);
  background-color: #fff;
  border-radius: 4px;
}

.filter__option--is-selected {
  background-color: #fff !important;
  color: #7d92eb !important;
}

.filter__option--is-focused, .drop-option:focus {
  background-color: #7d92eb !important;
  color: #fff !important;
  outline: 0 !important;
}

.filter__option, .drop-option {
  padding: 7px 12px !important;
}

.selected-filter {
  position: absolute !important;
  top: 8px !important;
  right: 6px !important;
  visibility: hidden;
}

.filter__option--is-selected ~ .selected-filter {
  visibility: visible;
}
.filter__option--is-selected ~ .selected-filter svg {
  fill: #7d92eb !important;
}

.filter__option--is-focused ~ .selected-filter svg {
  fill: #fff !important;
}

@-webkit-keyframes showMenu {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

@keyframes showMenu {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
.react-select__option--is-selected {
  background-color: transparent !important;
  color: #222 !important;
}

.select-space.small {
  min-width: 100px;
}
.select-space.small .react-select__control {
  min-height: 0 !important;
  height: 30px;
  font-size: 14px;
  border: none;
}

.react-select__indicator-separator {
  display: none;
}

.select-space.dash {
  width: auto;
  min-width: 200px;
}
.select-space.dash .react-select__control {
  border: 1px solid rgba(0, 0, 0, 0.1);
  font-size: 13px;
}
.select-space.dash .react-select__option {
  font-weight: 400;
}

.react-select__option--is-focused {
  color: #222 !important;
}

.react-select__multi-value__remove:hover {
  color: #fff !important;
  background-color: rgba(0, 0, 0, 0.1) !important;
  border-radius: 50%;
  cursor: pointer;
}

.react-select__clear-indicator {
  padding: 3px !important;
}

.ReactTable {
  border: none;
  border-radius: 5px;
}

.ReactTable .rt-thead.-header {
  box-shadow: none;
  color: #888;
  font-size: 15px;
  font-weight: bold;
  border-bottom: 1px solid rgb(234, 234, 234);
}

.ReactTable .rt-thead .rt-th {
  border-right: 1px solid rgba(0, 0, 0, 0);
}

.ReactTable .rt-td {
  font-size: 14px;
  border-right: none !important;
  padding: 10px 17px;
  position: relative;
  display: flex;
  align-items: center;
}
.ReactTable .rt-td:first-child {
  padding-left: 15px;
}
.ReactTable .rt-td a {
  font-weight: 500;
}

.ReactTable .-pagination {
  padding: 15px;
  box-shadow: none;
  border-top: none;
}

.rt-th {
  outline: none;
}
.rt-th > div {
  padding: 5px 16px 16px;
  text-align: left;
  padding-left: 12px;
  padding-bottom: 5px;
  font-size: 12px;
  line-height: 20px;
  font-weight: 400;
}

.ReactTable .rt-thead .rt-th.-sort-desc,
.ReactTable .rt-thead .rt-td.-sort-desc {
  box-shadow: none;
}

.ReactTable .rt-thead .rt-th.-sort-asc,
.ReactTable .rt-thead .rt-td.-sort-asc {
  box-shadow: none;
}

.table-header {
  display: flex;
  align-items: center;
  color: #888;
  fill: #888;
}
.table-header i {
  margin-bottom: 0px;
  margin-right: 4px;
  font-size: 16px;
}
.table-header .caret-big {
  visibility: hidden;
  margin-left: 3px;
}

.rt-th:hover a.table-header {
  color: #7d92eb;
}
.rt-th:hover a.table-header span {
  text-decoration: underline;
}
.rt-th:hover a.table-header svg {
  fill: #7d92eb;
}

a.table-header {
  pointer-events: none;
}

.-sort-asc a.table-header .caret-big {
  visibility: visible;
}

.-sort-desc a.table-header .caret-big {
  visibility: visible;
  transform: rotate(180deg);
}

/* No data
===================================*/
.no-data {
  padding: 40px;
}

.no-data-inner {
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
}
.no-data-inner img {
  max-height: 150px;
}
.no-data-inner h2 {
  opacity: 0.6;
  font-size: 15px;
}

.ReactTable .rt-tbody .rt-tr-group {
  border-bottom: 0;
  min-height: 55px;
  border-radius: 0;
  cursor: pointer;
  border-bottom: 1px solid rgb(244, 244, 244);
}

.table-badge {
  padding: 4px 15px;
  background: #ccc;
  color: #1f2b36;
  display: inline-block;
  line-height: 1;
  border-radius: 15px;
  margin-right: 20px;
  font-size: 14px;
}

.table-end {
  margin: 50px 30px 30px;
  text-align: center;
}
.table-end .table-end-line {
  width: 50px;
  height: 1.5px;
  margin: 0 auto;
  border-radius: 2px;
}
.table-end .table-end-circle {
  border-radius: 50%;
  height: 8px;
  width: 8px;
  margin: 0 4px;
}

.table-end-line,
.table-end-circle {
  background: #7d92eb;
  opacity: 0.5;
  box-shadow: 0 1px 0 #fff;
  display: inline-block;
  vertical-align: middle;
}

.table-edit-row {
  display: none;
  font-size: 14px;
  color: #9ba2b4;
  align-items: center;
}
.table-edit-row i {
  font-size: 14px;
  color: #9ba2b4;
  margin-right: 5px;
}

.danger .table-edit-row {
  display: flex;
}

.rt-tr-group:hover {
  background-color: #f3f4f5;
  border-radius: 8px;
}
.rt-tr-group:hover .table-edit-row {
  display: flex;
}
.rt-tr-group:hover .table-edit-actions {
  display: flex;
}
.rt-tr-group:hover .table-edit-actions button:first-child {
  margin-right: 5px;
}

.table-icon-cell {
  display: flex;
  align-items: center;
}
.table-icon-cell i {
  margin-right: 5px;
  font-size: 16px;
}

.table-title {
  display: flex;
  align-items: center;
  padding: 10px 15px;
  border-bottom: 1px solid rgba(0, 0, 0, 0.065);
  height: 53px;
  box-sizing: border-box;
  margin-top: -4px;
}
.table-title h1,
.table-title h2 {
  margin: 0;
}
.table-title h1 {
  font-size: 17px;
  font-weight: 500;
  color: #383838;
}

.table-title-badge {
  color: rgba(0, 0, 0, 0.7);
  border-radius: 14px;
  min-width: 12px;
  padding: 3px 8px 0px;
  margin-left: 9px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 12px;
  font-weight: 600;
  border: 1px solid rgba(0, 0, 0, 0.1);
  display: none;
}

.button-page-table-add {
  color: black !important;
  margin-left: auto !important;
}
.button-page-table-add i {
  color: #7d92eb !important;
}

.table-icon {
  margin-right: 20px;
  font-size: 18px;
  color: #7d92eb;
}

.inbox .rt-td {
  padding: 20px 37px;
}
.inbox .rt-td:first-child {
  padding-left: 20px;
}

.inbox .ReactTable .rt-tbody .rt-tr-group {
  margin-bottom: 0px;
}

.table-icon-description {
  display: flex;
  align-items: center;
}
.table-icon-description i {
  margin-right: 8px;
}

.table-overflow-visible .rt-td, .table-overflow-visible .ReactTable .rt-table, .table-overflow-visible .ReactTable .rt-tbody {
  overflow: visible;
}

.list-heading {
  display: flex;
  align-items: center;
  margin-top: -1px;
  padding-bottom: 3px;
  min-height: 31px;
}
.list-heading h2 {
  margin-right: 20px;
}

.webcam img {
  object-fit: cover;
  box-sizing: border-box;
  display: block;
}
.webcam video {
  display: block;
}

.no-webcam {
  border: 2px dashed rgba(0, 0, 0, 0.2);
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  box-sizing: border-box;
  font-size: 14px;
  opacity: 0.7;
  text-transform: uppercase;
}
.no-webcam img {
  width: 60px;
  height: 60px;
  margin-bottom: 10px;
}

.webcam-actions {
  display: flex;
  justify-content: space-between;
  margin-top: 10px;
}

.page.login {
  background-color: #f8f8f8;
  height: 100vh;
  display: flex;
  flex-direction: column;
  overflow: auto;
}

.auth-header {
  height: 70px;
  min-height: 70px;
  width: 100%;
  background-color: #fff;
  border-bottom: 1px solid #ddd;
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: 900;
  font-size: 28px;
}
.auth-header span {
  margin-left: 7px;
}
.auth-header a {
  display: flex;
  align-items: center;
}

.login-page-inner {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-top: 35px;
}

.login-box {
  box-shadow: 0 2px 40px 0 rgba(0, 0, 0, 0.06);
  z-index: 1;
  box-sizing: content-box;
}

.login-signup {
  font-size: 16px;
  margin-bottom: 20px;
}

.login-actions a:hover {
  text-decoration: underline;
}

.auth-image {
  display: flex;
  justify-content: center;
  margin-top: 20px;
}
.auth-image img {
  height: 240px;
}

/* Old 
============================== */
.auth-box {
  width: 330px;
}
.auth-box .kinput {
  height: 45px;
}
.auth-box .kinput input {
  font-size: 16px;
  height: 100%;
}

#login-bg {
  position: fixed;
  top: 1px;
  height: 100%;
  right: 0;
  fill: #f3f3f8;
  z-index: -1;
}

.login-dashboard {
  z-index: 99;
  height: 700px;
  right: -400px;
  top: 200px;
  position: fixed;
}

h1.headline {
  font-size: 28px;
  font-weight: 700;
  max-width: 470px;
  margin-bottom: 20px;
  display: block;
  text-align: center;
  margin-top: 0;
}

.logo-login {
  margin-bottom: 60px;
  margin-top: 25px;
}

.login-actions {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.login-btn {
  border-radius: 100px;
  padding: 14px 80px;
  font-size: 16px;
  margin-bottom: 20px;
  background-color: #6474be !important;
  border-radius: 4px;
  width: 100%;
  text-align: center;
}
.login-btn:hover {
  background-color: #8aadfe !important;
}
.login-btn .btn-text {
  display: flex;
  align-items: center;
  text-align: center;
  width: 100%;
  display: block;
}

.register-success-space {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
.register-success-space h1 {
  width: 470px;
  text-align: center;
}
.register-success-space i {
  color: #7d92eb;
  border-radius: 50%;
  font-size: 55px;
  padding: 30px;
  margin: 10px 0 20px;
  border: 4px solid;
}
.register-success-space h2 {
  text-align: center;
  background-color: rgba(0, 0, 0, 0.05);
  border-radius: 21px;
  padding: 10px 20px;
  font-size: 17px;
}

/* Responsividade 
============================== */
@media screen and (max-width: 1740px) {
  .login-dashboard {
    height: 600px;
    right: -360px;
    top: 170px;
  }
}
@media screen and (max-width: 1440px) {
  h1.headline {
    font-size: 34px;
  }
  .login-dashboard {
    height: 510px;
    right: -355px;
    top: 110px;
  }
}
@media screen and (max-width: 1200px) {
  h1.headline {
    font-size: 27px;
    max-width: 300px;
  }
  .login-dashboard {
    height: 460px;
    right: -325px;
    top: 110px;
  }
  .auth-box {
    max-width: 300px;
  }
}
.registering .loading-space {
  margin-top: 0 !important;
}
.registering .loading2 {
  stroke: #7d92eb;
}

.menu {
  border-radius: 4px;
  background: #fff;
  box-shadow: 0 0 0 1px rgba(0, 0, 0, 0.08), 0 4px 12px 0 rgba(0, 0, 0, 0.12);
  padding: 15px 0;
  box-sizing: border-box;
  transition: opacity 0.15s ease-in-out, transform 0.15s ease-in-out;
  z-index: 999;
}

.menu-row {
  display: flex;
  padding: 7px 25px;
}
.menu-row .nav-user-space {
  height: auto;
}
.menu-row .right {
  display: flex;
  flex-direction: column;
  margin-left: 20px;
  margin-top: -1px;
}
.menu-row .right .name {
  font-weight: 500;
  font-size: 15px;
  line-height: 15px;
}
.menu-row .right .profile {
  font-weight: 400;
  color: #888;
  font-size: 15px;
  line-height: 24px;
}

.menu-space {
  position: relative;
}
.menu-space .separator {
  height: 1px;
  width: 100%;
  background-color: rgba(0, 0, 0, 0.1);
  margin: 3px 0 10px;
}

.menu {
  position: absolute;
  bottom: 6px;
  left: 72px;
  width: 242px;
  color: #222;
}
.menu a {
  font-size: 15px;
  font-weight: 500;
}

.menu-inner {
  position: relative;
}
.menu-inner:after, .menu-inner:before {
  right: 100%;
  border: solid transparent;
  content: " ";
  height: 0;
  width: 0;
  position: absolute;
  pointer-events: none;
  bottom: 5px;
}
.menu-inner:after {
  border-color: rgba(255, 255, 255, 0);
  border-right-color: #fff;
  border-width: 7px;
  margin-top: -6px;
}
.menu-inner:before {
  border-color: rgba(220, 220, 220, 0);
  border-right-color: #757575;
  border-width: 7px;
  margin-top: -7px;
}

/* Animations
========================== */
.menu-fade-enter {
  opacity: 0.01;
  left: 72px;
}

.menu-fade-enter.menu-fade-enter-active {
  opacity: 1;
  left: 62px;
  transition: opacity 300ms ease-in, left 300ms ease-out;
}

.menu-fade-leave {
  left: 62px;
  opacity: 1;
}

.menu-fade-leave.menu-fade-leave-active {
  opacity: 0;
  left: 72px;
  transition: opacity 200ms ease-in, left 200ms ease-out;
}

/* Menu Dropdown
========================== */
.menu-dropdown {
  position: relative;
}
.menu-dropdown .menu {
  top: 36px;
  bottom: auto;
  left: auto;
  right: 0;
  padding: 0px 0;
}
.menu-dropdown .menu a {
  margin-left: 0;
  display: flex;
  align-items: center;
}

.drop-option:hover {
  background-color: #7d92eb;
  color: white;
}

.drop-option {
  display: flex !important;
  align-items: center;
}
.drop-option i {
  margin-right: 6px;
  font-size: 17px;
}

.menu-available {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.menu-available span {
  font-size: 15px;
  margin-left: 10px;
}

.user-menu-basic {
  display: flex;
  align-items: center;
  margin-left: 10px;
}
.user-menu-basic .left {
  position: relative;
}
.user-menu-basic img {
  width: 35px;
  height: 35px;
  border-radius: 50%;
}
.user-menu-basic .right {
  display: flex;
  flex-direction: column;
  margin-left: 20px;
}
.user-menu-basic .right .name {
  font-weight: 500;
}

.user-menu li {
  padding: 0 15px;
}

.status-indicator {
  position: absolute;
  right: 0;
  bottom: 4px;
  width: 10px;
  height: 10px;
  background-color: #1bb157;
  border-radius: 50%;
  border: 2px solid white;
}
.status-indicator.side {
  border-color: #30374d;
  bottom: -2px;
}

.section-loading {
  width: 30%;
  display: inline-block;
  text-align: center;
  min-height: 215px;
  vertical-align: top;
  margin: 1%;
  border-radius: 5px;
}

.loader {
  position: relative;
  width: 60px;
  height: 60px;
  border-radius: 50%;
  margin: 75px;
  display: inline-block;
  vertical-align: middle;
}

.loader-5 {
  border: 8px dotted rgb(0, 0, 0);
  transition: all 1s ease;
  -webkit-animation: dotted-spin 1s linear infinite;
  animation: dotted-spin 1s linear infinite;
  border-bottom-width: 1px;
  border-bottom-color: rgba(0, 0, 0, 0.3);
  border-left-width: 2px;
  border-left-color: rgba(0, 0, 0, 0.5);
  border-top-width: 3px;
  border-right-width: 4px;
  border-top-color: rgba(0, 0, 0, 0.7);
}

.loader-5 .loader-pacman,
.loader-pacman {
  position: absolute;
  top: 40px;
  left: 25px;
  width: 0px;
  height: 0px;
  border-right: 12px solid transparent;
  border-top: 12px solid rgb(255, 255, 0);
  border-left: 12px solid rgb(255, 255, 0);
  border-bottom: 12px solid rgb(255, 255, 0);
  border-top-left-radius: 12px;
  border-top-right-radius: 12px;
  border-bottom-left-radius: 12px;
  border-bottom-right-radius: 12px;
}

@-webkit-keyframes dotted-spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(-360deg);
  }
}

@keyframes dotted-spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(-360deg);
  }
}
.loading2 {
  fill: none;
  stroke: rgba(0, 0, 0, 0.2);
  stroke-dasharray: 135.088px;
  stroke-dashoffset: 108.071px;
  stroke-linecap: round;
  stroke-width: 5px;
  transform-origin: center center 0px;
  -webkit-animation: dotted-spin 0.86s cubic-bezier(0.4, 0.15, 0.6, 0.85) 0s infinite normal none running;
          animation: dotted-spin 0.86s cubic-bezier(0.4, 0.15, 0.6, 0.85) 0s infinite normal none running;
  margin: 15px;
}

.loading-space.center {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 85px;
}

.table-loading-wrapper {
  position: absolute;
  width: 100%;
  top: 70px;
  left: 0;
  right: 0;
  margin: auto;
}

.invisible-table .rt-tbody {
  opacity: 0;
}
.invisible-table .pagination-bottom {
  display: none;
}

.rotateSpin {
  -webkit-animation: dotted-spin 0.86s cubic-bezier(0.4, 0.15, 0.6, 0.85) 0s infinite normal none running;
          animation: dotted-spin 0.86s cubic-bezier(0.4, 0.15, 0.6, 0.85) 0s infinite normal none running;
}

.customize-block {
  display: flex;
}
.customize-block .left {
  width: 200px;
}
.customize-block .block-picker > div:first-child {
  display: none;
}

.widget-preview {
  position: absolute;
  right: 40px;
  top: 85px;
}
.widget-preview .widget-preview-inner {
  width: 400px;
  background-color: #f4f5f6;
  background-color: #f2f5f7;
  min-height: 200px;
  border-radius: 5px;
  box-shadow: 0 5px 40px rgba(0, 0, 0, 0.16) !important;
  transition: all 195ms ease-in;
  height: 100%;
  background-color: white;
  display: flex;
  flex-direction: column;
}
.widget-preview .widget-header {
  box-shadow: 0 0 4px 0 rgba(0, 0, 0, 0.15);
  background-color: #34495e;
  padding: 18px 20px 20px;
  border-radius: 5px 5px 0 0;
  color: white;
}
.widget-preview .widget-header .widget-go-back {
  margin-bottom: 3px;
}
.widget-preview .widget-header .widget-go-back i {
  color: #fff;
}
.widget-preview .widget-header .widget-channel-name {
  font-size: 23px;
  font-weight: 600;
  margin-top: 3px;
}
.widget-preview .widget-header .widget-channel-response-time {
  font-size: 12px;
  font-weight: 500;
  opacity: 0.5;
}
.widget-preview .widget-chat {
  overflow-y: auto;
  max-height: 450px;
  height: 100%;
  padding: 25px;
  box-sizing: border-box;
}
.widget-preview .send-box {
  background-color: #fff;
  transition: 0.6s;
  border-radius: 0 0 5px 5px;
  padding: 22px;
  border-top: 1px solid #e6e6e6;
}
.widget-preview .send-box .send-input {
  display: flex;
  align-items: center;
  font-size: 14px;
  opacity: 0.6;
}
.widget-preview .chat-balloon {
  margin-left: 100px;
  border-radius: 20px 20px 4px !important;
  background-color: #f2f4fb;
}

.widget-user-msg {
  display: flex;
  justify-content: flex-end;
  flex-shrink: 0;
}

.widget-chat-inner {
  margin-top: 100%;
}

.assign-conditions-space {
  position: relative;
  margin-top: 5px;
}
.assign-conditions-space .vertical-line {
  border-left: solid 1px #d3d8e0;
  width: 1px;
  height: 100%;
  position: absolute;
  left: 30px;
}

.assign-badge {
  padding: 6px 10px;
  width: 60px;
  position: relative;
  display: block;
  text-align: center;
  border-radius: 3px;
  background-color: #9ba2b4;
  color: #fff;
  box-sizing: border-box;
  text-transform: uppercase;
  font-weight: 500;
  font-size: 13px;
}
.assign-badge.condition {
  background-color: #75cce1;
  padding: 4px 4px;
  width: 45px;
  margin-left: 40px;
  margin-bottom: 2px;
}
.assign-badge.and {
  background-color: #ffab5d;
}
.assign-badge.block {
  margin-left: 0;
}

.assign-header {
  display: flex;
  text-align: center;
}
.assign-header label {
  font-weight: 600;
  margin-left: 10px;
  display: flex;
  align-items: center;
  text-transform: uppercase;
  font-size: 12px;
}

.assign-blocks {
  margin-bottom: 100px;
  padding-left: 70px;
}
.assign-blocks p {
  margin-top: 10px;
}

.assign-block {
  padding: 8px 20px;
  border-radius: 5px;
  background-color: #f8f8f8;
  box-sizing: border-box;
}

.assign-rule {
  display: flex;
  align-items: center;
}
.assign-rule .react-select__control {
  margin-right: 5px;
  min-width: 200px;
  font-size: 14px;
}
.assign-rule > div {
  flex: 1 1;
}
.assign-rule button {
  padding: 8px;
  margin-bottom: 2px;
  color: #6641a6;
  background-color: transparent;
  border: 0;
  transition: all 0.1s cubic-bezier(0, 0, 0, 1), visibility 1ms;
}
.assign-rule button i {
  font-size: 23px;
  margin-right: 0;
}
.assign-rule button:first-child {
  margin-right: 5px;
  color: rgba(0, 0, 0, 0.3);
}
.assign-rule button:first-child i {
  font-size: 18px;
}
.assign-rule button:last-child {
  margin-right: 55px;
  margin-left: 5px;
}
.assign-rule button:last-child.invisible {
  visibility: hidden;
}
.assign-rule .kinput input {
  font-size: 14px;
}

.condition-switch {
  display: flex;
  justify-content: center;
  margin-top: 10px;
  margin-bottom: 5px;
}

.checked-icon-condition {
  color: white;
  font-weight: 600;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  font-size: 14px;
}
.checked-icon-condition.or {
  font-size: 13px;
  margin-right: 3px;
}

.add-block-space {
  max-width: 65px;
}

.add-block-inner {
  display: flex;
  align-items: center;
  flex-direction: column;
  position: relative;
}
.add-block-inner button {
  max-width: 55px;
  padding: 8px;
}
.add-block-inner button i {
  margin-right: 0;
  font-size: 23px;
  color: #2ecc71;
}

.add-block-vertical-line {
  width: 2px;
  border-left: 1px solid rgba(0, 0, 0, 0.1);
  border-right: 1px solid rgba(0, 0, 0, 0.1);
  height: 20px;
}

.assign.then {
  margin-bottom: 90px;
}
.assign.then .radio {
  margin-right: 15px;
  margin-left: 4px;
}
.assign.then .select-space {
  margin-left: 68px;
  margin-top: 3px;
  position: absolute;
  width: 200px;
}

.assign-blocks-title {
  display: flex;
  align-items: center;
}

.assign-blocks-title {
  font-size: 14px;
  margin-bottom: 5px;
  margin-top: 3px;
}
.assign-blocks-title .select-space {
  margin: 0 7px 5px;
}
.assign-blocks-title .react-select__control {
  background-color: #ffab5d;
  font-size: 12px;
  font-weight: 500;
}
.assign-blocks-title .select-space.any .react-select__control {
  background-color: #a8e5f4;
}
.assign-blocks-title .react-select__indicator {
  color: rgba(0, 0, 0, 0.7) !important;
}
.assign-blocks-title .react-select__single-value {
  font-size: 13px !important;
}

.subheader {
  width: 100%;
  background-color: #F8F9FB;
  padding: 40px 50px;
  box-shadow: 0 0 4px rgba(0, 0, 0, 0.12);
  z-index: 4;
  position: relative;
  padding: 20px;
  box-sizing: border-box;
  display: flex;
  align-items: center;
}

.filter-drop-inner {
  padding: 15px;
  white-space: normal;
}

.footer-actions button {
  margin-right: 3px;
}
.footer-actions .small {
  padding: 6px 16px;
  font-size: 13px;
}

.filter-dropdown-space {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
}

.help-banner {
  background-color: #edf5fe;
  padding: 30px;
  border-radius: 5px;
  margin: 25px 0 0;
  border: 1px solid rgba(0, 0, 0, 0.07);
  background-color: rgba(0, 0, 0, 0.02);
  padding: 30px;
  margin: 15px 30px 0px;
  border-radius: 5px;
}
.help-banner h1 {
  margin: 0 0 6px;
  font-weight: 600;
  font-size: 23px;
}
.help-banner p {
  margin: 0 0 15px;
  line-height: 24px;
  margin-bottom: 10px;
  font-size: 15px;
  color: rgba(0, 0, 0, 0.5);
}
.help-banner .btn {
  margin-right: 20px;
  padding-left: 18px;
  padding-right: 18px;
}

.fixed-helper {
  max-width: 600px;
  margin: 30px auto;
  text-align: center;
  font-size: 16px;
  color: #9b9b9b;
}
.fixed-helper h1 {
  color: #383838;
}
.fixed-helper h1 i {
  margin-right: 20px;
}

.fixed-separator {
  outline: 0;
  max-width: 80px;
  border: 0;
  border-top: 1px solid #e9e9e9;
  margin: 60px auto;
}

.settings-help {
  padding: 30px 20px;
  box-sizing: border-box;
  width: 315px;
}
.settings-help .top {
  display: flex;
  align-items: center;
  font-size: 29px;
}
.settings-help .top i {
  margin-right: 15px;
  color: #7d92eb;
  border: 1px solid #7d92eb;
  border-radius: 50%;
  padding: 5px;
  width: 18px;
  height: 18px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 18px;
}
.settings-help .top h2 {
  font-size: 22px;
}
.settings-help .settings-help-section {
  font-size: 17px;
  font-weight: 300;
  margin-top: 0;
  display: block;
  margin-bottom: 8px;
}
.settings-help p {
  color: rgba(0, 0, 0, 0.6);
  line-height: 22px;
}

.tab-header {
  box-sizing: border-box;
}
.tab-header .conve-filter-trigger {
  margin: 0;
  padding: 0;
  height: 40px;
  border-radius: 0;
  font-weight: 400;
}
.tab-header .conve-filter-trigger i {
  font-size: 17px;
  color: #7d92eb;
  margin: 0 8px;
}
.tab-header .row {
  display: flex;
  align-items: center;
}
.tab-header .midcol {
  display: flex;
  align-items: center;
  height: 100%;
}
.tab-header .filters-tab {
  background-color: rgba(0, 0, 0, 0.05);
  padding: 8px 20px;
  margin: 0 5px;
  border: 0;
  border-radius: 4px;
}

.panel-detail {
  position: relative;
  display: flex;
  flex: 1 1;
  overflow: hidden;
}

.panel-closer-space {
  position: absolute;
  top: 10px;
  left: 5px;
  z-index: 11;
}

.panel-closer {
  background-color: #fff;
  border-radius: 50%;
  height: 40px;
  width: 40px;
  box-sizing: border-box;
  display: flex;
  align-items: center;
  justify-content: center;
}
.panel-closer i {
  font-size: 22px;
  color: rgba(0, 0, 0, 0.1);
}

.quick-phrases-space {
  border-radius: 2px;
  background-color: #fff;
  box-shadow: 0 0 1px rgba(8, 22, 109, 0.7), 0 7px 17px -5px rgba(34, 39, 64, 0.25);
  padding: 20px 20px;
  display: flex;
  height: 220px;
  margin-bottom: 10px;
}
.quick-phrases-space .left {
  width: 160px;
  display: flex;
  flex-direction: column;
}
.quick-phrases-space .left .quick-phrases-item {
  width: 100px;
}
.quick-phrases-space .right {
  flex: 1 1;
  display: flex;
  flex-direction: column;
  padding-top: 47px;
}
.quick-phrases-space .right .quick-phrases-item {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.quick-phrases-space .quick-phrases-item {
  padding: 7px 15px;
  border-radius: 30px;
  margin-bottom: 2px;
}
.quick-phrases-space .quick-phrases-item:hover {
  background-color: #f6f6f6;
}

.quick-phrase-code {
  font-weight: 600;
}

.quick-phrase-phrase {
  margin-left: 6px;
  max-width: 100%;
  color: rgb(155, 156, 158);
  line-height: 20px;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
}

.quick-phrase-phrase-space {
  display: flex;
}

.simplebar-scrollbar:before {
  background: rgba(0, 0, 0, 0.2) !important;
}

.simplebar-horizontal {
  display: none;
}

.ScrollbarsCustom-Track {
  background-color: #e4e5e6 !important;
  border-radius: 0 !important;
  height: 100% !important;
  top: 0 !important;
}

.ScrollbarsCustom-Thumb {
  background-color: #cdcecf !important;
  border-radius: 0 !important;
}

.info-box {
  border: 1px solid rgba(0, 0, 0, 0.1019607843);
  border-radius: 4px;
  width: 100%;
}
.info-box .editMode {
  background-color: transparent;
}

.info-box-title {
  display: flex;
  border-radius: 4px 4px 0 0;
  align-items: center;
  justify-content: center;
  background-color: #dfe3e6;
  text-align: center;
  padding: 6px 0;
  font-size: 13px;
  font-weight: 500;
}
.info-box-title i {
  margin-right: 10px;
}

.info-box-content {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 10px;
}

.info-separator {
  height: 20px;
  width: 1px;
  background-color: rgba(0, 0, 0, 0.15);
  margin: 0 15px;
}

.agent-not-assign {
  color: #7F8FA4;
}

.agent-assigned {
  color: rgba(0, 0, 0, 0.8);
  display: flex;
  align-items: center;
}
.agent-assigned img {
  border-radius: 50%;
  width: 27px;
  min-width: 27px;
  height: 27px;
  margin-right: 12px;
}

.page-header {
  background-color: #fafafb;
  padding: 55px 50px 30px;
  margin-bottom: 30px;
  min-height: 180px;
  box-sizing: border-box;
  display: flex;
}
.page-header .title {
  font-size: 30px;
  margin: 0 0 20px;
  font-weight: 500;
  color: #1f2b36;
}
.page-header .description {
  font-size: 15px;
  color: rgba(0, 40, 80, 0.45);
  font-weight: 500;
  margin: 0;
  line-height: 1.5;
  display: block;
  max-width: 750px;
}
.page-header .page-header-image {
  width: 60px;
  height: 60px;
  object-fit: cover;
  margin-right: 25px;
}
.page-header .after-description {
  display: block;
  margin-top: 10px;
}
.page-header .action {
  border-left: 1px solid rgba(0, 0, 0, 0.1);
  margin-left: 50px;
  padding-left: 50px;
  height: 50px;
  align-self: center;
}

/* Tags
================================================ */
.editable-tag {
  display: flex;
  align-items: center;
  margin-bottom: 2px;
}
.editable-tag .left {
  position: relative;
  box-sizing: border-box;
  background-color: #8f7ed2;
  color: white;
  border-radius: 4px;
  padding: 9px 15px;
  font-weight: 500;
  width: 200px;
  text-align: left;
  transition: padding 85ms, margin 85ms, width 85ms, box-shadow 85ms;
}
.editable-tag .left:hover {
  box-shadow: 8px 0 rgba(0, 0, 0, 0.15) inset;
  margin-left: 4px;
  width: 196px;
}

.tag-box {
  display: flex;
  align-items: center;
}
.tag-box .drop-trigger:hover {
  background: none;
  overflow: hidden;
}
.tag-box .drop-trigger:hover .tag-box-item:before {
  border-radius: 4px;
  content: "";
  top: 0;
  left: 0;
  position: absolute;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.01);
}

.tag-box-item {
  box-sizing: border-box;
  background-color: #8f7ed2;
  color: white;
  border-radius: 15px;
  padding: 2px 10px;
  font-weight: 500;
  font-size: 12px;
  margin-right: 5px;
  position: relative;
  position: relative;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
}

.tag-colors {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.tag-color {
  width: 24px;
  height: 24px;
  border-radius: 4px;
  position: relative;
  display: inline-flex;
  align-items: center;
  justify-content: center;
}
.tag-color:hover::before {
  position: absolute;
  content: "";
  height: 100%;
  width: 100%;
  background-color: rgba(255, 255, 255, 0.3);
}
.tag-color i {
  margin: auto;
  bottom: 0;
  left: 0;
  right: 0;
  width: 10px;
  color: white;
  font-size: 14px;
  margin-left: 5px;
}

.color-emoji-separator {
  width: 1px;
  height: 30px;
  background-color: rgb(212, 214, 217);
  margin: 0 15px;
}

.sidebar {
  background-color: #f9f9f9;
  background-color: #f3f2f1;
  border-right: 1px solid #edebe9;
  z-index: 9;
  position: relative;
  width: 235px;
  height: 100%;
  display: flex;
  flex-direction: column;
}

.sidebar-inner {
  display: flex;
  flex-direction: column;
  padding-top: 10px;
}

.sidebar-item {
  flex: 1 1 auto;
  display: flex;
  align-items: center;
  height: 36px;
  padding: 0 12px;
}
.sidebar-item > .name {
  margin: 0 8px;
  flex: 1 1;
  margin-right: 30px;
}
.sidebar-item:hover {
  background-color: #ffffff !important;
}
.sidebar-item i,
.sidebar-item .icon {
  display: flex;
  align-items: center;
  width: 24px;
  height: 24px;
  text-align: center;
  justify-content: center;
  font-size: 16px;
}
.sidebar-item i svg,
.sidebar-item .icon svg {
  width: 16px;
  height: 16px;
}
.sidebar-item .unread_count {
  margin-left: auto;
  padding: 0;
  padding-left: 5px;
  margin-right: 18px;
  min-width: 15px;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  text-align: right;
  font-size: 0.81rem;
  color: #808080;
  font-weight: 400;
  line-height: 1.6;
}
.sidebar-item.active {
  color: #6474be;
  background-color: #ebebeb !important;
}
.sidebar-item.active .name {
  font-weight: 700;
}
.sidebar-item.active .unread_count {
  color: #202020;
}
.sidebar-item .right {
  position: absolute;
  right: 15px;
}
.sidebar-item .drop-trigger {
  visibility: hidden;
  width: 24px;
  height: 24px;
}
.sidebar-item .drop-trigger i {
  color: #424242;
  margin-top: 3px;
  font-size: 18px;
}
.sidebar-item .drop-trigger:hover {
  background-color: transparent;
}
.sidebar-item:hover .unread_count {
  display: none;
}
.sidebar-item:hover .drop-trigger {
  visibility: visible;
}

.side-add-button {
  display: flex;
  flex: 0 0 auto;
  padding: 8px 0 8px 12px;
  align-items: center;
  text-align: left;
}
.side-add-button .title {
  width: 100%;
  font-size: 0.81rem;
  color: #808080;
  font-weight: 700;
  line-height: 1.6;
}
.side-add-button .side-add-icon {
  flex: 0 0 auto;
  width: 24px;
  height: 24px;
  margin-right: 10px;
  background: url(/static/media/plus.acfd5adc.svg) no-repeat;
  background-position: center;
}
.side-add-button:hover .title {
  text-decoration: underline;
}
.side-add-button:hover .side-add-icon {
  background: url(/static/media/plus_hover.7736c851.svg) no-repeat;
  background-position: center;
}

.resize-line div {
  cursor: e-resize !important;
  width: 13px !important;
  right: -13px !important;
  z-index: 9;
}

.resize-line-custom {
  height: 100%;
}
.resize-line-custom .line {
  display: block;
  height: 100%;
  width: 2px;
  background-color: transparent;
  margin-left: 10px;
  transition: background-color 300ms ease-in-out;
}
.resize-line-custom .line:before {
  content: " ";
  background: linear-gradient(to left, rgba(0, 0, 0, 0.03) 0px, rgba(0, 0, 0, 0.03) 1px, rgba(0, 0, 0, 0.02) 1px, rgba(0, 0, 0, 0) 100%);
  width: 10px;
  height: 100%;
  position: absolute;
  right: 10px;
  opacity: 0;
  transition: opacity 300ms cubic-bezier(0.2, 0, 0, 1);
}

.resizing .line:before {
  opacity: 1;
}

.resisable-trigger {
  width: 24px;
  height: 24px;
  position: absolute;
  top: 53px;
  z-index: 999;
  background-color: white;
  border-radius: 50%;
  right: -13px;
  opacity: 0;
  box-shadow: 0 0 0 1px rgba(9, 30, 66, 0.08), 0 2px 4px 1px rgba(9, 30, 66, 0.08);
  transition: background-color 100ms linear, color 100ms linear, opacity 300ms cubic-bezier(0.2, 0, 0, 1), transform 300ms cubic-bezier(0.2, 0, 0, 1);
}
.resisable-trigger:hover {
  background-color: #4c9aff;
  color: white;
}
.resisable-trigger:active {
  background-color: #2684ff;
  color: white;
}

.sidebar.show-resize .resize-line-custom .line {
  background-color: rgb(76, 154, 255);
}
.sidebar.show-resize .resisable-trigger {
  opacity: 1;
}

.workspace-trigger {
  height: auto;
  display: flex;
  width: 100%;
  font-weight: 400;
  padding: 4px;
  width: 100%;
  max-width: unset;
  font-size: 15px;
  color: #fff;
  font-weight: 400;
  padding-left: 6px;
}

.normal-trigger {
  height: 30px;
  display: flex;
  width: 100%;
  font-weight: 400;
  padding: 4px;
  width: 100%;
  max-width: unset;
  font-size: 15px;
  color: #42526e;
  font-weight: 400;
  height: 100%;
  font-weight: 600;
  flex: 1 1;
  padding: 4px 12px 5px;
  font-size: 14px;
  line-height: 17px;
  overflow: hidden;
  border-radius: 100px;
}
.normal-trigger:hover {
  background-color: #eaeaea;
}
.normal-trigger:hover .name {
  color: rgb(0, 69, 120) !important;
}

.workspace-dropdown {
  flex: 1 1;
  margin: 0px 0 0px;
}
.workspace-dropdown .menu-dropdown > a {
  width: 100%;
}
.workspace-dropdown .menu {
  width: 100% !important;
}

.sidebar-header {
  height: 60px;
  display: flex;
  align-items: center;
}

.workspace-trigger {
  padding-left: 11px;
  padding-right: 10px;
}
.workspace-trigger .left {
  align-items: center;
}
.workspace-trigger .left img {
  width: 32px;
  height: 32px;
  margin-right: 10px;
  object-fit: cover;
}
.workspace-trigger .workspace-info {
  display: flex;
  flex-direction: column;
  justify-content: center;
}
.workspace-trigger .name {
  font-size: 14px;
  font-weight: 600;
}
.workspace-trigger .description {
  font-size: 12px;
  opacity: 0.4;
  display: none;
}
.workspace-trigger .fa-angle-down {
  transition: all 0.2s ease-in 0s;
  width: 20px;
  text-align: center;
  font-size: 19px;
  line-height: 1;
  color: #8d8c8c;
}

.normal-trigger .left img {
  width: 20px;
  height: 20px;
  margin-right: 10px;
  border-radius: 50%;
  object-fit: cover;
  margin-left: -4px;
}
.normal-trigger .workspace-info {
  display: flex;
  flex-direction: column;
  justify-content: center;
}
.normal-trigger .name {
  font-size: 14px;
  font-weight: 400;
}
.normal-trigger .description {
  font-size: 12px;
  opacity: 0.4;
  display: none;
}
.normal-trigger .fa-angle-down {
  transition: all 0.2s ease-in 0s;
  width: 20px;
  text-align: center;
  font-size: 19px;
  line-height: 1;
  color: #8d8c8c;
}
.normal-trigger .cell-avatar {
  margin-right: 10px;
}

.sidebar-section {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 6px 7px 6px 15px;
}
.sidebar-section .title {
  font-size: 12px;
  font-weight: 600;
  color: rgb(119, 120, 122);
}

.sidebar-collapse {
  display: flex;
  flex-direction: column;
  margin-bottom: 16px;
}
.sidebar-collapse .top {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 6px 7px 6px 10px;
}
.sidebar-collapse .title {
  font-size: 12px;
  font-weight: 700;
  color: #77787a;
  flex: 1 1;
  text-align: start;
  display: flex;
  align-items: center;
  color: rgba(25, 23, 17, 0.6);
}
.sidebar-collapse .title svg {
  margin-top: 1px;
  margin-left: 3px;
  opacity: 0;
}
.sidebar-collapse .title:hover {
  color: rgb(62, 62, 64);
}
.sidebar-collapse .title:hover svg {
  opacity: 1;
}

.sidebar-separator {
  width: 100%;
  border-top: 1px solid #e8eaed;
  margin: 7px 0;
}

.dragging-segment {
  z-index: 999;
}

.sorting .settings-tab:hover {
  background-color: transparent;
}

.sidebar-section-title {
  padding: 6px 16px 2px 16px;
  font-size: 0.875rem;
  color: #808080;
  font-weight: 600;
  line-height: 1.7;
}

.settings-tab.inbox-side-item {
  font-size: 14px;
  padding-top: 8px;
  padding-bottom: 8px;
  max-height: 32px;
}
.settings-tab.inbox-side-item i {
  font-size: 14px;
  width: 20px;
  margin-right: 8px;
  margin-left: -6px;
  text-align: center;
}
.settings-tab.inbox-side-item img,
.settings-tab.inbox-side-item .default-letter {
  width: 16px;
  height: 16px;
  object-fit: cover;
  border-radius: 50%;
  margin-left: -4px;
  margin-right: 11px;
}

.settings-tab.go-back-item {
  font-size: 16px;
  max-height: 42px;
}

.vertical-tabs .fa-plus-circle {
  opacity: 0;
  font-size: 14px;
}
.vertical-tabs:hover .fa-plus-circle {
  opacity: 1;
  transition: opacity 0.15;
}

.sidebar.dark-theme .vertical-tabs {
  background-color: #36393f;
  color: white;
}
.sidebar.dark-theme i {
  opacity: 0.6;
}
.sidebar.dark-theme .fa-plus-circle {
  opacity: 0;
}
.sidebar.dark-theme .vertical-tabs:hover .fa-plus-circle {
  opacity: 1;
}
.sidebar.dark-theme .drop-trigger:hover,
.sidebar.dark-theme .settings-tab:hover {
  background-color: rgba(255, 255, 255, 0.08);
}
.sidebar.dark-theme .settings-tab.active {
  background-color: rgba(114, 118, 125, 0.4);
  color: #ffffff;
}
.sidebar.dark-theme .settings-tab.active .name,
.sidebar.dark-theme .settings-tab.active i,
.sidebar.dark-theme .settings-tab.active image,
.sidebar.dark-theme .settings-tab.active .item-count {
  opacity: 1 !important;
}
.sidebar.dark-theme .settings-tab:hover {
  background-color: #292b2f;
}
.sidebar.dark-theme .drop-trigger:hover i,
.sidebar.dark-theme .drop-trigger:hover .icon-trigger {
  color: #ffffff;
  opacity: 1;
}
.sidebar.dark-theme .select-trigger svg {
  color: white;
}
.sidebar.dark-theme .workspace-info .name {
  opacity: 1;
}
.sidebar.dark-theme .minimized-separator {
  background-color: rgba(255, 255, 255, 0.3);
}
.sidebar.dark-theme .sidebar-collapse .title {
  opacity: 0.6;
  text-transform: uppercase;
  font-size: 10px;
}
.sidebar.dark-theme .sidebar-collapse svg {
  color: #808082 !important;
}

.minimize-side-item {
  display: none;
  margin-top: auto;
  margin-bottom: 10px;
}
.minimize-side-item i {
  margin-left: auto;
  margin-right: 0 !important;
}

.vertical-tabs:hover .minimize-side-item {
  display: block;
}

.minimized-separator {
  width: 100%;
  height: 1px;
  background-color: rgba(0, 0, 0, 0.1);
  display: none;
  margin-bottom: 15px;
}

.sidebar.minimized {
  width: 48px;
}
.sidebar.minimized .sidebar-inner,
.sidebar.minimized .fakes-sidebar, .sidebar.minimized #new-message, .sidebar.minimized #update-inbox {
  display: none;
}

.sidebar.white {
  background-color: white;
}
.sidebar.white .sidebar-item:hover {
  background-color: #f4f4f4 !important;
}

.loading-sidebar-shadows {
  width: 100%;
}
.loading-sidebar-shadows div {
  background-color: rgba(0, 0, 0, 0.05);
  width: calc(100% - 20px);
  height: 18px;
  border-radius: 4px;
  margin: 10px 10px;
}

.settings-side-items {
  padding-bottom: 100px;
}
.settings-side-items i {
  font-size: 17px;
}

.fakes-sidebar {
  padding: 30px 40px 20px 15px;
}
.fakes-sidebar .fake-row {
  margin-bottom: 10px;
  display: flex;
  align-items: center;
  height: 20px;
}
.fakes-sidebar .fake-row .fake-line {
  background-color: #ececec;
  border-radius: 3px;
  height: 8px;
  width: 100%;
}
.fakes-sidebar .fake-row .fake-line.small {
  max-width: 120px;
}
.fakes-sidebar .fake-row .fake-circle {
  margin-left: 20px;
  background-color: #ececec;
  border-radius: 50%;
  min-width: 20px;
  width: 20px;
  height: 20px;
}

.browse-filters-title {
  height: 32px;
  background: #f9f9f9;
  font-size: 0.875rem;
  color: #808080;
  font-weight: 700;
  line-height: 1.7;
  text-align: left;
  padding: 0 10px;
  position: -webkit-sticky;
  position: sticky;
  top: -1px;
  border-bottom: 1px solid #dcdcdc;
  display: flex;
  align-items: center;
  margin-bottom: 10px;
}

.browse-filter-item {
  margin: 5px;
  border: 1px solid #dcdcdc;
  border-radius: 50px;
  padding: 5px 10px;
  display: inline-flex;
  align-items: center;
  height: 31px;
  box-sizing: border-box;
}
.browse-filter-item .name {
  margin-left: 7px;
  font-weight: 500;
}
.browse-filter-item.selected {
  background-color: #ecf0f1;
}
.browse-filter-item.selected .fa {
  color: #3f82ef;
  margin-left: 7px;
}

.browse-filters-section {
  display: flex;
  flex-wrap: wrap;
}

.menu-drop {
  position: relative;
}

.menu-drop-inner {
  display: flex;
  flex-direction: column;
  background-color: white;
  box-shadow: rgba(0, 0, 0, 0.3) 0px 0px 1px, rgba(0, 0, 0, 0.1) 0px 3px 10px;
  overflow: hidden;
  border-radius: 6px;
  max-height: 420px;
  position: absolute;
  z-index: 99;
  width: 100%;
}
.menu-drop-inner .input-space {
  width: 100%;
}

.menu-drop-header {
  display: flex;
  flex-direction: column;
  padding: 0px 12px;
  border-bottom: 1px solid rgb(232, 234, 237);
  align-items: center;
}
.menu-drop-header .title {
  padding: 12px 6px 10px;
  font-weight: 500;
  white-space: nowrap;
  text-align: center;
  text-overflow: ellipsis;
  overflow: hidden;
}

.menu-drop-inner .text-space,
.search.text-space {
  margin: 0;
}
.menu-drop-inner .text-space .kinput,
.search.text-space .kinput {
  color: rgb(25, 25, 26);
  background-color: rgba(0, 0, 0, 0.03);
  -webkit-user-select: text;
     -moz-user-select: text;
      -ms-user-select: text;
          user-select: text;
  border-radius: 4px;
  margin: 12px 0px;
  height: 26px;
  min-height: 26px;
  border: none;
  margin: 0 0 12px;
}
.menu-drop-inner .text-space .kinput input,
.search.text-space .kinput input {
  height: 100%;
  font-size: 13px;
  margin: 0px 8px 2px;
  background-color: transparent;
  box-sizing: border-box;
  padding: 0;
}
.menu-drop-inner .text-space .kinput input::-webkit-input-placeholder, .search.text-space .kinput input::-webkit-input-placeholder {
  color: rgba(0, 0, 0, 0.5);
}
.menu-drop-inner .text-space .kinput input::-ms-input-placeholder, .search.text-space .kinput input::-ms-input-placeholder {
  color: rgba(0, 0, 0, 0.5);
}
.menu-drop-inner .text-space .kinput input::placeholder,
.search.text-space .kinput input::placeholder {
  color: rgba(0, 0, 0, 0.5);
}
.menu-drop-inner .text-space .kinput:focus-within,
.search.text-space .kinput:focus-within {
  background-color: white;
  box-shadow: #7d92eb 0px 0px 0px 1px, #7d92eb 0px 0px 0px 1px inset !important;
  border: none;
}

.menu-drop-content {
  padding: 7px 0;
}

.menu-drop-item {
  display: flex;
  align-items: center;
  min-height: 30px;
}
.menu-drop-item .left {
  margin-right: 8px;
}
.menu-drop-item:hover, .menu-drop-item.focused {
  background-color: rgba(93, 105, 133, 0.1);
}
.menu-drop-item.selected .name {
  font-weight: 600;
}

.menu-drop-item-inner {
  padding: 0 12px;
  display: flex;
  align-items: center;
  flex: 1 1;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
}
.menu-drop-item-inner .selected-icon {
  margin-left: auto;
  color: #6474be;
  font-size: 11px;
}
.menu-drop-item-inner .name {
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
}
.menu-drop-item-inner .cell-avatar {
  margin-right: 8px;
}

.menu-item-with-avatar {
  display: flex;
  align-items: center;
}
.menu-item-with-avatar img {
  width: 20px;
  height: 20px;
  border-radius: 50%;
  margin-right: 8px;
  object-fit: cover;
}
.menu-item-with-avatar.not-assigned i {
  margin: 0 10px 0 0px;
  width: 25px;
  height: 25px;
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
}

.drop-trigger {
  border-radius: 4px;
  overflow: hidden;
  box-sizing: border-box;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}
.drop-trigger:hover {
  background-color: rgba(93, 105, 133, 0.1);
}
.drop-trigger:hover i,
.drop-trigger:hover .icon-trigger {
  color: rgb(119, 120, 122);
}

.select-trigger {
  display: flex;
  align-items: center;
  box-sizing: border-box;
  width: 100%;
}
.select-trigger .left {
  display: flex;
  align-items: center;
  flex: 1 1;
}
.select-trigger img {
  border-radius: 50%;
}
.select-trigger svg {
  margin-left: 7px;
  opacity: 0.4;
}

.menu-drop-separator {
  width: 100%;
  border-top: 1px solid #e8eaed;
  margin: 7px 0;
}

.icon-trigger {
  padding: 3px;
  width: 16px;
  height: 16px;
  font-size: 12px;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  color: rgb(187, 189, 191);
}

.drop-icon-add,
.drop-icon-tag {
  color: #6474be;
  background-color: rgb(224, 237, 255);
  border-radius: 50%;
  width: 20px;
  height: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.drop-icon-tag {
  background-color: #fff;
  font-size: 13px;
}
.drop-icon-tag :before {
  margin-top: 2px;
}

.emoji-trigger {
  font-size: 20px;
  padding: 9px;
  width: 36px;
  height: 36px;
  box-sizing: border-box;
  border-radius: 4px;
}
.emoji-trigger.bordered {
  border: 1px solid rgb(212, 214, 217);
}
.emoji-trigger span {
  margin-top: -2px;
}
.emoji-trigger.selected {
  background-color: rgb(241, 246, 253);
  border-color: rgb(190, 215, 250);
}

.emoji-mart-scroll {
  height: 200px !important;
  padding: 0px 6px 6px 6px;
}

.emoji-mart {
  border: none !important;
}

.emoji-mart-search {
  border-bottom: 1px solid #d9d9d9;
  padding: 0 10px;
}
.emoji-mart-search > input {
  background-color: rgb(247, 248, 250);
  border: none;
  margin: 12px 0px;
}
.emoji-mart-search > input:focus-within {
  box-shadow: #6474be 0px 0px 0px 1px, #6474be 0px 0px 0px 1px inset;
  background-color: white;
}

.emoji-mart-category-label {
  font-size: 13px;
  position: relative;
  color: rgb(119, 120, 122);
  margin: 12px 0 2px;
}

.emoji-mart-search-icon {
  display: none;
}

.emoji-mart-bar:last-child {
  border-top: 1px solid #d9d9d9;
}

.emoji-box {
  position: relative;
}
.emoji-box > .fa-check {
  background-color: #7d92eb;
  border-radius: 50%;
  position: absolute;
  top: -3px;
  right: -7px;
  color: white;
  font-size: 10px;
  width: 15px;
  height: 15px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 10px;
}

.emoji-mart-emoji span {
  display: block;
}

.menu-drop-section-title {
  color: rgb(155, 156, 158);
  font-size: 13px;
  font-weight: 600;
  padding: 9px 12px 6px;
}

.drop-trigger .medium {
  width: 18px;
  height: 18px;
  font-size: 16px;
  padding: 6px;
  box-sizing: content-box;
}

.drop-trigger .big {
  width: 20px;
  height: 20px;
  font-size: 20px;
  padding: 4px;
  box-sizing: content-box;
}

.drop-trigger .svg {
  background-repeat: no-repeat;
  background-position: center;
  opacity: 0.54;
}

.drop-trigger:hover .svg {
  opacity: 1;
}

.actions {
  display: flex;
  align-items: center;
}
.actions .drop-trigger {
  margin-right: 7px;
}

.select-dropdown-space .drop-trigger:hover {
  background-color: transparent;
}

.ellipse {
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
  width: 100%;
}

.chat-side {
  background: #fff;
  height: 100%;
  border-left: 1px solid #edebe9;
  display: flex;
  flex-direction: column;
  overflow: hidden;
}

.chat-side-header {
  border-bottom: 1px solid #edebe9;
  display: flex;
  align-items: center;
  height: 48px;
  box-sizing: border-box;
  width: 100%;
  padding: 20px;
}
.chat-side-header > h3 {
  color: rgb(119, 120, 122);
  font-size: 16px;
  font-weight: 500;
}
.chat-side-header .drop-trigger:hover {
  background: transparent;
}

.chat-side-trigger {
  font-size: 15px;
  font-weight: 600;
}
.chat-side-trigger .left {
  justify-content: center;
  flex: 0 1;
}

.chat-side-item {
  border-top: 1px solid #edebe9;
}
.chat-side-item .chat-side-item-title {
  display: block;
  padding: 9px 20px;
  font-weight: 600;
  background-color: #f4f4f4;
}
.chat-side-item .chat-side-item-title i {
  margin-right: 10px;
}

.chat-side-item-inner {
  border-top: 1px solid #eee;
  background-color: white;
}

.chat-side-inner {
  overflow: auto;
  flex: 1 1;
}

.email-unverified .chat-side-inner {
  height: calc(100vh - 136px);
}

.plugin-dialog .modal-footer {
  border: none;
}

.not-selected-plugin {
  font-style: italic;
  font-size: 14px;
  color: #bdbdbd;
  line-height: 1.3;
  margin: 20px 10px 10px;
  display: block;
  text-align: center;
}

.contact-call-card-wrapper {
  border-bottom: 1px solid #ececec;
}
.contact-call-card-wrapper .merge-btn {
  padding: 0 20px 10px 20px;
  margin-top: 5px;
}

.contact-call-card {
  display: flex;
  justify-content: space-between;
  padding: 10px 20px 10px 20px;
  width: 100%;
  text-align: left;
  font-size: 13px;
  box-sizing: border-box;
  line-height: 20px;
}
.contact-call-card:hover {
  background-color: rgba(0, 0, 0, 0.05);
}
.contact-call-card .right {
  color: #c7c7c7;
  font-size: 12px;
}
.contact-call-card i {
  color: #546e7a;
  margin-right: 8px;
}
.contact-call-card .more-left img {
  width: 20px;
  margin-top: 9px;
  margin-right: 13px;
}
.contact-call-card.channels .right i {
  margin-top: 10px;
  font-size: 15px;
  color: #f1c40f;
}
.contact-call-card.channels .right.finished i {
  color: #2ecc71;
}
.contact-call-card.channels.active .top {
  font-weight: 900;
  color: #6474be;
}

.merge-box {
  padding: 20px;
  font-size: 14px;
}
.merge-box p {
  opacity: 0.7;
  margin-bottom: 10px;
}

.chat.viewOnly .chat-header-inner .right, .chat.viewOnly .drop-trigger, .chat.viewOnly .chat-header-separator {
  display: none;
}
.chat.viewOnly .chat-actions-box, .chat.viewOnly .finished-chat-footer {
  display: none;
}
.chat.viewOnly .messages {
  height: 500px;
}
.chat.viewOnly .chat-inner-layout .right {
  display: none;
}
.chat.viewOnly .context-header-button {
  display: none;
}

.closed-conversation-banner.protocol {
  background-color: #30336b;
  color: white;
}
.closed-conversation-banner.protocol i {
  color: white;
}

.contact-property {
  margin-bottom: 10px;
  position: relative;
  display: flex;
  justify-content: space-between;
}
.contact-property:hover .action-hover {
  opacity: 1;
}
.contact-property .right {
  margin-top: 12px;
}

.contact-property-label {
  font-weight: 700;
  font-size: 10px;
  color: #c7c7c7;
  text-transform: uppercase;
}

.contact-property-value {
  font-weight: 400;
  font-size: 13px;
  color: #303133;
}

.add-prop {
  display: flex;
  align-items: center;
}
.add-prop i {
  margin-top: 0px;
  margin-right: 5px;
}

.contact-medias {
  display: flex;
  flex-wrap: wrap;
}

.contact-media-item {
  width: 25%;
  height: 60px;
  max-width: 70px;
  display: inline-block;
  box-sizing: border-box;
}
.contact-media-item img {
  object-fit: cover;
  width: 100%;
  height: 100%;
}

.top-header {
  flex: 0 0 auto;
  width: 100%;
  height: 40px;
  background-color: #7d92eb;
  display: flex;
  justify-content: space-between;
  align-items: center;
  z-index: 10;
}
.top-header > section {
  display: flex;
  align-items: center;
  height: 100%;
}

.header-link {
  position: relative;
  display: flex;
  align-items: center;
  height: 40px;
  margin-left: 26px;
  box-sizing: border-box;
  font-size: 0.75rem;
  color: rgba(255, 255, 255, 0.7);
  font-weight: 400;
}
.header-link i {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 24px;
  height: 24px;
  font-size: 18px;
  margin-right: 8px;
}
.header-link:first-child {
  margin-left: 12px;
}
.header-link:hover {
  color: white;
}
.header-link.active {
  color: white;
}
.header-link.active::after {
  display: block;
  content: "";
  height: 2px;
  background-color: white;
  border-radius: 2px;
  margin-top: -2px;
  width: 100%;
  bottom: 0;
  position: absolute;
}
.header-link .unread {
  position: absolute;
  top: 7px;
  left: 16px;
  display: block;
  width: 8px;
  height: 8px;
  border: 1px solid #3f82ef;
  border-radius: 5px;
  background-color: #ffb200;
}

.user-menu-drop-title {
  display: flex;
  align-items: center;
  padding: 12px 20px;
}
.user-menu-drop-title .user-name {
  margin-left: 15px;
}
.user-menu-drop-title:hover {
  background-color: rgba(93, 105, 133, 0.1);
}

.user-menu-drop-inner .title {
  width: 100%;
  padding: 0;
}
.user-menu-drop-inner .menu-drop-header {
  padding: 0;
}
.user-menu-drop-inner .menu-drop-item-inner {
  padding: 0 20px;
}

.user-menu-drop {
  height: 100%;
  padding: 0 11px 0 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 80px 0 0 80px;
}
.user-menu-drop:hover {
  background-color: rgba(0, 0, 0, 0.3);
}
.user-menu-drop .user-menu-drop-trigger {
  display: flex;
  align-items: center;
  color: white;
}
.user-menu-drop .user-menu-drop-trigger .workspace-name {
  margin-right: 10px;
}
.user-menu-drop .user-menu-drop-trigger .cell-avatar {
  margin-right: 0;
}

.header-fakes {
  display: flex;
  align-items: center;
  margin: 0 15px;
  opacity: 0.24;
}
.header-fakes .icon-fake {
  width: 18px;
  height: 18px;
  background-color: #fff;
  border-radius: 3px;
  margin-right: 10px;
}
.header-fakes .line-fake {
  width: 50px;
  height: 7px;
  background-color: #fff;
  border-radius: 2px;
  margin-right: 30px;
}

.chat-side-nav {
  border-left: 1px solid #edebe9;
  width: 50px;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-top: 10px;
}
.chat-side-nav.active {
  background: #fff;
  border-left: 1px solid #edebe9;
}

.chat-side-nav-item {
  width: 30px;
  height: 30px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 15px;
  outline: none;
  transition: transform 150ms ease 0s;
  margin-bottom: 15px;
}
.chat-side-nav-item i {
  color: #9b9c9e;
  font-size: 19px;
}
.chat-side-nav-item:hover {
  background-color: rgba(93, 105, 133, 0.1);
}
.chat-side-nav-item.active {
  box-shadow: rgba(0, 0, 0, 0.3) 0px 2px 9px;
}
.chat-side-nav-item.active:hover {
  transform: scale(1.1);
}
.chat-side-nav-item.active i {
  color: white !important;
}
.chat-side-nav-item .plugin-icon {
  font-weight: 800;
  font-style: normal;
  text-transform: uppercase;
}

.tooltip-custom {
  max-width: 400px;
  background-color: rgb(25, 25, 26);
  box-shadow: rgba(0, 0, 0, 0.15) 0px 2px 4px;
  color: rgb(255, 255, 255);
  font-size: 12px;
  font-weight: 400;
  line-height: 16px;
  padding: 6px 10px;
  border-radius: 4px;
}

.context-header {
  background-color: rgb(244, 244, 244);
  width: 100%;
  display: flex;
  align-items: center;
  padding: 0 16px;
  box-sizing: border-box;
  min-height: 40px;
  justify-content: space-between;
}
.context-header > div {
  display: flex;
  align-items: center;
}

.context-header-button {
  background-color: transparent;
  border: none;
  height: 40px;
  border-radius: 0;
  font-weight: 400;
  padding: 0 12px;
  flex-shrink: 0;
}
.context-header-button i {
  color: #7d92eb;
}
.context-header-button:hover {
  background-color: rgb(234, 234, 234);
  color: rgb(33, 33, 33);
}

.context-side-panel {
  width: 0;
  height: 100%;
  box-sizing: border-box;
  overflow: hidden;
  transition: 367ms cubic-bezier(0.1, 0.9, 0.2, 1);
}
.context-side-panel.open {
  width: 315px;
}

.banner-notification-space {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  margin: 0 auto;
  z-index: 10;
}

.banner-notification {
  height: 36px;
  display: flex;
  justify-content: center;
  margin: 0 auto;
  text-align: center;
  text-overflow: ellipsis;
  white-space: nowrap;
  font-weight: 500;
  font-size: 14px;
  color: #fff;
  border-radius: 0 0 6px 6px;
  max-width: 750px;
  align-items: center;
  -webkit-animation: slide-down 0.3s forwards;
          animation: slide-down 0.3s forwards;
  font-size: 15px;
}
.banner-notification.warning {
  background: #e3b200;
  box-shadow: 0 1px 5px 0 rgba(121, 95, 4, 0.21);
}
.banner-notification a {
  color: white;
  text-decoration: underline;
  margin-left: 10px;
}

@-webkit-keyframes slide-down {
  0% {
    transform: translateY(-36px);
  }
  100% {
    transform: translateY(0);
  }
}

@keyframes slide-down {
  0% {
    transform: translateY(-36px);
  }
  100% {
    transform: translateY(0);
  }
}
.collapse {
  border: 1px solid rgba(0, 0, 0, 0.3);
}
.collapse .top {
  background-color: rgba(0, 0, 0, 0.05);
}
.collapse .top button {
  padding: 0px 10px;
  height: 30px;
  font-size: 14px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
}
.collapse > section {
  padding: 15px 25px 25px;
}

.collapse.disabled {
  border-color: #f3f2f1;
}
.collapse.disabled button.title {
  background-color: #f8f8f8;
  color: rgba(33, 33, 33, 0.6);
}

.landing-space {
  font-family: "Be Vietnam Pro", sans-serif;
  overflow: hidden;
}
.landing-space .midcol {
  max-width: 1130px;
}

.landing-header {
  position: fixed;
  top: 0;
  left: 0;
  padding: 22px 0 16px;
  width: 100%;
  border-bottom: 1px solid #7d92eb;
  background-color: #7d92eb;
  z-index: 999;
  transition: border-bottom-color 0.3s cubic-bezier(0, 0, 0, 1);
  transition: background 0.1s;
}
.landing-header img {
  height: 35px;
}

.landing-logo {
  background-image: url(/static/media/kimochat-logo.5dff3e8c.png);
  min-width: 50px;
  height: 42px;
  background-repeat: no-repeat;
  background-position: left center;
  background-size: contain;
}

.landing-header.sticky .landing-logo,
.login .landing-logo {
  background-image: url(/static/media/kimochat-logo.5dff3e8c.png);
}

.login .landing-logo {
  margin: 18px;
  background-size: contain;
  background-image: url(/static/media/kimochat-logo.5dff3e8c.png);
  width: 50px;
  height: 40px;
  position: fixed;
  -webkit-filter: grayscale(1);
          filter: grayscale(1);
  opacity: 0.5;
}

.landing-header .midcol {
  display: flex;
  align-items: center;
  justify-content: space-between;
  color: white;
}
.landing-header .midcol a {
  color: white;
  border-color: white;
  font-weight: 500;
  font-size: 18px;
  margin-left: 40px;
  display: flex;
  align-items: center;
}
.landing-header .midcol h1 {
  margin: 0;
  font-size: 32px;
}
.landing-header .midcol .left {
  display: flex;
  align-items: flex-end;
}
.landing-header .midcol .right {
  display: flex;
}

.landing-header.sticky {
  border-bottom: 1px solid #ebebeb;
  background-color: white;
}
.landing-header.sticky a {
  color: #2a2a2a;
}
.landing-header.sticky .landing-register-btn {
  background-color: #7d92eb;
  color: white;
  box-shadow: 0 0 0 1px rgba(0, 0, 0, 0.02), 0 1px 5px 0 rgba(100, 136, 204, 0.06), 0 4px 15px 0 rgba(0, 0, 0, 0.07);
  border: 1px solid #8aadfe;
}
.landing-header.sticky .landing-register-btn:hover {
  color: white !important;
  background-color: #8aadfe;
}

.landing-top {
  height: 580px;
  background-color: #7d92eb;
  display: flex;
  align-items: center;
  justify-content: center;
}
.landing-top .midcol {
  display: flex;
  align-items: flex-start;
  justify-content: center;
}
.landing-top .top {
  color: #fff;
  text-align: center;
  width: 100%;
  margin-top: 210px;
}
.landing-top .top .slogan {
  font-size: 45px;
  font-weight: 700;
  line-height: 1.2;
  margin-bottom: 30px;
  letter-spacing: -1px;
}
.landing-top .top .app-description {
  font-size: 16px;
  line-height: 2;
  max-width: 530px;
  margin: 60px auto 0;
}
.landing-top .top .emoji {
  width: 55px;
  height: 40px;
  margin-bottom: -4px;
  display: inline-block;
  background: url("https://emojipedia-us.s3.dualstack.us-west-1.amazonaws.com/thumbs/160/emojidex/112/flag-for-brazil_1f1e7-1f1f7.png") no-repeat center;
  background-size: 100%;
}
.landing-top .top img {
  max-width: 1050px;
}
.landing-top .landing-typist {
  display: inline;
}

.bg-secondary {
  background-color: #E2F4FB;
}

.flex-wrapper {
  display: flex;
  justify-content: space-between;
  padding: 80px 0;
  grid-gap: 32px;
  gap: 32px;
}

.landing-space .btn {
  font-size: 16px;
  padding: 17px 34px;
  font-weight: 600;
  border: none;
  border-radius: 45px;
  margin-left: -50px;
  letter-spacing: 0.5px;
}
.landing-space .btn:hover {
  border: none;
  background-color: #8aadfe;
}

.register-email-space {
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 20px;
}
.register-email-space .btn {
  background-color: #6474be;
  color: white;
}
.register-email-space .input-space {
  flex: 0 1;
  border-radius: 50px;
}
.register-email-space .kinput,
.register-email-space .kinput input {
  min-height: 52px;
  height: 52px;
  background-color: #fff;
  font-weight: 300;
  font-family: sans-serif;
}
.register-email-space .kinput:focus-within,
.register-email-space .kinput input:focus-within {
  background-color: #fff;
  box-shadow: none !important;
}
.register-email-space .kinput {
  width: 480px;
  border-color: #ccc;
  border-radius: 50px;
}
.register-email-space .kinput input {
  padding-top: 2px;
  padding-left: 18px;
}
.register-email-space .kinput input::-webkit-input-placeholder {
  color: rgba(0, 0, 0, 0.4);
}
.register-email-space .kinput input::-ms-input-placeholder {
  color: rgba(0, 0, 0, 0.4);
}
.register-email-space .kinput input::placeholder {
  color: rgba(0, 0, 0, 0.4);
}

.mid-text {
  font-size: 32px;
  letter-spacing: -1px;
}

.small-text {
  font-size: 18px;
  letter-spacing: -1px;
}

.app-screen-main {
  display: flex;
  justify-content: center;
}
.app-screen-main img {
  width: 900px;
  height: 600px;
  box-shadow: 0 0 40px 0 rgba(0, 0, 0, 0.1);
}

.landing-section {
  padding: 90px 0 0;
  text-align: center;
  align-items: center;
}
.landing-section h2 {
  font-size: 40px;
  font-weight: 600;
  margin-bottom: 7px;
  line-height: 50px;
  max-width: 960px;
}
.landing-section h3 {
  font-size: 16.7px;
  line-height: 31px;
  font-weight: 400;
  max-width: 900px;
  color: #6c7c93;
  margin-top: 10px;
}
.landing-section .center {
  display: flex;
  flex-direction: column;
  align-items: center;
}
.landing-section img {
  object-fit: cover;
}

.bottom-offset {
  margin-bottom: 80px;
}

.landing-channels {
  display: flex;
  align-items: center;
  margin-top: 40px;
  flex-wrap: wrap;
}

.landing-channel {
  background-color: #00bf54;
  border-radius: 50%;
  width: 60px;
  height: 60px;
  box-sizing: border-box;
  color: white;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 8px;
}
.landing-channel i {
  font-size: 28px;
  transition: transform 0.1s;
}
.landing-channel img {
  width: 60px;
  height: 60px;
  border-radius: 12px;
}
.landing-channel .landing-channel-description {
  margin-top: 10px;
  font-size: 16px;
}
.landing-channel:hover i {
  transform: scale(1.2);
}

.landing-section.sideImage {
  display: flex;
}
.landing-section.sideImage.short .midcol {
  display: flex;
  align-items: center;
  justify-content: center;
}

.landing-section .info {
  margin-left: 150px;
  text-align: left;
  width: 450px;
}

.landing-section.sideImageRight {
  display: flex;
  justify-content: center;
}
.landing-section.sideImageRight .info {
  margin-left: 0;
  margin-right: 150px;
}

.landing-space .footer {
  background-color: #E2F4FB;
  font-size: 16px;
  padding: 70px 20px 40px;
}
.landing-space .footer a {
  color: #1B1A1A;
}
.landing-space .footer a:hover {
  color: black !important;
}
.landing-space .footer .midcol {
  display: flex;
  justify-content: space-evenly;
}
.landing-space .footer img {
  width: 120px;
}
.landing-space .footer .col {
  display: flex;
  flex-direction: column;
  width: 230px;
  text-align: left;
}
.landing-space .footer .col-item {
  margin-bottom: 22px;
}
.landing-space .footer .col-item:hover {
  color: #7d92eb;
}
.landing-space .footer .col-title {
  color: rgb(51, 51, 51);
  font-weight: 700;
  font-size: 16px;
  margin-bottom: 32px;
}
.landing-space .footer .fab {
  opacity: 0.5;
  font-size: 22px;
}
.landing-space .footer strong {
  color: rgb(51, 51, 51);
}
.landing-space .footer .address {
  display: flex;
  flex-direction: column;
  line-height: 22px;
  font-weight: 300;
}
.landing-space .footer .rodape {
  font-size: 14px;
  text-align: center;
  margin-top: 60px;
}

.landing-login-btn {
  color: #333;
  font-size: 16px;
  font-weight: 400;
  margin-right: 20px;
  padding: 6px 24px;
  border-radius: 35px;
}
.landing-login-btn:hover {
  color: #595ad4;
  border-color: #595ad4;
}

.landing-register-btn {
  font-size: 18px;
  font-weight: 500;
  border: 1px solid #333;
  border-radius: 50px;
  padding: 6px 20px;
  margin-left: 5px !important;
}
.landing-register-btn:hover {
  background-color: white;
  color: #7d92eb !important;
}

.auth-header {
  width: 100vw;
  background-color: #000;
  height: 70px;
  border: none;
  left: 0;
  top: 0;
  position: fixed;
}
.auth-header a {
  display: none;
}

.page.login {
  background-color: #fff;
}

.login-box {
  background-color: #fff;
}

.login-page-inner {
  padding: 0;
  height: 100%;
  justify-content: center;
}

.landing-space .companies .company {
  opacity: 0.5;
  -webkit-filter: grayscale(100%);
          filter: grayscale(100%);
  -webkit-filter: gray;
          filter: gray;
  transition: all 0.6s ease;
  width: 160px;
  margin-right: 20px;
  margin: 20px 20px 20px 0;
}
.landing-space .companies .company:hover {
  -webkit-filter: grayscale(0%);
          filter: grayscale(0%);
  opacity: 1;
}

.arrow-lottie {
  position: absolute;
  right: 181px;
  bottom: -58px;
  transform: rotate(-25deg);
}

.left.integrations {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  flex: 1 1;
}

.integration-circle {
  width: 65px;
  height: 65px;
  border-radius: 50%;
  padding: 24px;
  /* box-shadow: 0 4px 14px 0 rgba(0, 0, 0, 0.15); */
  background-color: rgb(249, 249, 249);
  display: flex;
  align-items: center;
  justify-content: center;
}
.integration-circle img {
  width: 100%;
  height: 100%;
  object-fit: contain;
}

.integration-channel-landing {
  box-shadow: 0 2px 16px 0 rgba(0, 0, 0, 0.2);
  margin: 16px;
  width: 20%;
  display: flex;
  justify-content: center;
  align-items: center;
}
.integration-channel-landing img {
  object-fit: contain;
  width: calc(100% - 20px);
  height: 110px;
}

.landing-integrations {
  display: flex;
  align-items: center;
  justify-content: center;
}

.arrow-lottie.arrow-footer {
  position: relative;
  transform: rotate(-97deg);
  right: 0px;
  bottom: 21px;
}

.test {
  border-radius: 4px;
  color: white !important;
  /* color: #00ac39!important; */
}

.Cursor {
  font-weight: 300;
  -webkit-animation: blink3 0.8s linear infinite !important;
          animation: blink3 0.8s linear infinite !important;
}

@-webkit-keyframes blink3 {
  0% {
    opacity: 0;
  }
  50% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}

@keyframes blink3 {
  0% {
    opacity: 0;
  }
  50% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}
@-webkit-keyframes blink {
  0% {
    opacity: 0;
  }
  50% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}
.blink {
  -webkit-animation: blink3 1s linear infinite;
  animation: blink3 1s linear infinite;
}

.landing-channel-space {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin: 0px 40px;
}

.landing-screenshot-space {
  display: flex;
}
.landing-screenshot-space img {
  width: 100%;
}
.landing-screenshot-space .left {
  display: flex;
  align-items: center;
  background-color: #E2F4FB;
  padding: 16px;
}
.landing-screenshot-space .right .screens-list {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100%;
}

.landing-screen-button {
  display: flex;
  flex: 1 1;
  align-items: center;
  justify-content: center;
  color: #1d1d1d;
  background: 0 0;
  text-decoration: none;
  border-radius: 4px;
  border: none;
  padding: 16px;
  width: 230px;
  font-size: 18px;
  letter-spacing: -0.5px;
}
.landing-screen-button.active {
  transition: all 420ms cubic-bezier(0.165, 0.84, 0.44, 1);
  outline: 0;
  background-color: #E2F4FB;
  font-weight: 700;
  border-radius: 0px 20px 20px 0px;
}

.landing-button-connect {
  border-radius: 4px;
  padding: 18px 26px;
  border: 1px solid #7d92eb;
  color: #7d92eb;
  margin-top: 35px;
  width: 370px;
  font-weight: 500;
  box-sizing: border-box;
}
.landing-button-connect:hover {
  color: white;
  background-color: #7d92eb;
}
.landing-button-connect.register-btn {
  background-color: white;
  font-weight: 600;
  font-size: 18px;
  color: #7d92eb;
  transition: transform 0.05s;
  margin-top: 25px;
  margin-bottom: 15px;
}
.landing-button-connect.register-btn:hover {
  transform: scale(1.1);
}

.landing-features-section {
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 100%;
}
.landing-features-section .row {
  display: flex;
  justify-content: space-between;
}

.landing-feature-card {
  display: flex;
  flex-direction: column;
  text-align: left;
  width: 300px;
  height: 264px;
  margin: 20px 0;
  padding: 32px 19px 16px;
  border-radius: 20px;
  background-color: #fff;
  box-sizing: border-box;
  box-shadow: 0px 0px 100px -5px rgba(0, 0, 0, 0.1);
}
.landing-feature-card img {
  max-width: 39px;
  max-height: 39px;
  margin-bottom: 25px;
  color: #7d92eb;
}
.landing-feature-card .name {
  font-size: 18px;
  font-weight: 700;
  margin-bottom: 10px;
  color: #2a2a2a;
}
.landing-feature-card .description {
  line-height: 24px;
  font-size: 16px;
}

.features-list {
  text-align: left;
  font-size: 18px;
  display: flex;
  flex-direction: column;
  grid-gap: 20px;
  gap: 20px;
  margin-top: 20px;
}
.features-list li {
  list-style: disc;
}
.features-list li span {
  font-size: 14px;
}

.landing-section.companies .center {
  display: flex;
  flex-direction: row;
  justify-content: center;
}
.landing-section.companies .center .left {
  text-align: left;
  margin-right: 100px;
}
.landing-section.companies .companies {
  background-color: #edf1ff;
  margin-top: -40px;
  border-radius: 4px;
  padding-left: 60px;
  padding-right: 60px;
}
.landing-section.companies .companies .row {
  display: flex;
}

.landing-section.blue {
  background-color: #7d92eb;
  color: white;
}

.landing-header-navigation {
  display: flex;
  margin-left: 60px;
  margin-bottom: 6px;
}

.landing-menu-hoverable {
  height: 100%;
  width: 420px;
  padding-top: 20px;
  position: absolute;
  margin-left: -30px;
  display: none;
}

.landing-drop-menu {
  display: none;
  opacity: 0;
  box-shadow: 0 14px 74px 0 rgba(0, 0, 0, 0.25);
  background: #fff;
  border-radius: 5px;
  -webkit-animation-name: fadeInUp;
          animation-name: fadeInUp;
  animation-duration: 0.3s;
  animation-fill-mode: both;
  -webkit-animation-duration: 0.3s;
  -webkit-animation-fill-mode: both;
  color: #4e5e71;
  padding: 30px;
  width: 420px;
  box-sizing: border-box;
}
.landing-drop-menu a {
  display: flex;
  align-items: flex-start;
  margin: 0 0 28px;
}
.landing-drop-menu a:last-child {
  margin-bottom: 0;
}
.landing-drop-menu .info {
  display: flex;
  flex-direction: column;
  color: #4e5e71;
  margin-top: -3px;
}
.landing-drop-menu .title {
  font-weight: 700;
  font-size: 17px;
}
.landing-drop-menu .description {
  font-weight: 300;
  font-size: 15px;
}
.landing-drop-menu i {
  width: 40px;
  height: 40px;
  margin-right: 20px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  min-width: 40px;
  color: white;
  transition: transform 0.1s;
  font-size: 20px;
}
.landing-drop-menu a:hover i {
  transform: scale(1.1);
}
.landing-drop-menu .landing-arrow {
  position: absolute;
  top: 4px;
  left: 102px;
}
.landing-drop-menu .landing-arrow:before {
  border-bottom: 15px solid #fff;
  bottom: 1px;
  z-index: 1;
  content: "";
  border-left: 14px solid transparent;
  border-right: 14px solid transparent;
  width: 0;
  height: 12px;
  position: absolute;
  right: 0;
}
.landing-drop-menu .landing-arrow:after {
  border-bottom: 15px solid #fff;
  bottom: 0;
  z-index: 2;
}

.landing-drop {
  position: relative;
}
.landing-drop a {
  margin-left: 0 !important;
}
.landing-drop:hover .landing-menu-hoverable {
  display: block;
}
.landing-drop:hover .landing-drop-menu,
.landing-drop .landing-menu-hoverable:hover .landing-drop-menu {
  display: flex;
  flex-direction: column;
}

@keyframes fadeInUp {
  from {
    transform: translate3d(0, 40px, 0);
  }
  to {
    transform: translate3d(0, 0, 0);
    opacity: 1;
  }
}
@-webkit-keyframes fadeInUp {
  from {
    transform: translate3d(0, 40px, 0);
  }
  to {
    transform: translate3d(0, 0, 0);
    opacity: 1;
  }
}
.register-box .text-space {
  margin-bottom: 27px;
}
.register-box .label-input {
  letter-spacing: 0.3px;
}

.terms-section {
  text-align: left;
  font-family: Whitney, sans-serif;
}
.terms-section p {
  font-weight: 300;
  font-size: 18px;
  margin: 20px 0;
  letter-spacing: 0.3px;
  line-height: 30px;
}
.terms-section h2 {
  font-size: 20px;
  font-weight: 700;
}

.landing-separator {
  height: 1px;
  background-color: #dae3f0;
  width: 100%;
}

.wpp-inbox {
  width: auto;
  max-width: 100%;
}

.base-platform-wrapper h1 {
  font-size: 45px;
  margin-bottom: 10px;
}
.base-platform-wrapper .wrapper p {
  font-size: 20px;
  margin: 67px 0px;
}
.base-platform-wrapper .wrapper img {
  width: 150px;
}

.green-box {
  background-image: url("https://i.imgur.com/BqQw2Ov.png");
  background-repeat: repeat;
  background-color: #075e54;
  max-width: 100% !important;
  height: 250px;
}

.insta-box {
  height: 250px;
  width: 100%;
  background: linear-gradient(45deg, #f09433 0%, #e6683c 25%, #dc2743 50%, #cc2366 75%, #bc1888 100%);
  max-width: 100% !important;
}

.blue-box {
  background-image: url("https://image.shutterstock.com/image-vector/social-media-minimalist-seamless-pattern-260nw-1169160307.jpg");
  background-color: #0084ff;
  max-width: 100% !important;
  height: 250px;
}

.landing-section .green-box {
  margin-top: 60px;
}
.landing-section .green-box h2 {
  color: white;
}
.landing-section .green-box p {
  color: white;
  font-size: 18px;
}

.sticky .menu-btn {
  color: black;
  margin: 0px 18px;
  font-size: 25px;
}

.menu-btn {
  color: white;
  margin: 0px 18px;
  font-size: 25px;
}

header .wrapper {
  display: flex;
  justify-content: space-between;
}
header .landing-header-navigation {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-left: 0;
}
header .landing-header-navigation a:nth-last-child(1) {
  border: 1px solid white;
  border-radius: 100px;
}
header .landing-header-navigation a {
  color: white;
  font-size: 20px;
  width: 90%;
  text-align: center;
  padding: 10px 0px;
}
header .landing-header-navigation .landing-drop {
  width: 100%;
  text-align: center;
}
header .landing-header-navigation .landing-drop a {
  font-size: 20px;
}
header .landing-menu-hoverable {
  height: 100%;
  padding-top: 20px;
  position: absolute;
  margin-left: 13px;
  display: none;
}
header .landing-menu-hoverable .landing-drop-menu {
  width: 350px;
}
header .landing-menu-hoverable .landing-drop-menu .landing-arrow {
  left: 186px;
}
header .landing-menu-hoverable .landing-arrow::before {
  right: 0px;
}

.landing-header.sticky header .landing-header-navigation .round {
  border: 1px solid #3780ee;
  background-color: #3780ee;
  color: #fff;
  border-radius: 100px;
}

.menu-shadow {
  box-shadow: 0 1px 1px 0 rgba(0, 0, 0, 0.14), 0 2px 1px -1px rgba(0, 0, 0, 0.12), 0 1px 3px 0 rgba(0, 0, 0, 0.2);
}

@media only screen and (max-width: 1200px) {
  .bottom-offset {
    margin-bottom: 60px;
  }
  .landing-section {
    padding: 70px 0 0;
  }
  .mid-text {
    font-size: 20px;
    letter-spacing: -1px;
  }
  .small-text {
    font-size: 16px;
    letter-spacing: -1px;
  }
  .register-email-space {
    flex-direction: column;
  }
  .register-email-space .kinput {
    width: 345px;
  }
  .register-email-space .btn {
    margin: auto;
    margin-top: 30px;
  }
  .features-list {
    font-size: 16px;
    grid-gap: 12px;
    gap: 12px;
    max-width: 270px;
    margin-left: 32px;
  }
  .flex-wrapper {
    flex-direction: column;
    padding: 60px 0;
  }
  .landing-screen-button.active {
    border-radius: 10px;
  }
  header .landing-header-navigation a {
    font-size: 16px;
  }
  header .landing-header-navigation .landing-drop a {
    font-size: 16px;
  }
  .base-platform-wrapper h1 {
    font-size: 30px;
    margin-top: 30px;
  }
  .base-platform-wrapper .wrapper p {
    font-size: 16px;
    margin: 40px 0px;
  }
  .base-platform-wrapper .wrapper img {
    width: 100px;
  }
  .landing-section .green-box {
    margin-top: 0;
  }
  .landing-channel-space {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin: 24px 26px;
    flex: 1 1;
  }
  .landing-button-connect {
    width: 290px;
  }
  .landing-features-section .row {
    flex-wrap: wrap;
    justify-content: center;
  }
  .landing-screenshot-space {
    display: flex;
    flex-direction: column-reverse;
  }
  .landing-screenshot-space img {
    width: 100%;
  }
  .landing-screenshot-space .left {
    padding: 10px;
    margin-top: 20px;
  }
  .landing-screenshot-space .right {
    margin-left: 0;
  }
  .landing-screenshot-space .right .screens-list {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
  .landing-screenshot-space .right .screens-list .landing-screen-button {
    width: 300px;
  }
  .landing-space .footer {
    padding: 60px 0 40px;
  }
  .landing-space .footer .midcol {
    justify-content: start;
    flex-wrap: wrap;
  }
  .landing-space .footer .col-title {
    margin-top: 28px;
    margin-bottom: 20px;
  }
  .landing-space .footer .col-item {
    font-size: 15px;
    margin-bottom: 16px;
  }
  .landing-space .footer .col-text {
    font-size: 15px;
  }
  .landing-space .footer .rodape {
    font-size: 12px;
    margin-top: 30px;
  }
  .wpp-inbox {
    width: 100%;
    height: 100%;
  }
  .landing-top {
    height: 375px;
  }
  .landing-top .top {
    margin-top: 120px;
  }
  .landing-top .top .slogan {
    font-size: 30px;
  }
  .landing-top .top img {
    max-width: 100%;
  }
  .pricing-plans {
    flex-wrap: wrap;
  }
  .pricing-plans .princing-plan-card {
    flex: 1 1;
  }
  .pricing-plans .princing-plan-card:not(:first-of-type) {
    margin-top: 40px;
  }
  .princing-plan-card.pro {
    margin-top: 40px;
  }
  .pricing-add-on .top {
    flex-wrap: wrap;
  }
  .princing-faq {
    flex-wrap: wrap;
  }
  .landing-feature-card {
    width: 290px;
    margin: 30px 12px;
  }
  .landing-feature-card .name {
    font-size: 16px;
  }
  .landing-feature-card .description {
    line-height: 20px;
    font-size: 14px;
  }
}
.landing-space .pricing {
  font-family: Whitney, sans-serif;
}
.landing-space .pricing h1 {
  font-weight: 600;
}
.landing-space .pricing h3 {
  font-weight: 300;
  font-size: 20px;
}
.landing-space .pricing .landing-button-connect {
  padding: 12px 0px;
  width: 100%;
  font-size: 17px;
  font-weight: 300;
  margin-top: 20px;
}
.landing-space .pricing .pro .landing-button-connect {
  background-color: #7d92eb;
  color: white;
  box-shadow: 0 0 0 1px rgba(0, 0, 0, 0.02), 0 1px 5px 0 rgba(100, 136, 204, 0.06), 0 4px 15px 0 rgba(0, 0, 0, 0.07);
  border: 1px solid #7d92eb;
}
.landing-space .pricing .pro .landing-button-connect:hover {
  background-color: #6474be;
}
.landing-space .pricing .fal.fa-arrow-down {
  display: block;
  margin: 40px 0 0;
  font-size: 38px;
  color: #367fee;
}

.pricing-midcol {
  max-width: 1300px;
  width: 100%;
  margin: 0 auto;
  padding: 0 20px;
  box-sizing: border-box;
  position: relative;
}

.pricing-plans {
  display: flex;
  justify-content: center;
  align-items: flex-start;
  margin-top: 16px;
}

.princing-plan-card {
  flex-basis: calc(25% - 30px);
  text-align: center;
  background: #fff;
  box-shadow: 0 12px 30px rgba(30, 39, 97, 0.04), 0 2px 14px rgba(19, 24, 57, 0.06);
  border-radius: 24px;
  padding: 55px 22px;
  margin: 0 20px;
}

.pricing-plan-name {
  font-weight: 700;
  font-size: 32px;
  margin-bottom: 16px;
  color: #4d4f51;
  margin-top: 0;
}

.pricing-plan-description {
  color: #888d92;
  line-height: 1.5;
  margin-bottom: 24px;
  font-size: 16px;
  font-weight: 300;
  letter-spacing: 0.7px;
}

.princing-plan-price {
  color: #4d4f51;
  display: flex;
  align-items: center;
  justify-content: center;
}
.princing-plan-price .amount {
  color: #24243b;
  font-weight: 400;
  font-size: 45px;
  display: flex;
}
.princing-plan-price .peruser {
  text-align: left;
  margin-left: 12px;
  font-weight: 300;
  letter-spacing: 0.8px;
  font-size: 16px;
}
.princing-plan-price .dollar-sign {
  font-size: 18px;
  font-weight: 300;
  margin-top: 11px;
  margin-right: 5px;
}

.plan-features {
  display: flex;
  flex-direction: column;
  text-align: left;
}
.plan-features i {
  color: #2ecc71;
  margin-right: 15px;
  font-size: 13px;
}
.plan-features span {
  color: #888d92;
  font-weight: 300;
  font-size: 15px;
}
.plan-features .plan-feature-row {
  margin: 7px;
  letter-spacing: 0.8px;
}

.princing-plan-card.pro {
  border: 2px solid #7d92eb;
}
.princing-faq {
  display: flex;
  justify-content: space-between;
  text-align: left;
}
.princing-faq .faq-item {
  max-width: 470px;
  margin-bottom: 40px;
}
.princing-faq .question {
  font-weight: 600;
  font-size: 18px;
  margin-bottom: 14px;
}
.princing-faq .answer {
  letter-spacing: 0.5px;
  font-size: 17px;
  font-weight: 400;
  color: #6c7c93;
  line-height: 29px;
}

.pricing-add-on {
  border: 2px solid #e8ebee;
  border-radius: 24px;
  padding: 40px;
  text-align: left;
}
.pricing-add-on img {
  width: 50px;
  height: 50px;
}
.pricing-add-on .top {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.pricing-add-on .top .left {
  display: flex;
  align-items: center;
}
.pricing-add-on .top .left i {
  font-size: 40px;
}
.pricing-add-on .top .title {
  font-size: 24px;
  font-weight: 600;
  color: #4d4f51;
  margin-left: 20px;
}
.pricing-add-on .bottom {
  max-width: 500px;
}
.pricing-add-on .add-on-description {
  letter-spacing: 0.5px;
  font-size: 17px;
  font-weight: 300;
  color: #6c7c93;
  line-height: 29px;
}

.page-layout.inbox {
  padding-bottom: 0;
}
.page-layout.inbox .conversations-wrapper {
  width: 100%;
  height: 100%;
  font-size: 12px;
  /* border-left: 1px solid #e7e9ec; */
  border-right: 1px solid #edebe9;
  background-color: #faf9f8;
}
.page-layout.inbox .conversations-wrapper.loading .inbox-conv-inner.first {
  display: none;
}
.page-layout.inbox .conversations-wrapper.loading .inbox-separator {
  display: none;
}
.page-layout.inbox.open .conversations-wrapper {
  display: flex;
  flex-direction: column;
  box-sizing: border-box;
}

.dark-theme ~ .layout-main .conversations-wrapper {
  border-left: none;
}

.conversations-header,
.chat-header {
  padding: 0 12px;
  min-height: 44px;
  box-sizing: border-box;
  z-index: 1;
  width: 100%;
  display: flex;
  align-items: center;
  border-bottom: 1px solid rgb(237, 235, 233);
}

.conversation-separator {
  background-color: #f9f9f9;
  width: 100%;
  height: 12px;
}

.conversations-header-context {
  height: 43px;
  min-height: 43px;
  background-color: #f4f4f4;
  border-bottom: 1px solid #edebe9;
  width: calc(100% + 1px);
}

.conversations-header {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: flex-start;
  padding: 12px;
  height: 110px;
  box-sizing: border-box;
  background-color: white;
  min-height: 48px;
  padding: 12px 16px 12px 28px;
}
.conversations-header .conversation-title {
  margin: 0;
  padding: 0;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  font-size: 1rem;
  color: #202020;
  font-weight: 600;
  line-height: 1.8;
}
.conversations-header .tab-header {
  box-shadow: none;
  background-color: transparent;
  padding: 0;
  width: 100%;
  max-width: 390px;
  margin-top: 0;
}
.conversations-header .row {
  height: 34px;
  margin: 0;
  padding: 0;
  justify-content: space-between;
}
.conversations-header .row .kinput {
  min-height: 30px;
  margin-bottom: 0;
}
.conversations-header .row .kinput input {
  font-size: 14px;
}
.conversations-header .row:last-child {
  justify-content: flex-start;
}
.conversations-header .filters-tab {
  padding: 7px 10px;
  font-size: 13px;
  margin: 0;
  background-color: transparent;
  color: rgba(0, 0, 0, 0.3);
  font-weight: 500;
  margin-top: 7px;
  text-align: center;
  padding: 0;
  margin-right: 25px;
}
.conversations-header .filters-tab:hover {
  color: rgba(0, 0, 0, 0.7);
}
.conversations-header .filters-tab.isActive {
  color: rgba(0, 0, 0, 0.8);
  font-weight: 600;
}
.conversations-header .btn-text {
  align-items: flex-start;
}
.conversations-header .drop-trigger i:first-child {
  transition: none !important;
  width: 25px;
  text-align: right;
}

.search-container {
  display: flex;
  align-items: center;
  grid-gap: 12px;
  gap: 12px;
  width: 100%;
}

.date-filter-button {
  font-size: 24px;
  color: #7d92eb;
  text-align: center !important;
}

.date-filter-button {
  font-size: 24px;
  color: #7d92eb;
  text-align: center !important;
}

.date-filter-button-wrapper {
  display: flex;
  justify-content: end;
  grid-gap: 16px;
  gap: 16px;
}

.date-filter-content {
  padding: 12px;
}
.date-filter-content label {
  color: #202020;
  font-weight: 600;
}

.conversation-item {
  height: 90px;
  display: flex;
  padding: 20px;
  box-sizing: border-box;
  position: relative;
  border-bottom: solid 1px #efefef;
  transition: all 0.3s ease-out;
}
.conversation-item:hover {
  background-color: #f9f9f9;
}
.conversation-item.active {
  background-color: #f2f3f7;
}
.conversation-item .customer-avatar {
  width: 42px;
  height: 42px;
  border-radius: 50%;
  object-fit: cover;
}
.conversation-item .info {
  margin-left: 20px;
  display: flex;
  flex-direction: column;
}
.conversation-item .info .last-message {
  display: flex;
  align-items: center;
  margin: 12px 0 20px 0;
  font-size: 13px;
  color: #7c818b;
}
.conversation-item .info .last-message i {
  font-size: 16px;
  color: #7c818b;
  margin-right: 5px;
  margin-bottom: 3px;
}
.conversation-item .info .name {
  font-size: 14px;
  text-transform: capitalize;
}
.conversation-item .channel {
  display: flex;
  align-items: center;
}
.conversation-item .channel img {
  width: 12px;
  height: 12px;
  margin-right: 6px;
}
.conversation-item .date {
  position: absolute;
  right: 30px;
  color: #90a4ae;
}

.arrow-left {
  width: 0;
  height: 0;
  border-top: 10px solid transparent;
  border-bottom: 10px solid transparent;
  border-right: 10px solid #f2f4fb;
  top: 12px;
  position: absolute;
  left: -7px;
  z-index: 0;
  content: "";
}

.inbox-user-title {
  font-weight: 600;
  margin-left: 17px;
  margin-top: 20px;
  font-size: 16px;
  color: #2a2a2a !important;
}

.inbox-filter-drop .menu-dropdown {
  width: 260px;
}

.inbox-filters-table-wrap .ReactTable .rt-thead.-header {
  display: none;
}

.button-with-count .btn-text {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
}

/* Chat
=================================== */
.chat {
  display: flex;
  flex-direction: column;
  height: 100%;
  flex: 1 1;
  overflow: hidden;
}

.sidebar .context-header {
  padding-left: 0;
  padding-right: 0;
}

.chat .context-header {
  padding-left: 0;
}

.chat .context-header,
.sidebar {
  min-height: 44px;
  border-bottom: 1px solid #edebe9;
}
.chat .context-header .drop-trigger,
.sidebar .drop-trigger {
  border-radius: 0;
}
.chat .context-header .context-header-button,
.sidebar .context-header-button {
  height: 43px;
}
.chat .context-header .normal-trigger,
.sidebar .normal-trigger {
  height: 43px;
  border-radius: 0;
  padding-left: 12px;
}
.chat .context-header .normal-trigger .name-space,
.sidebar .normal-trigger .name-space {
  align-items: center;
  display: flex;
}
.chat .context-header .normal-trigger .name,
.sidebar .normal-trigger .name {
  display: block;
  max-width: 115px;
}
.chat .context-header .normal-trigger i,
.sidebar .normal-trigger i {
  font-size: 17px;
}

.chat-inner-layout {
  display: flex;
  flex: 1 1;
  overflow: hidden;
}
.chat-inner-layout .left {
  flex: 1 1;
  flex-direction: column;
  display: flex;
  overflow: hidden;
  overflow: auto;
}
.chat-inner-layout .right {
  display: flex;
}

.chat-inner {
  flex: 1 1;
  display: flex;
  flex-direction: column;
  position: relative;
  overflow-y: scroll;
  overflow-x: hidden;
}

.chat-midcol {
  box-sizing: border-box;
  margin: 0 auto;
  width: 100%;
}

.chat-header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  background-color: #f4f4f4;
}
.chat-header .left .drop-trigger {
  border-radius: 50%;
  padding: 2px;
}

.chat-header-separator {
  width: 1px;
  box-shadow: inset -1px 0 0 rgba(100, 121, 143, 0.122);
  height: 20px;
  margin: 0 17px 0 5px;
}

.chat-box {
  background-size: 200px 200px;
  position: relative;
  height: 100%;
  display: flex;
  flex-direction: column;
  outline: none;
}

.chat-actions-box {
  display: flex;
  flex-direction: column;
  position: relative;
}
.chat-actions-box .separator {
  border-top: 1px solid #ddd;
  width: 100%;
  height: 1px;
}
.chat-actions-box .send {
  border-radius: 50%;
  width: 30px;
  height: 30px;
  padding: 0;
  display: flex;
  align-items: center;
  justify-content: center;
}
.chat-actions-box .send i {
  font-size: 16px;
}
.chat-actions-box .send[disabled] {
  background-color: rgb(212, 214, 217);
}
.chat-actions-box .send[disabled] i {
  color: rgb(119, 120, 122);
}

.inbox-separator {
  border-top: 1px solid #ddd;
  width: 100%;
  height: 1px;
  margin-top: 20px;
}

.inbox-conv-inner {
  margin-top: -12px;
}

.no-data-conversations {
  margin: 21px 17px 10px;
  padding: 11px 20px;
  border-radius: 12px;
  display: flex;
  align-items: center;
  border: 1px dashed rgba(0, 0, 0, 0.1);
}

.people-data {
  width: 300px;
  background: #f8f8f8;
  height: 100%;
  border-left: 1px solid #f5f5f7;
}

.people-data-inner {
  padding: 25px;
}

.quill {
  font-family: "Segoe UI", "Segoe UI Web (West European)", "Segoe UI", -apple-system, BlinkMacSystemFont, Roboto, "Helvetica Neue", sans-serif;
  border-top: 1px solid #dcdcdc;
  flex: 1 1;
  padding-right: 210px;
  overflow: hidden;
  background-color: white;
  min-height: 64px;
}
.quill .ql-editor {
  padding: 15px 20px;
  font-family: "Segoe UI", "Segoe UI Web (West European)", "Segoe UI", -apple-system, BlinkMacSystemFont, Roboto, "Helvetica Neue", sans-serif;
  max-width: calc(100% - 40px);
}
.quill .ql-editor img {
  display: block;
  margin-bottom: 5px;
  max-height: 100px;
}
.quill .ql-editor.ql-blank::before {
  color: #444352;
  font-family: "Segoe UI", "Segoe UI Web (West European)", "Segoe UI", -apple-system, BlinkMacSystemFont, Roboto, "Helvetica Neue", sans-serif;
  font-style: normal;
  font-size: 14px;
  opacity: 0.5;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  margin-right: 40px;
  max-width: 400px;
}
.quill img {
  max-height: 200px;
}

.blot-file {
  padding: 5px 12px;
  background-color: rgba(0, 0, 0, 0.05);
  border: 2px dashed rgba(0, 0, 0, 0.1);
  margin-bottom: 10px;
}

.isPrivate .quill {
  background: #fff9d9;
  border-color: #f2b161;
}
.isPrivate .quill .ql-editor.ql-blank::before {
  color: #ecbd82;
}

.chat-actions-box .actions-space {
  position: absolute;
  top: 12px;
  right: 20px;
}

.chat-actions-box .actions {
  display: flex;
  align-items: center;
}
.chat-actions-box .actions .action-tab {
  padding: 10px 15px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 14px;
  color: #ababab;
  border-radius: 5px;
}
.chat-actions-box .actions .action-tab.active {
  color: black;
  font-weight: 600;
}
.chat-actions-box .actions .action-tab:hover {
  background: #f5f5f5;
}
.chat-actions-box .actions .action-button {
  border-radius: 3px;
  transition: 0.3s all;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 30px;
  width: 30px;
  margin-right: 2px;
}
.chat-actions-box .actions .action-button i {
  color: #9298a7;
  font-size: 18px;
}
.chat-actions-box .actions .action-button:hover {
  background-color: #f5f5f5;
  color: #5d657a;
}

.channel-indicator {
  padding: 7px 16px 7px 14px;
  top: 13px;
  font-size: 13px;
  background-color: #dbdfe9;
  border-radius: 25px;
  font-weight: 500;
  text-transform: capitalize;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  width: 150px;
  position: absolute;
  transform: translateX(-50%) translateY(0);
  left: 50%;
  text-align: center;
  z-index: 9;
}
.channel-indicator i {
  font-size: 14px;
  margin-right: 7px;
}

.channel-indicator-inner {
  display: flex;
  align-items: center;
  justify-content: center;
}

.chat-balloon {
  display: inline-block;
  line-height: 1.24;
  max-width: 700px;
  word-wrap: break-word;
  position: relative;
  font-size: 14px;
  background-color: #f1f0f0;
  padding: 6px 10px;
  border-radius: 8px;
  min-width: 8px;
}
.chat-balloon:has(img) {
  max-width: 300px;
}
.chat-balloon img {
  cursor: zoom-in;
  max-height: 350px;
  object-fit: cover;
  object-position: top;
  width: 100%;
  border-radius: 4px;
}
.chat-balloon audio {
  border-radius: 4px;
  outline: none;
}
.chat-balloon video {
  border-radius: 4px;
}
.chat-balloon a {
  text-decoration: underline;
}
.chat-balloon .chat-text {
  max-width: 700px;
  word-break: break-word;
  position: relative;
  z-index: 9;
}
.chat-balloon .chat-text span {
  background-color: transparent !important;
}
.chat-balloon .chat-text p {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  flex-wrap: wrap;
}
.chat-balloon .chat-text p:has(img) {
  flex-direction: column;
}
.chat-balloon .chat-text p:has(audio) {
  flex-direction: column;
}
.chat-balloon .chat-text p:has(video) {
  flex-direction: column;
}
.chat-balloon .reply-to {
  background: rgba(0, 0, 0, 0.2);
  padding: 4px 8px 4px 8px;
  border-radius: 10px;
  position: relative;
  margin-bottom: 4px;
  display: flex;
  flex-direction: column;
}
.chat-balloon .reply-to span:first-child {
  font-weight: 600;
  font-size: 13px;
}
.chat-balloon .message-actions {
  position: absolute;
  right: -50px;
  top: 5px;
  display: none;
}
.chat-balloon .message-actions > div {
  display: flex;
  align-items: center;
}
.chat-balloon .message-actions.no-delete {
  right: -25px !important;
}
.chat-balloon .removed-message {
  font-weight: 600;
  color: #202020;
}
.chat-balloon .time {
  margin-bottom: 4px;
  font-size: 10px;
  color: rgba(0, 0, 0, 0.7);
  text-align: right;
  display: block;
  width: 100%;
}

.agentMessage .message-actions {
  left: -50px;
}

.agentMessageWithoutDelete .message-actions {
  left: -30px;
}

.message-box:hover .message-actions {
  display: inline-block;
}

.customer .arrow-left {
  border-right-color: rgb(177, 229, 253);
}

.chatWhatsapp .customer .chat-balloon {
  background-color: rgb(199, 231, 200);
}
.chatWhatsapp .customer .arrow-left {
  border-right-color: rgb(199, 231, 200);
}

.chatEmail .customer .chat-balloon {
  border-width: 1px;
  border-style: solid;
  border-color: rgb(232, 232, 232);
  background-color: #fff;
  border-color: rgba(0, 0, 0, 0.08);
  box-shadow: rgba(0, 0, 0, 0.08) 0px 1px 6px, rgba(0, 0, 0, 0) 0px 4px 30px;
  padding: 12px 16px;
}
.chatEmail .customer .arrow-left {
  border-right-color: rgb(225, 189, 232);
}

.agentMessage .chat-balloon {
  background-color: #7d92eb;
  color: white;
  word-break: break-word;
}
.agentMessage .chat-balloon a {
  background-color: #7d92eb !important;
  color: white !important;
  text-decoration: underline;
  margin: 0 0.3125rem;
}
.agentMessage .chat-balloon span {
  background-color: transparent !important;
  color: white !important;
}
.agentMessage .chat-balloon i {
  position: absolute;
  right: 8px;
  top: 10px;
}

.private .chat-balloon {
  background-color: #fff9d9;
  border: 1px solid #f2b161;
  color: #2a2a2a;
}
.private .chat-balloon a {
  background-color: #fff9d9 !important;
  color: #2a2a2a !important;
  position: relative;
  z-index: 999;
  text-decoration: underline;
}
.private .arrow-left {
  border-right-color: #fff4bb;
}

.system .message-content {
  justify-content: center;
  flex: 1 1;
  margin: 0 !important;
}
.system .chat-balloon {
  background-color: transparent !important;
  color: #546e7a !important;
  padding: 0 8px;
  width: auto;
  white-space: nowrap;
  font-weight: 500;
  font-size: 13px;
  opacity: 0.5;
  font-style: italic;
  box-shadow: none;
  border: none;
  margin: 12px auto;
}
.system .arrow-left {
  border-right: none;
}
.system img {
  display: none;
}
.system .message-author-avatar {
  display: none;
}

.message-box {
  display: flex;
  align-items: flex-start;
}

.sent-status {
  margin-left: 44px;
  color: #949db2;
  font-size: 12px;
  opacity: 0;
}

.messages {
  display: flex;
  margin-top: auto;
  max-height: 100%;
  height: 100%;
}
.messages .messages-inner {
  width: 100%;
  max-height: 100%;
  padding: 0 30px;
  box-sizing: border-box;
}
.messages .message {
  margin-bottom: 40px;
}
.messages .message.system {
  margin-bottom: 20px;
  margin-top: -15px;
}
.messages .message.deleted .chat-balloon {
  background-color: #f7d7d5;
}
.messages .message-content {
  display: flex;
  flex-direction: column;
  position: relative;
}
.messages .message-author,
.messages .message-date {
  font-size: 11px;
  color: #697379;
  font-weight: 400;
  margin-bottom: 4px;
}
.messages .message-date {
  margin-top: 4px;
  margin-left: 20px;
  font-size: 11px;
  color: #949db2;
  margin: 0;
  padding: 0;
  position: absolute;
  top: -22px;
  min-width: 220px;
  font-size: 0.75rem;
  color: #808080;
  font-weight: 400;
  text-transform: uppercase;
  text-transform: initial;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  cursor: default;
}
.messages .message-date .time {
  margin: 0 5px;
}
.messages .message-date i {
  visibility: hidden;
}
.messages .user-name {
  font-size: 0.75rem;
  color: #202020;
  font-weight: 600;
  text-transform: uppercase;
  text-transform: capitalize;
  margin-right: 5px;
}
.messages .message-content:hover .message-date i {
  visibility: unset;
}
.messages .message-author-avatar {
  line-height: 0;
  min-width: 28px;
  margin-top: 2px;
}
.messages .message-author-avatar img {
  width: 28px;
  height: 28px;
  border-radius: 50%;
  margin-right: 8px;
  object-fit: cover;
}
.messages .message-author-avatar .default-letter {
  margin-right: 8px;
}

.message.agentMessage:hover .sent-status,
.last-agent .sent-status {
  opacity: 1;
}

.send-indicator i {
  position: absolute;
  right: 4px;
  bottom: 2px;
  color: #9ba2b4;
  font-size: 12px;
}

.date-separator {
  display: flex;
  align-items: center;
  margin-bottom: 22px;
  opacity: 0.5;
}
.date-separator .line {
  background-image: linear-gradient(90deg, transparent, #c5d0d5);
  width: 50%;
  background-size: 100% 1px;
  background-position: 0 0, 0 100%;
  background-repeat: no-repeat;
  height: 2px;
  display: inline-block;
  opacity: 0;
}
.date-separator .line.right {
  background-image: linear-gradient(90deg, #c5d0d5, transparent);
}
.date-separator .date {
  color: #546e7a;
  padding: 0 8px;
  width: auto;
  white-space: nowrap;
  font-weight: 500;
  font-size: 13px;
  margin: 20px auto;
}

/* CHAT DATA
=================================== */
.people-data-section {
  padding: 0 8px 20px;
  font-size: 14px;
  font-weight: 400;
  overflow-x: hidden;
}

.user-card-basic {
  margin: 0 12px;
  padding: 15px 0 0;
  display: flex;
  flex-direction: column;
}
.user-card-basic .user-image-big {
  width: 100%;
  height: 160px;
  border-radius: 6px;
  object-fit: cover;
  background-color: #eff1f3;
  cursor: zoom-in;
  margin-bottom: 15px;
}
.user-card-basic .user-card-name .contact-property-value {
  font-size: 14px;
  font-weight: 700;
  text-transform: capitalize;
}
.user-card-basic .user-card-name .editable-property {
  padding: 0;
}

.user-card-details {
  font-weight: 400;
  margin: 0 12px;
}

.people-data-row {
  display: flex;
  padding: 6px 0;
}
.people-data-row .left {
  flex: 1 1;
  color: #7c818b;
}
.people-data-row .right {
  flex: 2 1;
  display: flex;
  align-items: center;
  overflow: hidden;
  text-overflow: ellipsis;
  display: block;
}

.people-data-section-title {
  font-size: 12px;
  font-weight: 700;
  text-transform: uppercase;
  line-height: 1;
  color: #2c3b48;
  margin: 20px 15px;
  position: relative;
}

/* Chat Data -> PROPRIEDADES
=================================== */
.add-property {
  position: absolute;
  right: 0;
  top: 0;
}
.add-property button {
  border-radius: 2px;
  box-sizing: border-box;
  padding: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 0;
  background: #c1c8ea;
  height: 24px;
  width: 24px;
}
.add-property i {
  color: #7d92eb;
  font-size: 18px;
}

.isSameUser {
  margin-top: -36px;
}
.isSameUser .message-author-avatar {
  visibility: hidden;
}
.isSameUser .arrow-left {
  display: none;
}
.isSameUser .message-date {
  visibility: hidden;
}

/* Dropzone chat
===================================*/
.chat-drag-active {
  position: absolute;
  z-index: 99;
  background-color: rgba(255, 255, 255, 0.95);
  width: 100%;
  height: 100%;
  top: 0;
}

.chat-drag-active-inner {
  display: flex;
  align-items: center;
  justify-content: center;
  border: 2px dashed #7d92eb;
  height: calc(100% - 50px);
  box-sizing: border-box;
  border-radius: 15px;
  margin: 25px;
}
.chat-drag-active-inner h1 {
  font-size: 25px;
  color: #7d92eb;
}

.attach-file-wrap {
  padding: 12px;
  border-radius: 4px;
  background-color: rgba(0, 0, 0, 0.05);
  border: 2px dashed rgba(0, 0, 0, 0.05);
}
.attach-file-wrap img {
  max-height: 110px;
  object-fit: contain;
}

.attach-file-item {
  padding: 12px;
  background-color: rgba(0, 0, 0, 0.05);
  border-radius: 7px;
  display: flex;
  align-items: center;
  margin-bottom: 10px;
  border: 2px dashed rgba(0, 0, 0, 0.1);
}
.attach-file-item i {
  font-size: 20px;
  margin-right: 12px;
}
.attach-file-item span {
  font-size: 15px;
}

.ql-video {
  width: 498px;
  height: 280px;
}

.ql-image {
  display: block;
}

.image-modal img {
  max-width: calc(100% - 40px);
  margin: 40px 20px;
  max-height: 80dvh;
}

.resolve-space {
  height: 40px;
  background-color: #feca57;
  color: rgba(0, 0, 0, 0.7);
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 16px;
  font-weight: 500;
  border-radius: 0 0 40px 40px;
  position: absolute;
  top: 0;
  z-index: 99;
  width: 100%;
  transition: height 85ms;
}
.resolve-space.resolved {
  background-color: #34bb1f;
  color: white;
}
.resolve-space:hover {
  height: 50px;
}

.user-custom-data {
  border-bottom: 1px solid #f0f0f0;
  padding: 15px 20px;
  margin-top: -35px;
}
.user-custom-data .people-data-row.readable-row {
  flex-direction: column;
}
.user-custom-data .left {
  font-weight: 600;
  font-size: 12px;
  text-transform: capitalize;
  color: black;
}

.tab-badge {
  background-color: rgba(0, 0, 0, 0.05);
  min-width: 8px;
  height: 14px;
  font-size: 10px;
  padding: 1px 4px;
  border-radius: 150px;
  font-weight: 800;
  display: block;
  margin-left: 5px;
}

.compact-user-inbox {
  width: 100%;
}
.compact-user-inbox .details {
  margin-left: auto;
  display: flex;
  flex-direction: column;
}
.compact-user-inbox .details i {
  background-color: #feca57;
  border-radius: 50%;
  width: 20px;
  height: 20px;
  color: white;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 11px;
}
.compact-user-inbox .time {
  margin-bottom: 4px;
  font-size: 12px;
  color: rgba(0, 0, 0, 0.7);
}

.inbox-customer-avatar {
  position: relative;
}

.conversation-channel-indicator {
  width: 18px;
  height: 18px;
  position: absolute;
  bottom: -1px;
  right: 9px;
  /* border: 0px solid #fafafb; */
  border-radius: 50%;
  object-fit: cover;
}

.conversations-wrapper-content {
  height: 100%;
  display: flex;
  flex-direction: column;
}

.conversations-header.bottom {
  border-top: 1px solid rgba(0, 0, 0, 0.1);
  min-height: 60px;
  height: 60px;
  position: relative;
  padding: 0 0;
}
.conversations-header.bottom .tab-header {
  padding: 0;
  margin: 0;
}
.conversations-header.bottom .row {
  padding: 0;
  margin: 0;
}

.ql-container {
  font-size: 14px;
}

.inbox .ReactTable .rt-tbody {
  margin-top: 0;
}

.newMessageBadge {
  display: none;
}

.hasNewMessages {
  position: relative;
}
.hasNewMessages .people-name-email span {
  position: relative;
  font-weight: 800;
  color: black;
}
> .hasNewMessages .people-name-email span:last-child {
  opacity: 1 !important;
  font-weight: 400;
}
.hasNewMessages .people-name-email .people-name {
  display: flex;
}
.hasNewMessages .newMessageBadge {
  text-align: center;
  color: white !important;
  font-size: 11px;
  padding: 0px 6px 3px;
  height: 16px;
  border-radius: 40px;
  margin-left: 5px;
  margin-top: 2px;
  display: none;
  background-color: #feca57;
}

.finished-chat-footer {
  border-top: 1px solid #ececec;
  height: 64px;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  padding: 0 20px;
  box-sizing: border-box;
}

.closed-conversation-banner {
  display: flex;
  padding: 8px 10px 8px 30px;
  background-color: #dfefe1;
  flex-shrink: 0;
  font-size: 0.875rem;
  color: rgb(41, 148, 56);
  font-weight: 400;
  line-height: 1;
  align-items: center;
  height: 40px;
  box-sizing: border-box;
}
.closed-conversation-banner i {
  color: rgb(41, 148, 56);
  font-size: 16px;
  margin-right: 15px;
}

/*
* Conversation Item
======================================= */
.message-item {
  width: 100%;
  flex: 1 1;
  display: flex;
  background-color: white;
  border-bottom: 1px solid #ececec;
}
.message-item:hover {
  background-color: #ececec;
}
.message-item.selected {
  background-color: #e2f0ff;
}
.message-item.selected .message-item-content {
  border-color: #d8e6f4;
}
.message-item .unread-icon i {
  color: rgb(63, 130, 239);
  font-size: 10px;
}
.message-item .what-channel {
  font-size: 11px;
  margin-left: 8px;
  background-color: rgba(0, 0, 0, 0.05);
  padding: 0 5px;
  border-radius: 25px;
  min-width: 14px;
  text-align: center;
  color: rgba(0, 0, 0, 0.3);
}
.message-item .message-item-content {
  display: flex;
  flex: 1 1;
  padding: 10px 20px 10px 0;
  width: calc(100% - 30px);
  box-sizing: border-box;
}
.message-item .mid {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: 12px;
  position: relative;
  width: 48px;
  height: 65px;
}
.message-item .mid img {
  width: 48px;
  height: 48px;
  object-fit: cover;
  border-radius: 50%;
}
.message-item .mid .channel-indicator-conversation {
  position: absolute;
  bottom: 5px;
  right: 0px;
  width: 24px;
  height: 24px;
  background-color: white;
  border-radius: 50%;
  z-index: 1;
}
.message-item .mid .channel-bg {
  background-position: center;
  background-size: cover;
  width: 17px;
  height: 17px;
  display: block;
  z-index: 1;
  margin: auto;
  margin-top: 3px;
  margin-left: 4px;
}
.message-item .left {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 30px;
  min-width: 30px;
}
.message-item .bottom {
  font-size: 0.75rem;
  color: #808080;
  font-weight: 400;
  line-height: 1.8;
  line-height: 1.6;
  overflow: hidden;
  max-height: 42px;
}
.message-item .right {
  flex: 1 1;
  overflow: hidden;
}
.message-item .right .top {
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex: 1 1;
}
.message-item .user-name {
  margin: 0;
  font-size: 0.875rem;
  color: #202020;
  font-weight: 600;
  line-height: 1.7;
  text-transform: capitalize;
}
.message-item .last-response-time {
  font-size: 0.75rem;
  color: #808080;
  font-weight: 400;
  line-height: 1.8;
  margin-left: 7px;
}
.message-item .cell-avatar.default-letter {
  margin: 0;
}
.message-item .more-bottom {
  display: flex;
  align-items: center;
}
.message-item .more-bottom .what-channel {
  margin-left: 0;
}
.message-item.assigned .cell-avatar,
.message-item.assigned .person-avatar-inbox {
  width: 32px !important;
  height: 32px !important;
  min-width: 32px !important;
  margin-top: -22px;
  margin-right: -9px;
  font-size: 19px !important;
}
.message-item.assigned .user-agent {
  position: absolute;
  bottom: 4px;
  left: -6px;
}
.message-item.assigned .user-agent img {
  width: 32px !important;
  height: 32px !important;
  border: 2px solid white;
  background-color: white;
}
.message-item.assigned .channel-indicator-conversation {
  bottom: 7px;
  right: -3px;
  background-color: transparent;
}
.message-item .tag-box-item {
  background-color: rgb(52, 128, 234);
  font-size: 11px;
  padding: 2px 7px;
}
.message-item .finished-icon {
  font-size: 14px;
}

.inbox-conversation-item {
  width: 100%;
  border-bottom: 1px solid rgba(0, 0, 0, 0.04);
  padding: 18px 14px;
  display: flex;
  flex-direction: column;
  box-sizing: border-box;
}
.inbox-conversation-item .top {
  display: flex;
  justify-content: space-between;
}
.inbox-conversation-item .cell-avatar.default-letter {
  margin-right: 8px;
}
.inbox-conversation-item .person-avatar-inbox {
  width: 20px;
  height: 20px;
  border-radius: 50%;
  object-fit: cover;
  margin-right: 8px;
}
.inbox-conversation-item .inbox-channel-indicator {
  text-align: center;
}
.inbox-conversation-item .inbox-channel-indicator img {
  width: 16px;
  height: 16px;
  margin-top: 3px;
}
.inbox-conversation-item .left {
  display: flex;
  flex: 1 1;
  overflow: hidden;
  align-items: center;
}
.inbox-conversation-item .left .col > span {
  color: rgb(62, 62, 64);
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
  font-size: 14px;
}
.inbox-conversation-item .left .col-2 {
  display: flex;
  flex-direction: column;
  margin-left: 12px;
  margin-top: -4px;
  line-height: 1.6;
  margin-right: 10px;
  overflow: hidden;
}
.inbox-conversation-item .user-name {
  flex-shrink: 2;
  font-weight: 600;
  font-size: 13px;
  font-weight: 500;
  opacity: 0.5;
}
.inbox-conversation-item .user-tags {
  display: flex;
  align-items: center;
  margin-top: 5px;
}
.inbox-conversation-item .user-tag {
  display: inline-block;
  color: rgb(242, 131, 11);
  background-color: rgb(255, 247, 240);
  padding: 1px 3px;
  border-radius: 2px;
  border-width: 1px;
  border-style: solid;
  border-color: rgb(250, 218, 185);
  line-height: 12px;
  font-size: 10px;
  font-weight: 600;
  text-transform: uppercase;
  letter-spacing: 0.17px;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
}
.inbox-conversation-item .user-tag i {
  margin-right: 5px;
  font-size: 9px;
  margin-left: 2px;
}
.inbox-conversation-item .user-tag.isFinished {
  color: rgb(16, 170, 64);
  border-color: rgb(16, 170, 64);
  background-color: rgb(238, 251, 243);
}
.inbox-conversation-item span.user-response {
  margin: 7px 0 0;
  overflow: hidden;
  display: flex;
  align-items: flex-start;
  font-size: 14px;
  opacity: 0.5;
}
.inbox-conversation-item .left .col > span.user-channel {
  color: rgb(155, 156, 158);
  font-size: 13px;
  display: flex;
  align-items: center;
}
.inbox-conversation-item .left .col > span.user-channel i {
  font-size: 15px;
  margin-right: 7px;
}
.inbox-conversation-item .last-response-time {
  margin-left: 5px;
}
.inbox-conversation-item .last-response-time,
.inbox-conversation-item .icon-channel {
  color: rgb(155, 156, 158);
}
.inbox-conversation-item .icon-channel {
  width: 18px;
  text-align: center;
  font-size: 14px;
}
.inbox-conversation-item.selected {
  background-color: #7d92eb;
  border-color: #7d92eb;
  color: white;
}
.inbox-conversation-item.selected .left .col > span {
  color: white !important;
}
.inbox-conversation-item.selected .last-response-time,
.inbox-conversation-item.selected .icon-channel {
  color: rgba(255, 255, 255, 0.8);
}
.inbox-conversation-item.selected span.user-response,
.inbox-conversation-item.selected .user-name {
  opacity: 1;
}
.inbox-conversation-item.selected .fa-archive {
  color: white !important;
  opacity: 0.9;
}
.inbox-conversation-item.selected .user-agent {
  color: white;
}
.inbox-conversation-item .right {
  display: flex;
  flex-direction: column;
  align-items: center;
}
.inbox-conversation-item .right i {
  border-radius: 50%;
  width: 15px;
  height: 15px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 15px;
  margin-top: 4px;
}
.inbox-conversation-item .circles {
  display: flex;
  align-items: center;
}
.inbox-conversation-item .user-agent {
  border-radius: 25px;
  border: 1px solid rgba(0, 0, 0, 0.09);
  padding: 2px;
  align-items: center;
  justify-content: center;
  height: 22px;
  box-sizing: border-box;
  display: flex;
  flex-direction: row;
  padding: 2px 5px;
  color: #9b9c9e;
  font-weight: 500;
}
.inbox-conversation-item .user-agent img {
  object-fit: cover;
  border-radius: 50%;
  width: 16px;
  height: 16px;
  z-index: 1;
  margin-right: 7px;
}
.inbox-conversation-item .user-agent .cell-avatar {
  margin-right: 7px;
}
.inbox-conversation-item .more-bottom {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: 7px;
}
.inbox-conversation-item .what-channel {
  border-radius: 25px;
  background-color: rgba(0, 0, 0, 0.05);
  padding: 4px 7px;
  font-weight: 800;
  color: rgba(0, 0, 0, 0.3);
  font-size: 10px;
  text-transform: uppercase;
  font-family: Roboto;
  margin-right: 6px;
  display: flex;
}
.inbox-conversation-item.hasNewMessages .user-name {
  opacity: 0.8;
  font-weight: 700;
}
.inbox-conversation-item.hasNewMessages .user-response {
  opacity: 1;
  font-weight: 500;
}

.empty-inbox-panel {
  display: flex;
  justify-content: center;
  align-items: center;
  flex: 1 1;
  z-index: -1;
}
.empty-inbox-panel .empty-inbox-inner {
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
}
.empty-inbox-panel .empty-inbox-details {
  color: #959da5;
  margin-top: -15px;
}
.empty-inbox-panel .empty-top {
  font-weight: 600;
  font-size: 19px;
  line-height: 29px;
  color: #545c77;
  letter-spacing: 0;
  text-align: center;
}
.empty-inbox-panel .empty-bottom {
  font-weight: 400;
  font-size: 16px;
  color: rgba(84, 92, 119, 0.5);
  letter-spacing: 0;
  text-align: center;
  line-height: 27px;
  max-width: 80vw;
  margin: 5px auto auto;
  max-width: 350px;
}
.empty-inbox-panel .empty-small {
  font-size: 12px;
}
.empty-inbox-panel .empty-image {
  background: url(/static/media/empty-inbox.b3bcf1ab.png);
  background-repeat: no-repeat;
  background-size: contain;
  width: 360px;
  height: 260px;
}

.header-mock-shadow {
  height: 43px;
  width: 100%;
  border-bottom: 1px solid #edebe9;
  background-color: #f4f4f4;
}

.chat-actions-box .actions .drop-trigger {
  margin-right: 7px;
}
.chat-actions-box .actions .icon-trigger {
  width: 20px;
  height: 20px;
  font-size: 20px;
  padding: 4px;
  box-sizing: content-box;
}

.chat-actions-box-inner > form {
  display: flex;
}

.isPrivate .icon-trigger {
  color: #f2b161;
}
.isPrivate .drop-trigger:hover {
  background-color: rgba(196, 149, 48, 0.1);
}
.isPrivate .drop-trigger:hover .icon-trigger {
  color: #f2b161;
}

.message.private .message-box,
.message.agentMessage .message-box {
  flex-direction: row-reverse;
}
.message.private .message-content,
.message.agentMessage .message-content {
  flex-direction: row-reverse;
  margin-right: 8px;
}
.message.private .message-date,
.message.agentMessage .message-date {
  margin-left: 0;
  margin-right: 0;
  flex-direction: row-reverse;
}
.message.private .message-author-avatar img,
.message.agentMessage .message-author-avatar img {
  margin-right: 0;
}
.message.private:hover .message-date,
.message.agentMessage:hover .message-date {
  opacity: 1;
}

.chat-header {
  width: 100%;
}
.chat-header .title-name {
  font-size: 1rem;
  color: #202020;
  font-weight: 600;
  line-height: 1.8;
}
.chat-header .icon-trigger {
  color: rgb(155, 156, 158);
}
.chat-header .normal-trigger {
  height: 24px;
}

.chat-header-inner {
  display: flex;
  flex-direction: column;
  flex: 1 1;
}
.chat-header-inner .top {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
}
.chat-header-inner .top .left .actions {
  flex-wrap: wrap;
}
.chat-header-inner .actions {
  height: 100%;
}
.chat-header-inner .bottom {
  display: flex;
  align-items: center;
}
.chat-header-inner .right {
  display: flex;
  max-height: 34px;
  flex: 0 0 auto;
}
.chat-header-inner > .left {
  display: flex;
  flex-direction: column;
}
.chat-header-inner .drop-trigger {
  margin-right: 12px;
}
.chat-header-inner .drop-trigger .icon-trigger {
  color: #808080;
}
.chat-header-inner .drop-trigger:hover .icon-trigger {
  color: #202020;
}
.chat-header-inner .normal-trigger {
  padding: 0px 10px 0px 10px;
  height: 26px;
}
.chat-header-inner .normal-trigger i {
  margin-right: 7px;
}
.chat-header-inner .normal-trigger .left,
.chat-header-inner .normal-trigger .name {
  font-size: 12px;
  color: #808080;
  font-weight: 400;
}
.chat-header-inner .normal-trigger > svg {
  display: none;
}
.chat-header-inner .tag-box {
  flex-wrap: wrap;
  margin-top: 7px;
}
.chat-header-inner .tag-box .menu-drop {
  margin-bottom: 2px;
}
.chat-header-inner .tag-box .drop-trigger {
  margin-right: 0;
}
.chat-header-inner .tag-box-item {
  margin-right: 4px;
}

.chat-inner-layout .tag-box {
  flex-wrap: wrap;
}
.chat-inner-layout .tag-box .menu-drop {
  margin-top: 4px;
}

.drop-trigger.resolved i {
  color: #2ecc71 !important;
}
.drop-trigger.resolved:hover i {
  color: #27ae60;
}

.drop-trigger.favorited i {
  color: #ffbf43 !important;
}
.drop-trigger.favorited:hover i {
  color: #ffbf43;
}

.no-conversations,
.loadingCenter.conversation-loading {
  font-weight: 600;
  font-size: 16px;
  display: flex;
  align-items: center;
  justify-content: center;
  height: calc(100vh - 150px);
}

.no-conversations {
  color: #cdd0d4;
  display: flex;
  flex-direction: column;
}

.email-balloon-header {
  font-weight: 700;
  color: black;
  line-height: 21px;
  font-size: 13px;
  margin-bottom: 10px;
}
.email-balloon-header .row span:first-child {
  width: 60px;
  display: inline-block;
}

.chat.viewOnly .messages-inner {
  padding: 0 15px;
}
.chat.viewOnly .tag-box {
  pointer-events: none;
}
.chat.viewOnly .chat-balloon {
  padding: 6px 9px;
}
.chat.viewOnly .title-name {
  margin-right: 50px;
}
.chat.viewOnly .chat-header {
  border-radius: 4px 4px 0 0;
}

.isInbox {
  border-right: 0;
}
.isInbox .sidebar-content {
  border-right: 1px solid #edebe9;
}

.modal-text-editor .chat-actions-box-inner {
  border: 1px solid #dcdcdc;
  border-radius: 3px;
}
.modal-text-editor .chat-actions-box-inner > form {
  flex-direction: column;
}
.modal-text-editor .quill {
  border: 0;
  padding: 0;
}
.modal-text-editor .ql-editor {
  min-height: 100px;
  max-width: 100%;
  padding: 10px 10px;
}
.modal-text-editor .actions-space {
  border-top: 1px solid #dcdcdc;
  padding: 10px 17px;
  position: unset;
}
.modal-text-editor .chat-only {
  display: none;
}
.modal-text-editor .disabled .actions-space {
  display: none;
}
.modal-text-editor .disabled .ql-editor {
  cursor: default !important;
  background-color: #f8f8f8;
  color: rgba(33, 33, 33, 0.6);
}
.modal-text-editor .disabled .chat-actions-box-inner {
  border-color: rgb(243, 242, 241);
  border-radius: 0;
}
.modal-text-editor .disabled .quill .ql-editor.ql-blank::before {
  opacity: 0;
}

.multi-page-modal .modal-text-editor .ql-editor {
  min-height: 166px;
}

.modal-text-editor-files {
  border: 1px solid #dcdcdc;
  border-radius: 3px 3px 0 0;
  border-bottom: 0;
  display: flex;
  align-items: center;
  padding: 5px 10px;
  flex-wrap: wrap;
}
.modal-text-editor-files img {
  max-height: 25px;
  margin-right: 5px;
}
.modal-text-editor-files .attach-file-item {
  padding: 1px 12px;
  border: 1px solid rgba(0, 0, 0, 0.1);
  margin-bottom: 0;
  margin-right: 5px;
}
.modal-text-editor-files .attach-file-item i {
  font-size: 14px;
  margin-right: 8px;
}

canvas.sound-wave {
  width: 100%;
}

.conversation-load-more {
  height: 64px;
}
.conversation-load-more .btn {
  justify-content: center;
  margin: 15px;
  width: calc(100% - 30px);
}
.conversation-load-more.isFetching {
  height: 24px;
}

.reply-message-editor {
  display: flex;
  align-items: center;
  border-top: 1px solid rgba(0, 0, 0, 0.1);
}
.reply-message-editor .chat-text {
  background-color: rgba(0, 0, 0, 0.05);
  padding: 5px 15px;
  border-radius: 10px;
  margin: 20px;
  position: relative;
  flex: 1 1;
}
.reply-message-editor button {
  margin-right: 25px;
}
.reply-message-editor .ql-image {
  max-height: 100px;
}

@media screen and (max-width: 1333px) {
  .chat .context-header .btn-text span {
    display: none;
  }
  .chat .context-header i {
    margin: 0 5px;
  }
}
@media screen and (max-width: 1400px) {
  .chat .context-header .select-trigger .name {
    display: none;
  }
}
@media screen and (max-width: 1265px) {
  .ql-editor.ql-blank::before {
    max-width: 266px !important;
  }
}
.page-layout.settings {
  flex-direction: column;
  padding-left: 15px;
  padding-right: 15px;
  padding-bottom: 60px;
}
.page-layout.settings .title {
  font-size: 14px;
  text-transform: uppercase;
  font-weight: 700;
  margin: 10px 0 12px;
}
.page-layout.settings .settings-section:first-child {
  padding-top: 30px;
}

.page-layout.has-subheader {
  flex-direction: column;
}

.settings-row {
  display: flex;
  flex-wrap: wrap;
}

.settings-card {
  margin: 8px;
  border: 1px solid #f5f5f5;
  border-radius: 5px;
  width: 150px;
  min-height: 150px;
  cursor: pointer;
  transition: box-shadow 0.25s ease, transform 0.25s ease;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  padding: 0 10px;
  box-sizing: border-box;
}
.settings-card img {
  width: 60px;
  height: 60px;
  margin-top: -8px;
  transition-duration: 0.3s;
}
.settings-card .settings-card-title {
  color: #2c3b48;
  font-size: 11px;
  padding-top: 16px;
  text-transform: uppercase;
  line-height: 16px;
  text-align: center;
  font-weight: 500;
}
.settings-card:hover {
  background: #ebebeb;
  font-weight: 500;
}
.settings-card:hover img {
  transform: scale(1.1) rotate(4deg);
}

.settings-section.separator-top {
  margin-top: 30px;
  padding-top: 30px;
}

.settings-section {
  padding: 0 15px;
}
.settings-section:first {
  padding-top: 30px;
}

/* Settings Header
========================== */
.settings-header {
  background-color: #fafafb;
  padding: 40px 50px;
  margin-bottom: 30px;
  border-bottom: 1px solid #dfe3e6;
}
.settings-header .midcol {
  display: flex;
  align-items: center;
}
.settings-header img {
  width: 64px;
  height: 64px;
}
.settings-header .right {
  display: flex;
  flex-direction: column;
  margin-left: 20px;
  margin-top: -10px;
}
.settings-header .more-right {
  margin-left: auto;
  flex-shrink: 0;
  margin-top: -30px;
}
.settings-header .title {
  font-size: 18px;
  margin: 0 0 3px;
  font-weight: 500;
  color: #1f2b36;
  text-transform: uppercase;
}
.settings-header .subtitle {
  font-size: 14px;
  color: #455a64;
  margin: 0;
  line-height: 1.3;
}

.settings-content {
  margin-top: 20px;
}

/* Tabs
========================== */
.settings-tab {
  border-radius: 3px;
  border: none;
  color: #42526e;
  padding: 10px 15px;
  text-align: left;
  width: 100%;
  display: flex;
  align-items: center;
  box-sizing: border-box;
  position: relative;
  padding-top: 8px;
  padding-bottom: 8px;
  max-height: 32px;
  height: 100%;
  font-weight: 400;
  color: white;
}
.settings-tab .right {
  position: absolute;
  right: 15px;
}
.settings-tab .drop-trigger {
  visibility: hidden;
}
.settings-tab .side-more {
  margin-right: 0;
  font-size: 13px;
}
.settings-tab .drop-trigger {
  margin-right: -11px;
  padding: 3px;
}
.settings-tab:hover {
  color: rgb(55, 53, 47);
  background: #e8eaed;
  border: none;
}
.settings-tab:hover .fa-arrow-right {
  opacity: 1 !important;
  -webkit-animation: slide-right 0.3s forwards;
          animation: slide-right 0.3s forwards;
}
.settings-tab:hover .item-count {
  display: none;
}
.settings-tab:hover .drop-trigger {
  visibility: visible;
}
.settings-tab:hover .drop-trigger:hover {
  background-color: transparent;
}
.settings-tab .name {
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
  margin-right: 5px;
  opacity: 0.8;
  text-transform: lowercase;
}
.settings-tab.active {
  color: rgb(55, 53, 47);
  border: none;
  text-align: left;
  background: #e8eaed;
}
.settings-tab.active .name {
  opacity: 1;
}
.settings-tab i {
  margin-right: 22px;
  font-size: 18px;
  opacity: 0.64;
}
.settings-tab .fa-arrow-right {
  opacity: 0;
  margin-left: auto;
  margin-right: 0;
  transform: translateX(-50%);
  font-size: 14px;
}
.settings-tab .fa-arrow-left {
  font-size: 12px !important;
}
.settings-tab.hasCount .name {
  font-weight: 700;
  opacity: 1 !important;
}

.settings-tab.active .item-count {
  background-color: white !important;
}

.tab-vertical-separator {
  height: 1px;
  width: 100%;
  background-color: rgba(0, 0, 0, 0.1);
  margin: 15px 0;
}

.tab-content {
  width: 100%;
  min-width: 500px;
  margin: 25px 30px;
}

.domain-url {
  margin: 8px 0;
  border: 1px solid #eaeaea;
  border-radius: 4px;
  padding: 8px;
  word-break: break-all;
  background: #eaeaea;
  font-family: Menlo, Monaco, Consolas, "Courier New", monospace;
}
.domain-url.light {
  background-color: #f8f8f8;
  border: 0;
  padding: 11px;
}

.key-bg {
  background-color: #f8f8f8;
  padding: 12px 20px;
  box-sizing: border-box;
  margin-bottom: 2px;
  border-radius: 3px;
  font-size: 14px;
  color: rgba(0, 0, 0, 0.6);
}
.to-copy-row {
  display: flex;
  align-items: center;
  margin: 15px 0;
}
.to-copy-row .to-copy-row-title {
  font-size: 14px;
  font-weight: 700;
  margin-right: 14px;
}
.to-copy-row .btn {
  border-radius: 2px;
  font-size: 14px;
}

.copy-block.code .to-copy-row-title {
  font-size: 16px;
  font-weight: 500;
}
.copy-block.step {
  border-bottom: 1px dotted #ccc;
  padding-bottom: 20px;
}

/* Integration Box
========================== */
.integration-box {
  text-align: center;
  border-radius: 5px;
  width: 270px;
  min-height: 250px;
  padding: 40px;
  transition: box-shadow 0.25s ease, transform 0.25s ease;
  overflow: hidden;
  background: #f9fafa;
  display: inline-flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  box-sizing: border-box;
  margin: 10px;
}
.integration-box img {
  max-width: 50px;
  height: 50px;
  transition-duration: 0.3s;
}
.integration-box .title {
  font-size: 14px;
  font-weight: 700;
  margin-top: 25px;
  margin-bottom: 5px;
  color: #2c3b48;
  text-transform: uppercase;
}
.integration-box .subtitle {
  padding-top: 5px;
  font-size: 13px;
  position: relative;
  color: #7c818b;
  line-height: 1.25;
}
.integration-box:hover {
  background: #f5f5f5;
}
.integration-box:hover img {
  transform: scale(1.1) rotate(4deg);
}

.settings-integration-service .service-header {
  position: relative;
}
.settings-integration-service .service-header img {
  max-height: 40px;
  margin-bottom: 20px;
}
.settings-integration-service .service-header .connect-button {
  position: absolute;
  right: 0;
  bottom: 10px;
}

.settings-layout {
  padding: 12px 30px 60px;
  max-width: 800px;
  margin: auto;
}
.settings-layout .form-dialog {
  border: 1px solid #e8e8e8;
  box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.06);
  border-radius: 12px;
  padding: 23px 28px 0;
}
.settings-layout .form-dialog .modal-footer {
  padding: 20px 2px;
  border: none;
}
.settings-layout .form-dialog .modal-body {
  padding: 0;
}

/* Labels
===================================== */
.label-wrapper {
  display: inline-block;
  padding: 5px 15px;
  background-color: #9175c0;
  border-radius: 16px;
  color: white;
}

@-webkit-keyframes slide-right {
  100% {
    transform: translateX(0%);
  }
}

@keyframes slide-right {
  100% {
    transform: translateX(0%);
  }
}
/* Settings Header
===================================== */
.settings-title {
  display: flex;
  align-items: center;
  padding: 10px 15px;
  border-bottom: 1px solid rgba(0, 0, 0, 0.065);
  height: 53px;
  box-sizing: border-box;
}
.settings-title h1,
.settings-title h2 {
  margin: 0;
}
.settings-title h1 {
  font-size: 17px;
  font-weight: 500;
}

.settings-title-badge {
  color: rgba(0, 0, 0, 0.7);
  border-radius: 14px;
  min-width: 12px;
  padding: 3px 8px 0px;
  margin-left: 9px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 12px;
  font-weight: 600;
  border: 1px solid rgba(0, 0, 0, 0.1);
  display: none;
}

.settings-midcol {
  max-width: 600px;
  width: 100%;
  margin: 0 auto;
}

.settings-midcols {
  padding: 0 15px;
  max-width: 600px;
  margin: 0 auto;
}

.theme-preview {
  width: 110px;
  height: 60px;
  border-radius: 6px;
  margin-top: 8px;
  box-shadow: 0 0 0 1px rgba(0, 0, 0, 0.08), 0 1px 3px 0 rgba(0, 0, 0, 0.08);
  box-sizing: border-box;
  overflow: hidden;
  margin-right: 40px;
  margin-bottom: 40px;
  margin-left: 30px;
}
.theme-preview .tp-header {
  background-color: #db4c3f;
  height: 20px;
}
.theme-preview .tp-shadow {
  background-color: rgba(93, 87, 89, 0.3);
  width: 50px;
  height: 4px;
  border-radius: 25px;
}
.theme-preview .tp-line {
  padding: 5px 4px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-radius: 3px;
  margin: 5px;
}
.theme-preview .tp-line.selected {
  background-color: #f4f4f4 !important;
}
.theme-preview .tp-count {
  background-color: orange;
  border-radius: 20px;
  height: 9px;
  width: 15px;
}

.theme-item-preference {
  display: inline-block;
}

.themes-defaults {
  max-width: 600px;
}

.api-page .label-input {
  font-size: 25px;
}

.answer-layout {
  height: 100vh;
  padding-bottom: 0;
}

.answers-space {
  display: flex;
  flex-direction: row !important;
}
.answers-space .answer-left {
  width: 350px;
  background-color: #fafafb;
  height: 100%;
  max-height: 100%;
  border-right: solid 1px #dae4e9;
}
.answers-space .answer-left-header {
  height: 55px;
  display: flex;
  justify-content: space-between;
  background-color: white;
  border-right: solid 1px #dae4e9;
  border-bottom: solid 1px #dae4e9;
  border-top: solid 1px #dae4e9;
  padding: 7px 15px;
  align-items: center;
  box-sizing: border-box;
}
.answers-space .answer-left-header label {
  text-transform: uppercase;
  font-size: 13px;
}
.answers-space .answer-right {
  flex: 1 1;
}

.answer-left-categories {
  display: flex;
  flex-direction: column;
  box-sizing: border-box;
  padding-top: 8px;
}

.answer-right-title-wrap {
  border-bottom: solid 1px #dae4e9;
  background-color: #f8f9fb;
  margin-top: 1px;
  padding: 10px 0;
  margin-bottom: 20px;
}
.answer-right-title-wrap .midcol {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.answer-right-title-wrap .title {
  font-size: 16px;
}

/* Category Card
============================ */
.answer-category-card {
  background-color: #f4f6f7;
  padding: 10px 12px;
  box-sizing: border-box;
  margin: 3px 10px;
  border-radius: 5px;
  border: 1px solid #e5e9f1;
  transition: 0.3s;
  display: flex;
  height: 80px;
  position: relative;
  align-items: center;
}
.answer-category-card:hover {
  background-color: #fff;
}
.answer-category-card.active {
  background-color: #fff;
  box-shadow: 0 4px 10px rgba(36, 37, 38, 0.1);
  font-weight: 700;
}
.answer-category-card img {
  width: 40px;
  height: 40px;
  margin-right: 15px;
}
.answer-category-card .title {
  font-size: 16px;
  color: #2C3B48;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  width: 200px;
  position: relative;
}
.answer-category-card > div {
  display: flex;
  flex-direction: column;
}
.answer-category-card .shared-label {
  display: flex;
  align-items: center;
  align-self: flex-start;
  font-size: 12px;
  margin-top: 5px;
  background-color: #cfd8dc;
  color: #455a64;
  padding: 3px 5px;
  line-height: 1;
  border-radius: 3px;
  margin-right: 5px;
}
.answer-category-card .shared-label i {
  font-size: 14px;
  margin-right: 5px;
}
.answer-category-card .answers-count-wrap {
  position: absolute;
  top: 0;
  bottom: 0;
  right: 20px;
}
.answer-category-card .answers-count {
  background-color: #cfd8dc;
  padding: 4px 8px 3px;
  border-radius: 15px;
  color: #626d8f;
  font-size: 11px;
  font-weight: 600;
  line-height: 11px;
  text-align: center;
  display: block;
  margin-top: 18px;
}

.dash-card .box {
  padding: 0;
}
.dash-card .title {
  padding: 13px 16px;
  font-size: 13px;
  border-bottom: 1px solid #f2f5f7;
}
.dash-card .title span {
  display: flex;
  align-items: center;
  position: relative;
}
.dash-card .title span i {
  margin-right: 8px;
  font-size: 17px;
  color: #7d92eb;
  margin-top: -2px;
}
.dash-card .title .dash-card-select {
  text-transform: none;
  position: absolute;
  right: 0;
}
.dash-card .title .select-space {
  max-width: 250px;
}
.dash-card.graph .title {
  border-bottom: none;
  padding: 19px;
  margin: 0;
}
.dash-card .react-select__control {
  box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.06) !important;
}

.dash-stats {
  display: flex;
}
.dash-stats .dash-stat {
  flex: 1 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-items: center;
  border-right: 1px dashed #e0e5ef;
  padding: 30px 0;
  margin-bottom: 25px;
}
.dash-stats .dash-stat .stat-value {
  font-size: 26px;
  margin-bottom: 10px;
}
.dash-stats .dash-stat .stat-label {
  font-size: 12px;
  color: #6d7d88;
}
.dash-stats .dash-stat:last-child {
  border-right: 0;
}

.dashboard .ReactTable .rt-td {
  margin-bottom: 14px;
}

.dashboard .ReactTable .rt-th > div,
.reports .ReactTable .rt-th > div {
  padding-bottom: 17px;
  font-size: 15px;
}

.graph-members-dash {
  width: calc(100vw - 160px);
}

.business-day-block {
  margin-bottom: 20px;
  background-color: #f8f9fb;
  padding: 20px;
  display: flex;
  flex-direction: column;
}
.business-day-block .day-left {
  width: 150px;
  margin-bottom: 20px;
}
.business-day-block .day-right {
  flex: 1 1;
  display: flex;
  justify-content: center;
  flex-direction: column;
}
.business-day-block .hour-block {
  display: flex;
  align-content: center;
  margin-bottom: 10px;
}
.business-day-block .hour-block .until {
  margin: 0 20px;
}
.business-day-block .hour-block .time-diff {
  margin: 0 30px;
  width: 150px;
}
.business-day-block .hour-block .until,
.business-day-block .hour-block .time-diff,
.business-day-block .hour-block .actions {
  display: flex;
  align-items: center;
}
.business-day-block .hour-block .select-space {
  width: 100px;
}
.business-day-block .hour-block .actions .btn {
  margin-left: 5px;
}
.business-day-block .hour-block .label-input {
  margin-bottom: 5px;
}

.closed {
  font-size: 12px;
  font-weight: 700;
  color: #606f81;
}

.report-table-header {
  color: #6e828c;
  font-weight: 700;
  height: 57px;
  border-radius: 4px;
  background-color: #e1e4e9;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 10px 30px;
  margin: 15px 0 5px;
}
.report-table-header h3 {
  text-transform: uppercase;
  font-size: 14px;
}

.reports .ReactTable .rt-tbody .rt-tr-group {
  min-height: 40px;
}

.report-col {
  flex: 1 1;
  text-align: center;
}
.report-col.bordered {
  border-left: 1px solid #ececec;
  border-right: 1px solid #ececec;
}

.report-stat {
  margin-bottom: 40px;
}

.report-stat-title {
  text-transform: uppercase;
  opacity: 0.4;
  font-weight: 500;
  font-size: 13px;
}

.report-stat-value {
  font-weight: 600;
  font-size: 33px;
}

.pulse {
  display: block;
  width: 8px;
  height: 8px;
  border-radius: 50%;
  background: #2ecc71;
  cursor: pointer;
  box-shadow: 0 0 0 rgba(46, 204, 113, 0.4);
  -webkit-animation: pulse 2s infinite;
          animation: pulse 2s infinite;
}

.pulse:hover {
  -webkit-animation: none;
          animation: none;
}

@-webkit-keyframes pulse {
  0% {
    -webkit-box-shadow: 0 0 0 0 rgba(46, 204, 113, 0.4);
  }
  70% {
    -webkit-box-shadow: 0 0 0 10px rgba(46, 204, 113, 0);
  }
  100% {
    -webkit-box-shadow: 0 0 0 0 rgba(46, 204, 113, 0);
  }
}
@keyframes pulse {
  0% {
    box-shadow: 0 0 0 0 rgba(46, 204, 113, 0.4);
  }
  70% {
    box-shadow: 0 0 0 10px rgba(46, 204, 113, 0);
  }
  100% {
    box-shadow: 0 0 0 0 rgba(46, 204, 113, 0);
  }
}
.page-layout.profile {
  height: 100vh;
  padding-bottom: 0;
}
.page-layout.profile .people-data {
  background-color: transparent;
  position: relative;
  min-width: 380px;
}
.page-layout.profile .people-data .user-card-basic {
  display: flex;
  flex-direction: column;
}
.page-layout.profile .people-data .user-card-basic .user-image-big {
  height: 90px;
  width: 90px;
  margin-bottom: 15px;
  z-index: 1;
  border: 6px solid white;
}
.page-layout.profile .people-data .user-card-basic .user-card-name {
  font-size: 22px;
  color: #2c3b48;
}
.page-layout.profile .people-data-bg {
  background-color: #3498db !important;
  background-size: 200px 200px !important;
  width: 100%;
  position: absolute;
  top: 0;
  left: 0;
  height: 80px;
}
.page-layout.profile .chat-header, .page-layout.profile .people-data-bg {
  border-radius: 5px 5px 0 0;
}
.page-layout.profile .chat-header {
  justify-content: flex-end;
  font-size: 14px;
}
.page-layout.profile .chat-header .select-space {
  margin-left: 10px;
}
.page-layout.profile .chat-box {
  border-radius: 0 0 5px 5px;
  background: #F2F5F7;
}

.people-name-email {
  display: flex;
  flex-direction: column;
  margin-left: 10px;
}
.people-name-email > span:last-child {
  opacity: 0.4 !important;
  font-size: 15px;
  margin-top: 2px;
  max-width: 260px;
  overflow: hidden;
  text-overflow: ellipsis;
}

.compact-user-inbox.active .people-name-email span:first-child {
  font-weight: 500;
}

.segment-title {
  color: #575C62;
  margin-left: 10px;
}

/* Timeline
==================================== */
.timeline-wrap {
  width: 360px;
  min-width: 360px;
  box-sizing: border-box;
}
.timeline-wrap .box-header {
  border-bottom: solid 1px #e8e8e8;
  background-color: #F6F8FA;
  padding: 19px 15px;
  border-radius: 5px 5px 0 0;
}
.timeline-wrap .box-header h3 {
  padding-left: 20px;
  text-transform: none;
  padding-bottom: 0;
  margin: 0;
  border: none;
}
.timeline-wrap .box-space {
  padding: 20px;
  height: auto;
}

.timeline-space {
  box-sizing: border-box;
  position: relative;
}

.timeline-event {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  margin-bottom: 15px;
}

.timeline-date-space {
  display: flex;
}

.timeline-event-date {
  position: relative;
  color: #5D657A;
  border-radius: 24px;
  background-color: #D5E0EF;
  font-weight: 500;
  padding: 3px 15px;
  margin: 0 27px;
  font-size: 12px;
  margin-bottom: 16px;
}

.timeline-event-icon i {
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  color: #fff;
  font-size: 10px;
  border: 2px solid #FFF;
  border-radius: 50%;
  background-color: #e74c3c;
  padding: 4px;
  width: 24px;
  height: 24px;
  box-sizing: border-box;
  border: 4px solid #fff;
  margin: -7px 0 0 10px;
}

.timeline-event-details {
  font-size: 12px;
  color: #1F2B36;
  display: flex;
  flex-direction: column;
  margin-left: 12px;
  word-break: break-all;
}
.timeline-event-details .top {
  margin-bottom: 7px;
}

.timeline-event-time {
  font-size: 12px;
  color: #7C818B;
  width: 97px;
  white-space: nowrap;
  min-width: 50px;
  text-align: right;
}

.timeline-line {
  position: absolute;
  border-right: 2px solid #D5E0EF;
  left: 70px;
  height: 100%;
  top: 0;
}

.timeline-event-content {
  display: flex;
}

.timeline-line-end {
  background: #D5E0EF;
  height: 10px;
  width: 10px;
  border-radius: 50%;
  margin-left: 66px;
  margin-top: 10px;
}

.page-bot {
  display: flex;
  background-color: #fff;
  padding-bottom: 0;
  border-radius: 6px;
}
.page-bot .flow {
  flex: 1 1;
  max-width: 400px;
  flex-direction: column;
  align-items: center;
}
.page-bot .flow .event-box:last-child {
  margin-bottom: 200px;
}
.page-bot .context-header {
  border-bottom: 1px solid rgba(0, 0, 0, 0.1);
}

.page-bot-content {
  display: flex;
  flex: 1 1;
  overflow: hidden;
}
.page-bot-content .bot-sidebar {
  border-right: 1px solid #edebe9;
  z-index: 9;
  position: relative;
  width: 220px;
  height: 100%;
  display: flex;
  overflow-y: auto;
}
.page-bot-content .bot-mid {
  display: flex;
  justify-content: center;
  flex: 1 1;
  padding: 30px 30px;
  background-size: 10px 10px;
  background-image: linear-gradient(to right, rgba(0, 0, 0, 0.02) 1px, transparent 1px), linear-gradient(to bottom, rgba(0, 0, 0, 0.02) 1px, transparent 1px);
  background-color: #f4f4f4;
  overflow-y: auto;
}

.box.event-box-space {
  border-radius: 6px;
  padding: 30px 25px;
  background-color: #fff;
  box-sizing: border-box;
  cursor: move;
  z-index: 5;
}

.event-title {
  font-size: 20px;
  margin-bottom: 20px;
}

.event-options {
  margin-top: 20px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}
.event-options button {
  border-radius: 100px;
  font-weight: 500;
}
.event-options .bot-menu-item {
  display: flex;
  align-items: center;
}
.event-options .bot-menu-item > span {
  margin-left: 15px;
  opacity: 0.45;
}
.event-options .bot-menu-item i {
  margin-right: 7px;
}

/* Bot Header
===================================*/
.bot-header {
  padding: 50px 30px 44px;
  background-color: #fafafb;
  margin-bottom: 30px;
  border-radius: 12px;
  margin-top: 20px;
  display: flex;
}
.bot-header img {
  border-radius: 50%;
  width: 55px;
  height: 55px;
}

.bot-setting-right {
  display: flex;
  align-items: center;
  flex: 1 1;
}
.bot-setting-right .text-space {
  margin-left: 25px;
  width: 400px;
}
.bot-setting-right .text-space .label-input {
  margin-bottom: 0;
  font-size: 12px;
  color: rgba(0, 0, 0, 0.6);
}

/* Path
====================== */
.page-bot .paths {
  display: flex;
  flex-direction: column;
  width: 100%;
  background-color: #fff;
  box-sizing: border-box;
  margin-top: 10px;
}
.page-bot .paths a {
  color: #575c62;
}
.page-bot .paths button {
  background: transparent;
  border: 1px;
  color: #575c62;
  margin: 0px 0;
  border: 1px solid #b2bbbd;
  border-radius: 100px;
  font-weight: 500;
  margin: 10px auto;
  font-size: 12px;
  text-transform: uppercase;
  padding: 5px 16px;
}

.bot-menu-path-link {
  height: 40px;
  padding: 18px 20px;
  box-sizing: border-box;
  display: flex;
  align-items: center;
  font-size: 15px;
  background-color: #fafafb;
  margin-bottom: 10px;
  border-radius: 33px;
}
.bot-menu-path-link i {
  margin-right: 15px;
  font-size: 17px;
}
.bot-menu-path-link:hover {
  background-color: #ececec;
  color: rgb(66, 82, 110);
  fill: rgba(9, 30, 66, 0.04);
  text-decoration: none;
}
.bot-menu-path-link.active {
  border-width: 1px;
  font-weight: 500;
  color: #4793b6 !important;
  background-color: #c6e5f8;
}

/* Event Box
====================== */
.event-box {
  max-width: 400px;
  width: 100%;
  z-index: 5;
}
.event-box .bot-image {
  width: 30px;
  height: 30px;
  border-radius: 50% 4px 50% 50%;
  object-fit: cover;
}
.event-box .bot-image.user {
  border-radius: 4px 50% 50%;
  margin-left: -15px;
}

.event-box-header {
  border-radius: 4px;
  height: 45px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 10px 0 22px;
  font-weight: 500;
  font-size: 16px;
}
.event-box-header i {
  margin-right: 16px;
}

.event-box-space {
  padding: 15px;
}
.event-box-space .clamp-lines {
  max-height: 165px;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2;
}

.event-box-message {
  border-radius: 4px 12px 12px;
  background-color: #7d92eb;
  color: white;
  padding: 12px 17px;
  margin-left: 5px;
}
.event-box-message pre {
  margin: 0;
  font-family: inherit;
}
.event-box-message.user {
  border-radius: 20px 4px 20px 20px;
  background-color: #e8eff7;
  margin-left: auto;
  max-width: calc(100% - 110px);
  margin-right: 5px;
  color: rgba(0, 0, 0, 0.8);
}
.event-box-message.system {
  border-radius: 20px;
  background-color: #e8eff7;
  text-align: center;
  font-weight: 500;
  color: rgba(0, 0, 0, 0.8);
}

.event-box-separator {
  height: 40px;
  width: 8px;
  background-color: rgba(0, 0, 0, 0.08);
  border-radius: 10px;
  margin: 0 auto;
}

/* Menu
================= */
.menu-option {
  display: flex;
  align-items: center;
  margin-bottom: 10px;
}
.menu-option .input-space {
  flex: 1 1;
}
.menu-option .select-space {
  flex: 1 1;
  font-size: 14px;
  margin-left: 10px;
}
.menu-option button {
  margin-left: 10px;
}

/* Integration
================= */
.integration-option {
  margin-bottom: 10px;
}
.integration-option .text-space {
  flex: 2 1;
}
.integration-option .select-space {
  flex: 1 1;
  font-size: 14px;
  margin-left: 10px;
}
.integration-option button {
  margin-left: 10px;
}

/* Preview
================= */
.bot-preview {
  width: 440px;
}

.bot-paths-space {
  position: relative;
  display: flex;
  align-items: center;
}
.bot-paths-space .path-edit {
  opacity: 0;
}
.bot-paths-space:hover .path-edit {
  opacity: 1;
}

.path-grabbing {
  cursor: -webkit-grabbing;
  cursor: grabbing;
  background-color: white;
  box-shadow: 0 5px 5px -5px rgba(0, 0, 0, 0.2), 0 -5px 5px -5px rgba(0, 0, 0, 0.2);
}

.same-line-row {
  display: flex;
  margin-top: 10px;
}
.same-line-row .input-space:first-child {
  margin-right: 20px;
}

.contract-status {
  color: rgba(0, 0, 0, 0.7);
  text-transform: uppercase;
  font-weight: 500;
  padding: 5px 14px;
  border-radius: 20px;
  font-size: 11px;
  background-color: rgba(0, 0, 0, 0.05);
}

.facebook-page {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 12px;
  border: 1px solid rgba(0, 0, 0, 0.1);
  padding: 10px 20px;
  border-radius: 8px;
  width: 100%;
}
.facebook-page .left {
  display: flex;
  align-items: center;
}
.facebook-page img {
  width: 40px;
  height: 40px;
  border-radius: 50%;
  margin-right: 30px;
}
.facebook-page .page-name {
  font-size: 15px;
}
.facebook-page:hover {
  border-color: #cce2ed;
  box-shadow: 0 4px 14px 0 rgba(5, 73, 209, 0.1);
}

.pup-section {
  padding: 40px 50px 30px;
  font-size: 26px;
}

.pup-cards {
  display: flex;
}

.pup-title {
  font-size: 24px;
  margin-bottom: 20px;
}

.pup-card {
  display: inline-flex;
  flex-direction: column;
  justify-content: space-between;
  width: 220px;
  border: 1px solid rgba(0, 0, 0, 0.1);
  border-radius: 12px;
  padding: 20px;
  box-sizing: border-box;
  margin-right: 15px;
  transition: none;
}
.pup-card .top {
  flex: 1 1;
  display: flex;
  flex-direction: column;
}
.pup-card img {
  width: 50px;
  height: 50px;
  border-radius: 14px;
  object-fit: cover;
}
.pup-card .name {
  font-size: 17px;
  font-weight: 500;
  margin: 15px 0 0px;
}
.pup-card .description {
  color: #a2aab6;
  font-size: 15px;
}
.pup-card:hover {
  border-color: #cce2ed;
  box-shadow: 0 4px 14px 0 rgba(5, 73, 209, 0.1);
}
.pup-card.disabled {
  pointer-events: none;
  opacity: 0.3;
}

.integration-status {
  font-size: 16px;
  color: #2ecc71;
  margin-top: 20px;
}
.integration-status i {
  margin-right: 10px;
}

.ganhomais {
  background: linear-gradient(135deg, #0079BF, #5067C5);
  width: 100%;
  height: 100%;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  /* overflow: auto; */
  padding-bottom: 100px;
}
.ganhomais .midcol {
  display: flex;
  justify-content: space-between;
}
.ganhomais .left, .ganhomais .right {
  display: flex;
  flex-direction: column;
  margin-bottom: 40px;
  max-width: 500px;
}
.ganhomais .feature {
  color: rgba(255, 255, 255, 0.8);
  font-size: 17px;
  margin: 5px 0;
}

.valor-total {
  color: white;
  font-size: 50px;
  margin-bottom: 40px;
  text-align: center;
}

.divisao-valores {
  display: flex;
  justify-content: space-between;
}

.value-block {
  color: #f1c40f;
  font-size: 50px;
  margin-bottom: 30px;
  text-align: center;
  display: flex;
  flex-direction: column;
  background-color: rgba(0, 0, 0, 0.05);
  padding: 10px;
  border-radius: 8px;
  min-width: 300px;
}
.value-block .title {
  font-size: 30px;
  color: white;
}

.separator {
  height: 2px;
  background: white;
  width: 100%;
  margin: 30px 0;
}

.rc-slider-rail {
  background-color: rgba(0, 0, 0, 0.3);
  height: 15px;
}

.rc-slider-track {
  height: 15px;
  background-color: #f1c40f;
}

.rc-slider-step {
  height: 9px;
}

.rc-slider-dot-active {
  border-color: #000;
  background-color: #000;
}

.rc-slider-handle {
  border: solid 3px #000;
  background-color: #f39c12;
  margin-top: -9px;
  width: 30px;
  height: 30px;
}

.rc-slider-handle:hover {
  border-color: #fff;
}

.rc-slider-handle:focus {
  border-color: #fff;
  box-shadow: none;
}

.join-avatar {
  width: 100px;
  height: 100px;
  object-fit: cover;
  background-color: #fafafb;
  border-radius: 50%;
}
.join-avatar img {
  width: 100%;
  height: 100%;
  border-radius: 50%;
  object-fit: cover;
}

.join-dashed {
  border-bottom: 2px dashed rgba(0, 0, 0, 0.1);
  width: 100%;
  flex: 1 1;
}

.join-workspace-space {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
.join-workspace-space h3 {
  background-color: #fafafb;
  max-width: 450px;
  padding: 15px 30px;
  box-sizing: border-box;
  border-radius: 8px;
  font-weight: 400;
  line-height: 27px;
  margin-top: 40px;
}
.join-workspace-space h3 span {
  font-weight: 600;
}

.join-workspace-space .top {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 350px;
}
.join-workspace-space .top i {
  font-size: 25px;
  margin: 0 10px;
  background-color: #fafafb;
  padding: 10px;
  border-radius: 50%;
}

.plans {
  display: flex;
  margin: 30px 0 60px;
}

.plan-box {
  border: solid 1px #d3dae4;
  border-radius: 3px;
  margin: 2px;
  display: flex;
  flex-direction: column;
  padding: 15px;
  text-align: center;
  width: 135px;
  margin-right: 20px;
  height: 92px;
  box-sizing: border-box;
  min-width: 170px;
}
.plan-box:hover {
  box-shadow: 0 5px 15px 3px rgba(0, 0, 0, 0.1);
}
.plan-box .period {
  font-size: 18px;
  margin-bottom: 10px;
}
.plan-box .discount {
  color: #2ecc71;
  font-family: sans-serif;
  font-size: 13px;
  margin-bottom: 5px;
}
.plan-box.disabled {
  pointer-events: none;
  opacity: 0.2;
}

.billing-row {
  display: flex;
  align-items: center;
}
.billing-row .left {
  width: 150px;
  margin-right: 30px;
}
.billing-row .left h3 {
  margin-top: 0;
  font-weight: 400;
  font-size: 18px;
  margin-bottom: 10px;
}
.billing-row .left .description {
  color: #a9abb5;
  font-size: 13px;
}
.billing-row .checkbox-space {
  margin-left: 30px;
}
.billing-row .credit {
  color: #a9abb5;
  font-size: 16px;
  margin-left: 15px;
}

.billing-total-per-user {
  font-size: 40px;
  letter-spacing: 1.5px;
  padding: 0 5px;
  font-weight: 300;
}
.billing-total-per-user:before {
  content: "R$";
  position: relative;
  top: -16px;
  font-size: 20px;
}

.billing-separator {
  height: 1px;
  width: 100%;
  background-color: rgba(0, 0, 0, 0.1);
  margin-bottom: 40px;
}

.billing-agents {
  display: flex;
  align-items: center;
  width: 70px;
}
.billing-agents .kinput {
  width: 150px;
}
.billing-agents button.only-icon {
  border: none;
  margin-left: -60px;
  border-left: 1px solid #e0e0e0;
  border-top: 1px solid #e0e0e0;
  border-bottom: 1px solid #e0e0e0;
  font-size: 18px;
  color: #7f7f7f;
  background: #f8f8f8;
  border-radius: 0;
  height: 35px;
  width: 79px;
  max-width: unset;
}
.billing-agents button.only-icon:last-child {
  margin-left: 0px;
  border-right: 1px solid #e0e0e0;
  border-radius: 0 3px 3px 0;
}
.billing-agents button.only-icon i {
  font-size: 15px !important;
}
.billing-agents button.only-icon:active {
  transform: scale(1);
}

.billing-agents.coupon .kinput {
  width: 160px;
}
.billing-agents.coupon button.only-icon:last-child {
  margin-left: -2px;
  margin-top: 11px;
}

.select-number-agents {
  font-weight: 500;
  color: #7f7f7f;
}

.billing-row.vertical .left {
  width: 750px;
  margin-right: 0;
}
.billing-row.vertical .checkbox-space {
  margin-left: 0;
}

.payment-methods {
  display: flex;
  flex-direction: column;
}
.payment-methods h4 {
  font-size: 18px;
  margin-bottom: 20px;
}

.payment-method {
  border: solid 1px #d3dae4;
  border-radius: 3px;
  display: flex;
  padding: 15px;
  text-align: center;
  width: 245px;
  margin-bottom: 10px;
  height: 60px;
  box-sizing: border-box;
  align-items: center;
  font-size: 15px;
}
.payment-method:hover {
  border-color: #2ecc71;
}
.payment-method i {
  margin-right: 15px;
  font-size: 26px;
  width: 30px;
}

.subscription-active-badge {
  background-color: #3498db;
  border-radius: 5px;
  padding: 10px 20px;
  color: #fff;
  margin-bottom: 20px;
  font-weight: 500;
  font-size: 16px;
}
.subscription-active-badge.canceled {
  background-color: #e74c3c;
  color: #fff;
}

.billing-space {
  display: flex;
}
.billing-space > .left {
  flex: 1 1;
  margin-right: 30px;
}
.billing-space > .right {
  max-width: 270px;
  flex: 1 1;
}

.billing-active-plan {
  box-sizing: border-box;
  padding: 10px 20px;
  background: #f6f7f9;
  border-radius: 5px;
  position: relative;
  width: 340px;
}
.billing-active-plan .row {
  display: flex;
  margin-bottom: 15px;
}
.billing-active-plan .row .left {
  margin-right: 0;
  font-weight: 500;
}
.billing-active-plan .row .right {
  min-width: 150px;
}

.invoice {
  background-color: #f5f9fd;
  border-radius: 3px;
  height: 80px;
  padding: 10px 20px;
  box-sizing: border-box;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 10px;
}
.invoice .left {
  display: flex;
  align-items: center;
}
.invoice .invoice-value {
  margin-left: 60px;
  font-size: 18px;
}
.invoice .invoice-info {
  display: flex;
  flex-direction: column;
}
.invoice .invoice-info span:last-child {
  font-size: 12px;
  color: #a9abb5;
}
.invoice .invoice-info span:first-child {
  font-size: 18px;
}

/* INVOICE - PRINT
==============================================*/
.invoice-print-space {
  background: #f0f0f0;
  height: 100vh;
  width: 100vw;
  position: fixed;
  overflow: auto;
}

.invoice-print-box {
  background-color: white;
  box-shadow: 0 1px 5px rgba(0, 0, 0, 0.1), 0 1px 1px rgba(0, 0, 0, 0.1);
  margin: 40px auto 0px;
  padding: 40px;
  width: 750px;
}
.invoice-print-box .top img {
  height: 84px;
  margin-left: -20px;
  width: 240px;
}
.invoice-print-box .top span {
  color: #ccc;
  float: right;
  font-size: 48px;
  line-height: 84px;
  font-weight: 800;
}
.invoice-print-box .invoice-box-inner {
  background: #fafafa;
  border: 1px solid #eaeaea;
  margin: 0 -20px;
  padding: 0 20px;
  width: 100%;
  margin-top: 60px;
}
.invoice-print-box .invoice-row {
  padding: 20px 0px;
}
.invoice-print-box .invoice-row span {
  font-weight: 500;
}
.invoice-print-box .order-details {
  border-top: 1px dashed #eaeaea;
  margin: 0 -20px;
  padding: 20px;
  width: 100%;
}
.invoice-print-box table {
  vertical-align: middle;
  font-size: 20px;
}
.invoice-print-box th {
  color: #aaa;
  font-size: 14px;
  font-weight: normal;
  line-height: 20px;
  padding: 20px inherit;
  text-transform: uppercase;
  vertical-align: middle;
}
.invoice-print-box .product td {
  padding-top: 5px;
  padding-bottom: 55px;
  font-size: 20px;
  vertical-align: middle;
  font-weight: 300;
}
.invoice-print-box th.date,
.invoice-print-box td.date {
  text-align: left;
  padding-right: 40px;
  vertical-align: middle;
}
.invoice-print-box th.description,
.invoice-print-box td.description {
  text-align: left;
  width: 80%;
  vertical-align: middle;
}
.invoice-print-box th.amount,
.invoice-print-box td.amount {
  text-align: right;
  vertical-align: top;
  padding-left: 20px;
  vertical-align: middle;
  min-width: 104px;
}
.invoice-print-box .total .description {
  color: #aaa;
  font-size: 14px;
  text-align: right;
  text-transform: uppercase;
  font-weight: normal;
}
.invoice-print-box .total .amount {
  border-top: 3px double #ccc;
}
.invoice-print-box .total td {
  padding-top: 10px;
}
.invoice-print-box .balance {
  text-align: right;
  vertical-align: top;
  padding-left: 20px;
}
.invoice-print-box .footer {
  color: #aaa;
  font-size: 14px;
  padding: 40px 0 0;
  text-align: center;
}
.invoice-print-box .footer a {
  color: #888;
  text-decoration: underline;
}
.invoice-print-box p {
  margin-bottom: 8px;
}
.invoice-print-box .address {
  font-size: 85%;
  padding-top: 40px;
}

.stamp-row img {
  margin-left: auto;
  display: block;
  height: 63px;
  margin-top: 10px;
  margin-bottom: -20px;
}

@media print {
  .invoice-print-box {
    width: 620px;
    box-shadow: none;
    overflow: hidden;
  }
}
.billing-box {
  padding: 35px 37px;
  border-radius: 14px;
  background: #f8f8f8;
}
.billing-box .top {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  margin-bottom: 20px;
}

.billing-box.white {
  background: #fff;
  box-shadow: 0 12px 30px rgba(30, 39, 97, 0.04), 0 2px 14px rgba(19, 24, 57, 0.06);
}

.per-user {
  text-align: left;
  font-size: 14px;
  line-height: 1.4;
  padding: 0 5px;
  white-space: nowrap;
}

.price-per-agent {
  display: flex;
  align-items: center;
}

.your-plan {
  display: flex;
  flex-direction: column;
}
.your-plan span:first-child {
  color: #7f7f7f;
  font-weight: 700;
}
.your-plan span:last-child {
  color: #383838;
  font-size: 24px;
  font-weight: 800;
}

.billing-tab-plan {
  background: #f8f8f8;
  border-radius: 50px;
  overflow: hidden;
  align-items: center;
  color: #7f7f7f;
  font-weight: 700;
  position: relative;
  border-radius: 15px;
  display: flex;
  margin-top: -20px;
  margin-left: -22px;
  margin-right: -22px;
}
.billing-tab-plan:hover {
  background-color: #f2f2f2;
}
.billing-tab-plan .billing-tab {
  width: 50%;
  text-align: center;
  padding: 5px 0;
  z-index: 1;
  color: #7f7f7f;
}
.billing-tab-plan .billing-tab.selected {
  color: #383838;
}
.billing-tab-plan .selected-tab-bg {
  position: absolute;
  width: 50%;
  left: 0;
  top: 0;
  height: 100%;
  border-radius: 15px;
  background: #deebfa;
  transition: transform 0.25s;
}

.landing-section .selected-tab-bg {
  background-color: #7d92eb;
}
.landing-section .billing-tab.selected {
  color: white;
}

.billing-item {
  text-align: left;
  color: #383838;
  font-weight: 700;
  line-height: 19px;
  margin-bottom: 12px;
  display: flex;
  flex-direction: column;
}
.billing-item .billing-item-value {
  color: #7f7f7f;
  font-weight: 700;
}

.billing-total {
  border-top: 1px solid #e0e0e0;
  padding-top: 20px;
  margin-top: 20px;
}
.billing-total .billing-total-value {
  font-size: 20px;
  font-weight: 700;
  margin: 2px 0 20px;
  font-family: Roboto, sans-serif;
  letter-spacing: 0.3px;
}
.billing-total .billing-total-value.line-through {
  text-decoration: line-through;
  opacity: 0.4;
}
.billing-total .billing-total-value.coupon {
  margin-top: -15px;
}

.change-for-discount {
  color: #7d92eb;
  font-size: 13px;
  font-weight: 700;
  line-height: 19px;
  margin-bottom: 27px;
}

.billing-title-gray {
  color: #7f7f7f;
  font-weight: 600;
}

.plan-addon {
  font-size: 18px;
  font-weight: 700;
  display: flex;
  align-items: center;
}
.plan-addon .plan-addon-name {
  margin-left: 15px;
}

.plan-addon-img {
  width: 38px;
  height: 38px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
}
.plan-addon-img img {
  width: 38px;
}
.plan-addon-img i {
  font-size: 28px;
}

.addon-description {
  margin: -6px 0 30px;
  color: #7f7f7f;
  font-size: 16px;
  line-height: 24px;
}
.addon-description.warning {
  color: #856404;
  background-color: #fff3cd;
  border-color: #ffeeba;
  padding: 0.75rem 1.25rem;
  border-radius: 0.25rem;
}

.awaiting-payment-modal {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 50px 0;
}
.awaiting-payment-modal.pending path {
  stroke: #7d92eb;
}

.awaiting-paid {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.license-space .princing-plan-price .amount {
  margin-right: 10px;
}

.add-on-button {
  font-size: 15px !important;
  width: auto !important;
}

.coupon-charges {
  display: inline-block;
  margin-left: 10px;
  font-size: 12px;
  opacity: 0.8;
}

.modal-billing-contact {
  display: flex;
  flex-direction: column;
  grid-gap: 16px;
  gap: 16px;
  padding: 24px;
  text-align: center;
  font-size: 16px;
}
.modal-billing-contact .title {
  font-size: 20px;
  font-weight: 700;
}

.channels-list {
  margin: 0 auto;
  max-width: 600px;
  width: 100%;
  display: block;
}
.channels-list .channel-title {
  display: flex;
  align-items: baseline;
  grid-gap: 10px;
  gap: 10px;
  justify-content: space-between;
}
.channels-list .channel {
  display: flex;
  padding: 20px 15px;
  border-top: 1px solid #e7e9ec;
}
.channels-list .channel img {
  width: 34px;
  height: 34px;
  object-fit: cover;
}
.channels-list .channel i {
  color: #cfcfcf;
  font-size: 28px;
}
.channels-list .channel .info {
  margin: 0px auto 0 13px;
  line-height: 20px;
  width: 100%;
}
.channels-list .channel .info .name {
  font-size: 17px;
  margin-bottom: 10px;
}
.channels-list .channel .info .description {
  color: #9b9c9e;
}
.channels-list .channel .right {
  align-self: center;
}
.channels-list .channel:hover, .channels-list .channel:hover ~ .channels-list .channel {
  border-radius: 6px;
  background-color: #f3f4f5;
  border-color: transparent;
}
.channels-list .channel:first-child {
  border-color: transparent;
}

.channel:hover ~ .channel {
  border-color: transparent;
}

.channel-list-items {
  height: 100%;
}

.channel-list-items .channel-icon {
  background-color: rgba(0, 0, 0, 0.05);
  border-radius: 50%;
  color: rgb(35, 86, 179);
  width: 30px;
  height: 30px;
  font-size: 18px;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
}
.channel-list-items .channel-icon:before {
  z-index: 1;
}

.channel-list-items .cell-avatar {
  border-radius: 0;
  width: 30px;
  height: 30px;
  background: transparent !important;
}

.channel-page .modal-body {
  padding: 10px 32px;
}
.channel-page .modal-footer {
  margin-left: 32px;
  padding-left: 0;
  margin-top: 15px;
  flex-direction: row-reverse;
  max-width: 700px;
}
.channel-page .modal-footer button {
  margin-right: 15px;
}
.channel-page .modal-footer .menu {
  left: 0;
}

.form-widget {
  position: relative;
  max-width: 460px;
}

.form-widget-offset {
  padding-bottom: 80px;
}

.color-picker-wrapper {
  position: absolute;
  right: -240px;
  top: 30px;
}

.campaign-type {
  margin-top: 10px;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 50px 15px;
  border-radius: 4px;
  margin-right: 10px;
  border: 1px solid rgba(0, 0, 0, 0.1);
  background: hsla(0, 0%, 100%, 0.75);
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.08);
  flex: 1 1;
}
.campaign-type img {
  width: 40px;
  height: 40px;
  transition: all 0.15s cubic-bezier(0, 0, 0, 1);
}
.campaign-type span {
  transition: all 0.15s cubic-bezier(0, 0, 0, 1);
}
.campaign-type .name {
  font-size: 17px;
  font-weight: 700;
  margin: 20px 0 10px;
}
.campaign-type .description {
  font-size: 14px;
  font-weight: 400;
  color: #737f8d;
  text-align: center;
}
.campaign-type:hover {
  border-color: #7d92eb;
}
.campaign-type:hover img,
.campaign-type:hover span {
  transform: translateY(-10px);
}

.campaign-icon {
  margin-right: 15px;
  font-size: 18px;
}

.tooltip-custom {
  max-width: 400px;
  background-color: rgb(25, 25, 26);
  box-shadow: rgba(0, 0, 0, 0.15) 0px 2px 4px;
  color: rgb(255, 255, 255);
  font-size: 12px;
  font-weight: 400;
  line-height: 16px;
  padding: 6px 10px;
  border-radius: 4px;
}

.auth-header img {
  height: 37px;
  margin-top: -11px;
}

.monster-wrap {
  display: flex;
  flex-direction: column;
}

.monster-row {
  display: flex;
}
.monster-row img {
  max-height: 150px;
  max-width: 80px;
  display: block;
}

body {
  margin: 0;
  padding: 0;
  font-family: "Segoe UI", "Segoe UI Web (West European)", "Segoe UI", -apple-system, BlinkMacSystemFont, Roboto, "Helvetica Neue", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: #fff;
  height: 100%;
  font-size: 14px;
  color: #2a2a2a;
  text-rendering: optimizelegibility;
}

input,
textarea,
select,
button {
  font-family: "Segoe UI", "Segoe UI Web (West European)", "Segoe UI", -apple-system, BlinkMacSystemFont, Roboto, "Helvetica Neue", sans-serif;
}

button {
  margin: 0;
  padding: 0;
}

@font-face {
  font-family: "Whitney";
  src: url(/static/media/whitney-light.eceb5fb7.woff) format("woff");
  font-weight: 300;
  font-style: normal;
}
@font-face {
  font-family: "Whitney";
  src: url(/static/media/whitney-medium.bd668709.woff) format("woff");
  font-weight: 500;
  font-style: normal;
}
@font-face {
  font-family: "Whitney";
  src: url(/static/media/whitney-bold.fa075681.woff) format("woff");
  font-weight: 700;
  font-style: normal;
}
/* Roboto
======================== */
/* Sofia
======================== */
/* Icones
======================== */
code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New", monospace;
}

/* Modules */
.App {
  text-align: left;
}

.App-logo {
  -webkit-animation: App-logo-spin infinite 20s linear;
          animation: App-logo-spin infinite 20s linear;
  height: 40vmin;
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@-webkit-keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
