.section-loading {
    width: 30%;
    display: inline-block;
    text-align: center;
    min-height: 215px;
    vertical-align: top;
    margin: 1%;
    // background: #080915;
    border-radius: 5px;
    // -webkit-box-shadow: 0px 0px 30px 1px #103136 inset;
    //         box-shadow: 0px 0px 30px 1px #103136 inset;
}


.loader {
    position: relative;
    width: 60px;
    height: 60px;
    border-radius: 50%;
    margin: 75px;
    display: inline-block;
    vertical-align: middle;
}

.loader-5 {
    border: 8px dotted rgba(0, 0, 0, 1);
    -webkit-transition: all 1s ease;
    -o-transition: all 1s ease;
    transition: all 1s ease;
    -webkit-animation: dotted-spin 1s linear infinite;
    animation: dotted-spin 1s linear infinite;
    border-bottom-width: 1px;
    border-bottom-color: rgba(0, 0, 0, 0.3);
    border-left-width: 2px;
    border-left-color: rgba(0, 0, 0, 0.5);
    border-top-width: 3px;
    border-right-width: 4px;
    border-top-color: rgba(0, 0, 0, 0.7);
}

.loader-5 .loader-pacman,
.loader-pacman {
    position: absolute;
    top: 40px;
    left: 25px;
    width: 0px;
    height: 0px;
    border-right: 12px solid transparent;
    border-top: 12px solid rgba(255, 255, 0, 1);
    border-left: 12px solid rgba(255, 255, 0, 1);
    border-bottom: 12px solid rgba(255, 255, 0, 1);
    border-top-left-radius: 12px;
    border-top-right-radius: 12px;
    border-bottom-left-radius: 12px;
    border-bottom-right-radius: 12px;
}


@keyframes dotted-spin {
    0% {
        -webkit-transform: rotate(0deg);
        transform: rotate(0deg);
    }
    100% {
        -webkit-transform: rotate(-360deg);
        transform: rotate(-360deg);
    }
}

.loading2 {
    fill: none;
    stroke: rgba(0,0,0,0.2);
    stroke-dasharray: 135.088px;
    stroke-dashoffset: 108.071px;
    stroke-linecap: round;
    stroke-width: 5px;
    transform-origin: center center 0px;
    animation: dotted-spin 0.86s cubic-bezier(0.4, 0.15, 0.6, 0.85) 0s infinite normal none running;
    margin: 15px;
}


.loading-space.center {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 85px;
}

.table-loading-wrapper {
    position: absolute;
    width: 100%;
    top: 70px;
    left: 0;
    right: 0;
    margin: auto;
}

.invisible-table {
    .rt-tbody {
        opacity: 0;
    }
    .pagination-bottom {
        display: none;
    }
}

.rotateSpin {
    animation: dotted-spin 0.86s cubic-bezier(0.4, 0.15, 0.6, 0.85) 0s infinite normal none running;
}   