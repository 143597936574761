.dropzone {
    width: 100%;
    min-height: 183px;
    border: 2px dashed rgba(0, 0, 0, 0.1);
    border-radius: 8px;
    display: flex;
    align-items: center;
    justify-content: center;
    outline: none;
    padding: 12px;
    box-sizing: border-box;
    margin-bottom: 10px;

    .drag-drop {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;

        i {
            font-size: 38px;
            margin: 16px;
            line-height: 20px;
            color: rgba(0, 0, 0, 0.3);
        }
        h1 {
            font-size: 18px;
            margin: 10px 0 0 0;
        }
        span {
            color: rgba(0, 0, 0, 0.6);
            font-size: 14px;
        }
    }

    .drop-rows {
        width: 100%;
    }

    .drop-row {
        display: flex;
        align-items: center;
        width: 100%;
        border: 1px solid rgba(0, 0, 0, 0.1);
        box-sizing: border-box;
        padding: 12px 15px;
        border-radius: 6px;
        margin-bottom: 10px;

        & > i {
            font-size: 27px;
            color: rgba(0, 0, 0, 0.3);
        }

        .details {
            display: flex;
            flex-direction: column;
            flex: 1;
            margin-left: 15px;
            & > span:first-child {
                font-size: 14px;
                font-weight: 600;
            }
            & > span:last-child {
                color: rgba(0, 0, 0, 0.6);
                font-size: 13px;
            }
        }
    }
}
