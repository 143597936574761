.plans {
    display: flex;
    margin: 30px 0 60px;
    // font-family: "roboto", sans-serif !important;
}

.plan-box {
    border: solid 1px #d3dae4;
    border-radius: 3px;
    // height: 92px;
    margin: 2px;
    display: flex;
    flex-direction: column;
    padding: 15px;
    text-align: center;
    width: 135px;
    margin-right: 20px;
    height: 92px;
    box-sizing: border-box;
    min-width: 170px;

    &:hover {
        box-shadow: 0 5px 15px 3px rgba(0, 0, 0, 0.1);
    }
    // .plan-badge {
    //     margin-bottom: 10px;
    // }

    // .plan-badge span {
    //     background-image: linear-gradient(-180deg,#1E94ED 0,#037DDA 100%);
    //     color: white;
    //     border-radius: 3px;
    //     padding: 2px 5px;
    // }
    .period {
        font-size: 18px;
        margin-bottom: 10px;
    }
    .discount {
        color: #2ecc71;
        // font-weight: 300;
        font-family: sans-serif;
        font-size: 13px;
        margin-bottom: 5px;
    }
    &.disabled {
        pointer-events: none;
        opacity: 0.2;
    }
}

.billing-row {
    display: flex;
    align-items: center;
    // margin-bottom: 50px;
    .left {
        width: 150px;
        margin-right: 30px;
        h3 {
            margin-top: 0;
            font-weight: 400;
            font-size: 18px;
            margin-bottom: 10px;
        }
        .description {
            color: #a9abb5;
            font-size: 13px;
        }
    }

    // .billing-total {
    //     font-size: 34px;
    //     font-weight: 300;
    // }
    .checkbox-space {
        margin-left: 30px;
    }
    .credit {
        color: #a9abb5;
        font-size: 16px;
        margin-left: 15px;
    }
}
.billing-total-per-user {
    font-size: 40px;
    letter-spacing: 1.5px;
    padding: 0 5px;
    font-weight: 300;
    &:before {
        content: "R$";
        position: relative;
        top: -16px;
        font-size: 20px;
    }
}

.billing-separator {
    height: 1px;
    width: 100%;
    background-color: rgba(0, 0, 0, 0.1);
    margin-bottom: 40px;
}

.billing-agents {
    display: flex;
    align-items: center;
    width: 70px;
    .kinput {
        width: 150px;
    }
    button.only-icon {
        border: none;
        margin-left: -60px;
        border-left: 1px solid #e0e0e0;
        border-top: 1px solid #e0e0e0;
        border-bottom: 1px solid #e0e0e0;
        font-size: 18px;
        color: #7f7f7f;
        background: #f8f8f8;
        border-radius: 0;
        height: 35px;
        width: 79px;
        max-width: unset;
        // &:first-child {
        //     margin-right: 5px;
        // }
        &:last-child {
            margin-left: 0px;
            border-right: 1px solid #e0e0e0;
            border-radius: 0 3px 3px 0;
        }
        i {
            font-size: 15px !important;
        }
        &:active {
            transform: scale(1);
        }
    }
}
.billing-agents.coupon {
    .kinput {
        width: 160px;
    }
    button.only-icon {
        &:last-child {
            margin-left: -2px;
            margin-top: 11px;
        }
    }
}

.select-number-agents {
    font-weight: 500;
    color: #7f7f7f;
}

.billing-row.vertical {
    .left {
        width: 750px;
        margin-right: 0;
    }
    .checkbox-space {
        margin-left: 0;
    }
}

.payment-methods {
    display: flex;
    flex-direction: column;
    h4 {
        font-size: 18px;
        margin-bottom: 20px;
    }
}

.payment-method {
    border: solid 1px #d3dae4;
    border-radius: 3px;
    display: flex;
    padding: 15px;
    text-align: center;
    width: 245px;
    margin-bottom: 10px;
    height: 60px;
    box-sizing: border-box;
    align-items: center;
    font-size: 15px;
    &:hover {
        border-color: #2ecc71;
    }
    i {
        margin-right: 15px;
        font-size: 26px;
        width: 30px;
    }
}

.subscription-active-badge {
    background-color: #3498db;
    border-radius: 5px;
    padding: 10px 20px;
    color: #fff;
    margin-bottom: 20px;
    font-weight: 500;
    font-size: 16px;
    &.canceled {
        background-color: #e74c3c;
        color: #fff;
    }
}

.billing-space {
    display: flex;
    & > .left {
        flex: 1;
        margin-right: 30px;
    }
    & > .right {
        max-width: 270px;
        flex: 1;
    }
}

.billing-active-plan {
    box-sizing: border-box;
    padding: 10px 20px;
    background: #f6f7f9;
    // border: 1px solid #a4b3c2;
    border-radius: 5px;
    position: relative;
    width: 340px;

    .row {
        display: flex;
        margin-bottom: 15px;
        .left {
            margin-right: 0;
            font-weight: 500;
        }
        .right {
            min-width: 150px;
        }
    }
}

.invoice {
    background-color: #f5f9fd;
    border-radius: 3px;
    height: 80px;
    padding: 10px 20px;
    box-sizing: border-box;
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 10px;

    .left {
        display: flex;
        align-items: center;
    }

    .invoice-value {
        margin-left: 60px;
        font-size: 18px;
    }

    .invoice-info {
        display: flex;
        flex-direction: column;
        span:last-child {
            font-size: 12px;
            color: #a9abb5;
        }
        span:first-child {
            font-size: 18px;
        }
    }
}

/* INVOICE - PRINT
==============================================*/
.invoice-print-space {
    background: #f0f0f0;
    height: 100vh;
    width: 100vw;
    position: fixed;
    overflow: auto;
}

.invoice-print-box {
    background-color: white;
    box-shadow: 0 1px 5px rgba(0, 0, 0, 0.1), 0 1px 1px rgba(0, 0, 0, 0.1);
    margin: 40px auto 0px;
    padding: 40px;
    width: 750px;

    .top {
        img {
            height: 84px;
            margin-left: -20px;
            width: 240px;
        }
        span {
            color: #ccc;
            float: right;
            font-size: 48px;
            line-height: 84px;
            font-weight: 800;
        }
    }

    .invoice-box-inner {
        background: #fafafa;
        border: 1px solid #eaeaea;
        margin: 0 -20px;
        padding: 0 20px;
        width: 100%;
        margin-top: 60px;
    }

    .invoice-row {
        padding: 20px 0px;
        span {
            font-weight: 500;
        }
    }

    .order-details {
        border-top: 1px dashed #eaeaea;
        margin: 0 -20px;
        padding: 20px;
        width: 100%;
    }
    table {
        vertical-align: middle;
        font-size: 20px;
    }
    th {
        color: #aaa;
        font-size: 14px;
        font-weight: normal;
        line-height: 20px;
        padding: 20px inherit;
        text-transform: uppercase;
        vertical-align: middle;
    }
    .product td {
        padding-top: 5px;
        padding-bottom: 55px;
        font-size: 20px;
        vertical-align: middle;
        font-weight: 300;
    }
    th.date,
    td.date {
        text-align: left;
        padding-right: 40px;
        vertical-align: middle;
    }
    th.description,
    td.description {
        // padding: 0 20px 0 0;
        text-align: left;
        width: 80%;
        vertical-align: middle;
    }
    th.amount,
    td.amount {
        text-align: right;
        vertical-align: top;
        padding-left: 20px;
        vertical-align: middle;
        min-width: 104px;
    }
    .total .description {
        color: #aaa;
        font-size: 14px;
        text-align: right;
        text-transform: uppercase;
        font-weight: normal;
    }
    .total .amount {
        border-top: 3px double #ccc;
    }
    .total td {
        padding-top: 10px;
    }
    .balance {
        text-align: right;
        vertical-align: top;
        padding-left: 20px;
    }
    .footer {
        color: #aaa;
        font-size: 14px;
        padding: 40px 0 0;
        text-align: center;
        a {
            color: #888;
            text-decoration: underline;
        }
    }
    p {
        margin-bottom: 8px;
    }
    .address {
        font-size: 85%;
        padding-top: 40px;
    }
}

.stamp-row {
    img {
        margin-left: auto;
        display: block;
        height: 63px;
        margin-top: 10px;
        margin-bottom: -20px;
    }
}

@media print {
    .invoice-print-box {
        width: 620px;
        box-shadow: none;
        overflow: hidden;
    }
}

.billing-box {
    padding: 35px 37px;
    border-radius: 14px;
    background: #f8f8f8;
    .top {
        display: flex;
        justify-content: space-between;
        align-items: flex-start;
        margin-bottom: 20px;
    }
}

.billing-box.white {
    background: #fff;
    box-shadow: 0 12px 30px rgba(30, 39, 97, 0.04), 0 2px 14px rgba(19, 24, 57, 0.06);
}

.per-user {
    text-align: left;
    font-size: 14px;
    line-height: 1.4;
    padding: 0 5px;
    white-space: nowrap;
}
.price-per-agent {
    display: flex;
    align-items: center;
}

.your-plan {
    display: flex;
    flex-direction: column;
    span:first-child {
        color: #7f7f7f;
        font-weight: 700;
    }
    span:last-child {
        color: #383838;
        font-size: 24px;
        font-weight: 800;
    }
}

.billing-tab-plan {
    background: #f8f8f8;
    border-radius: 50px;
    overflow: hidden;
    align-items: center;
    color: #7f7f7f;
    font-weight: 700;
    position: relative;
    border-radius: 15px;
    display: flex;

    margin-top: -20px;
    margin-left: -22px;
    margin-right: -22px;

    &:hover {
        background-color: #f2f2f2;
    }
    .billing-tab {
        width: 50%;
        text-align: center;
        padding: 5px 0;
        z-index: 1;
        color: #7f7f7f;
        &.selected {
            color: #383838;
        }
    }
    .selected-tab-bg {
        position: absolute;
        width: 50%;
        left: 0;
        top: 0;
        height: 100%;
        border-radius: 15px;
        background: #deebfa;
        transition: transform 0.25s;
    }
}

.landing-section {
    .selected-tab-bg {
        background-color: $primary;
    }
    .billing-tab.selected {
        color: white;
    }
}

.billing-item {
    text-align: left;
    color: #383838;
    font-weight: 700;
    line-height: 19px;
    margin-bottom: 12px;
    display: flex;
    flex-direction: column;
    .billing-item-value {
        color: #7f7f7f;
        font-weight: 700;
    }
}

.billing-total {
    border-top: 1px solid #e0e0e0;
    padding-top: 20px;
    margin-top: 20px;

    .billing-total-value {
        font-size: 20px;
        font-weight: 700;
        margin: 2px 0 20px;
        font-family: Roboto, sans-serif;
        letter-spacing: 0.3px;

        &.line-through {
            text-decoration: line-through;
            opacity: 0.4;
        }

        &.coupon {
            margin-top: -15px;
        }
    }
}

.change-for-discount {
    color: $primary;
    font-size: 13px;
    font-weight: 700;
    line-height: 19px;
    margin-bottom: 27px;
}

.billing-title-gray {
    color: #7f7f7f;
    font-weight: 600;
}

.plan-addon {
    font-size: 18px;
    font-weight: 700;
    display: flex;
    align-items: center;
    .plan-addon-name {
        margin-left: 15px;
    }
}
.plan-addon-img {
    width: 38px;
    height: 38px;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    img {
        width: 38px;
    }
    i {
        font-size: 28px;
    }
}

.addon-description {
    margin: -6px 0 30px;
    color: #7f7f7f;
    font-size: 16px;
    line-height: 24px;
    &.warning {
        color: #856404;
        background-color: #fff3cd;
        border-color: #ffeeba;
        padding: 0.75rem 1.25rem;
        border-radius: 0.25rem;
    }
}

.awaiting-payment-modal {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 50px 0;
    &.pending path {
        stroke: $primary;
    }
}

.awaiting-paid {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.license-space {
    .princing-plan-price .amount {
        // font-size: 30px;
        // font-weight: 600;
        margin-right: 10px;
    }
}

.add-on-button {
    font-size: 15px !important;
    width: auto !important;
}

.coupon-charges {
    display: inline-block;
    margin-left: 10px;
    font-size: 12px;
    opacity: 0.8;
}

.modal-billing-contact {
    display: flex;
    flex-direction: column;
    gap: 16px;
    padding: 24px;
    text-align: center;
    font-size: 16px;

    .title {
      font-size: 20px;
      font-weight: 700;
    }
}