.context-header {
    background-color: rgb(244, 244, 244);
    // height: 40px;
    width: 100%;
    display: flex;
    align-items: center;
    padding: 0 16px;
    box-sizing: border-box;
    min-height: $context-header-height;

    justify-content: space-between;
    & > div {
        display: flex;
        align-items: center;
    }
}

.context-header-button {
    background-color: transparent;
    border: none;
    height: $context-header-height;
    border-radius: 0;
    font-weight: 400;
    padding: 0 12px;
    flex-shrink: 0;
    i {
        color: $primary;
    }
    &:hover {
        background-color: rgb(234, 234, 234);
        color: rgb(33, 33, 33);
    }
}

.context-side-panel {
    width: 0;
    height: 100%;
    box-sizing: border-box;
    overflow: hidden;
    transition: 367ms cubic-bezier(.1,.9,.2,1);
    &.open {
        width: 315px;
    }
}
