.top-header {
    flex: 0 0 auto;
    width: 100%;
    height: 40px;
    background-color: $primary;
    // box-shadow: 0 1px 2px rgba(0, 0, 0, 0.15);
    display: flex;
    justify-content: space-between;
    align-items: center;
    z-index: 10;
    & > section {
        display: flex;
        align-items: center;
        height: 100%;
    }
}

.header-link {
    position: relative;
    display: flex;
    align-items: center;
    height: 40px;
    margin-left: 26px;
    box-sizing: border-box;
    font-size: 0.75rem;
    color: rgba(255, 255, 255, 0.7);
    font-weight: 400;
    i {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 24px;
        height: 24px;
        font-size: 18px;
        margin-right: 8px;
    }
    &:first-child {
        margin-left: 12px;
    }
    &:hover {
        color: white;
    }
    &.active {
        color: white;
        &::after {
            display: block;
            content: "";
            height: 2px;
            background-color: white;
            border-radius: 2px;
            margin-top: -2px;
            width: 100%;
            bottom: 0;
            position: absolute;
        }
    }
    .unread {
        position: absolute;
        top: 7px;
        left: 16px;
        display: block;
        width: 8px;
        height: 8px;
        border: 1px solid #3f82ef;
        border-radius: 5px;
        background-color: #ffb200;
    }
}

.user-menu-drop-title {
    display: flex;
    align-items: center;
    padding: 12px 20px;
    .user-name {
        margin-left: 15px;
    }
    &:hover {
        background-color: rgba(93, 105, 133, 0.1);
    }
}

.user-menu-drop-inner {
    .title {
        width: 100%;
        padding: 0;
    }
    .menu-drop-header {
        padding: 0;
    }
    .menu-drop-item-inner {
        padding: 0 20px;
    }
}

.user-menu-drop {
    height: 100%;
    padding: 0 11px 0 20px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 80px 0 0 80px;
    &:hover {
        background-color: rgba(0,0,0,0.3);
    }
    .user-menu-drop-trigger {
        display: flex;
        align-items: center;
        color: white;
        .workspace-name {
            margin-right: 10px;
        }
        .cell-avatar {
            margin-right: 0;
        }
    }
}

.header-fakes {
    display: flex;
    align-items: center;
    margin: 0 15px;
    opacity: .24;
    .icon-fake {
        width: 18px;
        height: 18px;
        background-color: #fff;
        border-radius: 3px;
        margin-right: 10px;
    }
    .line-fake {
        width: 50px;
        height: 7px;
        background-color: #fff;
        border-radius: 2px;
        margin-right: 30px;
    }
}