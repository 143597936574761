.mdc-dialog {
    z-index: 99 !important;
    padding-top: 30px !important;
    align-items: flex-start !important; 
}

.mdc-dialog__actions {
    padding: 20px 0px 10px !important;
    // border-top: 1px solid rgba(0,0,0,0.1) !important;
    justify-content: flex-start !important;
    border-top: none !important;
    button {
        margin-right: 10px !important; 
    }
}

.mdc-dialog__content {
    font-family: "sofia", "Roboto", sans-serif !important;
    padding: 0 !important;
    overflow: unset !important;
}

.mdc-dialog__container {
    // margin-top: -60px;
}

.mdc-dialog__surface {
    overflow-y: visible;
    // overflow-x: hidden;
}

.dialog-content {
    padding: 10px  20px 20px !important;
}

.mdc-dialog .mdc-dialog__title {
    color: rgba(0, 0, 0, 0.87);
    font-size: 14px;
    font-weight: 600;
    font-family: "sofia", "Roboto", sans-serif;
    padding-left: 24px;
    text-transform: uppercase;
}

.dialog-inner {
    // padding: 10px 24px 24px;
    padding: 16px 30px 22px;
    // width: 550px;
    box-sizing: border-box;
}

.mdc-dialog .mdc-dialog__scrim {
    background-color: rgba(45,51,57,0.6) !important;
}