.Selectable
    .DayPicker-Day--selected:not(.DayPicker-Day--start):not(.DayPicker-Day--end):not(.DayPicker-Day--outside) {
    background-color: $primary-light-grey !important;
    color: $primary;
}
.Selectable .DayPicker-Day {
    border-radius: 0 !important;
}
.Selectable .DayPicker-Day--start {
    border-top-left-radius: 50% !important;
    border-bottom-left-radius: 50% !important;
}
.Selectable .DayPicker-Day--end {
    border-top-right-radius: 50% !important;
    border-bottom-right-radius: 50% !important;
}

.DayPicker-wrapper {
    outline: none;
}

.DayPicker-Day--selected:not(.DayPicker-Day--disabled):not(.DayPicker-Day--outside) {
    background-color: $primary !important;
}

.DayPicker:not(.DayPicker--interactionDisabled)
    .DayPicker-Day:not(.DayPicker-Day--disabled):not(.DayPicker-Day--selected):not(.DayPicker-Day--outside):hover {
    background-color: $primary-light-grey !important;
}

.range-description {
    display: flex;
    align-items: center;

    i {
        font-size: 18px;
        margin-left: 2px;
    }
}

.data-strong {
    font-weight: 500;
}

.RangeExample {
    display: flex;
    flex-direction: column;
}

.DayPicker.Selectable {
    border-top: 1px solid rgba(0,0,0,0.1);
    border-bottom: 1px solid rgba(0,0,0,0.1);
    margin: 13px 0;
    padding: 2px 0;
}