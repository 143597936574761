.ganhomais {
    background: linear-gradient(135deg, #0079BF, #5067C5);
    width: 100%;
    height: 100%;
    min-height: 100vh;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    /* overflow: auto; */
    padding-bottom: 100px;

    .midcol {
        display: flex;
        justify-content: space-between;
    }

    .left, .right {
        display: flex;
        flex-direction: column;
        margin-bottom: 40px;
        max-width: 500px;
    }

    .feature {
        color: rgba(255,255,255,0.8);
        font-size: 17px;
        margin: 5px 0;
    }
}

.valor-total {
    color: white;
    font-size: 50px;
    margin-bottom: 40px;
    text-align: center;
}

.divisao-valores {
    display: flex;
    justify-content: space-between;
}

.value-block {
    color: #f1c40f;
    font-size: 50px;
    margin-bottom: 30px;
    text-align: center;
    display: flex;
    flex-direction: column;
    background-color: rgba(0,0,0,0.05);
    padding: 10px;
    border-radius: 8px;
    min-width: 300px;
    .title {
        font-size: 30px;
        color: white;
    }
}

.separator {
    height: 2px;
    background: white;
    width: 100%;
    margin: 30px 0;
}

.rc-slider-rail {
    background-color: rgba(0,0,0,0.3);
    height: 15px;
}

.rc-slider-track {
    height: 15px;
    background-color: #f1c40f;
}

.rc-slider-step {
    height: 9px;
}

.rc-slider-dot-active {
    border-color: #000;
    background-color: #000;
} 

.rc-slider-handle {
    border: solid 3px #000;
    background-color: #f39c12;
    margin-top: -9px;
    width: 30px;
    height: 30px;
}

.rc-slider-handle:hover {
    border-color: #fff;
} 

.rc-slider-handle:focus {
    border-color: #fff;
    box-shadow: none;
} 