.chat-side {
    background: #fff;
    height: 100%;
    border-left: 1px solid #edebe9;
    display: flex;
    flex-direction: column;
    overflow: hidden;
    // font-family: Roboto, Helvetica Neue, Segoe UI, Helvetica, Arial, sans-serif;
}

.chat-side-header {
    border-bottom: 1px solid #edebe9;
    // background-color: white;
    // border-left: 1px solid #ececec;
    display: flex;
    align-items: center;
    height: 48px;
    box-sizing: border-box;
    width: 100%;
    padding: 20px;

    & > h3 {
        color: rgb(119, 120, 122);
        font-size: 16px;
        font-weight: 500;
    }

    .drop-trigger:hover {
        background: transparent;
    }
}

.chat-side-trigger {
    font-size: 15px;
    font-weight: 600;
    .left {
        justify-content: center;
        flex: 0;
    }
}

.chat-side-item {
    border-top: 1px solid #edebe9;
    .chat-side-item-title {
        display: block;
        padding: 9px 20px;
        font-weight: 600;
        background-color: #f4f4f4;
        i {
            margin-right: 10px;
        }
    }
}

.chat-side-item-inner {
    border-top: 1px solid #eee;
    background-color: white;
}

.chat-side-inner {
    // height: calc(100vh - 236px);
    // padding-bottom: 100px;
    overflow: auto;
    flex: 1;
}

.email-unverified .chat-side-inner {
    height: calc(100vh - 136px);
}

.plugin-dialog {
    .modal-footer {
        border: none;
    }
}

.not-selected-plugin {
    font-style: italic;
    font-size: 14px;
    color: #bdbdbd;
    line-height: 1.3;
    margin: 20px 10px 10px;
    display: block;
    text-align: center;
}
.contact-call-card-wrapper  {

    border-bottom: 1px solid #ececec;
    .merge-btn {
        padding: 0 20px 10px 20px;
        margin-top: 5px;
    }
}

.contact-call-card {
    display: flex;
    justify-content: space-between;
    // border-bottom: 1px solid #ececec;
    padding: 10px 20px 10px 20px;
    width: 100%;
    text-align: left;
    font-size: 13px;
    box-sizing: border-box;
    line-height: 20px;
    &:hover {
        background-color: rgba(0,0,0,0.05);
    }
    .right {
        color: #c7c7c7;
        font-size: 12px;
    }
    i {
        color: #546e7a;
        margin-right: 8px;
    }
    .more-left img {
        width: 20px;
        margin-top: 9px;
        margin-right: 13px;
    }
    &.channels {
        .right i {
            margin-top: 10px;
            font-size: 15px;
            color: #f1c40f;
        }
        .right.finished {
            i {
                color: #2ecc71;
            }
        }
        &.active {
            .top {
                font-weight: 900;
                color: $primary-dark;
            }
            // background-color: rgba(0,0,0,0.03);
            // &:hover {
            //     background-color: rgba(0,0,0,0.08);
            // }
        }
    }
}

.merge-box {
    padding: 20px;
    font-size: 14px;
    p {
        opacity: 0.7;
        margin-bottom: 10px;
    }
}

.chat.viewOnly {
    .chat-header-inner .right, .drop-trigger, .chat-header-separator {
        display: none;
    }
    .chat-actions-box, .finished-chat-footer {
        display: none;
    }
    .messages {
        height: 500px;
    }
    .chat-inner-layout .right {
        display: none;
    }
    .context-header-button {
        display: none;
    }
}

.closed-conversation-banner.protocol {
    background-color: #30336b;
    color: white;
    i {
        color: white;
    }
}

// .contact-conversations .contact-call-card:first-child {
//     font-weight: 700;
// }

.contact-property {
    margin-bottom: 10px;
    position: relative;
    display: flex;
    justify-content: space-between;
    &:hover {
        .action-hover {
            opacity: 1;
        }
    }
    .right {
        margin-top: 12px;
    }
}

.contact-property-label {
    font-weight: 700;
    font-size: 10px;
    color: #c7c7c7;
    // margin-bottom: 3px;
    text-transform: uppercase;
}
.contact-property-value {
    font-weight: 400;
    font-size: 13px;
    color: #303133;
    // margin-right: 55px;
}

.add-prop {
    display: flex;
    align-items: center;

    i {
        margin-top: 0px;
        margin-right: 5px;
    }
}

.contact-medias {
    display: flex;
    flex-wrap: wrap;
}

.contact-media-item {
    width: 25%;
    height: 60px;
    max-width: 70px;
    display: inline-block;
    box-sizing: border-box;
    img {
        object-fit: cover;
        width: 100%;
        height: 100%;
    }
}