.answer-layout {
    height: 100vh;
    padding-bottom: 0;
}

.answers-space {
    display: flex;
    flex-direction: row !important;
    // height: 100%;
    .answer-left {
        width: 350px;
        // background-color: #f8f9fb;
        background-color: $background-light;
        height: 100%;
        max-height: 100%;
        border-right: solid 1px #dae4e9;
    }
    .answer-left-header {
        height: 55px;
        display: flex;
        justify-content: space-between;
        background-color: white;
        border-right: solid 1px #dae4e9;
        border-bottom: solid 1px #dae4e9;
        border-top: solid 1px #dae4e9;
        padding: 7px 15px;
        align-items: center;
        box-sizing: border-box;
        label {
            text-transform: uppercase;
            font-size: 13px;
        }
    }
    .answer-right {
        flex: 1;
        // padding-top: 40px;
    }
}

.answer-left-categories {
    display: flex;
    flex-direction: column;
    box-sizing: border-box;
    padding-top: 8px;
}
.answer-right-title-wrap {
    border-bottom: solid 1px #dae4e9;
    background-color: #f8f9fb;
    margin-top: 1px;
    padding: 10px 0;
    margin-bottom: 20px;
    // padding-bottom: 14px;
    // margin-bottom: 10px;
    .midcol {
        display: flex;
        justify-content: space-between;
        align-items: center;
    }
    .title {
        font-size: 16px;
    }
}

/* Category Card
============================ */
.answer-category-card {
    // background-color: #eef2f9;
    background-color: #f4f6f7;
    padding: 10px 12px;
    box-sizing: border-box;
    // width: 100%;
    margin: 3px 10px;
    border-radius: 5px;
    border: 1px solid #e5e9f1;
    transition: .3s;
    display: flex;
    height: 80px;
    position: relative;
    align-items: center;

    &:hover {
        background-color: #fff;
    }
    
    &.active {
        background-color: #fff;
        box-shadow: 0 4px 10px rgba(36,37,38,.1);
        font-weight: 700;
    }

    img {
        width: 40px;
        height: 40px;
        margin-right: 15px;
    }

    .title {
        font-size: 16px;
        color: #2C3B48;
        text-overflow: ellipsis;
        white-space: nowrap;
        overflow: hidden;
        width: 200px;
        position: relative;
    }

    & > div {
        display: flex;
        flex-direction: column;
    }

    .shared-label {
        i {
            font-size: 14px;
            margin-right: 5px;
        }
        display: flex;
        align-items: center;
        align-self: flex-start;
        font-size: 12px;
        margin-top: 5px;
        background-color: #cfd8dc;
        color: #455a64;
        padding: 3px 5px;
        line-height: 1;
        border-radius: 3px;
        margin-right: 5px;
    }

    .answers-count-wrap {
        position: absolute;
        top: 0;
        bottom: 0;
        right: 20px;
    }
    .answers-count {
        background-color: #cfd8dc;
        padding: 4px 8px 3px;
        border-radius: 15px;
        color: #626d8f;
        font-size: 11px;
        font-weight: 600;
        line-height: 11px;
        text-align: center;
        display: block;
        margin-top: 18px;
    }
}