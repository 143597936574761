.user-avatar {
    background-size: cover;
    background-position: 50%;
    border: none;
    height: 100px;
    width: 100px;
    position: relative;
    // background-image: url(https://cdn.dribbble.com/users/759083/screenshots/5036501/dsd.png);
    // background-image: url(https://cdn.dribbble.com/users/759083/screenshots/3661083/animal-skin-pattern-7789141.jpg);
    display: flex;
    border-radius: 50%;
    transition: box-shadow 0.1s;
    // margin: 0 auto;
    // cursor: pointer;
    // margin-right: 20px;

    // &:hover {
    //     box-shadow: inset 0 0 120px rgba(0, 0, 0, 0.75);
    //     i {
    //         visibility: visible;
    //     }
    // }

    // i {
    //     margin: auto;
    //     color: white;
    //     visibility: hidden;
    // }

    .avatar-upload-indicator {
        background-position: 50%;
        background-repeat: no-repeat;
        border-radius: 50%;
        box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.2);
        height: 28px;
        left: auto;
        position: absolute;
        right: 0;
        top: 0;
        width: 28px;
        background-color: #dcddde;
        background-image: url(https://discordapp.com/assets/d5c25e76af04cea8997e4a060572feae.svg);
    }

    input {
        position: absolute;
        opacity: 0;
        width: 100%;
        cursor: pointer;
        height: 100%;
        z-index: 2;
        top: 0;
    }

    .overlay {
        background-color: rgba(0, 0, 0, 0.6);
        height: 100%;
        width: 100%;
        position: absolute;
        top: 0;
        left: 0;
        border-radius: 50%;
        color: white;
        opacity: 0;
        i {
            margin-bottom: 3px;
        }
    }
    .overlay-inner {
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;
        height: 100%;
    }
    &:hover .overlay{
        opacity: 1;
    }
}
.profile .user-avatar {
    margin: 0;
}

.input-none {
    display: none;
}

.file-input-menu {
    position: absolute;
    width: 100%;
    left: 0;
    margin-top: -2px;
    height: calc(100% + 6px);
    opacity: 0;
    cursor: pointer !important;
    overflow: hidden;
    font-size: 0px;
}

.menu-open .user-avatar {
    box-shadow: inset 0 0 120px rgba(0, 0, 0, 0.75);
    i {
        visibility: visible;
    }
}

.cell-avatar {
    width: 35px;
    height: 35px;
    object-fit: cover;
    border-radius: 50%;
    margin-right: 20px;

    // opacity: 0.7;
    background-color: #f3f4f5;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 15px;
    color: rgba(0,0,0,0.3);

}

.default-letter {
    color: rgba(0,0,0, 0.5);
    font-weight: 500;
    font-size: 19px;
}

.no-pointer {
    cursor: none;
}

.avatar-space {
    display: flex;
    align-items: center;
    border: 2px dashed rgba(0,0,0,0.1);
    padding: 20px;
    margin-top: 10px;
}