.page-header {
    background-color: #fafafb;
    padding: 55px 50px 30px;
    margin-bottom: 30px;
    min-height: 180px;
    box-sizing: border-box;
    // border-bottom: 1px solid #dfe3e6;
    display: flex;

    .title {
        font-size: 30px;
        margin: 0 0 20px;
        font-weight: 500;
        color: #1f2b36;
    }
    .description {
        font-size: 15px;
        color: rgba(0, 40, 80, 0.45);
        font-weight: 500;
        margin: 0;
        line-height: 1.5;
        display: block;
        max-width: 750px;
    }
    .page-header-image {
        width: 60px;
        height: 60px;
        object-fit: cover;
        margin-right: 25px;
    }
    .after-description {
        display: block;
        margin-top: 10px;
    }
    .action {
        border-left: 1px solid rgba(0, 0, 0, 0.1);
        margin-left: 50px;
        padding-left: 50px;
        height: 50px;
        align-self: center;
    }
}
