.filter__placeholder {

}

.filter__single-value, .filter__placeholder {
    position: relative !important;
    overflow: visible !important;
    margin-top: 17px !important;
    display: block !important;
    font-size: 14px !important;
    font-weight: 500 !important;
    display: flex !important;
    align-items: center;

    i {
        font-size: 16px;
        margin-right: 7px;
        margin-left: 2px;
        margin-top: 2px;
    }
}

.filter__control {
    min-height: 36px !important;
    background-color: #ebebeb !important;
    border: 1px solid transparent !important;
    color: #222 !important;
    border-radius: 4px !important;
    cursor: pointer !important;
    transition: box-shadow linear 40ms,border-color linear .2s !important;
    -webkit-backface-visibility:hidden;
    -webkit-transform: translateZ(0) scale(1.0, 1.0);
    transform: translateZ(0);

    &:hover, &.filter__control--is-focused {
        background-color: #e8f0ff !important;
        .filter__single-value {
            color: $primary;
        }
        .filter__placeholder {
            color: $primary !important;
        }
    }
    &.filter__control--is-focused {
        border-color: $primary !important;
        box-shadow: none !important;
    }

}

.filter__value-container {
    padding-bottom: 0 !important;
    padding-top: 1px !important;
    padding-right: 0 !important;
}

// .filter__input {
//     opacity: ;: none;
// }

.filter__dropdown-indicator, .filter__indicator-separator {
    display: none !important;
}

.filter__indicators {
    opacity: 0;
    svg {
        fill: #888 !important;
        height: 16px !important;
        width: 16px !important;
    }
}

.filter__control:hover .filter__indicators {
    opacity: 1;
}

.filter__indicator {
    padding-left: 0 !important;
}

.filter__menu, {
    width: auto !important;
    box-shadow: 0 4px 14px 0 rgba(0,0,0,.2), 0 0 0 1px rgba(0,0,0,.05);
    font-size: 14px !important;
    font-weight: 500 !important;
    min-width: 100%;

    animation-name: showMenu;
    animation-duration: 150ms;

    border: 1px solid #D3D8E0;
    box-shadow: 0 3px 10px 0 rgba(0,0,0,.2);
    background-color: #fff;
    border-radius: 4px;
    
}

.filter__option--is-selected {
    background-color: #fff !important;
    color: $primary !important;
}

.filter__option--is-focused, .drop-option:focus {
    background-color: $primary !important;
    color: #fff !important;
    outline: 0 !important;
}

.filter__option, .drop-option {
    padding: 7px 12px !important;
}

.selected-filter {
    position: absolute !important;
    top: 8px !important;
    right: 6px !important;
    visibility: hidden;
}
.filter__option--is-selected ~ .selected-filter {
    visibility: visible;
    svg {
        fill: $primary !important;
    }
}
.filter__option--is-focused ~ .selected-filter {
    svg {
        fill: #fff !important;
    }
}

@keyframes showMenu {
    from {opacity: 0;}
    to {opacity: 1;}
}

.react-select__option--is-selected {
    // background-color: $primary !important;
    background-color: transparent !important;
    color: #222 !important;
}

.select-space.small {
    min-width: 100px;
    .react-select__control {
        min-height: 0 !important;
        height: 30px;
        font-size: 14px;
        border: none;
    }

}

.react-select__indicator-separator {
    display: none;
}

.select-space.dash {
    width: auto;
    min-width: 200px;
    .react-select__control {
        border: 1px solid rgba(0,0,0,0.1);
        font-size: 13px;
    }
    .react-select__option {
        font-weight: 400;
    }
}

.react-select__option--is-focused {
    color: #222 !important;
}

.react-select__multi-value__remove {
    &:hover {
        color: #fff !important;
        background-color: rgba(0,0,0,0.1) !important;
        border-radius: 50%;
        cursor: pointer;
    }
}

.react-select__clear-indicator {
    padding: 3px !important;
}