.assign-conditions-space {
    position: relative;
    margin-top: 5px;

    .vertical-line {
        border-left: solid 1px #d3d8e0;
        width: 1px;
        height: 100%;
        position: absolute;
        left: 30px;
    }
}

.assign-badge {
    padding: 6px 10px;
    width: 60px;
    // z-index: 10;
    position: relative;
    display: block;
    text-align: center;
    border-radius: 3px;
    background-color: #9ba2b4;
    color: #fff;
    box-sizing: border-box;
    text-transform: uppercase;
    font-weight: 500;
    font-size: 13px;

    &.condition {
        // background-color: #e74c3c;
        // background-color: #3498db;
        background-color: #75cce1;
        padding: 4px 4px;
        width: 45px;
        margin-left: 40px;
        margin-bottom: 2px;
    }
    &.and {
        // background-color: #FC983C;
        background-color: #ffab5d;
    }
    &.block {
        margin-left: 0;
    }
}

.assign-header {
    display: flex;
    text-align: center;
    label {
        font-weight: 600;
        margin-left: 10px;
        display: flex;
        align-items: center;
        text-transform: uppercase;
        font-size: 12px;
    }
}

.assign-blocks {
    margin-bottom: 100px;
    padding-left: 70px;
    p {
        margin-top: 10px;
    }
}

.assign-block {
    padding: 8px 20px;
    border-radius: 5px;
    background-color: #f8f8f8;
    box-sizing: border-box;
}

.assign-rule {
    display: flex;
    align-items: center;
    .react-select__control {
        margin-right: 5px;
        min-width: 200px;
        font-size: 14px;
    }
    & > div {
        flex: 1;
    }
    button {
        padding: 8px;
        margin-bottom: 2px;
        color: #6641a6;
        background-color: transparent;
        border: 0;
        transition: all 0.1s cubic-bezier(0, 0, 0, 1), visibility 1ms;
        i {
            font-size: 23px;
            margin-right: 0;
        }
    }
    button:first-child {
        // color: #e74c3c;
        margin-right: 5px;
        color: rgba(0,0,0,0.3);
        i {
            font-size: 18px;
        }
    }
    button:last-child {
        margin-right: 55px;
        margin-left: 5px;
        &.invisible {
            visibility: hidden;
        }
    }
    .kinput input {
        font-size: 14px;
    }
}

.condition-switch {
    display: flex;
    justify-content: center;
    margin-top: 10px;
    margin-bottom: 5px;
}
.checked-icon-condition {
    color: white;
    font-weight: 600;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
    font-size: 14px;
    &.or {
        font-size: 13px;
        margin-right: 3px;
    }
}

.add-block-space {
    max-width: 65px;
}

.add-block-inner {
    display: flex;
    align-items: center;
    flex-direction: column;
    position: relative;
    button {
        max-width: 55px;
        padding: 8px;
        i {
            margin-right: 0;
            font-size: 23px;
            color: #2ecc71;
        }
    }
}

.add-block-vertical-line {
    width: 2px;
    border-left: 1px solid rgba(0,0,0,0.1);
    border-right: 1px solid rgba(0,0,0,0.1);
    height: 20px;
}

.assign.then {
    margin-bottom: 90px;
    .radio {
        margin-right: 15px;
        margin-left: 4px;
    }
    .select-space {
        margin-left: 68px;
        margin-top: 3px;
        position: absolute;
        width: 200px;
    }
}

.assign-blocks-title {
    display: flex;
    align-items: center;
}

.assign-blocks-title {
    font-size: 14px;
    margin-bottom: 5px;
    margin-top: 3px;
    .select-space {
        margin: 0 7px 5px;
    }
    .react-select__control {
        background-color: #ffab5d;
        font-size: 12px;
        font-weight: 500;
    }
    & .select-space.any .react-select__control {
        background-color: #a8e5f4;
    }
    .react-select__indicator {
        color: rgba(0,0,0,0.7) !important;
    }
    .react-select__single-value {
        font-size: 13px !important;
    }
}