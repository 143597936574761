.join-avatar {
    width: 100px;
    height: 100px;
    object-fit: cover;
    background-color: #fafafb;
    border-radius: 50%;
    img {
        width: 100%;
        height: 100%;
        border-radius: 50%;
        object-fit: cover;
    }
}

.join-dashed {
    border-bottom: 2px dashed rgba(0,0,0,0.1);
    width: 100%;
    flex: 1;
}

.join-workspace-space {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    h3 {
        background-color: #fafafb;
        max-width: 450px;
        padding: 15px 30px;
        box-sizing: border-box;
        border-radius: 8px;
        font-weight: 400;
        line-height: 27px;
        margin-top: 40px;
        span {
            font-weight: 600;
        }
    }
}

.join-workspace-space .top {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 350px;

    i {
        font-size: 25px;
        margin: 0 10px;
        background-color: #fafafb;
        padding: 10px;
        border-radius: 50%;
    }
}