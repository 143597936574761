.sidebar {
    background-color: #f9f9f9;
    background-color: #f3f2f1;
    border-right: 1px solid #edebe9;
    z-index: 9;
    position: relative;
    width: 235px;
    height: 100%;
    display: flex;
    flex-direction: column;
    // &.minimizing {
    //     .resizer {
    //         transition: width 150ms ease-in-out;
    //     }
    // }
    // &:hover {
    //     .resisable-trigger {
    //         opacity: 1 !important;
    //     }
    // }
}

.sidebar-inner {
    display: flex;
    flex-direction: column;
    padding-top: 10px;
}

.sidebar-item {
    flex: 1 1 auto;
    display: flex;
    align-items: center;
    height: 36px;
    padding: 0 12px;
    & > .name {
        margin: 0 8px;
        flex: 1;
        margin-right: 30px;
    }
    &:hover {
        background-color: #ffffff !important;
    }
    i,
    .icon {
        display: flex;
        align-items: center;
        width: 24px;
        height: 24px;
        text-align: center;
        justify-content: center;
        font-size: 16px;
        svg {
            width: 16px;
            height: 16px;
        }
    }
    .unread_count {
        margin-left: auto;
        padding: 0;
        padding-left: 5px;
        margin-right: 18px;
        min-width: 15px;
        text-overflow: ellipsis;
        white-space: nowrap;
        overflow: hidden;
        text-align: right;
        font-size: 0.81rem;
        color: #808080;
        font-weight: 400;
        line-height: 1.6;
    }
    &.active {
        // background-color: #ececec !important;
        color: $primary-dark;
        background-color: #ebebeb !important;
        .name {
            font-weight: 700;
        }
        .unread_count {
            color: #202020;
        }
    }
    .right {
        position: absolute;
        right: 15px;
    }
    .drop-trigger {
        visibility: hidden;
        width: 24px;
        height: 24px;
        i {
            color: #424242;
            margin-top: 3px;
            font-size: 18px;
        }
        &:hover {
            background-color: transparent;
        }
    }
    &:hover {
        .unread_count {
            display: none;
        }
        .drop-trigger {
            visibility: visible;
        }
    }
}

.side-add-button {
    display: flex;
    flex: 0 0 auto;
    padding: 8px 0 8px 12px;
    align-items: center;
    text-align: left;
    .title {
        width: 100%;
        font-size: 0.81rem;
        color: #808080;
        font-weight: 700;
        line-height: 1.6;
    }
    .side-add-icon {
        flex: 0 0 auto;
        width: 24px;
        height: 24px;
        margin-right: 10px;
        background: url(../assets/icons/svg/plus.svg) no-repeat;
        background-position: center;
    }
    &:hover {
        .title {
            text-decoration: underline;
        }
        .side-add-icon {
            background: url(../assets/icons/svg/plus_hover.svg) no-repeat;
            background-position: center;
        }
    }
    // i {
    //     flex: 0 0 auto;
    //     width: 24px;
    //     height: 24px;
    //     margin-right: 10px;
    //     display: flex;
    //     align-items: center;
    //     justify-content: center;
    // }
}

// .sidebar-items {
//     padding: 0 4px;
//     height: 100vh;
//     display: flex;
//     flex: 1;
//     flex-direction: column;
// }

// .item-count {
//     // margin-left: auto;
//     // background-color: rgb(223, 225, 230);
//     // color: rgb(23, 43, 77);
//     border-radius: 2em;
//     // padding: 2px 6px;
//     font-size: 12px;
//     // color: rgba(25, 23, 17, 0.6);
//     border-radius: 16px;
//     color: #fff;
//     display: inline-block;
//     font-size: 12px;
//     font-weight: 700;
//     height: 18px;
//     line-height: 18px;
//     margin-left: auto;
//     padding: 0 9px;
// }

.resize-line {
    div {
        cursor: e-resize !important;
        width: 13px !important;
        right: -13px !important;
        z-index: 9;
    }
}

// .vertical-tabs-space {
//     width: 100%;
//     height: 100%;
//     overflow: hidden;
// }

// .vertical-tabs {
//     width: 220px;
//     padding: 0 3px;
// }
// .vertical-tabs.hovering-resize {
//     border-right: 2px solid blue;
//     transition: background-color 300ms ease-in-out 200ms;
// }

.resize-line-custom {
    height: 100%;
    .line {
        display: block;
        height: 100%;
        width: 2px;
        background-color: transparent;
        margin-left: 10px;
        transition: background-color 300ms ease-in-out;
        &:before {
            content: " ";
            background: linear-gradient(
                to left,
                rgba(0, 0, 0, 0.03) 0px,
                rgba(0, 0, 0, 0.03) 1px,
                rgba(0, 0, 0, 0.02) 1px,
                rgba(0, 0, 0, 0) 100%
            );
            width: 10px;
            height: 100%;
            position: absolute;
            right: 10px;
            opacity: 0;
            transition: opacity 300ms cubic-bezier(0.2, 0, 0, 1);
        }
    }
}

.resizing .line:before {
    opacity: 1;
}

.resisable-trigger {
    width: 24px;
    height: 24px;
    position: absolute;
    top: 53px;
    z-index: 999;
    background-color: white;
    border-radius: 50%;
    right: -13px;
    opacity: 0;
    box-shadow: 0 0 0 1px rgba(9, 30, 66, 0.08), 0 2px 4px 1px rgba(9, 30, 66, 0.08);
    transition: background-color 100ms linear, color 100ms linear,
        opacity 300ms cubic-bezier(0.2, 0, 0, 1), transform 300ms cubic-bezier(0.2, 0, 0, 1);
    &:hover {
        background-color: #4c9aff;
        color: white;
    }
    &:active {
        background-color: #2684ff;
        color: white;
    }
}

.sidebar.show-resize {
    .resize-line-custom .line {
        background-color: rgb(76, 154, 255);
    }
    .resisable-trigger {
        opacity: 1;
    }
}

.workspace-trigger {
    height: auto;
    display: flex;
    width: 100%;
    font-weight: 400;
    padding: 4px;
    width: 100%;
    max-width: unset;
    font-size: 15px;
    // color: #42526e;
    color: #fff;
    font-weight: 400;
    padding-left: 6px;
    // padding-right: 7px;
}

.normal-trigger {
    height: 30px;
    display: flex;
    width: 100%;
    font-weight: 400;
    padding: 4px;
    width: 100%;
    max-width: unset;
    font-size: 15px;
    color: #42526e;
    font-weight: 400;
    // background-color: rgb(255, 255, 255);
    height: 100%;
    font-weight: 600;
    flex: 1 1 0%;
    padding: 4px 12px 5px;
    font-size: 14px;
    line-height: 17px;
    // box-shadow: rgba(0, 0, 0, 0.04) 0px 1px 1px 0px;
    overflow: hidden;
    border-radius: 100px;
    // border: 1px solid rgba(0, 0, 0, 0.1);

    &:hover {
        // background-color: rgb(239, 240, 243);
        // background-color: rgb(199, 224, 244);
        background-color: #eaeaea;

        .name {
            color: rgb(0, 69, 120) !important;
        }
    }
}

.workspace-dropdown {
    flex: 1;
    & .menu-dropdown > a {
        width: 100%;
    }
    margin: 0px 0 0px;
    .menu {
        width: 100% !important;
    }
}

.sidebar-header {
    height: 60px;
    display: flex;
    align-items: center;
    // padding: 0 8px;
}

.workspace-trigger {
    padding-left: 11px;
    padding-right: 10px;
    .left {
        // flex: 0 !important;
        align-items: center;
        img {
            width: 32px;
            height: 32px;
            margin-right: 10px;
            object-fit: cover;
            // border-radius: 4px;
        }
    }
    .workspace-info {
        display: flex;
        flex-direction: column;
        justify-content: center;
    }
    .name {
        font-size: 14px;
        font-weight: 600;
    }
    .description {
        font-size: 12px;
        opacity: 0.4;
        display: none;
    }
    .fa-angle-down {
        transition: all 0.2s ease-in 0s;
        width: 20px;
        text-align: center;
        font-size: 19px;
        line-height: 1;
        color: #8d8c8c;
    }
}

.normal-trigger {
    .left {
        // img {
        //     width: 30px;
        //     height: 30px;
        //     margin-right: 10px;
        //     border-radius: 4px;
        // }
        img {
            width: 20px;
            height: 20px;
            margin-right: 10px;
            border-radius: 50%;
            object-fit: cover;
            margin-left: -4px;
        }
    }
    .workspace-info {
        display: flex;
        flex-direction: column;
        justify-content: center;
    }
    .name {
        font-size: 14px;
        font-weight: 400;
    }
    .description {
        font-size: 12px;
        opacity: 0.4;
        display: none;
    }
    .fa-angle-down {
        transition: all 0.2s ease-in 0s;
        width: 20px;
        text-align: center;
        font-size: 19px;
        line-height: 1;
        color: #8d8c8c;
    }
    .cell-avatar {
        margin-right: 10px;
    }
}

.sidebar-section {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 6px 7px 6px 15px;

    .title {
        font-size: 12px;
        font-weight: 600;
        color: rgb(119, 120, 122);
    }
}

.sidebar-collapse {
    display: flex;
    // justify-content: space-between;
    // align-items: center;
    flex-direction: column;
    margin-bottom: 16px;
    .top {
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding: 6px 7px 6px 10px;
    }

    .title {
        font-size: 12px;
        font-weight: 700;
        color: #77787a;
        flex: 1 1;
        text-align: start;
        display: flex;
        align-items: center;
        color: rgba(25, 23, 17, 0.6);
        svg {
            margin-top: 1px;
            margin-left: 3px;
            opacity: 0;
        }
        &:hover {
            color: rgb(62, 62, 64);
            svg {
                opacity: 1;
            }
        }
    }
}

.sidebar-separator {
    width: 100%;
    border-top: 1px solid #e8eaed;
    margin: 7px 0;
}

.dragging-segment {
    z-index: 999;
    // background-color: #f7f8fa;
}

.sorting {
    .settings-tab {
        &:hover {
            background-color: transparent;
        }
    }
}

.sidebar-section-title {
    // text-transform: uppercase;
    // font-weight: 600;
    // font-size: 12px;
    // color: rgba(0, 0, 0, 0.4);
    // margin: 10px 14px 4px;
    padding: 6px 16px 2px 16px;
    font-size: 0.875rem;
    color: #808080;
    font-weight: 600;
    line-height: 1.7;
}

.settings-tab.inbox-side-item {
    font-size: 14px;
    padding-top: 8px;
    padding-bottom: 8px;
    max-height: 32px;
    i {
        font-size: 14px;
        width: 20px;
        margin-right: 8px;
        margin-left: -6px;
        text-align: center;
        // opacity: 0.5;
    }
    img,
    .default-letter {
        width: 16px;
        height: 16px;
        object-fit: cover;
        border-radius: 50%;
        margin-left: -4px;
        margin-right: 11px;
    }
}

.settings-tab.go-back-item {
    font-size: 16px;
    max-height: 42px;
    // color: rgba(0, 0, 0, 0.7);
}

.vertical-tabs {
    .fa-plus-circle {
        opacity: 0;
        font-size: 14px;
    }
    &:hover {
        .fa-plus-circle {
            opacity: 1;
            transition: opacity 0.15;
        }
    }
}

.sidebar.dark-theme {
    .vertical-tabs {
        // background-color: #30374d;
        // background-color: #36393f;
        background-color: #36393f;
        color: white;
    }
    i {
        opacity: 0.6;
    }
    .name,
    .title,
    .item-count {
        // opacity: 0.6;
        // color: white;
        // color: #b7babe;
    }
    .fa-plus-circle {
        opacity: 0;
    }
    .vertical-tabs:hover .fa-plus-circle {
        opacity: 1;
    }
    .drop-trigger:hover,
    .settings-tab:hover {
        background-color: rgba(255, 255, 255, 0.08);
    }
    .settings-tab.active {
        // background-color: rgba(255, 255, 255, 0.3);
        background-color: rgba(114, 118, 125, 0.4);
        color: #ffffff;
        .name,
        i,
        image,
        .item-count {
            opacity: 1 !important;
            // color: rgba(255, 255, 255, 0.9);
        }
    }
    .settings-tab:hover {
        background-color: #292b2f;
    }
    .drop-trigger:hover i,
    .drop-trigger:hover .icon-trigger {
        color: #ffffff;
        opacity: 1;
    }
    .select-trigger svg {
        color: white;
    }
    .workspace-info .name {
        opacity: 1;
    }
    // .sidebar-section-title,
    // .go-back-item {
    //     color: rgba(255, 255, 255, 0.6);
    // }
    .minimized-separator {
        background-color: rgba(255, 255, 255, 0.3);
    }
    .inbox-side-item {
        i {
            // opacity: 1;
            // color: #808082;
        }
    }
    .sidebar-collapse {
        .title {
            opacity: 0.6;
            text-transform: uppercase;
            font-size: 10px;
        }
        svg {
            color: #808082 !important;
        }
    }
}

.minimize-side-item {
    display: none;
    margin-top: auto;
    margin-bottom: 10px;
    i {
        margin-left: auto;
        margin-right: 0 !important;
    }
}

.vertical-tabs:hover {
    .minimize-side-item {
        display: block;
    }
}

.minimized-separator {
    width: 100%;
    height: 1px;
    background-color: rgba(0, 0, 0, 0.1);
    display: none;
    margin-bottom: 15px;
}

.sidebar.minimized {
    width: 48px;
    .sidebar-inner,
    .fakes-sidebar, #new-message, #update-inbox {
        display: none;
    }
    // .vertical-tabs {
    //     width: 48px;
    // }
    // .sidebar-collapse .top,
    // .workspace-info {
    //     display: none;
    // }
    // .workspace-dropdown {
    //     .workspace-trigger {
    //         padding: 0px;
    //     }
    //     img {
    //         margin: 0;
    //     }
    //     .drop-trigger:hover {
    //         background-color: transparent;
    //     }
    // }
    // .settings-tab {
    //     padding: 8px 0 !important;
    //     justify-content: center !important;
    //     & > span {
    //         margin-right: 0 !important;
    //         margin-left: 0 !important;
    //     }
    //     .name,
    //     .item-count {
    //         display: none;
    //     }
    //     .right {
    //         display: none;
    //     }
    //     img,
    //     i {
    //         margin: 0 !important;
    //     }
    // }
    // .minimize-side-item {
    //     display: block;
    //     .fa-arrow-left {
    //         transform: rotate(180deg);
    //     }
    // }
    // // .minimized-separator {
    // //     display: block;
    // // }
}

.sidebar.white {
    background-color: white;
    .sidebar-item:hover {
        background-color: #f4f4f4 !important;
    }
}

.loading-sidebar-shadows {
    width: 100%;
    div {
        background-color: rgba(0, 0, 0, 0.05);
        width: calc(100% - 20px);
        height: 18px;
        border-radius: 4px;
        margin: 10px 10px;
    }
}

.settings-side-items {
    padding-bottom: 100px;
    i {
        // color: #b8babc;
        // margin: 0 13px 0 13px;
        font-size: 17px;
    }
}

.fakes-sidebar {
    padding: 30px 40px 20px 15px;
    .fake-row {
        margin-bottom: 10px;
        display: flex;
        align-items: center;
        height: 20px;
        .fake-line {
            background-color: #ececec;
            border-radius: 3px;
            height: 8px;
            width: 100%;
            &.small {
                max-width: 120px;
            }
        }
        .fake-circle {
            margin-left: 20px;
            background-color: #ececec;
            border-radius: 50%;
            min-width: 20px;
            width: 20px;
            height: 20px;
        }
    }
}

.browse-filters-title {
    height: 32px;
    background: #f9f9f9;
    font-size: 0.875rem;
    color: #808080;
    font-weight: 700;
    line-height: 1.7;
    text-align: left;
    padding: 0 10px;
    position: sticky;
    top: -1px;
    border-bottom: 1px solid #dcdcdc;
    display: flex;
    align-items: center;
    margin-bottom: 10px;
}

.browse-filter-item {
    margin: 5px;
    border: 1px solid #dcdcdc;
    border-radius: 50px;
    padding: 5px 10px;
    display: inline-flex;
    align-items: center;
    height: 31px;
    box-sizing: border-box;
    .name {
        margin-left: 7px;
        font-weight: 500;
    }
    &.selected {
        background-color: #ecf0f1;
        // border-color: #7ba6eb;
        .fa {
            color: #3f82ef;
            margin-left: 7px;
        }
    }
}

.browse-filters-section {
    display: flex;
    flex-wrap: wrap;
}

// MEDIA RULES

// @media only screen and (max-width: 1400px) {
//     .normal-trigger {
//         .name {
//             font-size: 12px;
//             font-weight: 600;
//             white-space: nowrap;
//         }
//     }

//     .normal-trigger {
//         white-space: nowrap;
//         font-size: 12px;
//     }
// }
