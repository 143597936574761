.business-day-block {
    margin-bottom: 20px;
    background-color: #f8f9fb;
    padding: 20px;
    display: flex;
    flex-direction: column;

    .day-left {
        width: 150px;
        margin-bottom: 20px;
    }
    .day-right {
        flex: 1;
        display: flex;
        justify-content: center;
        flex-direction: column;
    }

    .hour-block {
        display: flex;
        // justify-content: flex-end;
        align-content: center;
        margin-bottom: 10px;

        .until {
            margin: 0 20px;
        }

        .time-diff {
            margin: 0 30px;
            width: 150px;
        }

        .until,
        .time-diff,
        .actions {
            display: flex;
            align-items: center;
        }

        .select-space {
            width: 100px;
        }

        .actions {
            .btn {
                margin-left: 5px;
            }
        }
        .label-input {
            margin-bottom: 5px;
        }
    }
}

.closed {
    font-size: 12px;
    font-weight: 700;
    color: #606f81;
}
