.page-bot {
    display: flex;
    background-color: #fff;
    // background-color: #fafafa;
    // min-height: calc(100vh - 55px);
    // min-height:-+ 600px;
    padding-bottom: 0;
    border-radius: 6px;
    // overflow: hidden;

    .flow {
        // padding: 0 30px;
        flex: 1;
        max-width: 400px;
        // display: flex;
        flex-direction: column;
        align-items: center;
        & .event-box:last-child {
            margin-bottom: 200px;
        }
    }
    .context-header {
        border-bottom: 1px solid rgba(0, 0, 0, 0.1);
    }
}

.page-bot-content {
    display: flex;
    flex: 1;
    overflow: hidden;
    .bot-sidebar {
        border-right: 1px solid #edebe9;
        z-index: 9;
        position: relative;
        width: 220px;
        height: 100%;
        display: flex;
        overflow-y: auto;
    }
    .bot-mid {
        display: flex;
        justify-content: center;
        flex: 1;
        padding: 30px 30px;
        background-size: 10px 10px;
        background-image: linear-gradient(to right, rgba(0, 0, 0, 0.02) 1px, transparent 1px),
            linear-gradient(to bottom, rgba(0, 0, 0, 0.02) 1px, transparent 1px);
        background-color: #f4f4f4;
        overflow-y: auto;
        // background-image: radial-gradient(circle, rgba(0, 0, 0, 0.05) 1px, rgba(0, 0, 0, 0) 1px);
    }
}

.box.event-box-space {
    border-radius: 6px;
    padding: 30px 25px;
    background-color: #fff;
    box-sizing: border-box;
    cursor: move;
    z-index: 5;
    // border-top-right-radius: 0;
    // border-top-left-radius: 0;
}

.event-title {
    font-size: 20px;
    margin-bottom: 20px;
}

.event-options {
    margin-top: 20px;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    button {
        border-radius: 100px;
        font-weight: 500;
    }
    .bot-menu-item {
        display: flex;
        align-items: center;
        & > span {
            margin-left: 15px;
            opacity: 0.45;
        }
        i {
            margin-right: 7px;
        }
    }
}

/* Bot Header
===================================*/
.bot-header {
    padding: 50px 30px 44px;
    background-color: #fafafb;
    margin-bottom: 30px;
    border-radius: 12px;
    margin-top: 20px;

    // position: sticky;
    // top: 30px;
    display: flex;
    img {
        border-radius: 50%;
        width: 55px;
        height: 55px;
    }
}

.bot-setting-right {
    display: flex;
    align-items: center;
    flex: 1;
    .text-space {
        margin-left: 25px;
        width: 400px;
        .label-input {
            margin-bottom: 0;
            font-size: 12px;
            color: rgba(0, 0, 0, 0.6);
        }
    }
}

/* Path
====================== */
.page-bot .paths {
    display: flex;
    flex-direction: column;
    // max-width: 240px;
    width: 100%;
    background-color: #fff;
    // background-color: #f5f5f5;
    // padding: 0 20px 0 0;
    box-sizing: border-box;
    margin-top: 10px;
    a {
        color: #575c62;
    }
    button {
        background: transparent;
        border: 1px;
        color: #575c62;
        margin: 0px 0;
        border: 1px solid #b2bbbd;
        border-radius: 100px;
        font-weight: 500;
        margin: 10px auto;
        font-size: 12px;
        text-transform: uppercase;
        padding: 5px 16px;
    }
    // font-size: 16px;
    // font-weight: 500;
}

.bot-menu-path-link {
    height: 40px;
    padding: 18px 20px;
    box-sizing: border-box;
    display: flex;
    align-items: center;
    font-size: 15px;
    background-color: #fafafb;
    // border: 1px solid rgba(0, 0, 0, 0.1);
    margin-bottom: 10px;
    border-radius: 33px;

    i {
        margin-right: 15px;
        font-size: 17px;
    }

    &:hover {
        // background-color: rgba(9, 30, 66, 0.04);
        background-color: #ececec;
        color: rgb(66, 82, 110);
        fill: rgba(9, 30, 66, 0.04);
        text-decoration: none;
    }
    &.active {
        // background-color: #dcdcdc;
        // font-weight: 500;
        // color: rgb(10, 10, 10) !important;

        // border-color: #3498db;
        // border-width: 1px;
        // font-weight: 500;
        // color: #3498db !important;
        // background-color: #e9f3f9;
        // border-color: #55c6ac;
        border-width: 1px;
        font-weight: 500;
        color: #4793b6 !important;
        background-color: #c6e5f8;
    }
}

/* Event Box
====================== */
.event-box {
    max-width: 400px;
    width: 100%;
    z-index: 5;

    .bot-image {
        width: 30px;
        height: 30px;
        border-radius: 50% 4px 50% 50%;
        object-fit: cover;
        // margin-right: 5px;
        &.user {
            border-radius: 4px 50% 50%;
            margin-left: -15px;
        }
    }
}

.event-box-header {
    // background-color: #3e474f;
    // border: 1px solid #3e474f;
    // color: white;
    // border-radius: 4px 4px 0 0;
    border-radius: 4px;
    height: 45px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0 10px 0 22px;
    font-weight: 500;
    font-size: 16px;
    i {
        margin-right: 16px;
    }
}

.event-box-space {
    padding: 15px;
    .clamp-lines {
        max-height: 165px;
        // font-size: 15px;
        overflow: hidden;
        // color: #7b8b8e;
        text-overflow: ellipsis;
        display: -webkit-box;
        -webkit-line-clamp: 2;
    }
}

.event-box-message {
    border-radius: 4px 12px 12px;
    background-color: $primary;
    color: white;
    padding: 12px 17px;
    margin-left: 5px;
    pre {
        margin: 0;
        font-family: inherit;
    }
    &.user {
        border-radius: 20px 4px 20px 20px;
        background-color: #e8eff7;
        margin-left: auto;
        max-width: calc(100% - 110px);
        margin-right: 5px;
        color: rgba(0, 0, 0, 0.8);
    }
    &.system {
        border-radius: 20px;
        background-color: #e8eff7;
        text-align: center;
        font-weight: 500;
        color: rgba(0, 0, 0, 0.8);
    }
}

.event-box-separator {
    height: 40px;
    width: 8px;
    background-color: rgba(0, 0, 0, 0.08);
    border-radius: 10px;
    margin: 0 auto;
}

/* Menu
================= */
.menu-option {
    display: flex;
    align-items: center;
    margin-bottom: 10px;
    .input-space {
        flex: 1;
    }
    // .text-space {
    //     flex: 2;
    // }
    .select-space {
        flex: 1;
        font-size: 14px;
        margin-left: 10px;
    }
    button {
        margin-left: 10px;
    }
}

/* Integration
================= */
.integration-option {
    margin-bottom: 10px;
    .text-space {
        flex: 2;
    }
    .select-space {
        flex: 1;
        font-size: 14px;
        margin-left: 10px;
    }
    button {
        margin-left: 10px;
    }
}

/* Preview
================= */
.bot-preview {
    width: 440px;
}

.bot-paths-space {
    position: relative;
    display: flex;
    align-items: center;
    .path-edit {
        opacity: 0;
    }
    &:hover .path-edit {
        opacity: 1;
    }
}

.path-grabbing {
    cursor: grabbing;
    background-color: white;
    // // // // box-shadow: 1px 1px 1px 1px rgba(0, 0, 0, 0.1);
    // border: 1px solid rgba(0, 0, 0, 0.1);
    box-shadow: 0 5px 5px -5px rgba(0,0,0,.2), 0 -5px 5px -5px rgba(0,0,0,.2);
}

.same-line-row {
    display: flex;
    margin-top: 10px;

    .input-space:first-child {
        margin-right: 20px;
    }
}