// Base
@import "base/variables.scss";
@import "base/global.scss";
@import "base/text.scss";
@import "base/modules";
@import "base/forms.scss";
@import "base/icons.scss";
@import "icons/fontawesome.scss";
@import "icons/regular.scss";
@import "icons/brands.scss";
@import "icons/light.scss";
@import "icons/solid.scss";

// Layout
@import "layout/basic_layout.scss";
@import "layout/panel_layout.scss";
@import "layout/page_layout.scss";

// Modules
@import "~react-table/react-table.css";
@import "components/dialog.scss";

/* Components Forms */
@import "components/forms/checkbox.scss";
@import "components/forms/switch.scss";
@import "components/forms/button.scss";
@import "components/forms/dropzone.scss";

/* Components */
@import "components/page_table.scss";
@import "components/sidenav.scss";
@import "components/dropdown.scss";
@import "components/date_range.scss";
@import "components/filter_tag.scss";
@import "components/misc.scss";
@import "components/vertical_tabs.scss";
@import "components/editable_prop.scss";
@import "components/box.scss";
@import "components/modal.scss";
@import "components/avatar.scss";
@import "components/select.scss";
@import "components/table.scss";
@import "components/list_heading.scss";
@import "components/webcam.scss";
@import "components/auth.scss";
@import "components/menu.scss";
@import "components/loading.scss";
@import "components/toast.scss";
@import "components/widget.scss";
@import "components/assign_conditions.scss";
@import "components/subheader.scss";
@import "components/filters.scss";
@import "components/help_banner.scss";
@import "components/tab_header.scss";
@import "components/panel_detail.scss";
@import "components/quick_phrases.scss";
@import "components/scrollbars.scss";
@import "components/info_box.scss";
@import "components/page_header.scss";
@import "components/tags.scss";
@import "components/sidebar.scss";
@import "components/menu_drop.scss";
@import "components/chat_side.scss";
@import "components/header.scss";
@import "components/chatnav.scss";
@import "components/tooltip.scss";
@import "components/context_header.scss";
@import "components/banner_notification.scss";
@import "components/collapse.scss";

// Pages
@import "pages/landing.scss";
@import "pages/pricing.scss";
@import "pages/inbox.scss";
@import "pages/settings.scss";
@import "pages/answers.scss";
@import "pages/dashboard.scss";
@import "pages/businesshours.scss";
@import "pages/reports.scss";
@import "pages/people.scss";
@import "pages/bot.scss";
@import "pages/contracts.scss";
@import "pages/integrations.scss";
@import "pages/power_ups.scss";
@import "pages/ganhomais.scss";
@import "pages/join.scss";
@import "pages/billing.scss";
@import "pages/channels.scss";
@import "pages/campaign.scss";

// Must be later
@import "components/tooltip.scss";
@import "monster.scss";

body {
    margin: 0;
    padding: 0;
    // font-family: "sofia", "Roboto", "Whitney", "Segoe UI", "Roboto", "Oxygen", "Roboto", "Cantarell",
    // "Roboto", "Droid Sans", "Helvetica Neue", sans-serif;
    font-family: $font_family;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    // background-color: #f4f1ec;
    background-color: $background;
    height: 100%;
    // backface-visibility: hidden;
    font-size: 14px;
    // line-height: 1.42857143;
    color: $text-color;
    text-rendering: optimizelegibility;

    // a, button {
    //     transition: color .1s linear,background-color .1s ease-in;
    // }
}

input,
textarea,
select,
button {
    font-family: $font_family;
}

button {
    margin: 0;
    padding: 0;
}

// @font-face {
//     font-family: "oi";
//     src: url("/assets/fonts/QuicksandNovaRegular.ttf");
// }

// @font-face {
//     font-family: 'Roboto';
//     src: local('MyFont'), url('../assets/fonts/Roboto Nova Regular.woff') format('woff');
//   }
// @font-face {
//     font-family: "Inter";
//     src: local("MyFont"), url(../assets/fonts/font.woff) format("woff");
// }

@font-face {
    font-family: "Whitney";
    src: url(../assets/fonts/whitney-light.woff) format("woff");
    font-weight: 300;
    font-style: normal;
}
// @font-face {
//     font-family: 'Whitney';
//     src: url(../assets/fonts/whitney-book.woff) format('woff');
//     font-weight: 400;
//     font-style: normal;
// }
@font-face {
    font-family: "Whitney";
    src: url(../assets/fonts/whitney-medium.woff) format("woff");
    font-weight: 500;
    font-style: normal;
}
// @font-face {
//     font-family: 'Whitney';
//     src: url(../assets/fonts/whitney-semibold.woff) format('woff');
//     font-weight: 600;
//     font-style: normal;
// }
@font-face {
    font-family: "Whitney";
    src: url(../assets/fonts/whitney-bold.woff) format("woff");
    font-weight: 700;
    font-style: normal;
}
// @font-face {
//     font-family: 'Whitney';
//     src: url(../assets/fonts/whitney-blacksc.woff) format('woff');
//     font-weight: 900;
//     font-style: normal;
// }

/* Roboto
======================== */
// @font-face {
//     font-family: 'Roboto';
//     src: url(../assets/fonts/QuicksandNova-Light.woff) format('woff');
//     font-weight: 300;
//     font-style: normal;
// }
// @font-face {
//     font-family: 'Roboto';
//     src: url(../assets/fonts/QuicksandNova-Regular.woff) format('woff');
//     font-weight: 400;
//     font-style: normal;
// }
// @font-face {
//     font-family: 'Roboto';
//     src: url(../assets/fonts/QuicksandNova-Semibold.woff) format('woff');
//     font-weight: 500;
//     font-style: normal;
// }
// @font-face {
//     font-family: 'Roboto';
//     src: url(../assets/fonts/QuicksandNova-Bold.woff) format('woff');
//     font-weight: 700;
//     font-style: normal;
// }

/* Sofia
======================== */
// @font-face {
//     font-family: "sofia";
//     src: url(../assets/fonts/sofia-light.woff2) format("woff2");
//     font-weight: 300;
//     font-style: normal;
// }
// @font-face {
//     font-family: "sofia";
//     src: url(../assets/fonts/sofia-regular.woff2) format("woff2");
//     font-weight: 400;
//     font-style: normal;
// }
// @font-face {
//     font-family: "sofia";
//     src: url(../assets/fonts/sofia-bold.woff2) format("woff2");
//     font-weight: 500;
//     font-style: normal;
// }

/* Icones
======================== */
// @font-face {
//     font-family: "icomoon";
//     src: url(../assets/fonts/icomoon.woff) format("woff");
// }

// .mdc-dialog-scroll-lock #root {
//     height: 100%;
//     overflow: hidden;
// }

// body.mdc-dialog-scroll-lock {
//     overflow: visible;
//     overflow-y: scroll;
// }

code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New", monospace;
}

/* Modules */

// @import "modules/mdc.button.min.css";
// @import "modules/mdc.textfield.min.css";
// @import "@material/textfield/mdc-text-field";
// label {
//     --mdc-theme-primary: #abd733;
// }

// button.dark {
//     --mdc-theme-primary: #363636;;
// }
