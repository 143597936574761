// .ScrollbarsCustom-Track.ScrollbarsCustom-TrackY {
//     background: transparent !important;
//     width: 6px !important;
// }

// .ScrollbarsCustom-Thumb.ScrollbarsCustom-ThumbY {
//     background: rgba(0, 0, 0, 0.1) !important;
// }

.simplebar-scrollbar:before {
    background: rgba(0,0,0,0.2) !important;
}

.simplebar-horizontal {
    display: none;
}

.ScrollbarsCustom-Track {
    background-color: #e4e5e6 !important;
    border-radius: 0 !important;
    height: 100% !important;
    top: 0 !important;
}

.ScrollbarsCustom-Thumb {
    background-color: #cdcecf !important;
    border-radius: 0 !important;
}