.btn {
    position: relative;
    font-size: 14px;
    line-height: 18px;
    font-weight: 500;
    border: 1px solid;
    border-color: rgba(0, 0, 0, 0.2);
    // border-color: rgba(0, 0, 0, 0.2);
    border-radius: 4px;
    padding: 7px 16px 7px;
    display: inline-flex;
    text-decoration: none;
    cursor: pointer;
    user-select: none;
    background-color: #fff;
    color: #222;
    align-items: center;
    // outline: none;
    box-sizing: border-box;
    // max-height: 32px;
    // border: 1px solid #D3D8E0;
    text-shadow: none;
    transition: all 0.1s cubic-bezier(0, 0, 0, 1), visibility 1ms;
    box-shadow: none !important;

    /* Light Version */
    &.light {
        color: #505f79;
        background: #f4f5f7;
        border: none;
        height: 32px;
        font-weight: 400;
        &:hover {
            background: #ebecf0;
            color: #505f79;
            border: none;
        }
        // &:focus {
        //     box-shadow: none;
        // }
    }

    i {
        font-size: 17px;
        margin-right: 8px;
        // position: absolute;
        // left: 11px;
    }
    .btn-icon-right {
        // font-size: 19.5px;
        // margin-left: 2px;
        // margin-right: -4px;
        // position: initial;
        // height: 18px;
        margin-left: auto;
        position: absolute;
        right: 10px;
        margin: 0;
        color: #2a2a2a;
    }

    &:hover {
        // color: $primary;
        // border: 1px solid $primary;
        // box-shadow: none;
        // background-color: #fff;
        // background-color: #e1e4e9;
        // border-color: #e1e4e9;
        background-color: #f4f4f4;
        color: rgba(0, 0, 0, 0.9);
    }
    &:active {
        // box-shadow: 0 0 0 3px rgba(40, 110, 250, 0.2);
        box-shadow: none;
        transform: scale(0.95);
    }
    // &:active,
    // &.light:active {
    //     color: $primary-hover;
    //     background-color: #e8f0ff;
    //     box-shadow: none;
    // }
    &.only-icon {
        // padding-left: 36px;
        padding: 5px 12px 5px;
        i {
            margin: 0;
            // font-size: 24px;
            // color: #2ecc71;
        }
    }
    &.only-icon.danger {
        i {
            color: #e74c3c;
        }
    }

    &.only-icon.simple {
        background: transparent;
        border: none;
        i {
            color: rgba(0,0,0,0.45);
        }
        &:hover i {
            color: rgba(0,0,0,0.55);
        }
    }

    &.big {
        padding: 9px 23px !important;
    }
    .loading-icon {
        display: none;
    }
    &.small {
        padding: 4px 11px;
        font-size: 12px;
        font-weight: 400;
        i {
            font-size: 13px;
        }
    }

    &.listItem {
        width: 100%;
        border: none;
        // border-radius: 0;
        padding: 7px 10px;
        font-size: 15px;
        font-weight: 400;
        i {
            margin-right: 15px;
        }
    }

    &.full {
        width: 100%;
        flex: 1;
        justify-content: center;
    }
}

// .btn:focus {
// outline: none;
// overflow: hidden;
// &::before {
//     content:"";
//     width: 100%;
//     height: 100%;
//     position: absolute;
//     background-color: rgba(0,0,0,0.05);
//     left: 0;
//     top: 0;
// }
// }

body:not(.user-is-tabbing) button:focus,
body:not(.user-is-tabbing) input:focus,
body:not(.user-is-tabbing) select:focus,
body:not(.user-is-tabbing) textarea:focus {
    outline: none;
}

/* 
* Icon buttons
*/

.btn.only-icon {
    &.round {
        border-radius: 50%;
        border: none;
        background-color: transparent;
        // padding: 8px;
        width: 25px;
        height: 25px;
        padding: 0;
        display: flex;
        align-items: center;
        justify-content: center;
        i {
            color: #798d99;
        }
        &:hover {
            i {
                color: #092d42;
            }
            background-color: rgba(9, 45, 66, 0.13);
        }
    }
}

/* 
* Themed buttons
*/
.btn.primary {
    background-color: $primary-button;
    // box-shadow: 0 1px 0 0 $primary-button-shadow;
    box-shadow: rgba(0, 0, 0, 0.15) 0px 1px 3px 0px;
    border: none;
    // color: #fff;
    color: white;
    &:hover,
    &:active {
        // border-color: rgba(0, 0, 0, 0.2);
        background-color: $primary-button-hover;
    }
    // &:focus {
    //     box-shadow: 0 0 0 3px rgba(40, 110, 250, 0.2);
    // }
}
.btn.danger {
    background-color: #eb5a46;
    color: #fff;
    border: none;
    box-shadow: 0 1px 0 0 #b04632;
    &:hover {
        background-color: #cf513d;
    }
}

.btn.success {
    background-color: #2ecc71;
    color: #fff;
    border: none;
    box-shadow: 0 1px 0 0 #27ae60;
    &:hover {
        background-color: #27ae60;
    }
}

.btn.cancel {
    border: 1px solid transparent;
    color: rgb(80, 95, 121) !important;
    font-weight: 400;
    background-color: transparent;

    &:hover,
    &:active {
        background: #ebecf0;
        color: #505f79;
    }
    &:focus {
        box-shadow: 0 0 0 3px rgba(40, 110, 250, 0.2);
    }
}

.btn.gray {
    background-color: #dfe3e6;
    box-shadow: 0 1px 0 0 #c2ccd1;
    border: none;
    // border-radius: 3px;
    height: 32px;
    font-weight: 600;
    padding: 8px 17px;
    // font-size: 15px;
    &:hover {
        background-color: #c2ccd1;
        box-shadow: 0 1px 0 0 #b3bec4;
        border: none;
    }
    &:active {
        background-color: #17394d;
        box-shadow: 0 1px 0 0 #092d42;
        border: none;
        color: #fff;
    }
    i {
        color: #798d99;
        font-size: 15px;
        margin-right: 10px;
        margin-left: -5px;
        margin-top: -2px;
    }
}

.btn.ios {
    background-color: transparent;
    box-shadow: none;
    border-radius: 20px;
    &:hover {
        background-color: #f6f6f6;
        box-shadow: 1px 1px 1px 1px #e1e1e1;
        border-color: transparent;
    }
    &:active {
        background-color: #f6f6f6;
        box-shadow: 0 1px 0 0 #d7d7d7;
        border: none;
        color: #fff;
    }
}

.btn.action {
    font-weight: 400;
    background-color: #f2f2f2;
    border-color: #f2f2f2;
    color: #6b7075 !important;
    i {
        color: #6b7075;
    }

    &:hover,
    &:active {
        background-color: #d3d8e0;
        border-color: #d3d8e0;
        color: #1f2b36;
    }
    &.only-icon {
        padding: 8px;
        display: flex;
        align-items: center;
        justify-content: center;
        i {
            font-size: 17px;
        }
    }
    // &:focus {
    //     box-shadow: 0 0 0 3px rgba(40, 110, 250, 0.2);
    // }
}

.btn-text {
    display: flex;
    align-items: center;
}

.btn.loading {
    pointer-events: none;
    background-color: rgba(0, 0, 0, 0.1);
    box-shadow: 0 1px 0 0 rgba(0, 0, 0, 0.2);
    // border: 1px solid #95b6f7;
    .loading-icon {
        position: absolute;
        left: 0;
        right: 0;
        display: block;
        animation: rotation 0.7s infinite linear;
        width: 20px;
        margin: 0 auto;
        font-size: 15px;
    }
    .btn-text {
        visibility: hidden;
    }
}
