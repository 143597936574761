.menu {
    border-radius: 4px;
    background: #fff;
    // box-shadow: 0 4px 14px 0 rgba(0, 0, 0, 0.2), 0 0 0 1px rgba(0, 0, 0, 0.05);
    box-shadow: 0 0 0 1px rgba(0,0,0,.08), 0 4px 12px 0 rgba(0,0,0,.12);
    padding: 15px 0;
    box-sizing: border-box;
    transition: opacity 0.15s ease-in-out, transform 0.15s ease-in-out;
    z-index: 999;
}

.menu-row {
    display: flex;
    padding: 7px 25px;

    .nav-user-space {
        height: auto;
    }
    .right {
        display: flex;
        flex-direction: column;
        margin-left: 20px;
        margin-top: -1px;

        .name {
            font-weight: 500;
            font-size: 15px;
            line-height: 15px;
        }
        .profile {
            font-weight: 400;
            color: #888;
            font-size: 15px;
            line-height: 24px;
        }
    }
}

.menu-space {
    position: relative;
    .separator {
        height: 1px;
        width: 100%;
        background-color: rgba(0, 0, 0, 0.1);
        margin: 3px 0 10px;
    }
}

.menu {
    position: absolute;
    bottom: 6px;
    left: 72px;
    width: 242px;
    color: #222;
    // transition: opacity 3.3s ease-in-out, left .3s ease-in-out, visibility .3s ease-in-out;
    // opacity: 0;

    // &.show {
    //     opacity: 1;
    //     left: 62px;
    // }
    a {
        font-size: 15px;
        font-weight: 500;
    }
}

.menu-inner {
    position: relative;
    &:after,
    &:before {
        right: 100%;
        border: solid transparent;
        content: " ";
        height: 0;
        width: 0;
        position: absolute;
        pointer-events: none;
        bottom: 5px;
    }

    &:after {
        border-color: rgba(255, 255, 255, 0);
        border-right-color: #fff;
        border-width: 7px;
        margin-top: -6px;
    }
    &:before {
        border-color: rgba(220, 220, 220, 0);
        border-right-color: #757575;
        border-width: 7px;
        margin-top: -7px;
    }
}

/* Animations
========================== */
.menu-fade-enter {
    opacity: 0.01;
    left: 72px;
}

.menu-fade-enter.menu-fade-enter-active {
    opacity: 1;
    left: 62px;
    transition: opacity 300ms ease-in, left 300ms ease-out;
}

.menu-fade-leave {
    left: 62px;
    opacity: 1;
}

.menu-fade-leave.menu-fade-leave-active {
    opacity: 0;
    left: 72px;
    transition: opacity 200ms ease-in, left 200ms ease-out;
}

/* Menu Dropdown
========================== */

.menu-dropdown {
    position: relative;
    .menu {
        top: 36px;
        bottom: auto;
        left: auto;
        right: 0;
        padding: 0px 0;
        a {
            // display: block;
            margin-left: 0;

            display: flex;
            align-items: center;
        }
    }
}

.drop-option:hover {
    background-color: $primary;
    color: white;
}

.drop-option {
    display: flex !important;
    align-items: center;
    i {
        margin-right: 6px;
        font-size: 17px;
    }
}




.menu-available {
    display: flex;
    align-items: center;
    justify-content: space-between;
    span {
        font-size: 15px;
        margin-left: 10px;
    }
}
.user-menu-basic {
    display: flex; 
    align-items: center;
    margin-left: 10px;
    .left {
        position: relative;
    }
    img {
        width: 35px;
        height: 35px;
        border-radius: 50%;
    }
    .right {
        display: flex;
        flex-direction: column;
        margin-left: 20px;
        .name {
            font-weight: 500;
        }
        
    }
}
.user-menu {
    li {
        padding: 0 15px;
    }
}

.status-indicator {
    position: absolute;
    right: 0;
    bottom: 4px;
    width: 10px;
    height: 10px;
    background-color: #1bb157;
    border-radius: 50%;
    border: 2px solid white;
    &.side {
        border-color: $sidenav;
        bottom: -2px;
    }
}