.page-layout {
    display: flex;
    // padding-top: 55px;
    padding-bottom: 30px;
    box-sizing: border-box;
    height: calc(100vh - 40px);
    // color: #2c3b48;
}

.page-layout.tabs {
    // padding: 0 20px 30px;
    // height: 100vh;
    // flex-direction: column;
}

.page-inner {
    margin: 25px 30px 25px 50px;
    box-sizing: border-box;
    // &.small {
    //     margin-top: 0;
    //     padding: 10px;
    // }
    // &.flex {
    //     display: flex;
    //     align-items: center;
    // }
}

.page-layout.dashboard {
    padding-bottom: 0;
    padding: 8px;
    // background-color: #f7f7f7;
    min-height: calc(100vh - 55px);
    .page-inner {
        width: auto;
    }
}