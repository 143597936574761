.landing-space .pricing {
    font-family: Whitney, sans-serif;
    h1 {
        font-weight: 600;
    }

    h3 {
        font-weight: 300;
        font-size: 20px;
    }

    .landing-button-connect {
        padding: 12px 0px;
        width: 100%;
        font-size: 17px;
        font-weight: 300;
        margin-top: 20px;
    }

    .pro .landing-button-connect {
        background-color: $primary;
        color: white;
        box-shadow: 0 0 0 1px rgba(0, 0, 0, 0.02), 0 1px 5px 0 rgba(100, 136, 204, 0.06),
            0 4px 15px 0 rgba(0, 0, 0, 0.07);
        border: 1px solid $primary;
        &:hover {
            background-color: $primary-dark;
        }
    }
    .fal.fa-arrow-down {
        display: block;
        margin: 40px 0 0;
        font-size: 38px;
        color: #367fee;
    }
}

.pricing-midcol { 
  max-width: 1300px;
  width: 100%;
  margin: 0 auto;
  padding: 0 20px;
  box-sizing: border-box;
  position: relative;
}

.pricing-plans {
    display: flex;
    justify-content: center;
    align-items: flex-start;
    margin-top: 16px;
}

.princing-plan-card {
    flex-basis: calc(100% / 4 - 30px);
    text-align: center;
    background: #fff;
    box-shadow: 0 12px 30px rgba(30, 39, 97, 0.04), 0 2px 14px rgba(19, 24, 57, 0.06);
    border-radius: 24px;
    padding: 55px 22px;
    margin: 0 20px;
}

.pricing-plan-name {
    font-weight: 700;
    font-size: 32px;
    margin-bottom: 16px;
    color: #4d4f51;
    margin-top: 0;
}
.pricing-plan-description {
    color: #888d92;
    line-height: 1.5;
    margin-bottom: 24px;
    font-size: 16px;
    font-weight: 300;
    letter-spacing: 0.7px;
}

.princing-plan-price {
    color: #4d4f51;
    display: flex;
    align-items: center;
    justify-content: center;
    .amount {
        color: #24243b;
        font-weight: 400;
        font-size: 45px;
        display: flex;
    }
    .peruser {
        text-align: left;
        margin-left: 12px;
        font-weight: 300;
        letter-spacing: 0.8px;
        font-size: 16px;
    }
    .dollar-sign {
        font-size: 18px;
        font-weight: 300;
        margin-top: 11px;
        margin-right: 5px;
    }
}

.plan-features {
    display: flex;
    flex-direction: column;
    text-align: left;
    i {
        color: #2ecc71;
        margin-right: 15px;
        font-size: 13px;
    }
    span {
        color: #888d92;
        font-weight: 300;
        font-size: 15px;
    }

    .plan-feature-row {
        margin: 7px;
        letter-spacing: 0.8px;
    }
}

.princing-plan-card.pro {
    border: 2px solid $primary;
    .pricing-plan-name {
        // color: $primary;
    }
}

.princing-faq {
    display: flex;
    justify-content: space-between;
    text-align: left;

    .faq-item {
        max-width: 470px;
        margin-bottom: 40px;
    }

    .question {
        font-weight: 600;
        font-size: 18px;
        margin-bottom: 14px;
    }
    .answer {
        letter-spacing: 0.5px;
        font-size: 17px;
        font-weight: 400;
        color: #6c7c93;
        line-height: 29px;
    }
}

.pricing-add-on {
    border: 2px solid #e8ebee;
    border-radius: 24px;
    padding: 40px;
    text-align: left;

    img {
        width: 50px;
        height: 50px;
        // border-radius: 50%;
    }
    .top {
        display: flex;
        align-items: center;
        justify-content: space-between;
        .left {
            display: flex;
            align-items: center;

            i {
                font-size: 40px;
            }
        }
        .title {
            font-size: 24px;
            font-weight: 600;
            color: #4d4f51;
            margin-left: 20px;
        }
    }
    .bottom {
        max-width: 500px;
    }
    .add-on-description {
        letter-spacing: 0.5px;
        font-size: 17px;
        font-weight: 300;
        color: #6c7c93;
        line-height: 29px;
    }
}
