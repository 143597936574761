.landing-space {
    font-family: 'Be Vietnam Pro', sans-serif;

    .midcol {
        max-width: 1130px;
    }
    overflow: hidden;
}

.landing-header {
    position: fixed;
    top: 0;
    left: 0;
    padding: 22px 0 16px;
    width: 100%;
    border-bottom: 1px solid $primary;
    // background-color: white;
    background-color: $primary;
    z-index: 999;
    transition: border-bottom-color 0.3s cubic-bezier(0, 0, 0, 1);
    transition: background 0.1s;
    img {
        height: 35px;
    }
}

.landing-logo {
    background-image: url(../assets/images/kimochat-logo.png);
    // width: 91px;
    // height: 27px;
    min-width: 50px;
    height: 42px;
    background-repeat: no-repeat;
    background-position: left center;
    background-size: contain;
}

.landing-header.sticky .landing-logo,
.login .landing-logo {
    background-image: url(../assets/images/kimochat-logo.png);
}

.login .landing-logo {
    margin: 18px;
    background-size: contain;
    background-image: url(../assets/images/kimochat-logo.png);
    width: 50px;
    height: 40px;
    position: fixed;
    filter: grayscale(1);
    opacity: 0.5;
    // height: 27px;
}

.landing-header .midcol {
    display: flex;
    align-items: center;
    justify-content: space-between;
    color: white;
    a {
        color: white;
        border-color: white;
        font-weight: 500;
        font-size: 18px;
        margin-left: 40px;
        display: flex;
        align-items: center;
    }
    h1 {
        margin: 0;
        font-size: 32px;
    }
    .left {
        display: flex;
        align-items: flex-end;
    }
    .right {
        display: flex;
    }
    // .landing-header-navigation {
    //     a:first-child {
    //         margin-left: 60px;
    //     }
    // }
}
.landing-header { 
    &.sticky {
        border-bottom: 1px solid #ebebeb;
        background-color: white;
        a {
            color: #2a2a2a;
        }
        .landing-register-btn {
            background-color: $primary;
            color: white;
            box-shadow: 0 0 0 1px rgba(0, 0, 0, 0.02), 0 1px 5px 0 rgba(100, 136, 204, 0.06),
                0 4px 15px 0 rgba(0, 0, 0, 0.07);
            border: 1px solid $primary-hover;
            &:hover {
                color: white !important;
                background-color: $primary-hover;
            }
        }
    }
}

.landing-top {
    height: 580px;
    background-color: $primary;
    // background: linear-gradient(135deg, #0079bf, #5067c5);
    display: flex;
    align-items: center;
    justify-content: center;

    .midcol {
        display: flex;
        align-items: flex-start;
        justify-content: center;
    }

    .top {
        color: #fff;
        // width: 750px;
        // margin-right: 100px;
        text-align: center;
        width: 100%;
        margin-top: 210px;
        .slogan {
            // font-size: 45px;
            // font-weight: 600;
            // height: 200px;
            font-size: 45px;
            font-weight: 700;
            line-height: 1.2;
            margin-bottom: 30px;
            letter-spacing: -1px;
            // height: 225px;
            // min-height: 125px;
            // margin-top: -170px;
        }
        .app-description {
            // margin-top: 30px;
            font-size: 16px;
            line-height: 2;
            max-width: 530px;
            margin: 60px auto 0;
        }
        .emoji {
            width: 55px;
            height: 40px;
            margin-bottom: -4px;
            display: inline-block;
            background: url("https://emojipedia-us.s3.dualstack.us-west-1.amazonaws.com/thumbs/160/emojidex/112/flag-for-brazil_1f1e7-1f1f7.png")
                no-repeat center;
            background-size: 100%;
        }
        img {
          max-width: 1050px;
        }
    }

    .landing-typist {
        display: inline;
    }
}

.bg-secondary {
    background-color: $secondary;
}

.flex-wrapper {
    display: flex;
    justify-content: space-between;
    padding: 80px 0;
    gap: 32px;
}

.landing-space {
    .btn {
        font-size: 16px;
        padding: 17px 34px;
        font-weight: 600;
        border: none;
        border-radius: 45px;
        margin-left: -50px;
        letter-spacing: 0.5px;
        &:hover {
            border: none;
            background-color: $primary-button-hover;
        }
    }
}
.register-email-space {
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 20px;
    .btn {
        background-color: $primary-dark;
        color: white;
    }
    .input-space {
        flex: 0;
        border-radius: 50px;
    }
    .kinput,
    .kinput input {
        min-height: 52px;
        height: 52px;
        background-color: #fff;
        font-weight: 300;
        font-family: sans-serif;
        &:focus-within {
            background-color: #fff;
            box-shadow: none !important;
        }
    }
    .kinput {
        width: 480px;
        border-color: #ccc;
        border-radius: 50px;
    }
    .kinput input {
        padding-top: 2px;
        padding-left: 18px;
        &::placeholder {
            color: rgba(0, 0, 0, 0.4);
        }
    }
}

.mid-text {
    font-size: 32px;
    letter-spacing: -1px;
}

.small-text {
    font-size: 18px;
    letter-spacing: -1px;
  }

.app-screen-main {
    display: flex;
    justify-content: center;
    img {
        width: 900px;
        height: 600px;
        box-shadow: 0 0 40px 0 rgba(0, 0, 0, 0.1);
    }
}

.landing-section {
    padding: 90px 0 0;
    text-align: center;
    align-items: center;

    h2 {
        font-size: 40px;
        font-weight: 600;
        margin-bottom: 7px;
        line-height: 50px;
        max-width: 960px;
    }

    h3 {
        font-size: 16.7px;
        line-height: 31px;
        font-weight: 400;
        max-width: 900px;
        color: #6c7c93;
        margin-top: 10px;
    }
    .center {
        display: flex;
        flex-direction: column;
        align-items: center;
    }
    img {
        object-fit: cover;
    }
}

.bottom-offset {
  margin-bottom: 80px;
}

.landing-channels {
    display: flex;
    align-items: center;
    margin-top: 40px;
    flex-wrap: wrap;
}

.landing-channel {
    background-color: #00bf54;
    border-radius: 50%;
    width: 60px;
    height: 60px;
    box-sizing: border-box;
    color: white;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: 8px;
    i {
        font-size: 28px;
        transition: transform 0.1s;
    }
    img {
        width: 60px;
        height: 60px;
        border-radius: 12px;
    }
    .landing-channel-description {
        margin-top: 10px;
        font-size: 16px;
    }
    &:hover i {
        transform: scale(1.2);
    }
}

.landing-section.sideImage {
    display: flex;
    &.short .midcol {
        display: flex;
        align-items: center;
        justify-content: center;
    }
    // img {
    //     height: 581px;
    //     box-shadow: 6px 30px 30px rgba(23, 43, 77, 0.1);
    // }
    // .left {
    //     margin-left: -130px;
    // }
}

.landing-section .info {
    margin-left: 150px;
    text-align: left;
    // margin-top: -170px;
    width: 450px;
}

.landing-section.sideImageRight {
    display: flex;
    justify-content: center;
    // img {
    //     height: 581px;
    //     box-shadow: 6px 30px 30px rgba(23, 43, 77, 0.1);
    // }
    .left {
        // margin-left: -130px;
    }
    .info {
        margin-left: 0;
        margin-right: 150px;
    }
}

.landing-space {
    .footer {
        background-color: $secondary;
        font-size: 16px;
        padding: 70px 20px 40px;
        a {
            color: #1B1A1A;
            &:hover {
                color: black !important;
            }
        }
        .midcol {
            display: flex;
            justify-content: space-evenly;
        }
        img {
            // height: 36px;
            width: 120px;
        }
        .col {
            display: flex;
            flex-direction: column;
            width: 230px;
            text-align: left;
        }
        .col-item {
            margin-bottom: 22px;
            &:hover {
                color: $primary;
            }
        }
        .col-title {
            color: rgb(51, 51, 51);
            font-weight: 700;
            font-size: 16px;
            margin-bottom: 32px;
        }
        .fab {
            opacity: 0.5;
            font-size: 22px;
        }
        strong {
            color: rgb(51, 51, 51);
        }
        .address {
            display: flex;
            flex-direction: column;
            line-height: 22px;
            font-weight: 300;
        }
        .rodape {
            font-size: 14px;
            text-align: center;
            margin-top: 60px;
        }
    }
}

.landing-login-btn {
    color: #333;
    font-size: 16px;
    font-weight: 400;
    margin-right: 20px;
    // border: 1px solid #333;
    padding: 6px 24px;
    border-radius: 35px;
    &:hover {
        color: #595ad4;
        border-color: #595ad4;
        // text-decoration: underline;
    }
}

.landing-register-btn {
    font-size: 18px;
    font-weight: 500;
    border: 1px solid #333;

    // background-color: white;
    // color: #0079bf;
    border-radius: 50px;
    padding: 6px 20px;
    margin-left: 5px !important;
    &:hover {
        background-color: white;
        color: $primary !important;
    }
}

.auth-header {
    width: 100vw;
    background-color: #000;
    height: 70px;
    border: none;
    left: 0;
    top: 0;
    position: fixed;
    a {
        display: none;
    }
}

.page.login {
    background-color: #fff;
}

.login-box {
    background-color: #fff;
}

.login-page-inner {
    padding: 0;
    height: 100%;
    justify-content: center;
}

// .login-box {
//     box-shadow: none;
//     border: none;
// }

.landing-space {
    .companies .company {
        opacity: 0.5;
        filter: grayscale(100%);
        filter: gray;
        transition: all 0.6s ease;
        width: 160px;
        margin-right: 20px;
        margin: 20px 20px 20px 0;
        &:hover {
            filter: grayscale(0%);
            opacity: 1;
        }
    }
}

.arrow-lottie {
    position: absolute;
    right: 181px;
    bottom: -58px;
    transform: rotate(-25deg);
}

.left.integrations {
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    flex: 1;
}

.integration-circle {
    width: 65px;
    height: 65px;
    border-radius: 50%;
    padding: 24px;
    /* box-shadow: 0 4px 14px 0 rgba(0, 0, 0, 0.15); */
    background-color: rgb(249, 249, 249);
    display: flex;
    align-items: center;
    justify-content: center;
    img {
        width: 100%;
        height: 100%;
        object-fit: contain;
    }
}

.integration-channel-landing {
    box-shadow: 0 2px 16px 0 rgba(0, 0, 0, 0.2);
    margin: 16px;
    width: 20%;
    display: flex;
    justify-content: center;
    align-items: center;
    img {
        object-fit: contain;
        width: calc(100% - 20px);
        height: 110px;
    }
}

.landing-integrations {
    display: flex;
    align-items: center;
    justify-content: center;
}

.arrow-lottie.arrow-footer {
    position: relative;
    transform: rotate(-97deg);
    right: 0px;
    bottom: 21px;
}

.test {
    // background-color: rgba(0, 0, 0, 0.1);
    // /* background-color: #00ac39; */
    border-radius: 4px;
    // padding-right: 5px;
    // text-shadow: 2px 2px #1b5095;
    color: white !important;
    /* color: #00ac39!important; */
}

.Cursor {
    font-weight: 300;
    // animation: blink2 1s linear infinite !important;
    animation: blink3 0.8s linear infinite !important;
    // transition: opacity 0.5s ease;
}

@keyframes blink3 {
    0% {
        opacity: 0;
    }
    50% {
        opacity: 1;
    }
    100% {
        opacity: 0;
    }
}
@-webkit-keyframes blink {
    0% {
        opacity: 0;
    }
    50% {
        opacity: 1;
    }
    100% {
        opacity: 0;
    }
}
.blink {
    -webkit-animation: blink3 1s linear infinite;
    -moz-animation: blink3 1s linear infinite;
    animation: blink3 1s linear infinite;
}

.landing-channel-space {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin: 0px 40px;
}

.landing-screenshot-space {
    display: flex;

    img {
        width: 100%;
    }

    .left {
        display: flex;
        align-items: center;
        background-color: $secondary;
        padding: 16px;
    }

    .right {
        .screens-list {
            display: flex;
            flex-direction: column;
            justify-content: space-between;
            height: 100%;
        }
    }
}
.landing-screen-button {
    display: flex;
    flex: 1;
    align-items: center;
    justify-content: center;
    color: #1d1d1d;
    background: 0 0;
    text-decoration: none;
    border-radius: 4px;
    border: none;
    padding: 16px;
    width: 230px;
    font-size: 18px;
    letter-spacing: -0.5px;

    &.active {
        transition: all 420ms cubic-bezier(0.165, 0.84, 0.44, 1);
        outline: 0;
        background-color: $secondary;
        font-weight: 700;
        border-radius: 0px 20px 20px 0px;
    }
}

.landing-button-connect {
    border-radius: 4px;
    padding: 18px 26px;
    border: 1px solid $primary;
    color: $primary;
    margin-top: 35px;
    width: 370px;
    font-weight: 500;
    box-sizing: border-box;
    &:hover {
        color: white;
        background-color: $primary;
    }
    &.register-btn {
        background-color: white;
        font-weight: 600;
        font-size: 18px;
        color: $primary;
        transition: transform 0.05s;
        margin-top: 25px;
        margin-bottom: 15px;
        &:hover {
            transform: scale(1.1);
        }
    }
}

.landing-features-section {
    display: flex;
    flex-direction: column;
    justify-content: center;
    width: 100%;
    .row {
        display: flex;
        justify-content: space-between;
    }
}

.landing-feature-card {
    display: flex;
    flex-direction: column;
    text-align: left;
    width: 300px;
    height: 264px;
    margin: 20px 0;
    padding: 32px 19px 16px;
    border-radius: 20px;
    background-color: #fff;
    box-sizing: border-box;
    box-shadow: 0px 0px 100px -5px rgba(0, 0, 0, 0.1);

    img {
        // color: rgb(108, 124, 158);
        max-width: 39px;
        max-height: 39px;
        margin-bottom: 25px;
        color: $primary;
    }
    .name {
        font-size: 18px;
        font-weight: 700;
        margin-bottom: 10px;
        color: #2a2a2a;
    }
    .description {
        line-height: 24px;
        font-size: 16px;
    }
}

.features-list {
    text-align: left;
    font-size: 18px;
    display: flex;
    flex-direction: column;
    gap: 20px;
    margin-top: 20px;

    li {
        list-style: disc;

        span {
            font-size: 14px;
        }
    }
}

.landing-section.companies {
    .center {
        display: flex;
        flex-direction: row;
        justify-content: center;

        .left {
            text-align: left;
            margin-right: 100px;
        }
    }

    .companies {
        background-color: #edf1ff;
        margin-top: -40px;
        border-radius: 4px;
        padding-left: 60px;
        padding-right: 60px;
        .row {
            display: flex;
        }
    }
}

.landing-section.blue {
    background-color: $primary;
    color: white;
}

.landing-header-navigation {
    display: flex;
    margin-left: 60px;
    margin-bottom: 6px;
}

.landing-menu-hoverable {
    height: 100%;
    width: 420px;
    padding-top: 20px;
    position: absolute;
    margin-left: -30px;
    display: none;
}

.landing-drop-menu {
    // display: flex;
    display: none;
    opacity: 0;
    box-shadow: 0 14px 74px 0 rgba(0, 0, 0, 0.25);
    background: #fff;
    border-radius: 5px;
    // position: absolute;
    animation-name: fadeInUp;
    animation-duration: 0.3s;
    animation-fill-mode: both;
    -webkit-animation-duration: 0.3s;
    -webkit-animation-fill-mode: both;
    color: #4e5e71;
    padding: 30px;
    width: 420px;
    // margin-left: -30px;
    box-sizing: border-box;

    a {
        display: flex;
        align-items: flex-start;
        margin: 0 0 28px;
        &:last-child {
            margin-bottom: 0;
        }
    }

    .info {
        display: flex;
        flex-direction: column;
        color: #4e5e71;
        margin-top: -3px;
    }

    .title {
        font-weight: 700;
        font-size: 17px;
    }

    .description {
        font-weight: 300;
        font-size: 15px;
    }

    i {
        width: 40px;
        height: 40px;
        margin-right: 20px;
        border-radius: 50%;
        display: flex;
        align-items: center;
        justify-content: center;
        min-width: 40px;
        color: white;
        transition: transform 0.1s;
        font-size: 20px;
    }
    a:hover i {
        transform: scale(1.1);
    }
    .landing-arrow {
        position: absolute;
        top: 4px;
        left: 102px;
        &:before {
            border-bottom: 15px solid #fff;
            bottom: 1px;
            z-index: 1;
            content: "";
            border-left: 14px solid transparent;
            border-right: 14px solid transparent;
            width: 0;
            height: 12px;
            position: absolute;
            right: 0;
        }
        &:after {
            border-bottom: 15px solid #fff;
            bottom: 0;
            z-index: 2;
        }
    }
}

.landing-drop {
    position: relative;
    a {
        margin-left: 0 !important;
    }
    &:hover {
        .landing-menu-hoverable {
            display: block;
        }
    }
    &:hover,
    .landing-menu-hoverable:hover {
        .landing-drop-menu {
            display: flex;
            flex-direction: column;
        }
    }
}

@keyframes fadeInUp {
    from {
        transform: translate3d(0, 40px, 0);
    }

    to {
        transform: translate3d(0, 0, 0);
        opacity: 1;
    }
}

@-webkit-keyframes fadeInUp {
    from {
        transform: translate3d(0, 40px, 0);
    }

    to {
        transform: translate3d(0, 0, 0);
        opacity: 1;
    }
}

.register-box {
    .text-space {
        margin-bottom: 27px;
    }
    .label-input {
        letter-spacing: 0.3px;
    }
}

.terms-section {
    text-align: left;
    font-family: Whitney, sans-serif;
    p {
        font-weight: 300;
        font-size: 18px;
        margin: 20px 0;
        letter-spacing: 0.3px;
        line-height: 30px;
    }
    h2 {
        font-size: 20px;
        font-weight: 700;
    }
}

.landing-separator {
    height: 1px;
    background-color: #dae3f0;
    width: 100%;
}

.wpp-inbox {
    width: auto;
    max-width: 100%;
}

.base-platform-wrapper {
    h1 {
        font-size: 45px;
        margin-bottom: 10px;
    }

    .wrapper {
        p {
            font-size: 20px;
            margin: 67px 0px;
        }

        img {
            width: 150px;
        }
    }
}

.green-box {
    background-image: url("https://i.imgur.com/BqQw2Ov.png");
    background-repeat: repeat;
    background-color: #075e54;
    max-width: 100% !important;
    height: 250px;
}

.insta-box {
    height: 250px;
    width: 100%;
    background: linear-gradient(
        45deg,
        #f09433 0%,
        #e6683c 25%,
        #dc2743 50%,
        #cc2366 75%,
        #bc1888 100%
    );
    max-width: 100% !important;
}

.blue-box {
    background-image: url("https://image.shutterstock.com/image-vector/social-media-minimalist-seamless-pattern-260nw-1169160307.jpg");
    background-color: #0084ff;
    max-width: 100% !important;
    height: 250px;
}

.landing-section .green-box {
    margin-top: 60px;
    h2 {
        color: white;
    }

    p {
        color: white;
        font-size: 18px;
    }
}

.sticky .menu-btn {
    color: black;
    margin: 0px 18px;
    font-size: 25px;
}

.menu-btn {
    color: white;
    margin: 0px 18px;
    font-size: 25px;
}

header {
    .wrapper {
        display: flex;
        justify-content: space-between;
    }

    .landing-header-navigation {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        margin-left: 0;
        a:nth-last-child(1) {
            border: 1px solid white;
            border-radius: 100px;
        }
        a {
            color: white;
            font-size: 20px;
            width: 90%;
            // border-bottom: 1px solid white;
            text-align: center;
            padding: 10px 0px;
        }
        .landing-drop {
            width: 100%;
            // border-bottom: 1px solid white;
            text-align: center;
            a {
                // color: white;
                font-size: 20px;
            }
        }
    }

    .landing-menu-hoverable {
        height: 100%;
        padding-top: 20px;
        position: absolute;
        margin-left: 13px;
        display: none;
        .landing-drop-menu {
            width: 350px;
            .landing-arrow {
                left: 186px;
            }
        }
        .landing-arrow::before {
            right: 0px;
        }
    }
}

.landing-header.sticky {
    header {
        .landing-header-navigation {
            .round {
                border: 1px solid #3780ee;
                background-color: #3780ee;
                color: #fff;
                border-radius: 100px;
            }
        }
    }
}

.menu-shadow {
    box-shadow: 0 1px 1px 0 rgba(0, 0, 0, 0.14), 0 2px 1px -1px rgba(0, 0, 0, 0.12),
        0 1px 3px 0 rgba(0, 0, 0, 0.2);
}

// MEDIA RULES

@media only screen and (max-width: 1200px) {
    .bottom-offset {
        margin-bottom: 60px;
    }

    .landing-section {
        padding: 70px 0 0;
    }

    .mid-text {
        font-size: 20px;
        letter-spacing: -1px;
    }

    .small-text {
        font-size: 16px;
        letter-spacing: -1px;
    }

    .register-email-space {
        flex-direction: column;

        .kinput {
            width: 345px;
        }

        .btn {
            margin: auto;
            margin-top: 30px;
        }
    }

    .features-list {
        font-size: 16px;
        gap: 12px;
        max-width: 270px;
        margin-left: 32px;
    }

    .flex-wrapper {
        flex-direction: column;
        padding: 60px 0;
    }

    .landing-screen-button {
        &.active {
            border-radius: 10px;
        }
    }

    header {
        .landing-header-navigation {
            a {
                font-size: 16px;
            }
            .landing-drop {
                a {
                    font-size: 16px;
                }
            }
        }
    }

    .base-platform-wrapper {
        h1 {
            font-size: 30px;
            margin-top: 30px;
        }

        .wrapper {
            p {
                font-size: 16px;
                margin: 40px 0px;
            }
        
            img {
                width: 100px;
            }
        }
    }

    .landing-section .green-box {
        margin-top: 0;
    }

    .landing-channel-space {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        margin: 24px 26px;
        flex: 1;
    }

    .landing-button-connect {
        width: 290px;
    }

    .landing-features-section {
        .row {
            flex-wrap: wrap;
            justify-content: center;
        }
    }
    .landing-screenshot-space {
        display: flex;
        flex-direction: column-reverse;

        img {
            width: 100%;
        }

        .left {
            padding: 10px;
            margin-top: 20px;
        }

        .right {
            margin-left: 0;
            .screens-list {
                display: flex;
                flex-direction: column;
                justify-content: center;
                align-items: center;
                .landing-screen-button {
                    width: 300px;
                }
            }
        }
    }

    .landing-space {
        .footer {
            padding: 60px 0 40px;

            .midcol {
                justify-content: start;
                flex-wrap: wrap;
            }
            .col-title {
                margin-top: 28px;
                margin-bottom: 20px;
            }
            .col-item {
                font-size: 15px;
                margin-bottom: 16px;
            }
            .col-text {
                font-size: 15px;
            }
            .rodape {
                font-size: 12px;
                margin-top: 30px;
            }
        }
    }

    .wpp-inbox {
        width: 100%;
        height: 100%;
    }

    .landing-top {
        height: 375px;
    }

    .landing-top .top {
        margin-top: 120px;

        .slogan {
            font-size: 30px;
        }

        img {
          max-width: 100%;
        }
    }

    .pricing-plans {
        flex-wrap: wrap;
        .princing-plan-card {
            flex: 1;

            &:not(:first-of-type) {
                margin-top: 40px;
            }
        }
    }

    .princing-plan-card.pro {
        margin-top: 40px;
    }

    .pricing-add-on {
        .top {
            flex-wrap: wrap;
        }
    }

    .princing-faq {
        flex-wrap: wrap;
    }
    .landing-feature-card {
        width: 290px;
        margin: 30px 12px;

        .name {
            font-size: 16px;
        }
        .description {
            line-height: 20px;
            font-size: 14px;
        }
    }
}
