.box {
    margin-bottom: 15px;
    box-sizing: border-box;
    border: 1px solid #e8e8e8;
    box-shadow: 0 1px 2px 0 rgba(0,0,0,.06);
    border-radius: 12px;
    padding: 23px 28px;

    p {
        font-size: 14px; 
    }

    .title {
        margin: 0 0 20px;
        font-size: 14px;
        font-weight: 700;
        border-bottom: 1px dotted #CCC;
        padding-bottom: 12px;
        text-transform: uppercase;
    }
    &.grey {
        // background-color: #F8F9FB;
        background-color: #f9fafa;
    }
    &.fullheight {
        height: 100%;
        margin-bottom: 0;
    }
    &.nopad {
        padding: 0;
    }
}
.box-actions {
    margin-top: 35px;
}

// .box {
//     position: relative;
//     margin: 40px 0;
//     border-radius: 5px;
//     // background-color: white;
//     color: #2A2A2A;
//     width: 100%;
//     min-height: 100px;
//     text-align: left;
// }

.box-space {
    // padding: 0px 30px 30px 30px;
    height: 100%;
}

// .grey .box-space {
//     background-color: #eae8e2 !important;
//     color: #2A2A2A !important;
// }

// .colored h4 {
//     margin-bottom: 0;
// }

// .box-header {
//     // padding: 30px;
//     padding-bottom: 15px;
//     // border-radius: 5px 5px 0 0;
// }

// .colored .box-header {
//     padding: 18px 30px;
//     background-color: #abd733;
//     color: white;
//     margin-bottom: 40px;
//     display: flex;
//     justify-content: space-between;
//     align-items: center;
// }

// .box-header-tab {
//     text-transform: capitalize;
//     font-size: 16px;
//     color: #FFFFFF;
//     padding: 11px 15px;
//     transition: background 400ms cubic-bezier(0.25, 0.46, 0.45, 0.94) 0ms,border-color 400ms cubic-bezier(0.25, 0.46, 0.45, 0.94) 0ms,color 400ms cubic-bezier(0.25, 0.46, 0.45, 0.94) 0ms,box-shadow 400ms cubic-bezier(0.25, 0.46, 0.45, 0.94) 0ms;
//     font-weight: 700;
//     text-align: center;
//     white-space: nowrap;
//     border-radius: 3px;
//     margin: 0 9px 0 0;

//     &:hover {
//         background-color: #91B827;
//         border-color: #91B827;
//         cursor: pointer;
//         color: #FFFFFF;
//     }

//     &.active {
//         background-color: #75971A;
//         border-color: #75971A;
//     }
// }

// .box.table {
//     .box-space {
//         padding: 0;
//     }
//     .box-header {
//         display: none;
//     }
// }

