.auth-header img {
    // height: 60px;
    height: 37px;
    margin-top: -11px;
}

.monster-wrap {
    display: flex;
    flex-direction: column;
}

.monster-row {
    display: flex;
    img {
        max-height: 150px;
        max-width: 80px;
        display: block;
    }
}