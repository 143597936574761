.subheader {
    width: 100%;
    background-color: #F8F9FB;
    padding: 40px 50px;
    box-shadow: 0 0 4px rgba(0,0,0,.12);
    z-index: 4;
    position: relative;
    padding: 20px;
    box-sizing: border-box;
    display: flex;
    align-items: center;
}