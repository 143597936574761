.collapse {
    border: 1px solid rgba(0, 0, 0, 0.3);

    .top {
        background-color: rgba(0, 0, 0, 0.05);
    }

    .top button {
        padding: 0px 10px;
        height: 30px;
        font-size: 14px;
        display: flex;
        align-items: center;
        justify-content: space-between;
        width: 100%;
    }
    & > section {
        padding: 15px 25px 25px;
    }
}

.collapse.disabled {
    border-color: #f3f2f1;
    button.title {
        background-color: #f8f8f8;
        color: rgba(33, 33, 33, 0.6);
    }
}
