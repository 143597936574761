.facebook-page {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 12px;
    border: 1px solid rgba(0,0,0,0.1);
    padding: 10px 20px;
    border-radius: 8px;
    width: 100%;
    .left {
        display: flex;
        align-items: center;
    }
    img {
        width: 40px;
        height: 40px;
        border-radius: 50%;
        margin-right: 30px;
    }
    .page-name {
        font-size: 15px;
    }
    &:hover {
        border-color: #cce2ed;
        box-shadow: 0 4px 14px 0 rgba(5, 73, 209, 0.1);
    }
}
