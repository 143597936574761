.campaign-type {
    margin-top: 10px;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 50px 15px;
    border-radius: 4px;
    margin-right: 10px;
    border: 1px solid rgba(0, 0, 0, 0.1);
    background: hsla(0, 0%, 100%, 0.75);
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.08);
    flex: 1;
    img {
        width: 40px;
        height: 40px;
        // margin-right: 20px;
        transition: all 0.15s cubic-bezier(0, 0, 0, 1);
    }
    span {
        transition: all 0.15s cubic-bezier(0, 0, 0, 1);
    }
    .name {
        font-size: 17px;
        font-weight: 700;
        margin: 20px 0 10px;
    }
    .description {
        font-size: 14px;
        font-weight: 400;
        color: #737f8d;
        text-align: center;
    }
    &:hover {
        border-color: $primary;
        // span {
        //     color: $primary;
        // }
        img,
        span {
            transform: translateY(-10px);
        }
    }
}

.campaign-icon {
    margin-right: 15px;
    font-size: 18px;
}
