.channels-list {
    margin: 0 auto;
    max-width: 600px;
    width: 100%;
    display: block;
    .channel-title {
        display: flex;
        align-items: baseline;
        gap: 10px;
        justify-content: space-between;
    }
    .channel {
        display: flex;
        padding: 20px 15px;
        border-top: 1px solid #e7e9ec;
        img {
            width: 34px;
            height: 34px;
            object-fit: cover;
        }
        i {
            color: #cfcfcf;
            font-size: 28px;
        }
        .info {
            margin: 0px auto 0 13px;
            line-height: 20px;
            width: 100%;
            .name {
                font-size: 17px;
                margin-bottom: 10px;
                // font-weight: 500;
            }
            .description {
                color: #9b9c9e;
            }
        }
        .right {
            // display: none;
            align-self: center;
        }

        &:hover, &:hover ~ & {
            border-radius: 6px;
            background-color: #f3f4f5;
            border-color: transparent;
        }
        &:first-child {
            border-color: transparent;
        }
    }
}

.channel:hover ~ .channel {
    border-color: transparent;
}

.channel-list-items {
    height: 100%;
}

.channel-list-items .channel-icon {
    background-color: rgba(0,0,0,0.05);
    border-radius: 50%;
    color: rgb(35, 86, 179);
    width: 30px;
    height: 30px;
    font-size: 18px;
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
    &:before {
        z-index: 1;
    }
}

.channel-list-items .cell-avatar {
    border-radius: 0;
    width: 30px;
    height: 30px;
    background: transparent !important;
}

// .list-separator {
//     width: 100%;
//     height: 1px;
//     background-color: #e7e9ec;
// }

.channel-page {
    .modal-body {
        padding: 10px 32px;
    }
    .modal-footer {
        margin-left: 32px;
        padding-left: 0;
        margin-top: 15px;
        // padding-left: 32px;
        flex-direction: row-reverse;
        max-width: 700px;
        button {
            margin-right: 15px;
        }
        .menu {
            left: 0;
        }
    }
}

.form-widget {
  position: relative;
  max-width: 460px;
}

.form-widget-offset {
  padding-bottom: 80px;
}

.color-picker-wrapper {
  position: absolute;
  right: -240px;
  top: 30px;
}