.quick-phrases-space {
    border-radius: 2px;
    background-color: #fff;
    // background-color: #f4f5f7;
    box-shadow: 0 0 1px rgba(8, 22, 109, 0.7), 0 7px 17px -5px rgba(34, 39, 64, 0.25);
    padding: 20px 20px;;
    display: flex;
    height: 220px;
    margin-bottom: 10px;

    .left {
        width: 160px;
        display: flex;
        flex-direction: column;
        .quick-phrases-item {
            width: 100px;
        }
    }
    .right {
        flex: 1;
        display: flex;
        flex-direction: column;
        padding-top: 47px;
        .quick-phrases-item {
            display: flex;
            align-items: center;
            justify-content: space-between;
        }
    }

    .quick-phrases-item {
        padding: 7px 15px;
        border-radius: 30px;
        margin-bottom: 2px;
        &:hover {
            background-color: #f6f6f6;
        }
    }
}

.quick-phrase-code {
    font-weight: 600;
}

.quick-phrase-phrase {
    margin-left: 6px;
    max-width: 100%;
    color: rgb(155, 156, 158);
    line-height: 20px;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
}

.quick-phrase-phrase-space {
    display: flex;
}