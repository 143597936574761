.webcam {
    img {
        object-fit: cover;
        box-sizing: border-box;
        display: block;
    }
    video {
        display: block;
    }
}
.no-webcam {
    border: 2px dashed rgba(0,0,0,0.2);
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    box-sizing: border-box;
    // margin-bottom: 10px;
    img {
        width: 60px;
        height: 60px;
        margin-bottom: 10px;
    }
    font-size: 14px;
    opacity: 0.7;
    text-transform: uppercase;
}

.webcam-actions {
    display: flex;
    justify-content: space-between;
    margin-top: 10px;
}