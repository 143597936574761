.tab-header {
    // padding: 0 23px;
    box-sizing: border-box;
    // background-color: #f9f9f9;
    // width: 100%;
    // border-radius: 4px;
    // margin-top: 20px;

    .conve-filter-trigger {
        margin: 0;
        padding: 0;
        height: 40px;
        border-radius: 0;
        font-weight: 400;
        i {
            font-size: 17px;
            color: $primary;
            margin: 0 8px;
        }
    }
    
    .row {
        // height: 80px;
        // padding: 14px;
        display: flex;
        align-items: center;
    }

    .midcol {
        display: flex;
        align-items: center;
        height: 100%;
    }

    // a {
    //     display: flex;
    //     flex-direction: column;
    //     justify-content: center;
    //     align-items: center;
    //     font-weight: 500;
    //     font-size: 15px;
    //     width: 100px;
    //     height: 80px;
    //     border-radius: 4px;
    //     &.active {
    //         background-color: #f6f6f6;
    //     }
    // }

    // i {
    //     font-size: 30px;
    //     color: rgba(0,0,0,0.5);
    //     margin-bottom: 10px;
    // }

    .filters-tab {
        // background-color: rgba(0, 0, 0, 0.17);
        // background-color: rgba(0,0,0,0.1);
        background-color: rgba(0, 0, 0, 0.05);
        // border-radius: 20px;
        padding: 8px 20px;
        margin: 0 5px;
        border: 0;
        border-radius: 4px;
    }
}