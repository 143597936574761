.modal-overlay {
    align-items: flex-start;
    justify-content: center;
    background-color: rgba(0, 0, 0, 0.3);
    display: none;
    height: 100%;
    width: 100%;
    left: 0;
    top: 0;
    overflow-y: auto;
    overflow-x: hidden;
    position: fixed;
    z-index: 20;
    overflow-x: hidden;
}

.modal-space.open .modal-overlay {
    display: flex;
}

.modal-box {
    // background-color: #ebeef0;
    background-color: #fff;
    border-radius: 4px;
    // margin: 70px 0 80px;
    // margin: auto;
    margin: auto 0;
    // overflow: hidden;
    position: relative;
    width: 480px;
    z-index: 25;
    box-shadow: rgba(0, 0, 0, 0.4) 0px 0px 5px 0px;

    &.side {
        min-height: 100vh;
        margin: 0 0 0 auto;
        border-radius: 0;
        width: 50vw;
    }
}

.modal-actions {
    display: flex;
    justify-content: flex-end;
    padding: 20px 25px;
    background-color: white;
    border-top: 1px solid #ececec;
}

.modal-title {
    font-size: 20px;
    font-weight: 700;
    padding: 5px 0 15px;
}

.modal-closer.btn.only-icon {
    position: absolute;
    right: 15px;
    top: 10px;
    z-index: 9;
    padding: 4px;
    width: auto;
    height: auto;
    i {
        font-size: 22px;
        width: 22px;
        height: 22px;
    }
}

.modal-overlay-clickable {
    height: 100%;
    width: 100%;
    position: fixed;
    z-index: 0;
}

.subactions {
    display: flex;
    align-items: center;
    padding: 18px;
    border: 1px dashed rgba(0, 0, 0, 0.15);
    border-radius: 5px;
    margin-bottom: 15px;
    i {
        font-size: 23px;
        margin-right: 10px;
        color: rgba(0, 0, 0, 0.5);
    }
}

.modal-header {
    display: flex;
    align-items: center;
    background-color: #f9f9f9;
    border-top-left-radius: 4px;
    border-top-right-radius: 4px;
    border-bottom: 1px solid #fafafa;
    min-height: 47px;

    .modal-header-title {
        margin: 0 0 0 25px;
        padding: 10px 0;
        font-size: 0.875rem;
        color: #202020;
        font-weight: 700;
        line-height: 1.7;
    }
}

.modal-body {
    padding: 20px 25px 25px 25px;
    // max-height: 500px;
    overflow-y: auto;
}

.modal-footer {
    display: flex;
    justify-content: flex-end;
    padding: 20px 25px;
    border-top: 1px solid #ececec;
    border-bottom-left-radius: 4px;
    border-bottom-right-radius: 4px;
}

.modal-wide {
    .modal-box {
        width: 800px;
    }
}

.multi-page-modal {
    display: flex;
    height: 560px;

    .modal-body {
        padding: 55px 40px;
    }
}

.modal-side {
    width: 300px;
    box-sizing: border-box;
    padding: 20px 25px;
    background-color: rgb(243, 242, 241);
    // height: 100%;
    h2 {
        font-size: 28px;
        font-weight: 600;
        padding-bottom: 40px;
        padding-top: 30px;
        line-height: 1.2;
    }
    p {
        font-size: 14px;
        font-weight: 400;
        margin-top: 0px;
        white-space: pre-wrap;
    }
    img {
        // width: 135px;
        height: 100px;
        margin: 50px auto 50px;
        left: 0;
        display: block;
        right: 0;
    }
}

.modal-page-indicators {
    display: flex;
    align-items: center;
    justify-content: center;
}

.mp-indicator {
    border-radius: 50%;
    background-color: rgba(0, 0, 0, 0.2);
    width: 7px;
    height: 7px;
    margin: 0 5px;
    &.active {
        width: 10px;
        height: 10px;
        background: $primary;
    }
}
