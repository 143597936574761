.panel-layout {
    // padding: 3px 30px;
}

.panel-filters {
    margin-top: 76px;
    margin-bottom: 25px;
    padding: 0 30px;
}

.panel-content:before {
    content: '';
    height: 1px;
    display: block;
    background-color: rgba(0,0,0,.1);
    margin: 5px 0 25px;
}

.panel-content {
    margin: 20px auto;
    width: 100%;
    // width: calc(100% - 20px);
    // padding: 30px;
    box-sizing: border-box;
    padding: 0 30px;
}
